import React from "react";
import { Layout } from "antd";
import "./Footer.scss";
import logoINE from "../../assets/img/logoINE_bco.svg";

export default function Footer(props) {
  const { Footer } = Layout;

  return (
    <Footer className="footer">
      {/* Le puedes asignar un class directamente al componente de ant desig  sino sería ant-layout-footer*/}
      <div className="footer__instituto">
        <img src={logoINE} alt="Logo" />
        <span dangerouslySetInnerHTML={{ __html: "&copy;" }} />
        INE México {props.anio}
        <a href="https://cau.ine.mx/" target="blank">
          CAU
        </a>
      </div>
      <div className="footer__area">
        Unidad Técnica de Servicios de Informática
      </div>
      <div className="footer__version">
        <span style={{ color: "#333333" }}>{`12.10.0 Rev.98 23/05/2022 15:40`}</span> &nbsp;&nbsp;&nbsp;&nbsp; 12.10 / {new Date().getFullYear()}
      </div>
    </Footer>
  );
}
