import { Button, DatePicker, Form, message, Row, Modal, Select, Alert } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { getFechas, sendFechas } from "../../../api/configFechas";
import useAuth from "../../../hooks/useAuth";
import { rol_captura_config_fechas, rol_cau_config_fechas } from "../../../utils/ConfigFechas/rolesConfigFechas";
import { formatDate, MSN_GUARDAR, placeholderDate } from "../../../utils/constanst";
import moment from 'moment';
import { Ast } from "../../../utils/Reports/functions";
import { Input } from "antd";

function ConfigFechas(props) {
    const { TextArea } = Input;
    const { proceso, setLoader } = props;
    const { getFieldDecorator } = props.form; //Método para validar los inputs
    const {
        user: { rolUsuario, user }, //Se obtiene el rol del usuario
    } = useAuth();
    const isMounted = useRef(true); //Referencia que permite verificar si el componente esta visible
    const [rolModulo, setRolModulo] = useState({}); //Hook para almacenar los permisos
    const [fechas, setFechas] = useState([]);
    const [character, setCharacter] = useState(4000);
    //Variable que contiene el numero maximo de caracteres
    const maxChar = 600;
    //Función para contar los caracteres restantes
    const wordCount = e => {
        const charCount = e.target.value.length;
        const charLength = maxChar - charCount;
        setCharacter(charLength);
    };
    const [muestraMotivo, setMuestraMotivo] = useState(false);
    const [refresh, setRefresh] = useState(false);
    let idProceso, idDetalle;
    //Se verica que este seleccionado un proceso electoral
    if(proceso.proceso) {
        idProceso = proceso.proceso.idProcesoElectoral;
        idDetalle = proceso.proceso.idDetalleProceso;
    }
    //useEffect que valida que el componente este seleccionado de lo contrario cancela cualquier subcripción
    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);
    //useEffect que valida el rol del usuario
    useEffect(() => {
        //Valida los permisos que tienen el usuario
        let captura = false;
        let cau = false;
        //Devuelve un true si cuenta con los permisos
        captura = rol_captura_config_fechas.includes(rolUsuario);
        cau = rol_cau_config_fechas.includes(rolUsuario);
        isMounted.current && setRolModulo(() => ({ captura, cau })); //Se almacenan en el hook
    }, [rolUsuario]);

    useEffect(() => {
        //useEffect que inicializa los valores cuando ya actualizaron alguna fecha
        props.form.setFieldsValue({
            etapa: undefined,
        });
        setFechas([]);
        setMuestraMotivo(false);
    // eslint-disable-next-line
    }, [refresh]);

    const actualizaFechas = (event) => {
        event.preventDefault();
        props.form.validateFields((err, values) => {
            if(!err) {
                values.idProceso = idProceso;
                values.idDetalle = idDetalle;
                values.fechaRegistro = moment(values.fechaRegistro).format(formatDate);
                values.limitePresencial = moment(values.limitePresencial).format(formatDate);
                values.limiteLinea = moment(values.limiteLinea).format(formatDate);
                values.limiteAcreditacion = moment(values.limiteAcreditacion).format(formatDate);
                values.limiteInforme = moment(values.limiteInforme).format(formatDate);
                values.usuario = user.sub;
                Modal.confirm({
                    title: "Se actualizará la fecha con los datos capturados.",
                    content: "¿Deseas continuar?",
                    okText: "Continuar",
                    cancelText: "Cancelar",
                    onOk() {
                        setLoader({ loading: true, message: MSN_GUARDAR }); //Se activa el loader
                        enviaFechas(values);
                    },
                });
            } else {
                message.info("Faltan datos requeridos por completar.");
            }
        });
    };

    function enviaFechas(values) {
        sendFechas(values).then((res) => {
            if(res.data.code === 200) {
                setLoader({ loading: false, message: "" }); //Se desactiva el loader
                Modal.success({
                    content: res.data.message,
                    okText: "Aceptar",
                    onOk() {
                        //Para actualizar la pantalla al no poder usar el history.push
                        setRefresh(refresh ? false : true);
                    }
                });
            } else {
                setLoader({ loading: false, message: "" }); //Se desactiva el loader
                Modal.error({
                    content: res.data.message,
                    onOk() {
                        //Para actualizar la pantalla al no poder usar el history.push
                        setRefresh(refresh ? false : true);
                    }
                });
            }
        }).catch((error) => {
            console.log(error);
            setLoader({ loading: false, message: "" }); //Se desactiva el loader
            //Para actualizar la pantalla al no poder usar el history.push
            setRefresh(refresh ? false : true);
        });
    }

    function onChangeEtapa(value) {
        setLoader({ loading: true, message: MSN_GUARDAR }); //Se activa el loader
        getFechas(idProceso, idDetalle, value).then((res) => {
            setFechas(res.data);
            if(res.data.code === 200) {
                props.form.setFieldsValue({
                    fechaRegistro: res.data.fechaRegistro && moment(res.data.fechaRegistro, formatDate),
                    limitePresencial: res.data.limitePresencial && moment(res.data.limitePresencial, formatDate),
                    limiteLinea: res.data.limiteLinea && moment(res.data.limiteLinea, formatDate),
                    limiteAcreditacion: res.data.limiteAcreditacion && moment(res.data.limiteAcreditacion, formatDate),
                    limiteInforme: res.data.limiteInforme && moment(res.data.limiteInforme, formatDate),
                    motivo: res.data.motivo,
                });
                //Se resta el numero de caracteres
                setCharacter(maxChar - res.data.motivo.length);
                setMuestraMotivo(res.data.motivo !== "" ? true :  false);
            } else {
                setLoader({ loading: false, message: "" }); //Se desactiva el loader
                Modal.error({
                    content: res.data.message,
                });
            }
        }).catch((error) => {
            console.log(error);
            setLoader({ loading: false, message: "" }); //Se desactiva el loader
        });
        setLoader({ loading: false, message: "" }); //Se desactiva el loader
    }

    const validMotivo = [
        {
            message: "Caracteres inválidos.",
            pattern: /^[^{}\\_!"#$%&()=¡|"*'¨°|;<>-]+$/,
        },
        { required: true, message: "Dato Requerido." },
        { validateStatus: "error" },
    ];

    function onChangeFecha(date, dateString) {
        switch (props.form.getFieldValue("etapa")) {
            case 1: //Registro
                if(fechas.fechaRegistro !== dateString) {
                    setMuestraMotivo(true);
                } else if(fechas.motivo !== "") {
                    setMuestraMotivo(true);
                } else {
                    setMuestraMotivo(false);
                }
                break;
            case 2: //Capacitación presencial
                if(fechas.limitePresencial !== dateString) {
                    setMuestraMotivo(true);
                } else if(fechas.motivo !== "") {
                    setMuestraMotivo(true);
                } else {
                    setMuestraMotivo(false);
                }
                break;
            case 3: //Capacitación en línea 
                if(fechas.limiteLinea !== dateString) {
                    setMuestraMotivo(true);
                } else if(fechas.motivo !== "") {
                    setMuestraMotivo(true);
                } else {
                    setMuestraMotivo(false);
                }
                break;
            case 4: //Acreditación 
                if(fechas.limiteAcreditacion !== dateString) {
                    setMuestraMotivo(true);
                } else if(fechas.motivo !== "") {
                    setMuestraMotivo(true);
                } else {
                    setMuestraMotivo(false);
                }
                break;
            case 5: //Informes 
                if(fechas.limiteInforme !== dateString) {
                    setMuestraMotivo(true);
                } else if(fechas.motivo !== "") {
                    setMuestraMotivo(true);
                } else {
                    setMuestraMotivo(false);
                }
                break;
            default:
                console.log("Error - Etapa no contemplada");
                break;
        }
    }

    function validaBoton() {
        switch (props.form.getFieldValue("etapa")) {
            case 1: //Registro
                if(fechas.fechaRegistro === moment(props.form.getFieldValue("fechaRegistro")).format(formatDate)) {
                    return true;
                }
                break;
            case 2: //Capacitación presencial
                if(fechas.limitePresencial === moment(props.form.getFieldValue("limitePresencial")).format(formatDate)) {
                    return true;
                }
                break;
            case 3: //Capacitación en línea 
                if(fechas.limiteLinea === moment(props.form.getFieldValue("limiteLinea")).format(formatDate)) {
                    return true;
                }
                break;
            case 4: //Acreditación 
                if(fechas.limiteAcreditacion === moment(props.form.getFieldValue("limiteAcreditacion")).format(formatDate)) {
                    return true;
                }
                break;
            case 5: //Informes 
                if(fechas.limiteInforme === moment(props.form.getFieldValue("limiteInforme")).format(formatDate)) {
                    return true;
                }
                break;
            default:
                console.log("Error - Etapa no contemplada");
                break;
        }
        return false;
    }

    return (
        <div>
            <Row>
                <h1>Configuración de fechas en el sistema</h1>
            </Row>
            <Form onSubmit={actualizaFechas}>
                <Row style={{ marginTop:"20px"}}>
                    <Ast/> Etapa
                    <Form.Item>
                        {getFieldDecorator("etapa", {
                            rules: [{ required: true, message: "Dato requerido" }],
                        })(
                            <Select placeholder="Selecciona" style={{ width: 250 }} onChange={onChangeEtapa}>
                                <Select.Option value={1}>Registro</Select.Option>
                                <Select.Option value={2}>Capacitación presencial</Select.Option>
                                <Select.Option value={3}>Capacitación en línea</Select.Option>
                                <Select.Option value={4}>Acreditación</Select.Option>
                                <Select.Option value={5}>Informes</Select.Option>
                            </Select>
                        )}
                    </Form.Item>
                </Row>
                {fechas.fechaRegistro && (
                    <Row>
                        <Alert message={<p style={{ marginBottom: "0px" }}>
                            Sistema: En el módulo “Registro de ciudanía” se mostrará como fecha límite en el 
                            campo “Fecha de recepción de la solicitud”.<br/>
                            Portal: No se mostrará en la Entidad a observar, al cerrar la fecha menor al día actual.</p>}
                            type="info" showIcon />
                        <Ast/> Fecha límite para presentar la solicitud de acreditación:
                        <Form.Item>
                            {getFieldDecorator("fechaRegistro", {
                                rules: [{ required: true, message: "Dato requerido" }],
                            })(
                                <DatePicker placeholder={placeholderDate} format={formatDate} onChange={onChangeFecha}/>
                            )}
                        </Form.Item>
                    </Row>
                )}
                {fechas.limitePresencial && (
                    <Row>
                        <Alert message={<p style={{ marginBottom: "0px" }}>
                            Sistema: En el módulo “Registrar cursos” se mostrará como fecha límite en el 
                            campo “Fecha de realización”.</p>}
                            type="info" showIcon />
                        <Ast/> Fecha límite para impartir cursos de capacitación presencial:
                        <Form.Item>
                            {getFieldDecorator("limitePresencial", {
                                rules: [{ required: true, message: "Dato requerido" }],
                            })(
                                <DatePicker placeholder={placeholderDate} format={formatDate} onChange={onChangeFecha}/>
                            )}
                        </Form.Item>
                    </Row>
                )}
                {fechas.limiteLinea && (
                    <Row>
                        <Alert message={<p style={{ marginBottom: "0px" }}>
                            Portal: Fecha que tendrá disponible el ciudadano para tomar el curso en línea 
                            (se dejará de mostrar el botón “Recibir capacitación”).</p>}
                            type="info" showIcon />
                        <Ast/> Fecha límite para realizar cursos de capacitación en línea:
                        <Form.Item>
                            {getFieldDecorator("limiteLinea", {
                                rules: [{ required: true, message: "Dato requerido" }],
                            })(
                                <DatePicker placeholder={placeholderDate} format={formatDate} onChange={onChangeFecha}/>
                            )}
                        </Form.Item>
                    </Row>
                )}
                {fechas.limiteAcreditacion && (
                    <Row>
                        <Alert message={<p style={{ marginBottom: "0px" }}>
                            Sistema: En el módulo “Acreditación de solicitudes” se mostrará como fecha límite 
                            en el campo “Fecha de acreditación de solicitudes”.</p>}
                            type="info" showIcon />
                        <Ast/> Fecha límite para expedir la acreditación:
                        <Form.Item>
                            {getFieldDecorator("limiteAcreditacion", {
                                rules: [{ required: true, message: "Dato requerido" }],
                            })(
                                <DatePicker placeholder={placeholderDate} format={formatDate} onChange={onChangeFecha}/>
                            )}
                        </Form.Item>
                    </Row>
                )}
                {fechas.limiteInforme && (
                    <Row>
                        <Alert message={<p style={{ marginBottom: "0px" }}>
                            Sistema: En el módulo “Informes” se mostrará como fecha límite en el campo 
                            “Fecha elaboración del informe”.<br/>
                            Portal: Fecha que tendrá disponible el ciudadano para capturar su informe 
                            (se dejará de mostrar el botón “Presentar informe”).</p>}
                            type="info" showIcon />
                        <Ast/> Fecha límite para presentar/modificar informes:
                        <Form.Item>
                            {getFieldDecorator("limiteInforme", {
                                rules: [{ required: true, message: "Dato requerido" }],
                            })(
                                <DatePicker placeholder={placeholderDate} format={formatDate} onChange={onChangeFecha}/>
                            )}
                        </Form.Item>
                    </Row>
                )}
                {muestraMotivo && (
                    <Row>
                        <Ast/> Motivo:
                        <Form.Item>
                            {getFieldDecorator("motivo", {
                                rules: validMotivo,
                                initialValue: fechas.motivo,
                            })(
                                <TextArea rows={4} maxLength={600}
                                    style={{ width: 600 }} onChange={wordCount} />
                            )}
                            <p>Caracteres restantes: {character} </p>
                        </Form.Item>
                    </Row>
                )}
                <Row style={{ textAlign: "center" }}>
                    <Form.Item>
                        <Button type="primary" disabled={rolModulo.cau || validaBoton()}
                          htmlType="submit" onClick={() => {}}>
                              Guardar cambios
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
        </div>
    );

}

const ConfigFechasForm = Form.create()(ConfigFechas);
export default withRouter(ConfigFechasForm);
