import React from "react";
import { Button, Card, Col, message, Row } from "antd";
import { useDispatch } from "react-redux";
import { getLessons } from "../../../api/courses";
import { cleanMenu, setMenu } from "../../../redux/actions/course";
import { addEntity } from "../../../redux/actions/entity";
import Images from "./Images";
import { CAL_MIN, ACT_VALORACION, ACT_FINAL } from "../../../utils/constanst";

/**
 * Componente que renderiza las entidades para poder iniciar el curso de la entidad seleccionada
 * @param {*} array de entidades
 */
export const RenderCards = ({ entidades, idObservador, history }) => {
  const dispatch = useDispatch(); //Se declara el dispatch para ejecutar las acciones de redux

  //Función que guarda el id y el nombre de la entidad seleccionada para iniciar el curso, la información se guarda
  //en el store - Redux
  const addState = (item, e) => {
    e.preventDefault();
    const { idEntidad, entidad, leccionCurso, cal } = item; //Destructuring de la información
    let exist = startEntity(); //Valida si existe un curso iniciado
    if (exist) {
      //Cuando quiere acceder a un curso finalizado
      if (leccionCurso === ACT_FINAL && cal >= CAL_MIN) {
        redirect(idEntidad, entidad, leccionCurso);
        return;
      }
      //Accede a continuar el curso
      if (exist.idEntidad === idEntidad) {
        //console.log("Dirigiendo al curso");
        redirect(idEntidad, entidad, leccionCurso);
      } else {
        //Quiere acceder a otro curso
        message.info("Ya cuenta con un curso iniciado.");
      }
    } else {
      //Si no tiene ningún curso iniciado
      redirect(idEntidad, entidad, leccionCurso);
    }
  };

  //Función que consulta el temario de la Entidad y direcciona a la pantalla correspondiente
  const redirect = (id, entidad, leccion) => {
    //Se ejecuta la acción - Se almacena la entidad seleccionada en el state-Redux
    dispatch(addEntity(id, entidad, startCourse(leccion)));
    //Se realiza la petición para cargar el temario de acuerdo a la entidad seleccionada
    getLessons(id, idObservador)
      .then((res) => {
        if (res.data.temario.length > 0) {
          //Se almacena el temario en el state-Redux
          dispatch(setMenu(res.data.temario));
        } else {
          //Se ejecuta la acción - limpiar store-Temario para que no se quede información de otra entidad
          dispatch(cleanMenu());
        }
      })
      .catch((e) => {
        dispatch(cleanMenu()); //Se ejecuta la acción - limpiar store-Redux
        console.log(e);
      });
    //Termina la petición y dirige a la pantalla correspondiente
    if (startCourse(leccion)) {
      //Si el cursos ya esta comenzado
      history.push("/ciudadano/lecciones");
    } else {
      //Si el curso es nuevo
      history.push("/ciudadano/introduccion");
    }
  };

  //Función que indica que el curso de la entidad seleccionada ya a sido empezado
  const startCourse = (leccion) => {
    return leccion === 0 ? false : true;
  };

  //Función que muestra el texto en el componente Card de acuerdo si ya comenzo o finalizó el curso el ciudadano
  const getText = (leccion, cal, materiales) => {
    if (leccion === 0) {
      if (!materiales) {
        return "Próximamente";
      }
      return "Comenzar curso";
    }
    if (leccion > 0 && leccion < ACT_VALORACION) {
      return leccion === ACT_FINAL && cal >= CAL_MIN
        ? "Curso finalizado"
        : "Continuar curso";
    } else {
      return "Curso finalizado";
    }
  };

  //Función que bloquea el botón si el curso ya esta finalizado
  /* const setDisabled = (leccion) => {
    if (leccion >= 0 && leccion < ACT_VALORACION) {
      return false;
    } else {
      return true;
    }
  }; */

  //Función que indica si un curso ya esta iniciado y/o terminado
  const startEntity = () =>
    entidades.find(
      (entity) => entity.leccionCurso > 0 && entity.leccionCurso < ACT_FINAL
    );

  return (
    <>
      <Row gutter={24}>
        {entidades &&
          entidades.map((item, i) => (
            <Col span={8} xxl={8} xl={8} lg={8} md={8} sm={24} xs={24} key={i}>
              <div className="site-card-border-less-wrapper">
                <Card title="Curso" bordered={false} className="title">
                  <span>{item.entidad}</span>
                  <br />
                  <Images id={item.idEntidad} />
                  <Button
                    type="primary"
                    className="btn_Course"
                    onClick={(e) => {
                      addState(item, e);
                    }}
                    //disabled={setDisabled(item.leccionCurso)}
                    disabled={!item.materiales}
                  >
                    {getText(item.leccionCurso, item.cal, item.materiales)}
                  </Button>
                  {/* </Link> */}
                </Card>
              </div>
            </Col>
          ))}
      </Row>
    </>
  );
};
