import React, { useEffect, useState } from "react";
import { Row, Col, List, Avatar, message } from "antd";
import { withRouter, Link } from "react-router-dom";
import { saveAs } from "file-saver";
import { getDownload } from "../../../api/reports";
//import { IconoReporte } from "../../../assets/img/detalleSolRepo_icon.svg";
import { ReactComponent as IconoReporte} from "../../../assets/img/detalleSolRepo_icon.svg"

function Reports(props) {
  const [listaReportes, setListaReportes] = useState({
    lista: [],
    loading: false,
  });
  const {
    history: {
      location: { pathname },
    },
  } = props;
  const { proceso } = props;

  useEffect(() => {
    setListaReportes({ loading: true }); //Se habilita el componente de loading
    if (proceso.distrito !== undefined && proceso.distrito !== null) {
      if (proceso.distrito.idDistrito !== 0) {
        creaListaReportesJD();
      } else {
        creaListaReportesJL();
      }
    } else if (proceso.estado !== undefined && proceso.estado !== null) {
      if (proceso.estado.idEstado !== 0) {
        creaListaReportesJL();
      } else {
        creaListaReportesOC();
      }
    } else if (proceso.proceso !== undefined && proceso.proceso !== null) {
      creaListaReportesOC();
    } else {
      creaListaReportesPE();
    }
    //La función useEffect se ejecutará cada vez que una de estas variables cambie su valor - U
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proceso]);

  //Función que descarga el formato de la solicitud de acreditación
  const download = () => {
    getDownload()
      .then((res) => {
        if (res.data) {
          saveAs(new Blob([res.data]), "Formato.pdf");
        }
      })
      .catch((error) => {
        message.error("El archivo no se encuentra disponible.");
      });
  };

  //Funcion que crea la lista de reportes a nivel JD
  function creaListaReportesJD() {
    const listaRep = [
      {
        avatar: <Avatar icon="file-text" className="icono_reportes" />,
        reporte: "Concentrado de solicitudes",
        desc:
          "Reporte que visualiza el concentrado de solicitudes de ciudadanos para ser Observadoras/es Electorales",
        ruta: "reportes/solicitudes",
      },
      {
        avatar: <Avatar icon="team" className="icono_reportes" />,
        reporte: "Organizaciones",
        desc: "Reporte que visualiza las organizaciones registradas",
        ruta: "reportes/organizaciones",
      },
      {
        avatar: <Avatar icon="team" className="icono_reportes" />,
        reporte: "Solicitudes por Organización",
        desc: "Reporte que visualiza las solicitudes registradas por organización",
        ruta: "reportes/solicitudesOrg",
      },
      {
        avatar: <Avatar icon="notification" className="icono_reportes" />,
        reporte: "Acciones de difusión",
        desc:
          "Reporte que visualiza las acciones de difusión realizadas en el proceso",
        ruta: "reportes/acciones",
      },
      {
        avatar: <Avatar icon="team" className="icono_reportes" />,
        reporte: "Cursos",
        desc: "Reporte que visualiza los cursos y asistencia registrados",
        ruta: "reportes/cursos",
      },
      {
        avatar: <Avatar icon="edit" className="icono_reportes" />,
        reporte: (
          <p
            className="liga_reportes"
            onClick={(e) => {
              download();
            }}
          >
            Solicitud de acreditación de Observadoras/es de las actividades de la Jornada Electoral </p>
        ),
        desc: "",
        ruta: pathname,
      },
      {
        avatar: <Avatar icon={<IconoReporte />} className="icono_reportes" />,
        reporte: "Detalle de solicitudes",
        desc:
          "Detalle de solicitudes",
        ruta: "reportes/detalleSolicitudes",
      },
      {
        avatar: <Avatar icon={<IconoReporte />} className="icono_reportes" />,
        reporte: "Transparencia (Art. 74 fracción 1 inciso a)",
        desc: "Reporte de Transparencia",
        ruta: "reportes/transparencia",
      },
      {
        avatar: <Avatar icon={<IconoReporte />} className="icono_reportes" />,
        reporte: "Informes",
        desc: "Reporte que visualiza los informes registrados",
        ruta: "reportes/informes",
      },
    ];
    setListaReportes({ lista: listaRep, loading: false }); //Se almacenan en el hook
  }

  //Funcion que crea la lista de reportes a nivel JL
  function creaListaReportesJL() {
    const listaRep = [
      {
        avatar: <Avatar icon="file-text" className="icono_reportes" />,
        reporte: "Concentrado de solicitudes",
        desc:
          "Reporte que visualiza el concentrado de solicitudes de ciudadanos para ser Observadoras/es Electorales",
        ruta: "reportes/solicitudes",
      },
      {
        avatar: <Avatar icon="team" className="icono_reportes" />,
        reporte: "Organizaciones",
        desc: "Reporte que visualiza las organizaciones registradas",
        ruta: "reportes/organizaciones",
      },
      {
        avatar: <Avatar icon="team" className="icono_reportes" />,
        reporte: "Solicitudes por Organización",
        desc: "Reporte que visualiza las solicitudes registradas por organización",
        ruta: "reportes/solicitudesOrg",
      },
      {
        avatar: <Avatar icon="notification" className="icono_reportes" />,
        reporte: "Acciones de difusión",
        desc:
          "Reporte que visualiza las acciones de difusión realizadas en el proceso",
        ruta: "reportes/acciones",
      },
      {
        avatar: <Avatar icon="team" className="icono_reportes" />,
        reporte: "Cursos",
        desc: "Reporte que visualiza los cursos y asistencia registrados",
        ruta: "reportes/cursos",
      },
      {
        avatar: <Avatar icon="edit" className="icono_reportes" />,
        reporte: (
          <p
            className="liga_reportes"
            onClick={(e) => {
              download();
            }}
          >
            Solicitud de acreditación de Observadoras/es de las actividades del Proceso Electoral
          </p>
        ),
        desc: "",
        ruta: pathname,
      },
      {
        avatar: <Avatar icon={<IconoReporte />} className="icono_reportes" />,
        reporte: "Detalle de solicitudes",
        desc:
          "Detalle de solicitudes",
        ruta: "reportes/detalleSolicitudes",
      },
      {
        avatar: <Avatar icon={<IconoReporte />} className="icono_reportes" />,
        reporte: "Transparencia (Art. 74 fracción 1 inciso a)",
        desc: "Reporte de Transparencia",
        ruta: "reportes/transparencia",
      },
      {
        avatar: <Avatar icon={<IconoReporte />} className="icono_reportes" />,
        reporte: "Informes",
        desc: "Reporte que visualiza los informes registrados",
        ruta: "reportes/informes",
      },
    ];
    setListaReportes({ lista: listaRep, loading: false }); //Se almacenan en el hook
  }

  //Funcion que crea la lista de reportes a nivel OC
  function creaListaReportesOC() {
    const listaRep = [
      {
        avatar: <Avatar icon="file-text" className="icono_reportes" />,
        reporte: "Concentrado de solicitudes",
        desc:
          "Reporte que visualiza el concentrado de solicitudes de ciudadanos para ser Observadoras/es Electorales",
        ruta: "reportes/solicitudes",
      },
      {
        avatar: <Avatar icon="team" className="icono_reportes" />,
        reporte: "Organizaciones",
        desc: "Reporte que visualiza las organizaciones registradas",
        ruta: "reportes/organizaciones",
      },
      {
        avatar: <Avatar icon="team" className="icono_reportes" />,
        reporte: "Solicitudes por Organización",
        desc: "Reporte que visualiza las solicitudes registradas por organización",
        ruta: "reportes/solicitudesOrg",
      },
      {
        avatar: <Avatar icon="notification" className="icono_reportes" />,
        reporte: "Acciones de difusión",
        desc:
          "Reporte que visualiza las acciones de difusión realizadas en el proceso",
        ruta: "reportes/acciones",
      },
      {
        avatar: <Avatar icon="team" className="icono_reportes" />,
        reporte: "Cursos",
        desc: "Reporte que visualiza los cursos y asistencia registrados",
        ruta: "reportes/cursos",
      },
      {
        avatar: <Avatar icon="edit" className="icono_reportes" />,
        reporte: (
          <p
            className="liga_reportes"
            onClick={(e) => {
              download();
            }}
          >
            Solicitud de acreditación de Observadoras/es de las actividades de la Jornada Electoral
          </p>
        ),
        desc: "",
        ruta: pathname,
      },
      {
        avatar: <Avatar icon={<IconoReporte />} className="icono_reportes" />,
        reporte: "Detalle de solicitudes",
        desc:
          "Detalle de solicitudes",
        ruta: "reportes/detalleSolicitudes",
      },
      {
        avatar: <Avatar icon={<IconoReporte />} className="icono_reportes" />,
        reporte: "Transparencia (Art. 74 fracción 1 inciso a)",
        desc: "Reporte de Transparencia",
        ruta: "reportes/transparencia",
      },
      {
        avatar: <Avatar icon={<IconoReporte />} className="icono_reportes" />,
        reporte: "Informes",
        desc: "Reporte que visualiza los informes registrados",
        ruta: "reportes/informes",
      },
    ];
    setListaReportes({ lista: listaRep, loading: false }); //Se almacenan en el hook
  }

  function creaListaReportesPE() {
    const listaRep = [
      {
        reporte: "No existen reportes a mostrar en este nivel",
        ruta: "home",
      },
    ];
    setListaReportes({ lista: listaRep, loading: false }); //Se almacenan en el hook
  }

  return (
    <div>
      <Row>
        <Col span={24}>
          <h1>Reportes</h1>
        </Col>
      </Row>
      <Row>
        <List
          itemLayout="horizontal"
          dataSource={listaReportes.lista}
          renderItem={(item) => (
            <List.Item style={{ padding: "6px" }}>
              <List.Item.Meta
                className="lista_reportes"
                avatar={item.avatar}
                title={
                  <Link to={item.ruta} className="liga_reportes">
                    {item.reporte}
                  </Link>
                }
              />
            </List.Item>
          )}
          loading={listaReportes.loading}
        />
      </Row>
    </div>
  );
}

export default withRouter(Reports);