import React, { useState, useEffect } from "react";
import { Input, Tooltip, Table, Form } from "antd";
import { Link, withRouter } from "react-router-dom";

function TableReq(props) {
  const { data, rolModulo: { captura }, loading, moduloAbierto } = props;
  const [value, setValue] = useState(""); //Hook para el filtrado de nombre
  const [valClave, setValClave] = useState(""); //Hook para el filtrado de clave
  const [valFolio, setValFolio] = useState(""); //Hook para el filtrado de folio
  const [dataSource, setDataSource] = useState([]); //hook para almacenar el array

  useEffect(() => {
    setDataSource(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  //Función que filtra los registros por el nombre
  const FilterByNameInput = (
    <>
      Observador
      <Input
        placeholder="Nombre del observador"
        value={value}
        onChange={(e) => {
          const currValue = e.target.value;
          setValue(currValue);
          const filteredData = data.filter((entry) =>
            entry.nombreObservador
              .toString()
              .toLowerCase()
              .includes(currValue.toLowerCase())
          );
          setDataSource(filteredData);
        }}
      />
    </>
  );

  //Función que filtra los registros por clave de elector
  const FilterByClaveInput = (
    <>
      Clave de Elector
      <Input
        placeholder="Clave de Elector"
        valClave={valClave}
        onChange={(e) => {
          const currValue = e.target.value;
          setValClave(currValue);
          const filteredData = data.filter((entry) =>
            entry.claveElector
              .toString()
              .toLowerCase()
              .includes(currValue.toLowerCase())
          );
          setDataSource(filteredData);
        }}
      />
    </>
  );

  //Función que filtra los registros por folio
  const FilterByFolioInput = (
    <>
      Folio
      <Input
        placeholder="Folio"
        valFolio={valFolio}
        onChange={(e) => {
          const currValue = e.target.value;
          setValFolio(currValue);
          const filteredData = data.filter((entry) =>
            entry.folio
              .toString()
              .toLowerCase()
              .includes(currValue.toLowerCase())
          );
          setDataSource(filteredData);
        }}
      />
    </>
  );

  //Se definen las columnas de la tabla de Solicitudes acreditadas
  const columns = [
    {
      title: "#",
      dataIndex: "indice",
      key: "indice",
      width: 60,
      align: "center",
    },
    {
      title: FilterByNameInput,
      dataIndex: "nombreObservador",
      key: "nombreObservador",
    },
    {
      title: FilterByClaveInput,
      dataIndex: "claveElector",
      key: "claveElector",
      width: 250,
    },
    {
      title: FilterByFolioInput,
      dataIndex: "folio",
      key: "folio",
      width: 150,
    },
    {
      title: "Tipo de Solicitud",
      dataIndex: "tipoSolicitud",
      key: "tipoSolicitud",
    },
    {
      title: "Fecha de acreditación",
      dataIndex: "fechaAcreditacion",
      key: "fechaAcreditacion",
    },
    {
      title: "Acciones",
      fixed: "right",
      align: "center",
      width: 150,
      render: (record) => (
        <>
          {captura && (
            <Tooltip title="Registrar Informe">
              <Link
                to={{
                  pathname: "/admin/informes/registro",
                  data: record,
                }}
                disabled={!moduloAbierto}
              >
                <button style={{ marginLeft: "0px", width: "115px" }} className="ant-btn-default" disabled={!moduloAbierto}>
                    Capturar
                </button>
              </Link>
            </Tooltip>
          )}
        </>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      locale={{ emptyText: "Sin registros" }}
      scroll={{ x: true }}
    />
  );
}
const TableRequest = Form.create()(TableReq);
export default withRouter(TableRequest);