import React, { useState, useEffect, useRef }  from "react";
import { Icon, message, Select } from "antd";
import { downloadConvocation } from "../../../../api/convocation";
import "./ContentConvocation.scss";

const { Option } = Select;

export default function ContentConvocation(props) {
  const { ine } = props;
  const isMounted = useRef(true);
  const [convoca, setConvoca] = useState([]);

  // if (loading) {
  //   return <Loading />;
  // }

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (ine.length > 0) {
      setConvoca(ine[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ine]);

  const download = (con) => {
    const json = {
      idTipo: con.idTipo,
      idConvocatoria: con.idConvocatoria,
    };
    downloadConvocation(json)
      .then((response) => {
        if (response.data) {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );
          link.setAttribute("download", `Convocatoria_${convoca.label}.pdf`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch((error) => message.error("El archivo no se encuentra disponible"));
  };

  const getConvocatoria = (value, event) => {
    for (let i = 0; i < ine.length; i++) {
      if (value === ine[i].idConvocatoria.toString()) {
        setConvoca(ine[i]);
      }      
    }
  };
  ///////////////-----------------------------------------/////////////////////////////////////////////
  return (
    <div style={{ marginRight: 50 }}>
      {ine.length > 0 && convoca.idConvocatoria > 0 ? (
        <>
          <Select
            showSearch
            style={{ width: '25%' }}
            placeholder="Selecciona"
            defaultValue={[convoca.idConvocatoria.toString()]}
            onSelect={(value, e) => getConvocatoria(value, e)}
          >
            {ine.map((item) => (
              <Option key={item.idConvocatoria}>{item.label}</Option>
            ))}
          </Select>

          <div style={{marginTop: 15, marginBottom: 15}}>
            <button className="btnDescargar" onClick={(e) => download(convoca, e)}>
              Descargar convocatoria{" "}
              <Icon type="download" style={{ fontSize: 25 }} />
            </button>
          </div>
          <div>
            <embed
              src={`data:application/pdf;base64,${convoca.convocatoria}`}
              type="application/pdf"
              width="100%"
              height="1500px"
            />
          </div>           
        </>
      ) : (
        <p>En este momento no hay convocatorias publicadas.</p>
      )}
    </div>
  );
}