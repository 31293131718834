import React, { useState, useEffect } from "react";
import { Row, Col, Icon, Button } from "antd";
import { withRouter, Link } from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";
import moment from "moment";
import "./Information.scss";
import Table from "../../../../components/Admin/Reports/TableInformation";
import { getReporteInformes } from "../../../../api/reports";
import { descargaExcel } from "../../../../components/Admin/Reports/InformationXls";

function Information(props) {
  const { proceso, setLoader } = props;
  const { user: { rolUsuario } } = useAuth();
  const [reload, setReload] = useState(false);
  const [calendario, setCalendario] = useState({ fecha: "", hora: "" }); 
  const [tipoUsuario, setTipoUsuario] = useState();
  const [entidad, setEntidad] = useState({ id: null, nombre: null }); 
  const [junta, setJunta] = useState({ id: null, nombre: null });
  const [total, setTotal] = useState(0);
  const [informes, setInformes] = useState({ list: [], loading: false }); 

  useEffect(() => {
    if (proceso.proceso !== undefined) {
        const fechaHora = new Date();
        moment.locale("es");
        setCalendario({fecha:moment(fechaHora).format("DD/MMMM/YYYY"), hora:moment(fechaHora).format("HH:mm:ss") });
        const usuario = rolUsuario.substring(rolUsuario.lastIndexOf(".")+1,rolUsuario.length);
        if (proceso.estado !== undefined && proceso.estado !== null && proceso.estado.idEstado !== 0) {
          setEntidad({ id: proceso.estado.idEstado, nombre: proceso.estado.nombreEstado });
          if (proceso.distrito !== undefined && proceso.distrito !== null && proceso.distrito.idDistrito !== 0) {
            setJunta({ id: proceso.distrito.idDistrito, nombre: `${proceso.distrito.idDistrito}. ${proceso.distrito.nombreDistrito}`  });
          } 
        } else {
          setEntidad({ id: null, nombre: null });
        }
        setTipoUsuario(usuario);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proceso]);

  useEffect(() => {
    setReload(true);
  }, [entidad, junta]);

  useEffect(() => {
    if (reload) {
      setInformes({...informes, loading: true });
      const fechaHora = new Date();
      moment.locale("es");
      setCalendario({fecha:moment(fechaHora).format("DD/MMMM/YYYY"), hora:moment(fechaHora).format("HH:mm:ss") });
      setTotal(0);
      getReporteInformes(proceso.proceso.idProcesoElectoral, proceso.proceso.idDetalleProceso, entidad.id, junta.id).then((res) => {
        const dataSource = [];
        if (res.data.concentrado !== null) {
          res.data.concentrado.map((reg, index) => {
            return dataSource.push({
              key: index,
              indice: index + 1,
              ...reg,
            });
          });
          actualizaTotal(dataSource); 
          setInformes({ list: dataSource, loading: false }); 
        } else {
          if (res.data.detalle !== null) {
            res.data.detalle.map((reg, index) => {
              return dataSource.push({
                key: index,
                indice: index + 1,
                ...reg,
              });
            });
            actualizaTotal(dataSource); 
            setInformes({ list: dataSource, loading: false }); 
          } else {
            setInformes({ list: dataSource, loading: false }); 
          }
        }
      })
      .catch((error) => {
        setInformes({ list: [], loading: false }); 
      });
    }
    setReload(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  function actualizaTotal(list) {
    let totalPantalla = 0;
    //if (junta.id === null) {  // reporte nivel OC/JL
      //for (let i = 0; i < list.length; i++) {
        //totalPantalla = totalPantalla + list[i].totalInformes;
      //}
    //} else {   // reporte nivel JD
      totalPantalla = list.length;
    //}
    setTotal(totalPantalla);
  }

  function regresaNacional() {
    setEntidad({id:null, nombre: null});
    setJunta({id:null, nombre: null});
    setReload(true);
  }

  function regresaEntidad() {
    setJunta({id:null, nombre: null});
    setReload(true);
  }

  const downloadExcel = () => {
    descargaExcel("Informes", "Reporte de Informes", entidad.nombre, junta.nombre, informes.list, calendario);
  }

  function EtiquetaVinculo() {
    if (informes.list !== null && informes.list.length > 0) {
      if (entidad.id === null) {
        return ( <div>Nacional</div> ); 
      } else {
        if (tipoUsuario === "OC" || tipoUsuario === "JSP") { //JSP para usuarios sin proceso asignado
          if (junta.id === null) {
            return ( <div><Button className="buttonReports" onClick={() => regresaNacional()} type="link">Nacional</Button> | {entidad.nombre}</div> );
          } else {
            return (
              <div><Button className="buttonReports" onClick={() => regresaNacional()} type="link">Nacional</Button> |&nbsp;
              <Button className="buttonReports" onClick={() => regresaEntidad()} type="link">{entidad.nombre}</Button> | {junta.nombre}</div>
            ); 
          }
        }
        if (tipoUsuario === "JL") {
          if (junta.id === null) {
            return ( <div>Nacional | {entidad.nombre}</div> );
          } else {
            return ( <div>Nacional | <Button className="buttonReports" onClick={() => regresaEntidad()} type="link">{entidad.nombre}</Button> | {junta.nombre}</div> ); 
          }
        }
        if (tipoUsuario === "JD") {
          return ( <div>Nacional | {entidad.nombre} | {junta.nombre}</div> ); 
        }
      }
    } else {
      return (
        <div></div>
      );
    }
  }

  return (
    <div>
      <Row className="backReportes">
        <Link to={"/admin/reportes"} className="back">
          <Icon type="play-circle" theme="filled" rotate="180" /> Volver a "Reportes" 
        </Link>
      </Row>
      <Row>
        <Col span={12}>
          <h1>Reporte de Informes</h1>
        </Col>
        <Col span={12}>
          <div style={{ float:"right", fontWeight:"bold", fontSize:"12px" }}>
            Última actualización
          </div>
          <div style={{ fontSize:"12px", position:"absolute", marginTop:"20px", right:"0" }}>
            <Icon type="calendar" /> {calendario.fecha} <Icon type="clock-circle" /> {calendario.hora}
          </div>
        </Col>
      </Row>
      <Row  className="rowList">
        <Col span={12} className="colSpace">
          <EtiquetaVinculo />
        </Col>
        <Col span={12} className="colSpaceTotal">
          <div><h1 style={{marginBottom: "0px"}}>{total}</h1></div>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <div><strong>Descarga el reporte:</strong> 
            <Button onClick={() => downloadExcel()} className="btn_descarga_link" type="link" >
              <Icon type="file-excel" />Excel
            </Button>
          </div>
        </Col>
        <Col span={12} className="colSpaceTotal">
          <div>Informes recibidos</div>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="colSpace">
          <div>
            <Table
              data={informes.list}
              loading={informes.loading}
              setEntidad={setEntidad}
              setJunta={setJunta}
              entidad={entidad}
              junta={junta}
              setLoader={setLoader}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default withRouter(Information);