import { Button, Modal, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { getProcesoRatificacion, sendNotifRat } from "../../../api/notifica";
import useAuth from "../../../hooks/useAuth";
import { MSN_GUARDAR } from "../../../utils/constanst";
import { rol_captura_notifica, rol_consulta_notifica } from "../../../utils/Notifications/rolesNotifica";

function Notifications(props) {
    const { proceso, setLoader } = props;
    const {
        user: { rolUsuario }, //Se obtiene el rol del usuario
    } = useAuth();
    const isMounted = useRef(true); //Referencia que permite verificar si el componente esta visible
    const [rolModulo, setRolModulo] = useState({}); //Hook para almacenar los permisos
    const [ratificacion, setRatificacion] = useState([]);
    const [refresh, setRefresh] = useState(false);
    let idProceso//, idDetalle;
    //Se verica que este seleccionado un proceso electoral
    if(proceso.proceso) {
        idProceso = proceso.proceso.idProcesoElectoral;
        //idDetalle = proceso.proceso.idDetalleProceso;
    }
    //useEffect que valida que el componente este seleccionado de lo contrario cancela cualquier subcripción
    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);
    //useEffect que valida el rol del usuario
    useEffect(() => {
        //Valida los permisos que tienen el usuario
        let captura = false;
        let consulta = false;
        //Devuelve un true si cuenta con los permisos
        captura = rol_captura_notifica.includes(rolUsuario);
        consulta = rol_consulta_notifica.includes(rolUsuario);
        isMounted.current && setRolModulo(() => ({ captura, consulta })); //Se almacenan en el hook
    }, [rolUsuario]);

    useEffect(() => {
        if(idProceso) {
            getProcesoRatificacion().then((res) => {
                if(res.data.code === 200) {
                    setRatificacion(res.data);
                } else {
                    setRatificacion(null);
                }
            }).catch((error) => {
                console.log(error);
                setRatificacion(null);
            });
        }
    }, [idProceso, refresh]);

    const confirmaEnvio = (event) => {
        event.preventDefault();
        Modal.confirm({
            title: <p>Se enviarán correos electrónicos a las/os Observadoras/es 
                acreditados del Proceso Electoral anterior.</p>,
            content: "¿Deseas continuar?",
            okText: "Continuar",
            cancelText: "Cancelar",
            onOk() {
                setLoader({ loading: true, message: MSN_GUARDAR }); //Se activa el loader
                enviaNotificacion();
            },
        });
    };

    function enviaNotificacion() {
        sendNotifRat(ratificacion.idProceso, ratificacion.idDetalle).then((res) => {
            if(res.data.code === 200) {
                setLoader({ loading: false, message: "" }); //Se desactiva el loader
                Modal.success({
                    content: res.data.message,
                    okText: "Aceptar",
                });
            } else {
                setLoader({ loading: false, message: "" }); //Se desactiva el loader
                Modal.error({
                    content: res.data.message,
                });
            }
            if(refresh) { //Para cargar de nuevo el contador 
                setRefresh(false);
            } else {
                setRefresh(true);
            }
        }).catch((error) => {
            console.log(error);
            setLoader({ loading: false, message: "" }); //Se desactiva el loader
            Modal.error({
                content: "Hubo un problema al invocar el servicio web.",
            });
        });
    }

    return (
        <div>
            <Row>
                <h1>Notificaciones del sistema</h1>
            </Row>
            <Row style={{ marginTop:"20px"}}>
                <h4>Notificación para ratificación.</h4>
            </Row>
            {ratificacion ? (
                <>
                <Row>
                    Enviar notificación a las/os Observadoras/es acreditados del <strong>{ratificacion.nombreProceso}</strong>
                    &nbsp;para participar en el nuevo Proceso Electoral.
                </Row>
                <Row>
                    Número de veces de notificaciones enviadas: <strong>{ratificacion.notifEnviadas}</strong>
                </Row>
                <Row>
                    Número de correos electrónicos a enviar: <strong>{ratificacion.totalCuentas}</strong>
                </Row>
                <Row style={{ textAlign: "center" }}>
                    <Button type="primary" style={{ width: 180 }} disabled={rolModulo.consulta} onClick={confirmaEnvio}>
                        Enviar notificación
                    </Button>
                </Row>
                </>
            ) : (
                <>
                <Row>
                    No hay proceso de ratificación configurado en el sistema.
                </Row>
                </>
            )}
        </div>
    );
}

export default withRouter(Notifications);
