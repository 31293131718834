import React, { useState, useEffect, useRef } from "react";
import { Link, withRouter } from "react-router-dom";
import { Icon, Form, Select, Row, Col } from "antd";
import { Input, Radio, Alert, Modal, message } from "antd";
import { Upload, Button, Checkbox } from "antd";
import { required, soloTexto, RFC, tel, calleOrganizacion, numeroInt, cpOrganizacion } from "../../../utils/rules";
import { apellidos, soloDigitos } from "../../../utils/rules";
import { correo, nombres } from "../../../utils/rules";
import {
  ModalConfirm,
  beforeUpload,
  valueUpperCase,
} from "../../../utils/Organitation/function";
import { validaRepre, Ast, getUrl } from "../../../utils/Organitation/function";
import useAuth from "../../../hooks/useAuth";
import {
  getTypeOrganitation,
  getEntidad,
  getMunicipios,
  getDownload,
  getOrganitationViewUpd
} from "../../../api/organitation";
import { rol_captura, rol_modifica } from "../../../utils/Organitation/roles";
import { txtNombre, txtOrg } from "../../../utils/regex";
import "./style.scss";
import { saveAs } from "file-saver";
import { URL_ORGANIZACION } from "../../../api/config";
import { USUARIO_CAU, MSN_GUARDAR } from "../../../utils/constanst";

const { Option } = Select;


function FormOrganitation(props) {
  const {
    user: { user, rolUsuario },
  } = useAuth();
  const {
    history,
    setLoader,
    proceso: { proceso },
  } = props; //Para redirreccionar a otra ruta con withRouter (history)
  const { getFieldDecorator } = props.form; //Método para validar los inputs
  const [document, setDocument] = useState([
    { file: [], nameFile: "", url: "" },
  ]); //Se almacena el archivo
  const [select, setSelect] = useState([]);
  const [selectEstado, setSelectEstado] = useState([]);
  const [selectMunicipio, setSelectMunicipio] = useState([]);
  const [disabled, setDisabled] = useState({
    input: false,
    titulo: false,
    buttonUpd: false,
    title: false,
    chkSinNumero: false,
  }); //Para habilidar los inputs
  const [rolModulo, setRolModulo] = useState({
    modifica: false,
  });
  const [btnGuardar, setBtnGuardar] = useState(false);
  const isMounted = useRef(true);
  const [btnFile, setBtnFile] = useState(true);
  const { data, status } = props.location;
  const { idProcesoElectoral, idDetalleProceso} = proceso;

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  //Se llena el combo de tipos de organizaciones, el combo de Estados y Municipios
  useEffect(() => {
    //Valida los permisos que tienen el usuario
    let captura = false;
    let modifica = false;
    captura = rol_captura.includes(rolUsuario);
    modifica = rol_modifica.includes(rolUsuario);
    
    getTypeOrganitation().then((res) => {
      if (isMounted.current) {
        setSelect(res.data);
      }
    });
    getEntidad().then((response) => {
      if (isMounted.current) {
        setSelectEstado(response.data);
      }
    });

    if (history.location.pathname === "/admin/organizacion/registro") {
      if (!captura) {
        history.push("/admin/organizacion");
      }
    } else {
      if (history.location.pathname === "/admin/organizacion/modificar") {
        setRolModulo(() => ({ modifica }));
          getOrganitation(); //Verifica si es nueva organización o es actualizar
      }
      if (history.location.pathname === "/admin/organizacion/consulta") {
        getOrganitation(); //Verifica si es nueva organización o es actualizar
        setDisabled({
          input: true,
          titulo: true,
          buttonUpd: true,
          title: true,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Llena el formulario si recibe una organización
  const getOrganitation = () => {
    getOrganitationViewUpd(true, idProcesoElectoral, idDetalleProceso, data.idOrganizacion)
    .then((response) => {
      if (status !== undefined) {
        status
          ? setDisabled({
            input: true,
            titulo: true,
            buttonUpd: true,
            title: true,
          })
          : setDisabled({ title: true });
  
        if (!response.data.voOrganizacion.urlSolicitud) {
          setDocument([{ nameFile: "" }]);
          setBtnFile(true);
        } else {
          let nameFile = getUrl(response.data.voOrganizacion.urlSolicitud, URL_ORGANIZACION);
          setDocument([{ nameFile: nameFile, url: response.data.voOrganizacion.urlSolicitud }]);
          setBtnFile(false);
        }
        getMunicipios(response.data.voOrganizacion.idEstado).then((res) => {
          setSelectMunicipio(res.data);
        });
        props.form.setFieldsValue({
          //Se llenan los input del formulario
          idOrganizacion: response.data.voOrganizacion.idOrganizacion,
          //tipo: data.cTiposOrganizacion.idTipoOrganizacion,
          tipo: response.data.voOrganizacion.idTipoOrganizacion,
          idEstado: response.data.voOrganizacion.idEstado,
          organizacion: response.data.voOrganizacion.organizacion,
          siglas: response.data.voOrganizacion.siglasOrg,
          reprePaterno: response.data.voOrganizacion.apellidoPaternoRepre,
          repreMaterno: response.data.voOrganizacion.apellidoMaternoRepre,
          repreNombre: response.data.voOrganizacion.nombreRepre,
          rfc: response.data.voOrganizacion.rfcOrg,
          apoyo: response.data.voOrganizacion.banderaApoyo,
          telefono: response.data.voOrganizacion.telefono != null ? response.data.voOrganizacion.telefono.toString() : "",
          correo: response.data.voOrganizacion.correoElectronico,
          calle: response.data.voOrganizacion.calle,
          numeroExterior: response.data.voOrganizacion.numeroExterior,
          numeroInterior: response.data.voOrganizacion.numeroInterior,
          colonia: response.data.voOrganizacion.colonia,
          codigoPostal: (response.data.voOrganizacion.codigoPostal==null ? "": response.data.voOrganizacion.codigoPostal.toString()),
          idMunicipio: response.data.voOrganizacion.idMunicipio,
        });
        
        if (response.data.voOrganizacion.numeroExterior===null&& response.data.voOrganizacion.numeroInterior===null) {
          //Aca va acitvar el chk de sin número
          setDisabled({ chkSinNumero: true, checkNum: true, title: true })
        }
        if (response.data.voOrganizacion.codigoPostal!==null && response.data.voOrganizacion.codigoPostal!=="" && response.data.voOrganizacion.codigoPostal.toString().length < 5) {
          var faltanteCP = 5 - response.data.voOrganizacion.codigoPostal.toString().length;
          var finalCP = response.data.voOrganizacion.codigoPostal.toString();
          for (let i = 0; i < faltanteCP; i++) {
            finalCP = "0" + finalCP;
          }
          props.form.setFieldsValue({
            codigoPostal: finalCP.toString(),
          });
        }
      }
      if (history.location.pathname === "/admin/organizacion/consulta") {
        setDisabled({
          input: true,
          titulo: true,
          buttonUpd: true,
          title: true,
        })
      }
    })
    
  };
  //Validaciones para cargar el archivo
  const file = {
    name: "file",
    multiple: false,
    showUploadList: {
      showDownloadIcon: false,
    },
    onRemove: (file) => {
      setDocument((state) => {
        return {
          file: [],
        };
      });
    },

    beforeUpload: (file) => {
      if (beforeUpload(file)) {
        setDocument((state) => ({
          file: [file],
        }));
      }
      return false;
    },
    document,
  };
  //Función que guarda la organización
  const addOrganitation = (event) => {
    event.preventDefault();
    if (proceso !== undefined && proceso !== null) {
      //Se valida el proceso
      props.form.validateFields((err, values) => {
        let repre = validaRepre(values.reprePaterno, values.repreMaterno);
        if (!err) {
          if (repre) {
            values.idProcesoElectoral = proceso.idProcesoElectoral;
            values.idDetalleProceso = proceso.idDetalleProceso;
            values.usuario = user.sub;
            setBtnGuardar(true);
            // if (!btnFile) {
            //   console.log(" NO env file");
            //   ModalConfirm(
            //     values,
            //     null,
            //     history,
            //     setBtnGuardar,
            //     setLoader,
            //     false
            //   );
            // } else {
            //console.log("env file");
            ModalConfirm(
              values,
              document,
              history,
              setBtnGuardar,
              setLoader,
              true
            );
            // }
          } else {
            Modal.warning({
              title: "Atención",
              content: "Al menos uno de los dos apellidos es obligatorio.",
            });
          }
        }
      });
    } else {
      Modal.warning({
        title: "Atención",
        content: "Selecciona el Proceso Electoral",
      });
    }
  };

  const AlertLastName = () => {
    return (
      <Alert
        message="Al menos uno de los dos apellidos es obligatorio."
        type="info"
        showIcon
        style={disabled.input ? { display: "none" } : {}}
      />
    );
  };

  //Función que descarga un documento
  const download = (url, name) => {
    setLoader({ loading: true, message: MSN_GUARDAR }); //Se activa el loader
    const json = {
      url: url,
      nombre: name,
    };
    getDownload(json)
      .then((res) => {
        if (res.data) {
          saveAs(new Blob([res.data]), name);
        }
        setLoader({ loading: false }); //Se activa el loader
      })
      .catch((error) => {
        setLoader({ loading: false }); //Se activa el loader
        message.error("El archivo no se encuentra disponible");
      });
  };

  //Funciones para los campos de domicilio
  const onChangeSinNum = (e) => {
    if (e.target.checked) {
      setDisabled({ ...disabled, checkNum: true });
      props.form.setFieldsValue({ numeroExterior: "" });
      props.form.setFieldsValue({ numeroInterior: "" });
    } else {
      setDisabled({ ...disabled, checkNum: false });
    }
  };

  const onChangeEntidad = (e) => {
    getMunicipios(e).then((res) => {
      setSelectMunicipio(res.data);
    });
  };

  return (
    <div style={{ marginLeft: "20px" }}>
      <Link to={"/admin/organizacion"} className="back">
        <Icon type="play-circle" theme="filled" rotate="180" /> Volver a
        "Registro de organizaciones"
      </Link>
      <div style={{ marginTop: "30px" }}>
        {disabled.titulo ? (
          <h1>Ver organización</h1>
        ) : (
          <>
            <h1>
              {disabled.title ? "Editar organización" : "Nueva organización"}
            </h1>
            <p>
              Los datos con ( <Ast /> ) son requeridos
            </p>
          </>
        )}
      </div>
      <h4>Datos de la organización</h4>
      <Form>
        <Form.Item>
          {getFieldDecorator("idOrganizacion")(<Input disabled hidden />)}
        </Form.Item>
        <Form.Item>
          <div>{!disabled.input && <Ast />} Tipo de organización</div>
          {getFieldDecorator("tipo", { rules: required })(
            <Select
              placeholder="Selecciona"
              style={{ width: 315 }}
              disabled={disabled.input}
            >
              {select.map((item) => (
                <Option
                  key={item.idTipoOrganizacion}
                  value={item.idTipoOrganizacion}
                >
                  {item.tipoOrganizacion}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Row gutter={20}>
          <Col span={16}>
            <Form.Item>
              <div>{!disabled.input && <Ast />} Nombre de la organización</div>
              {getFieldDecorator("organizacion", {
                rules: soloTexto,
                getValueFromEvent: (event) => {
                  return txtOrg.test(event.target.value)
                    ? event.target.value.replace("-", "")
                    : event.target.value.slice(0, -1);
                },
              })(
                <Input
                  placeholder="Nombre completo sin abreviaturas"
                  maxLength={100}
                  disabled={disabled.input}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Siglas">
              {getFieldDecorator("siglas", { rules: apellidos })(
                <Input
                  placeholder="Siglas"
                  maxLength={50}
                  disabled={disabled.input}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <div>RFC de la organización</div>
          {getFieldDecorator("rfc", {
            rules: RFC,
            getValueFromEvent: valueUpperCase,
          })(
            <Input
              placeholder="Registro Federal de Contribuyentes"
              style={{ width: 315 }}
              maxLength={13}
              disabled={disabled.input}
            />
          )}
        </Form.Item>

        <p>{!disabled.input && <Ast />} Representante legal</p>
        <AlertLastName />
        <Row gutter={20}>
          <Col span={8}>
            <Form.Item>
              <div>Apellido paterno</div>
              {getFieldDecorator("reprePaterno", {
                rules: apellidos,
                getValueFromEvent: (event) => {
                  return txtNombre.test(event.target.value)
                    ? event.target.value.replace("-", "")
                    : event.target.value.slice(0, -1);
                },
              })(
                <Input
                  placeholder="Apellido paterno"
                  maxLength={40}
                  disabled={disabled.input}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              <div>Apellido materno</div>
              {getFieldDecorator("repreMaterno", {
                rules: apellidos,
                getValueFromEvent: (event) => {
                  return txtNombre.test(event.target.value)
                    ? event.target.value.replace("-", "")
                    : event.target.value.slice(0, -1);
                },
              })(
                <Input
                  placeholder="Apellido materno"
                  maxLength={40}
                  disabled={disabled.input}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              <div>{!disabled.input && <Ast />} Nombre(s)</div>
              {getFieldDecorator("repreNombre", {
                rules: nombres,
                getValueFromEvent: (event, previo) => {
                  return txtNombre.test(event.target.value)
                    ? event.target.value.replace("-", "")
                    : event.target.value.slice(0, -1);
                },
              })(
                <Input
                  placeholder="Nombre(s)"
                  maxLength={50}
                  type="text"
                  disabled={disabled.input}
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <div>
            {!disabled.input} ¿La organización solicita apoyo
            económico?
          </div>
          {getFieldDecorator("apoyo",)(
            <Radio.Group disabled={disabled.input}>
              <Radio value={1}>Sí</Radio>
              <Radio value={0}>No</Radio>
            </Radio.Group>
          )}
        </Form.Item>

        <h5 style={{ marginTop: 20 }}> Domicilio </h5>
        <Row>
          <Col span={8} style={{ paddingRight: "20px", paddingTop: "10px" }}>
            <Form.Item>
              <div>{!disabled.input} Calle </div>
              {getFieldDecorator("calle", { rules: calleOrganizacion })(
                <Input disabled={disabled.input} maxLength={50} />
              )}
            </Form.Item>
          </Col>
          <Col span={4} style={{ paddingRight: "20px", paddingLeft: "20px", paddingTop: "50px", }}>
            <Form.Item>
              {getFieldDecorator("sinNumero", {
                valuePropName: "checked",
                initialValue: disabled.chkSinNumero,
              })(
                <Checkbox disabled={disabled.input}  onChange={onChangeSinNum}>
                  Sin número
                </Checkbox>
              )}
            </Form.Item>
          </Col>
          <Col span={6} style={{ paddingRight: "20px", paddingTop: "10px" }}>
            <Form.Item>
              <div>{!disabled.input} No. exterior </div>
              {getFieldDecorator("numeroExterior", {
                getValueFromEvent: (event) => {
                  return numeroInt.test(event.target.value)
                    ? event.target.value
                    : (event.target.value).slice(0, -1);
                },
              })(
                <Input maxLength={20} disabled={disabled.checkNum || disabled.input} />
              )}
            </Form.Item>
          </Col>
          <Col span={6} style={{ paddingRight: "20px", paddingTop: "10px" }}>
            <Form.Item>
              <div>{!disabled.input} No. interior </div>
              {getFieldDecorator("numeroInterior", {
                getValueFromEvent: (event) => {
                  return numeroInt.test(event.target.value)
                    ? event.target.value
                    : (event.target.value).slice(0, -1);
                },
              })(
                <Input maxLength={20} disabled={disabled.checkNum || disabled.input} />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8} style={{ paddingRight: "20px", paddingTop: "10px" }}>
            <Form.Item>
              <div>{!disabled.input} Colonia </div>
              {getFieldDecorator("colonia", { rules: calleOrganizacion })(
                <Input disabled={disabled.input} maxLength={40} />
              )}
            </Form.Item>
          </Col>
          <Col span={4} style={{ paddingRight: "20px", paddingTop: "10px" }}>
            <Form.Item>
              <div>{!disabled.input} Código postal </div>
              {getFieldDecorator("codigoPostal", {
                rules: cpOrganizacion,
                getValueFromEvent: (event) => {
                  return soloDigitos.test(event.target.value)
                    ? event.target.value
                    : (event.target.value).slice(0, -1);
                },
              })(
                <Input disabled={disabled.input} maxLength={5} />
              )}
            </Form.Item>
          </Col>
          <Col span={6} style={{ paddingRight: "20px", paddingTop: "10px" }}>
            <Form.Item>
              <div>{!disabled.input} Estado </div>
              {getFieldDecorator("idEstado", {})(
                <Select
                  placeholder="Selecciona"
                  style={{ width: 250 }}
                  disabled={disabled.input}
                  onChange={onChangeEntidad}
                >
                  {selectEstado.map((item) => (
                    <Option key={item.id.idEstado} value={item.id.idEstado}>
                      {item.nombreEstado}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={6} style={{ paddingRight: "20px", paddingTop: "10px" }}>
            <Form.Item>
              <div>{!disabled.input} Municipio </div>
              {getFieldDecorator("idMunicipio", {})(
                <Select
                  placeholder="Selecciona"
                  style={{ width: 250 }}
                  disabled={disabled.input}
                >
                  {selectMunicipio.map((item) => (
                    <Option key={item.idMunicipio} value={item.idMunicipio}>
                      {item.municipio}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>

        <h5 style={{ marginTop: 20 }}>Datos de contacto</h5>
        <Row gutter={20}>
          <Col span={4}>
            <Form.Item>
              <div>{!disabled.input} Teléfono</div>
              {getFieldDecorator("telefono", {
                rules: tel,
                getValueFromEvent: (event, previo) => {
                  return soloDigitos.test(event.target.value)
                    ? event.target.value
                    : event.target.value.slice(0, -1);
                },
              })(
                <Input
                  placeholder="10 dígitos"
                  maxLength={10}
                  disabled={disabled.input}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <div>{!disabled.input} Correo electrónico</div>
              {getFieldDecorator("correo", { rules: correo })(
                <Input
                  placeholder="correo@dominio.com"
                  maxLength={100}
                  disabled={disabled.input}
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        <p>{!disabled.input} Soporte documental que sustente la solicitud</p>
        <Alert
          message="Algún documento que avale la legitimidad del trámite o de la organización que solicita el registro. Formato pdf que no exceda los 6MB."
          type="info"
          showIcon
          style={disabled.input ? { display: "none" } : {}}
        />

        {btnFile ? (
          <Form.Item style={{ marginTop: 20 }}>
            <Upload
              {...file}
              accept=".pdf,.PDF"
              fileList={document.file}
              disabled={disabled.input}
            >
              <Button type="default" disabled={disabled.input}>
                <Icon type="upload" />
                Cargar
              </Button>
            </Upload>
          </Form.Item>
        ) : (
          <div style={{ marginTop: 20 }}>
            <button
              className="btnDownload"
              onClick={(e) =>
                download(document[0].url, document[0].nameFile, e)
              }
            >
              <Icon type="paper-clip" />
              {document[0].nameFile}
            </button>
          </div>
        )}

        <Form.Item style={{ textAlign: "center", marginTop: 75 }}>
          {disabled.buttonUpd ? (
            rolModulo.modifica && (
              <Button
                type="primary"
                onClick={() => {
                  setDisabled({
                    input: false,
                    title: true,
                  });
                }}
              >
                Modificar
              </Button>
            )
          ) : (
            <>
              {rolUsuario === USUARIO_CAU ? (
                <>
                  <Button type="default">
                    <Link to={"/admin/organizacion"}>Cancelar</Link>
                  </Button>
                </>
              ) : (
                <>
                  <Button type="default" disabled={btnGuardar}>
                    <Link to={"/admin/organizacion"}>Cancelar</Link>
                  </Button>{" "}
                  <Button
                    type="primary"
                    onClick={(e) => {
                      addOrganitation(e);
                    }}
                    disabled={btnGuardar}
                  >
                    Guardar
                  </Button>
                </>
              )}
            </>
          )}
        </Form.Item>
      </Form>
    </div>
  );
}
export default withRouter(Form.create()(FormOrganitation));
