import { basePath } from "./config";
import axios from "axios";
import { getAccessTokenApi } from "./auth";
import { TIPO_SEDE } from "../utils/constanst";

export function getConvocations(json) {
  const url = `${basePath}/convocatoria/tabla`;
  const jwt = getAccessTokenApi();
  return axios.post(url, json, {
    headers: { Authorization: jwt, Parametrizacion: json.idProceso },
  });
}

export function getConvocationByUser(proceso, usuario) {
  const url = `${basePath}/convocatoria/tabla`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    { proceso: proceso, tipoUsuario: usuario },
    {
      headers: { Authorization: jwt },
    }
  );
}

export function saveConvocation(convocation) {
  const url = `${basePath}/convocatoria`;
  const jwt = getAccessTokenApi();
  return axios.post(url, convocation, {
    headers: {
      Authorization: jwt,
      Parametrizacion: convocation.idProcesoElectoral,
    },
  });
}

export function updateConvocation(convocation) {
  const url = `${basePath}/convocatoria/actualizar`;
  const jwt = getAccessTokenApi();
  return axios.post(url, convocation, {
    headers: {
      Authorization: jwt,
      Parametrizacion: convocation.idProcesoElectoral,
    },
  });
}
export function updateStatusConvocation(id) {
  const url = `${basePath}/convocatoria/estatus`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    { idConvocatoria: id },
    {
      headers: { Authorization: jwt },
    }
  );
}
export function getByIdConvocation(idTipo, id) {
  const url = `${basePath}/convocatoria/id`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    { idTipo: idTipo, idConvocatoria: id },
    {
      headers: { Authorization: jwt },
    }
  );
}

export function downloadConvocation(json) {
  const url = `${basePath}/convocatoria/descarga`;
  return axios.post(url, json, {
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "blob",
  });
}

export function getByAddressJunta(idEstado, idDistrito) {
  // const url = `${urlJuntas}/ws/domiciliosJunta/consulta`;
  const url = `${basePath}/sesiones/juntaEjecutiva`;;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    { idEstado: idEstado, idDistritoFederal: idDistrito, tipoSede: TIPO_SEDE },
    {
      headers: { Authorization: jwt },
    }
  );
}

////////////////////////////////PORTAL PUBLICO /////////////////////////////////////

export function getConvocationPub() {
  const url = `${basePath}/convocatoria/publica`;
  return axios.post(url, {  });
}
