import React, { useState } from "react";
import { Layout, Button, Icon, Modal } from "antd";
import Header from "../components/User/Header/Header";
import Footer from "../components/User/Footer/Footer";
import { ReactComponent as LogoHeader } from "../assets/img/logoINE_bco.svg";
import { Route, Switch, Link } from "react-router-dom";
import { cleanCitezen, logoutCiudadano } from "../api/auth";
//Se importa el context para obtener la información del usuario
import useAuth from "../hooks/useAuth";
import "./LayoutPublic.scss";
import BlockLoader from "../components/Generals/Loader";
//import { ACCESS_TOKEN_CI, DETALLE_PROCESO_CI } from "../utils/constanst";
import UpdatePass from "../components/User/UpdatePass/UpdatePass";

export default function LayoutPublic(props) {
  const { Content } = Layout;
  const { routes, history } = props;
  const { history: { location: { pathname }, }, } = props;
  const {
    ciudadano: { ciudadano, isLoading },
  } = useAuth();
  const [loader, setLoader] = useState({ loading: false, message: "" }); //State para mostrar el loader
  const [visibleModificaPass, setVisibleModificaPass] = useState(false);

  //Verifica si el usuario existe si no lo redirecciona al login
  if (!ciudadano && !isLoading) {
    cleanCitezen();
    history.push("/");
  }
  //Si existe renderiza el Layout principal
  const logoutUser = () => {
    const status = logoutCiudadano();
    if (status) {
      window.location.href = "/"; //Se redirecciona al home
    }
  };

  const showModalModificaPass = () => {
    setVisibleModificaPass(true);
  };

  const handleOk = (e) => {
    setVisibleModificaPass(false);
  };

  const handleCancel = (e) => {
    setVisibleModificaPass(false);
  };

  // console.log("Interno");
  if (ciudadano && !isLoading) {
    //history.push("/ciudadano/home");
    return (
      // Es para usar el loader en la aplicación interna
      <BlockLoader loading={loader.loading} message={loader.message}>
        <Layout style={{ minHeight: "100vh", background: "#FFFFFF" }}>
          {/* <RefreshTokenCi /> */}
          <Header Logo={LogoHeader} ciudadano={ciudadano} />
          <div className="barraUsuario">
            {ciudadano && (
              <>
                Hola{" "}
                <strong>
                  {ciudadano.nombreObservador
                    ? ciudadano.nombreObservador
                    : ciudadano.sub}{" "}
                </strong>
                {/* El state: true es para que no regrese la pantalla Home en consultar solicitud */}
                <Link to={{ pathname: pathname, state: true }}
                    style={{marginLeft: "14px"}} type="link" onClick={showModalModificaPass}>
                  Modificar contraseña
                </Link>
                <Button onClick={logoutUser} className="btnCerrarsesion">
                  <Icon type="logout" />
                  Cerrar sesión
                </Button>
              </>
            )}
          </div>
          <Layout style={{ background: "#FFFFFF" }} className="loggeado">
            <Layout style={{ background: "#FFFFFF" }}>
              <Content>
                {/**Contenido, se vuelven a iterar las rutas para poder navegar entre ellas */}
                <LoadRoutes routes={routes} setLoader={setLoader} />
              </Content>
            </Layout>
          </Layout>
          <Modal visible={visibleModificaPass} onOk={handleOk} onCancel={handleCancel} footer={null}
              className="modalLoginRegistro">
            <center><h1>Modifica tu contraseña</h1></center>
            <UpdatePass visibleModificaPass={visibleModificaPass}
              setVisibleModificaPass={setVisibleModificaPass} ciudadano={ciudadano} />
          </Modal>
          <Footer />
        </Layout>
      </BlockLoader>
    );
  }
  return null;
}

//Función que itera las rutas individualmente
function LoadRoutes({ routes, setLoader }) {
  return (
    <Switch>
      {routes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          //component={route.component}
          render={(props) => <route.component setLoader={setLoader} />}
        />
      ))}
    </Switch>
  );
}
//Función que refresca el token cada vez que cambia de pantalla
// const RefreshTokenCi = () => {
//   useEffect(() => {
//     console.log("Entrando al refresh ciudadano");
//     // const jwt = localStorage.getItem(ACCESS_TOKEN_CI);
//     // refreshAccessTokenCi(jwt);
//     // if (!getTokenCiudadano()) {
//     //   console.log("Token Ciudadno expirado");
//     //   localStorage.removeItem(ACCESS_TOKEN_CI);
//     //   localStorage.removeItem(DETALLE_PROCESO_CI);
//     //   window.location.reload();
//     // }
//   });
//   return <></>;
// };
