import axios from "axios";
import { getAccessTokenApi } from "./auth";
import { basePath } from "./config";

export function getFechas(idProceso, idDetalle, etapa) {
    const url = `${basePath}/configFechas/obtenFechas`;
    const jwt = getAccessTokenApi();
    return axios.post(
        url,
        { idProceso: idProceso, idDetalle: idDetalle, etapa: etapa },
        { headers: { Authorization: jwt, Parametrizacion: idProceso } }
    );
}

export function sendFechas(values) {
    const url = `${basePath}/configFechas/actualizaFechas`;
    const jwt = getAccessTokenApi();
    return axios.post(
        url, values,
        { headers: { Authorization: jwt, Parametrizacion: values.idProceso } }
    );
}
