import React, { useState, useEffect } from "react";
import { Input, Icon, Tooltip, Table, Form, message, Modal } from "antd";
import { Link, withRouter } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { getDownloadInforme } from "../../../api/information";
import { deleteInforme } from "../../../utils/Information/functions";
import { rol_cau } from "../../../utils/Information/roles";

function TableInfo(props) {
  const { user: { rolUsuario } } = useAuth();
  const { data, rolModulo: { consulta, modifica, descarga, borrar }, loading, setReload, setLoader, moduloAbierto } = props;
  const [value, setValue] = useState(""); //Hook para el filtrado de nombre
  const [valClave, setValClave] = useState(""); //Hook para el filtrado de clave
  const [valFolio, setValFolio] = useState(""); //Hook para el filtrado de folio
  const [dataSource, setDataSource] = useState([]); //hook para almacenar el array

  useEffect(() => {
    setDataSource(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
//Funcion para descarga de informe
  const download = (dataRow) => {
    setLoader({ loading: true, message: "Cargando..." }); 
    getDownloadInforme(dataRow.idObservador).then((res) => {
      setLoader({ loading: false, message: "" }); 
      if (res.data) {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(
            new Blob([res.data], { type: "application/pdf" })
          );
          link.setAttribute("download", "Informe.pdf");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
      }
    })
    .catch((error) => {
        setLoader({ loading: false, message: "" });
        message.error("El archivo no se encuentra disponible");
    });
  };
//Función para eliminar informe
  function modalElimina(record) {
    const { confirm } = Modal;
    var message = <div>
      <p>
        Estás a punto de <strong>eliminar</strong> el informe del/la observador/a electoral.<br /><br />
        Nombre: <strong>{record.nombreObservador}</strong><br />
        Clave de elector: <strong>{record.claveElector}</strong><br />
        Folio: <strong>{record.folio}</strong><br /><br />
        ¿Deseas continuar?
      </p>
    </div>
    confirm({
      title: message,
      width: "500px",
      icon: null,
      okText: "Eliminar informe",
      cancelText: "Cancelar",
      cancelButtonProps: { className: "ant-btn-default" },
      okButtonProps: { style: { width: 200 } },
      className: "modalDesacredita",
      onOk() {
        if (!rol_cau.includes(rolUsuario)) {
          deleteInforme( record.idObservador, setReload);
        }
      },
      onCancel() { },
    });
  }

  //Función que filtra los registros por el nombre
  const FilterByNameInput = (
    <>
      Observador
      <Input
        placeholder="Nombre del observador"
        value={value}
        onChange={(e) => {
          const currValue = e.target.value;
          setValue(currValue);
          const filteredData = data.filter((entry) =>
            entry.nombreObservador
              .toString()
              .toLowerCase()
              .includes(currValue.toLowerCase())
          );
          setDataSource(filteredData);
        }}
      />
    </>
  );

  //Función que filtra los registros por clave de elector
  const FilterByClaveInput = (
    <>
      Clave de Elector
      <Input
        placeholder="Clave de Elector"
        valClave={valClave}
        onChange={(e) => {
          const currValue = e.target.value;
          setValClave(currValue);
          const filteredData = data.filter((entry) =>
            entry.claveElector
              .toString()
              .toLowerCase()
              .includes(currValue.toLowerCase())
          );
          setDataSource(filteredData);
        }}
      />
    </>
  );

  //Función que filtra los registros por folio
  const FilterByFolioInput = (
    <>
      Folio
      <Input
        placeholder="Folio"
        valFolio={valFolio}
        onChange={(e) => {
          const currValue = e.target.value;
          setValFolio(currValue);
          const filteredData = data.filter((entry) =>
            entry.folio
              .toString()
              .toLowerCase()
              .includes(currValue.toLowerCase())
          );
          setDataSource(filteredData);
        }}
      />
    </>
  );

  //Se definen las columnas de la tabla de Solicitudes acreditadas
  const columns = [
    {
      title: "#",
      dataIndex: "indice",
      key: "indice",
      width: 60,
      align: "center",
    },
    {
      title: FilterByNameInput,
      dataIndex: "nombreObservador",
      key: "nombreObservador",
    },
    {
      title: FilterByClaveInput,
      dataIndex: "claveElector",
      key: "claveElector",
      width: 250,
    },
    {
      title: FilterByFolioInput,
      dataIndex: "folio",
      key: "folio",
      width: 150,
    },
    {
      title: "Tipo de Solicitud",
      dataIndex: "tipoSolicitud",
      key: "tipoSolicitud",
    },
    {
      title: "Medio de Captura",
      dataIndex: "medioCaptura",
      key: "medioCaptura",
    },
    {
      title: "Fecha de elaboración",
      dataIndex: "fechaElaboracion",
      key: "fechaElaboracion",
    },
    {
      title: "Acciones",
      fixed: "right",
      align: "center",
      render: (record) => (
        <>
          {consulta && (
            <Tooltip title="Visualizar">
              <Link
                to={{
                  pathname: "/admin/informes/consulta",
                  data: record,
                }}
              >
                <button className="btn-circle btn_secundario">
                  <Icon type="eye" theme="filled" />
                </button>
              </Link>
            </Tooltip>
          )}{" "}
          {modifica && moduloAbierto && (
            <Tooltip title="Modificar informe">
              <Link
                to={{
                  pathname: "/admin/informes/modifica",
                  data: record,
                }}
              >
                <button className="btn-circle btn_secundario">
                  <Icon type="edit" theme="filled" />
                </button>
              </Link>
            </Tooltip>
          )}{" "} 
          {borrar && moduloAbierto && (
            <Tooltip title="Eliminar informe">
              <button
                style={{ marginLeft: "0px" }}
                className="btn-circle btn_secundario"
                onClick={(e) => modalElimina(record)}
              > <Icon type="delete" />
              </button>
            </Tooltip>
          )}{" "} 
          {descarga && (
            <Tooltip title="Descargar informe">
                <button 
                style={{ marginLeft: "0px" }}
                className="btn-circle btn_secundario"
                onClick={(e) => download(record)}
                >
                <Icon type="download" />
                </button>
            </Tooltip>
          )}
        </>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      locale={{ emptyText: "Sin registros" }}
      scroll={{ x: true }}
    />
  );
}
const TableInformation = Form.create()(TableInfo);
export default withRouter(TableInformation);