import { Button, Input, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";

function TableInbox(props) {
    const {
        data,
        loading,
        rolModulo,
        proceso, moduloAbierto
    } = props;
    const [dataSource, setDataSource] = useState([]); //hook para almacenar el array
    const [filtroNombre, setFiltroNombre] = useState("");
    const [filtroClaveElector, setFiltroClaveElector] = useState("");
    const [filtroFolio, setFiltroFolio] = useState("");
    const [filtroEstadoGral, setFiltroEstadoGral] = useState("");
    const [filtroOtrosSistemas, setFiltroOtrosSistemas] = useState("");

    useEffect(() => {
        setDataSource(data);
    }, [data]);

    const FilterByName = (
        <>
            Nombre del solicitante
            <Input placeholder="Nombre del solicitante" value={filtroNombre}
                onChange={(e) => {
                    const currValue = e.target.value;
                    setFiltroNombre(currValue);
                    const filteredData = data.filter((entry) =>
                        entry.nombreCompleto.toLowerCase().includes(currValue.toLowerCase())
                        && entry.claveElector.toLowerCase().includes(filtroClaveElector.toLowerCase())
                        && entry.folio.toLowerCase().includes(filtroFolio.toLowerCase())
                        && entry.descripcion.toLowerCase().includes(filtroEstadoGral.toLowerCase())
                        && entry.observacionesSistema.toLowerCase().includes(filtroOtrosSistemas.toLowerCase())
                    );
                    setDataSource(filteredData);
                }} 
            />
        </>
    );

    const FilterByClaveElector = (
        <>
            Clave de elector
            <Input placeholder="Clave de elector" value={filtroClaveElector}
                onChange={(e) => {
                    const currValue = e.target.value;
                    setFiltroClaveElector(currValue);
                    const filteredData = data.filter((entry) =>
                        entry.claveElector.toLowerCase().includes(currValue.toLowerCase())
                        && entry.nombreCompleto.toLowerCase().includes(filtroNombre.toLowerCase())
                        && entry.folio.toLowerCase().includes(filtroFolio.toLowerCase())
                        && entry.descripcion.toLowerCase().includes(filtroEstadoGral.toLowerCase())
                        && entry.observacionesSistema.toLowerCase().includes(filtroOtrosSistemas.toLowerCase())
                    );
                    setDataSource(filteredData);
                }}
            />
        </>
    );

    const FilterByFolio = (
        <>
            Folio
            <Input placeholder="Folio" value={filtroFolio}
                onChange={(e) => {
                    const currValue = e.target.value;
                    setFiltroFolio(currValue);
                    const filteredData = data.filter((entry) =>
                        entry.folio.toLowerCase().includes(currValue.toLowerCase())
                        && entry.claveElector.toLowerCase().includes(filtroClaveElector.toLowerCase())
                        && entry.nombreCompleto.toLowerCase().includes(filtroNombre.toLowerCase())
                        && entry.descripcion.toLowerCase().includes(filtroEstadoGral.toLowerCase())
                        && entry.observacionesSistema.toLowerCase().includes(filtroOtrosSistemas.toLowerCase())
                    );
                    setDataSource(filteredData);
                }}
            />
        </>
    );

    const FilterByEstadoGeneral = (
        <>
            Estado general de la solicitud <br />
            <Select style={{ width: 220 }} optionFilterProp="children" placeholder="Todos" value={filtroEstadoGral}
                onChange={(value) => {
                    const currValue = value;
                    setFiltroEstadoGral(currValue);
                    const filteredData = data.filter((entry) =>
                        entry.descripcion.toLowerCase().includes(currValue.toLowerCase())
                        && entry.folio.toLowerCase().includes(filtroFolio.toLowerCase())
                        && entry.claveElector.toLowerCase().includes(filtroClaveElector.toLowerCase())
                        && entry.nombreCompleto.toLowerCase().includes(filtroNombre.toLowerCase())
                        && entry.observacionesSistema.toLowerCase().includes(filtroOtrosSistemas.toLowerCase())
                    );
                    setDataSource(filteredData);
                }}
            >
                <Select.Option value="">Todos</Select.Option>
                <Select.Option value="Pendiente">Pendiente</Select.Option>
                <Select.Option value="Registrado en otro sistema">Registrado en otro sistema</Select.Option>
                <Select.Option value="Denegada">Denegada</Select.Option>
                <Select.Option value="Declinada">Declinada</Select.Option>
                <Select.Option value="Aprobada">Aprobada</Select.Option>
                <Select.Option value="Cancelada">Cancelada</Select.Option>
                <Select.Option value="No completó los requisitos">No completó los requisitos</Select.Option>
            </Select>
        </>
    );

    const FilterByOtrosSistema = (
        <>
            Validación contra otros sistemas
            <Input placeholder="Validación contra otros sistemas" value={filtroOtrosSistemas}
                onChange={(e) => {
                    const currValue = e.target.value;
                    setFiltroOtrosSistemas(currValue);
                    const filteredData = data.filter((entry) =>
                        entry.observacionesSistema.toLowerCase().includes(currValue.toLowerCase())
                        && entry.folio.toLowerCase().includes(filtroFolio.toLowerCase())
                        && entry.claveElector.toLowerCase().includes(filtroClaveElector.toLowerCase())
                        && entry.nombreCompleto.toLowerCase().includes(filtroNombre.toLowerCase())
                        && entry.descripcion.toLowerCase().includes(filtroEstadoGral.toLowerCase())
                    );
                    setDataSource(filteredData);
                }}
            />
        </>
    );

    //Se definen las columnas
    const columnas = [
        {
            title: "#",
            dataIndex: "indice",
        },
        {
            title: FilterByName,
            dataIndex: "nombreCompleto",
        },
        {
            title: FilterByClaveElector,
            dataIndex: "claveElector",
            width: 250,
        },
        {
            title: FilterByFolio,
            dataIndex: "folio",
            width: 150,
        },
        {
            title: "Fecha de nacimiento",
            dataIndex: "fechaNac",
            width: 120,
            render: (record) => moment(record).format("DD/MM/YYYY"),//Formato a la fecha y hora
        },
        {
            title: "Edad",
            dataIndex: "edad",
        },
        {
            title: "Sexo",
            dataIndex: "sexo",
        },
        {
            title: "Nivel de estudios",
            dataIndex: "escolaridad",
        },
        {
            title: FilterByEstadoGeneral,
            dataIndex: "descripcion",
        },
        {
            title: "Tipo de solicitud",
            dataIndex: "tipoSolicitud",
        },
        {
            title: "Procedencia de la solicitud",
            dataIndex: "tipoObs",
        },
        {
            title: "Fecha de recepción de la solicitud",
            dataIndex: "fechaSolicitud",
            render: (record) => moment(record).format("DD/MM/YYYY"),//Formato a la fecha
        },
        {
            title: "Fecha y hora del último cambio",
            dataIndex: "fechaHora",
            render: (record) => moment(record).format("DD/MM/YYYY HH:mm"),//Formato a la fecha y hora
        },
        {
            title: "Origen de la solicitud",
            dataIndex: "origenSolicitud",
        },
        {
            title: "Estado de la revisión",
            dataIndex: "estadoRevision",
        },
        {
            title: "Estado del curso",
            dataIndex: "estadoCurso",
        },
        {
            title: "Modalidad del curso",
            dataIndex: "modalidadCurso",
        },
        {
            title: "Fecha en que completó el curso",
            dataIndex: "fechaCompCurso",
            render: (record) => moment(record).isValid() ? moment(record).format("DD/MM/YYYY") : "-",//Formato a la fecha y hora
        },
        {
            title: "Instancia que imparte el curso",
            dataIndex: "institucion",
        },        
        {
            title: FilterByOtrosSistema,
            dataIndex: "estadoCruce",
            render: (row) => (
                <>
                  {row.cruce.map((mensaje, i) => (
                    <p key={i}>
                      {mensaje.observacion} {"  "}
                    </p>
                  ))}
                </>
            ),
        },
        {
            title: "Motivo de cancelación o denegación / Justificación de cambio",
            dataIndex: "motivo",
        },
        {
            title: "Fecha de aprobación de la acreditación",
            dataIndex: "fechaSesionAcreditacion",
            render: (record) => moment(record).isValid() ? moment(record).format("DD/MM/YYYY") : "-",//Formato a la fecha y hora
        },
        {
            title: "",
            fixed: "right",
            align: "center",
            render: (record) => (
                <>
                    {rolModulo.captura && (
                        <Button type="default" disabled={rolModulo.rolJL || !moduloAbierto}>
                            <Link to={{ pathname:"/admin/estadoSolicitud/modificar", solicitud:record,
                                    proceso:proceso, rolModulo:rolModulo }}>
                                Cambiar estado
                            </Link>
                        </Button>
                    )}
                    {!rolModulo.captura && (
                        <Button type="default" disabled={true}>
                            Cambiar estado
                        </Button>
                    )}
                </>
            ),
        }
    ];

    return (
        <>
            <Table columns={columnas}
                dataSource={dataSource}
                loading={loading}
                locale={{ emptyText: "Sin registros" }}
                scroll={{ x:true }}
            />
        </>
    );
}

export default withRouter(TableInbox);
