import React, { useState, useEffect } from "react";
import { Input, Icon, Tooltip, Table, message, Modal, Select } from "antd";
import { withRouter, Link } from "react-router-dom";
import { getDownload, deleteRegister, updateDocs } from "../../../api/registerAdmin";
import { ORIGEN_PORTAL_PUBLICO } from "../../../utils/constanst";

function TableRequests(props) {
  const {
    proceso,
    data, //Se recibe el array de las solicitudes
    rolModulo: { consulta, modifica, descarga, borrar }, //Para habilitar los permisos
    loading, moduloAbierto, 
  } = props; //Variables que se reciben por los props
  const [value, setValue] = useState(""); //Hook para el filtrado de nombre
  const [valclave, setValClave] = useState(""); //Hook para el filtrado de clave
  const [dataSourceReg, setDataSourceReg] = useState([]); //hook para almacenar el array
  const [valTipoSol, setValTipoSol] = useState(""); //Hook para el filtrado de tipo de solicitud
  const { Option } = Select;

  useEffect(() => {
    setDataSourceReg(data); //Se inicializa el array de solicitudes en el hook
  }, [data]);

  const download = (dataRow) => {
    getDownload(dataRow.idObservador)
      .then((res) => {
        if (res.data) {
          var urlCreator = window.URL || window.webkitURL || window.mozURL || window.msURL;
          const url = urlCreator.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `Documentos.zip`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      })
      .catch((error) => message.error("El archivo no se encuentra disponible"));
  };

  const actualizarDocs = (dataRow, proceso) => {
    updateDocs(dataRow.idObservador, proceso.proceso.idProcesoElectoral, proceso.proceso.idDetalleProceso)
    .then((res) => {
      if (res.data.code === 200) {
        Modal.success({
          content: res.data.message,
          okText: "Aceptar",
          onOk() {
          },
        });
      } else {
        Modal.error({
          content: res.data.message,
        });
      }
    })
    .catch((error) => message.error("La actualización de los documentos no se pudo realizar"));
  };

  function mostrarModal(record) {
    const { confirm } = Modal;
    var message = <div>
                    <p>Estás a punto de eliminar un registro de solicitud para Observador/a Electoral.</p>
                    <p><strong>Esta acción no se puede deshacer</strong></p>
                    <p>¿Deseas continuar?</p>
                  </div>;
    confirm({
      title: message,
      width: "500px",
      icon: null,
      okText: "Eliminar solicitud",
      cancelText: "Cancelar",
      cancelButtonProps: { className: "ant-btn-default" },
      onOk() {
        eliminarSolicitud(record);
      },
      onCancel(){},
   });
  }

  function modalDocs(record) {
    const { confirm } = Modal;
    var message = <div>
                    <p>Estás a punto de volver a generar o actualizar la solicitud de acreditación y el comprobante del ciudadano.</p>
                    <p><strong>Esta acción no se puede deshacer</strong></p>
                    <p>¿Deseas continuar?</p>
                  </div>;
    confirm({
      title: message,
      width: "500px",
      icon: null,
      okText: "Aceptar",
      cancelText: "Cancelar",
      cancelButtonProps: { className: "ant-btn-default" },
      onOk() {
        actualizarDocs(record, proceso);
      },
      onCancel(){},
   });
  }

  function eliminarSolicitud(solicitud) {
    if (solicitud !== undefined && borrar) {
      deleteRegister(solicitud.idObservador).then((res) => {
        if (res.data.code === 200) {
          Modal.success({
              title: "Eliminar solicitud.",
              content: <p>{res.data.message}</p>,
              okText: "Aceptar",
              onOk() { actualizarTabla(solicitud) }
            });
        } else {
            Modal.info({
              title: "Atención",
              content: <p>{res.data.causa}</p>
            });
        }
      });
    }
  }

  function actualizarTabla(registro) {
    const datos = [];
    for (let i = 0; i < dataSourceReg.length; i++) {
      if (dataSourceReg[i].idObservador !== registro.idObservador) {
        datos.push(dataSourceReg[i]);
      }
    }
    setDataSourceReg(datos);
  }

  //Función que filtra los registros por el nombre
  const FilterByNameInput = (
    <>
      Nombre
      <Input
        placeholder="Nombre del solicitante"
        value={value}
        onChange={(e) => {
          const currValue = e.target.value;
          setValue(currValue);
          const filteredData = data.filter((entry) =>
            entry.nombreCompleto
              .toString()
              .toLowerCase()
              .includes(currValue.toLowerCase())
          );
          setDataSourceReg(filteredData);
        }}
      />
    </>
  );
  //Función que filtra los registros por clave de elector
  const FilterByClaveInput = (
    <>
      Clave de Elector
      <Input
        placeholder="Clave de Elector"
        valclave={valclave}
        onChange={(e) => {
          const currValue = e.target.value;
          setValClave(currValue);
          const filteredData = data.filter((entry) =>
            entry.claveElector
              .toString()
              .toLowerCase()
              .includes(currValue.toLowerCase())
          );
          setDataSourceReg(filteredData);
        }}
      />
    </>
  );
  //Función que filtra los registros por tipo de solicitud
  const FilterByTipoSol = (
    <>
      Tipo de solicitud
      <br />
      <Select style={{ width: 130 }} optionFilterProp="children" placeholder="Todos" value={valTipoSol}
        onChange={(value) => {
          const currValue = value;
          setValTipoSol(currValue);
          const filteredData = data.filter((entry) =>
            entry.tipoSolCadena
              .toString()
              .toLowerCase()
              .includes(currValue.toLowerCase())
            );
            setDataSourceReg(filteredData);
        }}
      >
        <Option value="">Todos</Option>
        <Option value="Individual">Individual</Option>
        <Option value="Organización">Organización</Option>
      </Select>
    </>
  );

  //Se definen las columnas de la tabla de Solicitudes
  const columns = [
    {
      title: "#",
      dataIndex: "indice",
      key: "indice",
      width: 60,
      align: "center",
    },

    {
      title: FilterByNameInput,
      dataIndex: "nombreCompleto",
      key: "nombreCompleto",
    },

    {
      title: FilterByClaveInput,
      dataIndex: "claveElector",
      key: "claveElector",
    },

    {
      title: "Folio",
      dataIndex: "folio",
      key: "folio",
    },

    {
      title: FilterByTipoSol,
      dataIndex: "tipoSolCadena",
      key: "tipoSolicitud",
      //sorter: (a, b) => a.tipoSolicitud - b.tipoSolicitud,
    },
    {
      title: "Recibido por",
      dataIndex: "origenSolCadena",
      key: "origenSolicitud",
    },
    {
      title: "Usuario que capturó",
      dataIndex: "usuario",
      key: "usuario",
    },
    {
      title: "Acciones",
      // width: 200,
      fixed: "right",
      align: "center",
      render: (record) => (
        <>
          {consulta && (
            <Tooltip title="Visualizar">
              <Link
                to={{
                  pathname: "/admin/solicitudes/consulta",
                  data: record,
                }}
              >
                <button className="btn-circle btn_secundario">
                  <Icon type="eye" theme="filled" />
                </button>
              </Link>
            </Tooltip>
          )}{" "}
          {modifica && moduloAbierto && (
            <Tooltip title="Modificar">
              <Link
                disabled={record.acreditado || record.declinado} 
                to={{
                  pathname: "/admin/solicitudes/modifica",
                  data: record,
                }}
              >
                <button
                  disabled={record.acreditado || record.declinado}
                  className="btn-circle btn_secundario" 
                  >
                  <Icon type="edit" theme="filled" />
                </button>
              </Link>
            </Tooltip>
          )}{" "}
          {modifica && moduloAbierto && (
            <Tooltip title="Eliminar">
              <button 
                style={{ marginLeft: "0px" }}
                disabled={record.origenSolicitud === ORIGEN_PORTAL_PUBLICO||record.acreditado||record.avanceCursoFinal} 
                className="btn-circle btn_secundario"
                onClick={(e) => mostrarModal(record)}
              >
                <Icon type="delete" />
              </button>
            </Tooltip>
          )}{" "}
          {descarga && (
            <Tooltip title="Descargar documentos">
              <button 
                style={{ marginLeft: "0px" }}
                className="btn-circle btn_secundario"
                onClick={(e) => download(record)}
              >
                <Icon type="download" />
              </button>
            </Tooltip>
          )}{" "}
          {descarga && moduloAbierto && (
            <Tooltip title="Generar/actualizar documentos">
            <button 
              style={{ marginLeft: "0px" }}
              disabled={record.declinado}
              className="btn-circle btn_secundario"
              onClick={(e) => modalDocs(record)}
            >
              <Icon type="redo" />
            </button>
          </Tooltip>
          )}
        </>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={dataSourceReg}
      loading={loading}
      locale={{ emptyText: "Sin registros" }}
      scroll={{ x: true }}
    />
  );
}
export default withRouter(TableRequests);
