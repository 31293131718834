import ExcelJS from 'exceljs/dist/es5/exceljs.browser.js'
import { saveAs } from 'file-saver'

//Estilos generales
const fuenteTitulo = {
  name: "Arial",
  size: 10,
  bold: true
};

const fuenteDatos = {
  name:"Arial",
  size: 10
}

const estiloGris = {
  type: "pattern",
  pattern: "solid",
  fgColor: { argb: "CCC2C2C2" },
  bgColor: { argb: "CCC2C2C2" }
};

const bordesTitulos = {
    top: {style:'thin'},
    left: {style:'thin'},
    bottom: {style:'thin'},
    right: {style:'thin'}
};

const bordesDistrito = {
  top: {style:'thin'},
  bottom: {style:'thin'}
};

const bordesLeft = {
  left: {style:'thin'},
  top: {style:'thin'},
  bottom: {style:'thin'}
};

export async function descargaExcel(sheetName, nombreReporte, entidad, junta, datos, sistema) {
  //Creación del workbook
  const wb = new ExcelJS.Workbook()
  wb.creator = 'Sistema de Observadores Electorales';
  wb.lastModifiedBy = 'Sistema de Observadores Electorales';
  wb.created = new Date();
  //Creación de nueva hoja
  const sheet = wb.addWorksheet(sheetName);
  /*sheet.views = [
    {state: 'frozen', xSplit: 1}  // para fijar la primera columna
    ];*/
  //Datos generales del reporte
  sheet.mergeCells("A1:Q1");
  sheet.getCell("A1").value = "Proceso Electoral Local 2021-2022";
  sheet.getCell("A1").font = fuenteTitulo;
  sheet.getCell("A1").alignment = { horizontal: "center"};
  sheet.mergeCells("A2:Q2");
  sheet.getCell("A2").value = nombreReporte;
  sheet.getCell("A2").font = fuenteTitulo;
  sheet.getCell("A2").alignment = { horizontal: "center"};
  var fila;

  sheet.unMergeCells("A1");
  sheet.mergeCells("A1:J1");
  sheet.getCell("A1").value = "Proceso Electoral Local 2021-2022";
  sheet.unMergeCells("A2");
  sheet.mergeCells("A2:J2");
  sheet.getCell("A2").value = nombreReporte;
  const rowEstadoDistrito = sheet.getRow(3);
  if (entidad !== null) {
    if(junta !== null) {
        rowEstadoDistrito.values = [
            "Estado:", entidad, "Distrito Federal:", junta, 
        ]
    } else {
      rowEstadoDistrito.values = [
        "Estado:", entidad, "Junta Local"
      ]
    } 
  } 

  if (entidad !== null || junta !== null) {
    sheet.getCell("A3").font = fuenteTitulo;
    sheet.getCell("A3").fill = estiloGris;
    sheet.getCell("B3").font = fuenteDatos;
    sheet.getCell("C3").font = fuenteTitulo;
    sheet.getCell("C3").fill = estiloGris;
    sheet.getCell("D3").font = fuenteDatos;
  }

  const rowFechaGeneracion = sheet.getRow(4);
  if (entidad !== null && junta !== null) { //Nivel JD
    rowFechaGeneracion.values = [
        "Fecha y horario de generación:", `${sistema.fecha} ${sistema.hora}`, "", "", "", "", "Etapa: Preparación de la elección",
        "", "", "", "", "", "", "Etapa: Jornada Electoral", "", "", "", "", "", "", "Etapa: Resultados y declaración de validez de la elección", 
        "", "", "", "", "", "", "Etapa: Dictamen y declaraciones de validez de la elección", "", "", "", "", "", "",
        "Información electoral"
    ]
    sheet.getCell("G4").alignment = { horizontal: "center"}; // TITULO PRIMERA ETAPA
    sheet.getCell("G4").fill = estiloGris;
    sheet.getCell("G4").font = fuenteTitulo;
    sheet.getCell("G4").border = bordesTitulos;
    sheet.mergeCells("G4:M4");
    sheet.getCell("N4").alignment = { horizontal: "center"}; // TITULO SEGUNDA ETAPA
    sheet.getCell("N4").fill = estiloGris;
    sheet.getCell("N4").font = fuenteTitulo;
    sheet.getCell("N4").border = bordesTitulos;
    sheet.mergeCells("N4:T4");
    sheet.getCell("U4").alignment = { horizontal: "center"}; // TITULO TERCERA ETAPA
    sheet.getCell("U4").fill = estiloGris;
    sheet.getCell("U4").font = fuenteTitulo;
    sheet.getCell("U4").border = bordesTitulos;
    sheet.mergeCells("U4:AA4");
    sheet.getCell("AB4").alignment = { horizontal: "center"}; // TITULO CUARTA ETAPA
    sheet.getCell("AB4").fill = estiloGris;
    sheet.getCell("AB4").font = fuenteTitulo;
    sheet.getCell("AB4").border = bordesTitulos;
    sheet.mergeCells("AB4:AH4");
    sheet.getCell("AI4").alignment = { horizontal: "center"}; // TITULO MATERIALES
    sheet.getCell("AI4").fill = estiloGris;
    sheet.getCell("AI4").font = fuenteTitulo;
    sheet.getCell("AI4").border = bordesTitulos;
    sheet.mergeCells("AI4:AJ4");
  } else if (entidad !== null && junta === null) { //Nivel JL
    rowFechaGeneracion.values = [
      "Fecha y horario de generación:", `${sistema.fecha} ${sistema.hora}`, "", "", "", "", "",
      "Etapa: Preparación de la elección",
      "", "", "", "", "", "", "Etapa: Jornada Electoral", "", "", "", "", "", "", "Etapa: Resultados y declaración de validez de la elección", 
      "", "", "", "", "", "", "Etapa: Dictamen y declaraciones de validez de la elección", "", "", "", "", "", "",
      "Información electoral"
    ]
    sheet.getCell("H4").alignment = { horizontal: "center"}; // TITULO PRIMERA ETAPA
    sheet.getCell("H4").fill = estiloGris;
    sheet.getCell("H4").font = fuenteTitulo;
    sheet.getCell("H4").border = bordesTitulos;
    sheet.mergeCells("H4:N4");
    sheet.getCell("O4").alignment = { horizontal: "center"}; // TITULO SEGUNDA ETAPA
    sheet.getCell("O4").fill = estiloGris;
    sheet.getCell("O4").font = fuenteTitulo;
    sheet.getCell("O4").border = bordesTitulos;
    sheet.mergeCells("O4:U4");
    sheet.getCell("V4").alignment = { horizontal: "center"}; // TITULO TERCERA ETAPA
    sheet.getCell("V4").fill = estiloGris;
    sheet.getCell("V4").font = fuenteTitulo;
    sheet.getCell("V4").border = bordesTitulos;
    sheet.mergeCells("V4:AB4");
    sheet.getCell("AC4").alignment = { horizontal: "center"}; // TITULO CUARTA ETAPA
    sheet.getCell("AC4").fill = estiloGris;
    sheet.getCell("AC4").font = fuenteTitulo;
    sheet.getCell("AC4").border = bordesTitulos;
    sheet.mergeCells("AC4:AI4");
    sheet.getCell("AJ4").alignment = { horizontal: "center"}; // TITULO MATERIALES
    sheet.getCell("AJ4").fill = estiloGris;
    sheet.getCell("AJ4").font = fuenteTitulo;
    sheet.getCell("AJ4").border = bordesTitulos;
    sheet.mergeCells("AJ4:AK4");
  } else { //Nivel OC
    rowFechaGeneracion.values = [
      "Fecha y horario de generación:", `${sistema.fecha} ${sistema.hora}`, "", "", "", "", "", "",
      "Etapa: Preparación de la elección",
      "", "", "", "", "", "", "Etapa: Jornada Electoral", "", "", "", "", "", "", "Etapa: Resultados y declaración de validez de la elección", 
      "", "", "", "", "", "", "Etapa: Dictamen y declaraciones de validez de la elección", "", "", "", "", "", "",
      "Información electoral"
    ]
    sheet.getCell("I4").alignment = { horizontal: "center"}; // TITULO PRIMERA ETAPA
    sheet.getCell("I4").fill = estiloGris;
    sheet.getCell("I4").font = fuenteTitulo;
    sheet.getCell("I4").border = bordesTitulos;
    sheet.mergeCells("I4:O4");
    sheet.getCell("P4").alignment = { horizontal: "center"}; // TITULO SEGUNDA ETAPA
    sheet.getCell("P4").fill = estiloGris;
    sheet.getCell("P4").font = fuenteTitulo;
    sheet.getCell("P4").border = bordesTitulos;
    sheet.mergeCells("P4:V4");
    sheet.getCell("W4").alignment = { horizontal: "center"}; // TITULO TERCERA ETAPA
    sheet.getCell("W4").fill = estiloGris;
    sheet.getCell("W4").font = fuenteTitulo;
    sheet.getCell("W4").border = bordesTitulos;
    sheet.mergeCells("W4:AC4");
    sheet.getCell("AD4").alignment = { horizontal: "center"}; // TITULO CUARTA ETAPA
    sheet.getCell("AD4").fill = estiloGris;
    sheet.getCell("AD4").font = fuenteTitulo;
    sheet.getCell("AD4").border = bordesTitulos;
    sheet.mergeCells("AD4:AJ4");
    sheet.getCell("AK4").alignment = { horizontal: "center"}; // TITULO MATERIALES
    sheet.getCell("AK4").fill = estiloGris;
    sheet.getCell("AK4").font = fuenteTitulo;
    sheet.getCell("AK4").border = bordesTitulos;
    sheet.mergeCells("AK4:AL4");
  }
  sheet.getCell("A4").font = fuenteTitulo;
  sheet.getCell("A4").fill = estiloGris;
  sheet.getCell("B4").font = fuenteDatos;

  if (entidad === null) {
    // nivel OC
    const divisiones = [
      "A5", "I5", "P5", "W5", "AD5", "AK5", "AM5", "AN5"
    ]
    const rowTituloDatos = sheet.getRow(5);
    rowTituloDatos.height = 42;
    rowTituloDatos.values = [
      "Estado", "Distrito",
      "Nombre del observador", "Clave de elector", "Folio", "Tipo de Solicitud", "Agrupación", "Jornada Electoral en la que participó", 
      "Entidad", "Municipio", "Distrito Federal", "Distrito Local", "Incidentes", "Actividades", "Propuesta",
      "Entidad", "Municipio", "Distrito Federal", "Distrito Local", "Incidentes", "Actividades", "Propuesta",
      "Entidad", "Municipio", "Distrito Federal", "Distrito Local", "Incidentes", "Actividades", "Propuesta",
      "Entidad", "Municipio", "Distrito Federal", "Distrito Local", "Incidentes", "Actividades", "Propuesta",
      "En materia de", "Detalle", "Medio de Captura", "Fecha de elaboración"
    ];
    rowTituloDatos.font = fuenteTitulo;
    rowTituloDatos.eachCell(function(cell) {
      cell.fill = estiloGris;
      cell.border = bordesDistrito;
      cell.alignment = { vertical: "middle", horizontal: "center", wrapText: true };
      if (divisiones.some(v => (v === cell.address.toString()))) {
        cell.border = bordesLeft;
        if (cell.address.toString() === "AN5") {
          cell.border = bordesTitulos;
        }
      }
    });
    //Datos del reporte
    fila = 6;
    datos.forEach((e) => {
      const rowDatos = sheet.getRow(fila);
      rowDatos.font = fuenteDatos;
      rowDatos.values = [
        e.nombreEstado, e.nombreDistrito,
        e.nombreObservador, e.claveElector, e.folio, e.tipoSolicitud, e.agrupacion, e.proceso, 
        e.entidadE1, e.municipioE1, e.distritoFederalE1, e.distritoLocalE1, e.incidentesE1, e.actividadesE1, e.propuestaE1,
        e.entidadE2, e.municipioE2, e.distritoFederalE2, e.distritoLocalE2, e.incidentesE2, e.actividadesE2, e.propuestaE2,
        e.entidadE3, e.municipioE3, e.distritoFederalE3, e.distritoLocalE3, e.incidentesE3, e.actividadesE3, e.propuestaE3,
        e.entidadE4, e.municipioE4, e.distritoFederalE4, e.distritoLocalE4, e.incidentesE4, e.actividadesE4, e.propuestaE4,
        e.materiales, e.detalle, e.medioCaptura, e.fechaInforme
      ];
      fila++;
    });
    sheet.getColumn(1).width = 35; //nombreEstado
    sheet.getColumn(2).width = 35; //nombreDistrito
    sheet.getColumn(3).width = 40; //nombre
    sheet.getColumn(4).width = 25; //clave
    sheet.getColumn(5).width = 15; //folio
    sheet.getColumn(6).width = 15; //tipoSolicitud
    sheet.getColumn(7).width = 20; //organizacion
    sheet.getColumn(8).width = 20; //proceso
    sheet.getColumn(9).width = 25; // Datos de la primera etapa
    sheet.getColumn(10).width = 25;
    sheet.getColumn(11).width = 25;
    sheet.getColumn(12).width = 25;
    sheet.getColumn(13).width = 35;
    sheet.getColumn(14).width = 35;
    sheet.getColumn(15).width = 35;
    sheet.getColumn(16).width = 25; // Datos de la segunda etapa
    sheet.getColumn(17).width = 25;
    sheet.getColumn(18).width = 25;
    sheet.getColumn(19).width = 25;
    sheet.getColumn(20).width = 35;
    sheet.getColumn(21).width = 35;
    sheet.getColumn(22).width = 35;
    sheet.getColumn(23).width = 25; // Datos de la tercera etapa
    sheet.getColumn(24).width = 25;
    sheet.getColumn(25).width = 25;
    sheet.getColumn(26).width = 25;
    sheet.getColumn(27).width = 35;
    sheet.getColumn(28).width = 35;
    sheet.getColumn(29).width = 35;
    sheet.getColumn(30).width = 25; // Datos de la cuarta etapa
    sheet.getColumn(31).width = 25;
    sheet.getColumn(32).width = 25;
    sheet.getColumn(33).width = 25;
    sheet.getColumn(34).width = 35;
    sheet.getColumn(35).width = 35;
    sheet.getColumn(36).width = 35;
    sheet.getColumn(37).width = 25; //materiales electorales
    sheet.getColumn(38).width = 25; // detalle de los materiales
    sheet.getColumn(39).width = 15; // medio de captura
    sheet.getColumn(40).width = 15; // fecha de elaboración
  } else {
    if (junta === null) {
      // nivel JL
      const divisiones = [
        "A5", "H5", "O5", "V5", "AC5", "AJ5", "AL5", "AM5"
      ]
      const rowTituloDatos = sheet.getRow(5);
      rowTituloDatos.height = 42;
      rowTituloDatos.values = [
        "Distrito",
        "Nombre del observador", "Clave de elector", "Folio", "Tipo de Solicitud", "Agrupación", "Jornada Electoral en la que participó", 
        "Entidad", "Municipio", "Distrito Federal", "Distrito Local", "Incidentes", "Actividades", "Propuesta",
        "Entidad", "Municipio", "Distrito Federal", "Distrito Local", "Incidentes", "Actividades", "Propuesta",
        "Entidad", "Municipio", "Distrito Federal", "Distrito Local", "Incidentes", "Actividades", "Propuesta",
        "Entidad", "Municipio", "Distrito Federal", "Distrito Local", "Incidentes", "Actividades", "Propuesta",
        "En materia de", "Detalle", "Medio de Captura", "Fecha de elaboración"
      ];
      rowTituloDatos.font = fuenteTitulo;
      rowTituloDatos.eachCell(function(cell) {
        cell.fill = estiloGris;
        cell.border = bordesDistrito;
        cell.alignment = { vertical: "middle", horizontal: "center", wrapText: true };
        if (divisiones.some(v => (v === cell.address.toString()))) {
          cell.border = bordesLeft;
          if (cell.address.toString() === "AM5") {
            cell.border = bordesTitulos;
          }
        }
      });
      //Datos del reporte
      fila = 6;
      datos.forEach((e) => {
        const rowDatos = sheet.getRow(fila);
        rowDatos.font = fuenteDatos;
        rowDatos.values = [
          e.nombreDistrito,
          e.nombreObservador, e.claveElector, e.folio, e.tipoSolicitud, e.agrupacion, e.proceso, 
          e.entidadE1, e.municipioE1, e.distritoFederalE1, e.distritoLocalE1, e.incidentesE1, e.actividadesE1, e.propuestaE1,
          e.entidadE2, e.municipioE2, e.distritoFederalE2, e.distritoLocalE2, e.incidentesE2, e.actividadesE2, e.propuestaE2,
          e.entidadE3, e.municipioE3, e.distritoFederalE3, e.distritoLocalE3, e.incidentesE3, e.actividadesE3, e.propuestaE3,
          e.entidadE4, e.municipioE4, e.distritoFederalE4, e.distritoLocalE4, e.incidentesE4, e.actividadesE4, e.propuestaE4,
          e.materiales, e.detalle, e.medioCaptura, e.fechaInforme
        ];
        fila++;
      });
      sheet.getColumn(1).width = 35; //nombreDistrito
      sheet.getColumn(2).width = 40; //nombre
      sheet.getColumn(3).width = 25; //clave
      sheet.getColumn(4).width = 15; //folio
      sheet.getColumn(5).width = 15; //tipoSolicitud
      sheet.getColumn(6).width = 20; //organizacion
      sheet.getColumn(7).width = 20; //proceso
      sheet.getColumn(8).width = 25; // Datos de la primera etapa
      sheet.getColumn(9).width = 25;
      sheet.getColumn(10).width = 25;
      sheet.getColumn(11).width = 25;
      sheet.getColumn(12).width = 35;
      sheet.getColumn(13).width = 35;
      sheet.getColumn(14).width = 35;
      sheet.getColumn(15).width = 25; // Datos de la segunda etapa
      sheet.getColumn(16).width = 25;
      sheet.getColumn(17).width = 25;
      sheet.getColumn(18).width = 25;
      sheet.getColumn(19).width = 35;
      sheet.getColumn(20).width = 35;
      sheet.getColumn(21).width = 35;
      sheet.getColumn(22).width = 25; // Datos de la tercera etapa
      sheet.getColumn(23).width = 25;
      sheet.getColumn(24).width = 25;
      sheet.getColumn(25).width = 25;
      sheet.getColumn(26).width = 35;
      sheet.getColumn(27).width = 35;
      sheet.getColumn(28).width = 35;
      sheet.getColumn(29).width = 25; // Datos de la cuarta etapa
      sheet.getColumn(30).width = 25;
      sheet.getColumn(31).width = 25;
      sheet.getColumn(32).width = 25;
      sheet.getColumn(33).width = 35;
      sheet.getColumn(34).width = 35;
      sheet.getColumn(35).width = 35;
      sheet.getColumn(36).width = 25; //materiales electorales
      sheet.getColumn(37).width = 25; // detalle de los materiales
      sheet.getColumn(38).width = 15; // medio de captura
      sheet.getColumn(39).width = 15; // fecha de elaboración
    } else {
      // nivel JD
      const divisiones = [
        "A5", "G5", "N5", "U5", "AB5", "AI5", "AK5", "AL5"
      ]
      const rowTituloDatos = sheet.getRow(5);
      rowTituloDatos.height = 42;
      rowTituloDatos.values = [
          "Nombre del observador", "Clave de elector", "Folio", "Tipo de Solicitud", "Agrupación", "Jornada Electoral en la que participó", 
          "Entidad", "Municipio", "Distrito Federal", "Distrito Local", "Incidentes", "Actividades", "Propuesta",
          "Entidad", "Municipio", "Distrito Federal", "Distrito Local", "Incidentes", "Actividades", "Propuesta",
          "Entidad", "Municipio", "Distrito Federal", "Distrito Local", "Incidentes", "Actividades", "Propuesta",
          "Entidad", "Municipio", "Distrito Federal", "Distrito Local", "Incidentes", "Actividades", "Propuesta",
          "En materia de", "Detalle", "Medio de Captura", "Fecha de elaboración"
      ];
      rowTituloDatos.font = fuenteTitulo;
      rowTituloDatos.eachCell(function(cell) {
          cell.fill = estiloGris;
          cell.border = bordesDistrito;
          cell.alignment = { vertical: "middle", horizontal: "center", wrapText: true };
          if (divisiones.some(v => (v === cell.address.toString()))) {
            cell.border = bordesLeft;
            if (cell.address.toString() === "AL5") {
              cell.border = bordesTitulos;
            }
          }
      });
      //Datos del reporte
      fila = 6;
      datos.forEach((e) => {
          const rowDatos = sheet.getRow(fila);
          rowDatos.font = fuenteDatos;
          rowDatos.values = [                
              e.nombreObservador, e.claveElector, e.folio, e.tipoSolicitud, e.agrupacion, e.proceso, 
              e.entidadE1, e.municipioE1, e.distritoFederalE1, e.distritoLocalE1, e.incidentesE1, e.actividadesE1, e.propuestaE1,
              e.entidadE2, e.municipioE2, e.distritoFederalE2, e.distritoLocalE2, e.incidentesE2, e.actividadesE2, e.propuestaE2,
              e.entidadE3, e.municipioE3, e.distritoFederalE3, e.distritoLocalE3, e.incidentesE3, e.actividadesE3, e.propuestaE3,
              e.entidadE4, e.municipioE4, e.distritoFederalE4, e.distritoLocalE4, e.incidentesE4, e.actividadesE4, e.propuestaE4,
              e.materiales, e.detalle, e.medioCaptura, e.fechaInforme
          ];
          fila++;
      });
      sheet.getColumn(1).width = 40; //nombre
      sheet.getColumn(2).width = 25; //clave
      sheet.getColumn(3).width = 15; //folio
      sheet.getColumn(4).width = 15; //tipoSolicitud
      sheet.getColumn(5).width = 20; //organizacion
      sheet.getColumn(6).width = 20; //proceso
      sheet.getColumn(7).width = 25; // Datos de la primera etapa
      sheet.getColumn(8).width = 25;
      sheet.getColumn(9).width = 25;
      sheet.getColumn(10).width = 25;
      sheet.getColumn(11).width = 35;
      sheet.getColumn(12).width = 35;
      sheet.getColumn(13).width = 35;
      sheet.getColumn(14).width = 25; // Datos de la segunda etapa
      sheet.getColumn(15).width = 25;
      sheet.getColumn(16).width = 25;
      sheet.getColumn(17).width = 25;
      sheet.getColumn(18).width = 35;
      sheet.getColumn(19).width = 35;
      sheet.getColumn(20).width = 35;
      sheet.getColumn(21).width = 25; // Datos de la tercera etapa
      sheet.getColumn(22).width = 25;
      sheet.getColumn(23).width = 25;
      sheet.getColumn(24).width = 25;
      sheet.getColumn(25).width = 35;
      sheet.getColumn(26).width = 35;
      sheet.getColumn(27).width = 35;
      sheet.getColumn(28).width = 25; // Datos de la cuarta etapa
      sheet.getColumn(29).width = 25;
      sheet.getColumn(30).width = 25;
      sheet.getColumn(31).width = 25;
      sheet.getColumn(32).width = 35;
      sheet.getColumn(33).width = 35;
      sheet.getColumn(34).width = 35;
      sheet.getColumn(35).width = 25; //materiales electorales
      sheet.getColumn(36).width = 25; // detalle de los materiales
      sheet.getColumn(37).width = 15; // medio de captura
      sheet.getColumn(38).width = 15; // fecha de elaboración
    }
  }

  //Creación del excel
  const buf = await wb.xlsx.writeBuffer();
  saveAs(new Blob([buf]), nombreReporte + ".xlsx");
}