//Validaciones a los inputs
export const inputNumber = [
  { required: true, message: "Dato requerido." },
  { message: " ", pattern: /^[0-9]+$/ },
  //{ min: 4, message: "INE inválida." },
  { validateStatus: " " },
];

export const inputNumberCG = [
  { required: true, message: "Dato requerido." },
  { message: " ", pattern: /^[0-9]+$/ },
  { min: 4, message: "Año inválido." },
  { validateStatus: " " },
];

export const texto = [
  {
    message: " ",
    pattern: /^[a-z]+[^\\_!"#$%&()=¿?/¡*¨'°|:;<>A-ZáéíóúÁÉÍÓÚÑñ0-9]+$/,
  },
  { validateStatus: "error" },
  { min: 4, message: " " },
];

export const textoNumeros = [
  {
    message: " ",
    pattern: /^[a-zA-Z-áéíóúÁÉÍÓÚÑñ0-9]+[^{}\\_!"#$%&()=?/¡*'¨°|;<>-]+$/,
  },
  { validateStatus: "error" },
  { min: 5, message: " " },
];

export const domicilio = [
  {
    message: " ",
    pattern: /^[a-zA-Z-áéíóúüÁÉÍÓÚÜ0-9]+[^\\_!"$%&()=?¡*'¨°|<>]+$/,
  },
  { validateStatus: "error" },
  { min: 5, message: " " },
];

export const inputTel = [
  { message: "Teléfono inválido.", pattern: /^[0-9]+$/ },
  { min: 10, message: "Teléfono inválido." },
  { validateStatus: "error" },
];

export const required = [{ required: true, message: "Dato requerido." }];
//////////////////////////Organizacion//////////////////////////////////////
export const soloTexto = [
  {
    message: "Caracteres inválidos.",
    pattern: /^[a-zA-Z-äëïöüÄËÏÖÜáéíóúÁÉÍÓÚÑñ]+[^0-9{}\\_!"´#$%&()=?/¡*'¨°|;<>-]+$/,
  },
  { required: true, message: "Dato requerido." },
  { validateStatus: "error" },
  { min: 3, message: "Mínimo 3 caracteres." },
];

export const codigo = [
  {
    message: "Caracteres inválidos.",
    pattern: /^[A-Z0-9]+[^áéíóúÁÉÍÓÚÑñ{}\\_!"´#$%&()=?/¡*'¨°|;<>-]+$/,
  },
  { required: true, message: "Dato requerido." },
  { validateStatus: "error" },
  //{ min: 3, message: "Mínimo 3 caracteres." },
];

export const RFC = [
  {
    message: "RFC inválido.",
    pattern: /^[A-ZÑ]{3,4}[0-9]{6}([A-Z0-9]{3})*$/,
  },
  //{ required: true, message: "Dato requerido." },
  { validateStatus: "error" },
  { min: 12, message: " " },
];

export const correo = [
  { validateStatus: "error" },
  {
    type: "email",
    message: "Correo Electrónico inválido.",
  },
];

export const nombres = [
  {
    message: "Caracteres inválidos.",
    pattern: /^[a-zA-Z-äëïöüÄËÏÖÜáéíóúÁÉÍÓÚÑñ]+[^0-9{}\\_!"´#$%&()=?/¡*'¨°|;,<>-]+$/,
  },
  { required: true, message: "Dato requerido." },
  { validateStatus: "error" },
];

export const ocupacion = [
  {
    message: "Caracteres inválidos.",
    pattern: /^[a-zA-Z-äëïöüÄËÏÖÜáéíóúÁÉÍÓÚÑñ]+[^0-9{}\\_!"´#$%&()=?/¡*'¨°|;,<>-]+$/,
  },
  { validateStatus: "error" },
];

export const apellidos = [
  {
    message: "Caracteres inválidos.",
    pattern: /^[a-zA-Z-äëïöüÄËÏÖÜáéíóúÁÉÍÓÚÑñ]+[^0-9{}\\_!"´#$%&()=?/¡*'¨°|;,<>-]+$/,
  },
  { validateStatus: "error" },
];

export const tel = [
  { message: "Teléfono inválido.", pattern: /^[0-9]+$/ },
  { min: 10, message: "Teléfono inválido." },
  { validateStatus: "error" },
];

///////////////Validacion de solicitudes
export const radioButton = [{ required: true, message: "Dato Requerido." }];

export const observaciones = [
  {
    message: "Caracteres inválidos.",
    pattern: /^[a-zA-Z-áéíóúÁÉÍÓÚÑñ0-9]+[^{}\\_!"#$%&()=/¡|"*'¨°|;<>-]+$/,
  },
  { required: true, message: "Dato Requerido." },
  { validateStatus: "error" },
  { min: 3, message: "Mínimo 3 caracteres." },
];

///////Registro de solicitudes
export const claveElector = [
  {
    message: "Clave de elector inválida.",
    pattern: /^([A-Z]{5,6}\d{8}[H|M]\d{3})$|^([0-9]{13})$/,
  },
  { required: true, message: "Dato requerido." },
  { min: 18, message: " " },
  { validateStatus: "error" },
];
export const soloDigitos = /^\d*$/;
export const numeroInt = /^[a-zA-Z-äëïöüÄËÏÖÜáéíóúÁÉÍÓÚÑñ0-9,;#'/\s.]*$/;
export const calle = [
  {
    message: "Caracteres inválidos.",
    pattern: /^[a-zA-Z-áéíóúüÁÉÍÓÚÜ0-9]+[^\\_\\!"$%&{}+\]()\\[=?¡^`*¨°|<>]+$/,
  },
  { required: true, message: "Dato requerido." },
  { validateStatus: "error" },
  //{ min: 5, message: "Mínimo 5 caracteres." },
];

export const calleOrganizacion= [
  {
    message: "Caracteres inválidos.",
    pattern: /^[a-zA-Z-áéíóúüÁÉÍÓÚÜ0-9]+[^\\_\\!"$%&{}+\]()\\[=?¡^`*¨°|<>]+$/,
  },
  { required: false, message: "Dato requerido." },
  { validateStatus: "error" },
  //{ min: 5, message: "Mínimo 5 caracteres." },
];

export const numero = [
  {
    message: "Caracteres inválidos.",
    pattern: /^[a-zA-Z-áéíóúüÁÉÍÓÚÜ0-9]+[^\\_!"$%&()=?¡*'¨°|<>]+$/,
  },  
  { validateStatus: "error" },
];

export const cp = [
  {
    message: "Código postal inválido.",
    pattern: /^[0-9]+$/,
  },
  { required: true, message: "Dato requerido." },
  { validateStatus: "error" },
  { min: 5, message: "Código postal inválido." },
];

export const cpOrganizacion = [
  {
    message: "Código postal inválido.",
    pattern: /^[0-9]+$/,
  },
  { required: false, message: "Dato requerido." },
  { validateStatus: "error" },
  { min: 5, message: "Código postal inválido." },
];

export const seccion = [
  { message: "Sección inválida.", pattern: /^[0-9]+$/ },
  { min: 4, message: "Sección inválida." },
  { required: true, message: "Dato requerido." },
  { validateStatus: "error" },
];