import React, { useEffect, useState } from "react";
import { Button, Col, DatePicker, Icon, Modal, Row } from "antd";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import { Ast } from "../../../../utils/Reports/functions";
import { getFechaInicioTransp, getReporteTransparencia } from "../../../../api/reports";
import "./Transparency.scss";
import TableTransp from "../../../../components/Admin/Transparency/TableTransp";
import { descargaExcelTransparencia } from "../../../../components/Admin/Transparency/xls";

function Transparency(props) {
    const { proceso } = props;
    const [ reporteTransparencia, setReporteTransparencia] = useState({
        info: [],
        loading: false,
    });
    const dateFormat = 'DD/MM/YYYY';
    let idProceso, idDetalle, idEstado, nombreEstado, idDistrito, nombreDistrito;
    //Se verica que este seleccionado un proceso electoral
    if(proceso.proceso) {
        idProceso = proceso.proceso.idProcesoElectoral;
        idDetalle = proceso.proceso.idDetalleProceso;
        idEstado = proceso.estado ? proceso.estado.idEstado : 0;
        nombreEstado = proceso.estado ? proceso.estado.nombreEstado : " ";
        idDistrito = proceso.distrito ? proceso.distrito.idDistrito : 0;
        nombreDistrito = proceso.distrito ? proceso.distrito.idDistrito + '. '+ proceso.distrito.nombreDistrito : " ";
    }

    useEffect(() => {
        if(idProceso) {
            setReporteTransparencia({ loading: true });
            getFechaInicioTransp(idProceso, "FECHAINICIOTRANSP", idDetalle).then((res) => {
                const fechaInicio = res.data;
                if(fechaInicio !== null) {
                    const fechaFin = moment(new Date()).format(dateFormat);
                    getReporteTransparencia(idProceso, idDetalle, idEstado, idDistrito, fechaInicio, fechaFin).then((res) => {
                        const dataSource = res.data.transparencia;
                        setReporteTransparencia({ info: dataSource, loading: false });
                    }).catch((error) => {
                        console.log(error);
                    });
                } else {
                    console.log("no hay fecha configurada");
                    Modal.error({
                        content: "No hay fecha inicial configurada",
                    });
                }
            }).catch((error) => {
                console.log(error);
                Modal.error({
                    content: "No hay fecha inicial configurada",
                });
            });
        }
    }, [idProceso, idDetalle, idEstado, nombreEstado, idDistrito, nombreDistrito]);

    const UltimaActualizacion = () => {
        const fechaHora = new Date();
        moment.locale("es");
        const fecha = moment(fechaHora).format("DD/MMMM/YYYY");
        const hora = moment(fechaHora).format("HH:mm:ss");
        return (
            <div className="fechaHoraDer">
                <Icon type="calendar" /> {fecha} <Icon type="clock-circle" /> {hora}
            </div>
        );
    }

    const Etiqueta = () => {
        if(idDistrito !== 0) {
            return ( <div>{ nombreDistrito }</div> );
        } else {
            if (idEstado !== 0) {
                return ( <div>{ nombreEstado }</div> );
            } else {
                return ( <div><p>NACIONAL</p></div> );
            }
        }
    }

    const Registros = () => {
        var total = 0;
        if(reporteTransparencia.info) {
            total = reporteTransparencia.info.length;
        }
        return (
            <div className="registrosDer" >
                {total}
            </div>
        );
    }

    const downloadExcel = () => {
        descargaExcelTransparencia(reporteTransparencia.info);
    }

    function validaBoton() {
        var band = true;
        if(reporteTransparencia.info) {
            if(reporteTransparencia.info.length > 0) {
                band = false;
                return band;
            } else
                return band;
        }
        return band;
    }

    function consultar(date, dateString) {
        setReporteTransparencia({ loading: true });
        getFechaInicioTransp(idProceso, "FECHAINICIOTRANSP", idDetalle).then((res) => {
            const fechaInicio = res.data;
            if(fechaInicio !== null) {
                const fechaFin = dateString;
                getReporteTransparencia(idProceso, idDetalle, idEstado, idDistrito, fechaInicio, fechaFin).then((res) => {
                    const dataSource = res.data.transparencia;
                    setReporteTransparencia({ info: dataSource, loading: false });
                }).catch((error) => {
                    console.log(error);
                });
            } else {
                console.log("no hay fecha configurada");
                Modal.error({
                    content: "No hay fecha inicial configurada",
                });
            }
        }).catch((error) => {
            console.log(error);
            Modal.error({
                content: "No hay fecha inicial configurada",
            });
        });
    }

    return (
        <div>
            <Row>
                <Link to={"/admin/reportes"} className="back">
                    <Icon type="play-circle" theme="filled" rotate="180" /> Volver a 
                    "Reportes"
                </Link>
            </Row>
            <Row className="row20px">
                <Col span={12}>
                    <h2>Transparencia (Art. 74 fracción 1 inciso a)</h2>
                </Col>
                <Col span={12}>
                    <div className="ultimaAct">
                        Última actualización
                    </div>
                    <UltimaActualizacion />
                </Col>
            </Row>
            <Row>
                <small>Los datos con ( <Ast /> ) son requeridos</small>
            </Row>
            <Row className="row20px">
                <h4><Ast /> Fecha de término del periodo que se informa</h4>
                <DatePicker defaultValue={moment(new Date())} format={dateFormat} onChange={consultar} />
            </Row>
            <Row className="row20px">
                <Col span={12}>
                    <Etiqueta />
                </Col>
                <Col span={12}>
                    <Registros />
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <div className="etiquetaExcel">
                        Descarga el reporte:
                        <Button onClick={() => downloadExcel()} className="btn_descarga_reporte" type="link"
                                disabled={validaBoton()}>
                            <Icon type="file-excel" />Excel
                        </Button>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="etiquetaRegistros">
                        Registros
                    </div>
                </Col>
            </Row>
            <Row>
                <TableTransp data={reporteTransparencia.info}
                    loading={reporteTransparencia.loading}
                />
            </Row>
        </div>
    );
}

export default withRouter(Transparency);