export const rol_captura = [
    "OBSERVADORES11.ADMIN.PARAM.CAPTURA.OC",
    "OBSERVADORES11.ADMIN.CAPTURA.OC",
    "OBSERVADORES11.CAPTURA.JL",
    "OBSERVADORES.CAPTURA.OPLE.JL",
    "OBSERVADORES11.CAPTURA.JD",
    "OBSERVADORES11.CAU.OC", //Cau
  ];
  
  export const rol_consulta = [
    "OBSERVADORES11.ADMIN.PARAM.CAPTURA.OC",
    "OBSERVADORES11.ADMIN.CAPTURA.OC",
    "OBSERVADORES11.CAPTURA.JL",
    "OBSERVADORES11.CONSULTA.JL",
    "OBSERVADORES.CAPTURA.OPLE.JL",
    "OBSERVADORES.CONSULTA.OPLE.JL",
    "OBSERVADORES11.CAPTURA.JD",
    "OBSERVADORES11.CONSULTA.JD",
    "OBSERVADORES11.CAU.OC", //Cau
    "OBSERVADORES11.CONSEJERO.OC",
    "OBSERVADORES11.CONSULTA.OC"
  ];

  export const rol_modifica = [
    "OBSERVADORES11.ADMIN.PARAM.CAPTURA.OC",
    "OBSERVADORES11.ADMIN.CAPTURA.OC",
    "OBSERVADORES11.CAPTURA.JL",
    "OBSERVADORES.CAPTURA.OPLE.JL",
    "OBSERVADORES11.CAPTURA.JD",
    "OBSERVADORES11.CAU.OC", //Cau
  ];
  
  export const rol_borrar = [
    "OBSERVADORES11.ADMIN.PARAM.CAPTURA.OC",
    "OBSERVADORES11.ADMIN.CAPTURA.OC",
    "OBSERVADORES11.CAPTURA.JL",
    "OBSERVADORES.CAPTURA.OPLE.JL",
    "OBSERVADORES11.CAPTURA.JD"
  ];

  export const rol_OC = 1;
  export const rol_JL = 2;
  export const rol_JD = 3;
  export const rol_OPL = 4;
  