import React, { useState, useEffect } from "react";
import useAuth from "../../../hooks/useAuth";
import { Table, Modal, Icon, Tooltip, Alert, Row  } from "antd";
import { Link, withRouter, BrowserRouter } from "react-router-dom";
import { deleteCourse, saveParticipants, getParticipantes } from "../../../api/courses";
import TableParticipantes from "../../../components/Admin/Courses/TableParticipantes";
import { ESTATUS_CURSO_INSCRITO, MSN_GUARDAR } from "../../../utils/constanst";
import "./Components.scss";

function TableCourses(props) {
  const { data, rolModulo: { consulta, modifica, elimina, cau }, loading, proceso, setReload, setLoader, moduloAbierto } = props; 
  const [dataSource, setDataSource] = useState([]);
  const [curso, setCurso] = useState([]);
  const [participantes, setParticipantes] = useState([]);
  const { user: { user } } = useAuth();

  useEffect(() => {
    setDataSource(data);
  }, [data]);

  useEffect(() => {
    if (curso !== undefined && participantes !== undefined && curso.idCurso !== undefined && participantes.length > 0) {
      mostrarModalCargado();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps    
  }, [participantes]);

  function mostrarModal(record) {
    const { confirm } = Modal;
    var message = <div>
                    <div style={{ paddingBottom: "20px" }}>
                      {(record.totalParticipantes > 0) ? <Alert
                        message={<strong>El curso seleccionado tiene {record.totalParticipantes} personas inscritas</strong>}
                        type="warning"
                        showIcon
                      />: null}
                    </div>
                    <p>Estás a punto de <strong>eliminar el curso</strong> impartido por <strong>{record.organoImparte} </strong> 
                    el {record.fecha}.</p>
                    <p><strong>Esta acción no se puede deshacer</strong></p>
                    <p>¿Deseas continuar?</p>
                  </div>;
    confirm({
      title: message,
      width: "500px",
      icon: null,
      okText: "Eliminar el curso",
      cancelText: "Cancelar",
      cancelButtonProps: { className: "ant-btn-default" },
      className: "modalNotifi",
      onOk() {
        setLoader({ loading: true, message: MSN_GUARDAR }); //Se activa el loader
        eliminarCurso(record);
      },
      onCancel(){},
   });
  }

  function eliminarCurso(curso) {
    if (curso !== undefined && !cau) {
      deleteCourse(curso.idCurso).then((res) => {
        setLoader({ loading: false, message: "" }); //Se desactiva el loader
        if (res.data.code === 200) {
          Modal.success({
              title: "El curso ha sido eliminado",
              content: <p>{res.data.message}</p>,
              okText: "Aceptar",
              onOk() { actualizarComponente() }
            });
        } else {
            Modal.info({
              title: "Atención",
              content: <p>{res.data.causa}</p>
            });
        }
      });
    }
  }

  const buscarParticipantes = (record) => {
    setCurso(record);
    const dataResult = [];
    if (record.idCurso !== undefined) {
      getParticipantes(proceso.proceso.idProcesoElectoral, proceso.proceso.idDetalleProceso, 
        proceso.estado.idEstado, proceso.distrito.idDistrito, null, record.idCurso).then((res) => {
          if (res.data !== undefined && res.data.length !== 0) {
            res.data.observadoresCurso.map((reg, index) => {
              return dataResult.push({
                key: index,
                indice: index + 1,
                ...reg,
              });
            });
          }
        setParticipantes(dataResult);
      });
    }
  };

  function mostrarModalCargado() {
    const { confirm } = Modal;
    var titleText = <div>
                      <div style={{ paddingBottom: "20px" }}>
                        <p><strong>Solicitudes validadas asignadas a la Junta</strong></p>
                        <Alert
                          message={<p style={{ marginBottom: "0px" }}>
                            Las solicitudes seleccionadas ya se encuentran inscritas al curso</p>}
                          type="info"
                          showIcon
                        />
                      </div>
                      <div>
                        <Row>
                        <BrowserRouter>
                            <TableParticipantes            
                            data={participantes}
                            className="-striped -highlight"
                            style={{ height: "350px", width: "900px" }}
                            locale={{ emptyText: "Sin registros" }}
                            pagination={{ pageSize: 10 }} 
                            scroll={{ x: 900, y: 250 }}
                            />
                        </BrowserRouter>
                        </Row>
                      </div>
                    </div>;
    confirm({
      title: titleText,
      icon: null,
      width: "1000px",
      height: "500px",
      okText: "Inscribir al curso",
      cancelText: "Cancelar",
      cancelButtonProps: { className: "ant-btn-default" },
      className: "modalCourses",
      onOk() {
        setLoader({ loading: true, message: MSN_GUARDAR }); //Se activa el loader
        editarParticipantes(curso, participantes);
      },
      onCancel(){},
   });
  }

  function editarParticipantes(record, dataParticipantes) {
    if (!cau) {
      var dataFilter = [];
      if (dataParticipantes !== undefined) {
        for (let i = 0; i < dataParticipantes.length; i++) {
          if (dataParticipantes[i].participanteSeleccionado || dataParticipantes[i].idObsCurso !== null) {
            dataFilter.push(dataParticipantes[i]);
          }
        }
      }
      saveParticipants(record.idCurso, user.sub, dataFilter).then((res) => {
        setLoader({ loading: false, message: "" }); //Se desactiva el loader
        if (res.data.code === 200) {
          Modal.success({
              title: "Los cambios han sido realizados.",
              content: <p>{res.data.message}</p>,
              okText: "Aceptar",
              onOk() { actualizarComponente() }
            });
        } else {
            Modal.info({
              title: "Atención",
              content: <p>{res.data.causa}</p>
            });
        }
      });
    }
  }

  function actualizarComponente() {
    setReload(true);
  }

  const columns = [
    {
      title: "#",
      dataIndex: "indice",
      key: "indice",
      width: 60,
      align: "center",
    },
    {
      title: "Fecha de impartición",
      dataIndex: "fecha",
      key: "fecha",
    },
    {
        title: "Hora de inicio",
        dataIndex: "hInicial",
        key: "horaInicial",
    },
    {
        title: "Hora de termino",
        dataIndex: "hFinal",
        key: "horaFinal",
    },
    {
        title: "Curso impartido por",
        dataIndex: "organoImparte",
        key: "impartidoPor",
    },
    {
        title: "Persona que imparte el curso",
        dataIndex: "nombreCompletoImparte",
        key: "imparte",
    },
    {
        title: "Personas inscritas",
        dataIndex: "totalParticipantes",
        key: "total",
    },
    {
        title: "Sede del curso",
        dataIndex: "sedeCurso",
        key: "sede",
    },
    {
      title: "Usuario que capturó",
      dataIndex: "usuario",
      key: "usuario",
  },
    {
      title: "Acciones",
      // width: 200,
      fixed: "right",
      align: "center",
      render: (record) => (
        <>
          {modifica && moduloAbierto && (
            <Tooltip title="Editar participantes">
            <button 
              disabled={record.cestatusCursos.idEstatusCurso === ESTATUS_CURSO_INSCRITO}
              style={{ marginLeft: "0px" }}
              className="btn-circle btn_secundario"
              onClick={(e) => buscarParticipantes(record)}
            >
              <Icon type="user-add" />
            </button>
          </Tooltip>
          )}{" "}
          {consulta && (
            <Tooltip title="Visualizar">
              <Link
                to={{
                  pathname: "/admin/cursos/consulta",
                  data: record,
                }}
              >
                <button className="btn-circle btn_secundario">
                  <Icon type="eye" theme="filled" />
                </button>
              </Link>
            </Tooltip>
          )}{" "}
          {modifica && moduloAbierto && (
            <Tooltip title="Modificar">
              <Link 
                disabled={record.cestatusCursos.idEstatusCurso === ESTATUS_CURSO_INSCRITO}
                to={{
                  pathname: "/admin/cursos/modifica",
                  data: record,
                }}
              >
                <button className="btn-circle btn_secundario" 
                  disabled={record.cestatusCursos.idEstatusCurso === ESTATUS_CURSO_INSCRITO}>
                  <Icon type="edit" theme="filled" />
                </button>
              </Link>
            </Tooltip>
          )}{" "}
          {elimina && moduloAbierto && (
            <Tooltip title="Eliminar">
              <button 
                disabled={record.cestatusCursos.idEstatusCurso === ESTATUS_CURSO_INSCRITO}
                style={{ marginLeft: "0px" }}
                className="btn-circle btn_secundario"
                onClick={(e) => mostrarModal(record)}
              >
                <Icon type="delete" />
              </button>
            </Tooltip>
          )}
        </>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      locale={{ emptyText: "Sin registros" }}
      className="-striped -highlight"
      style={{ height: "400px" }}
      scroll={{ x: true }}
    /> 
  );
}
export default withRouter(TableCourses);