import React, { useState, useEffect } from "react";
import { Icon, Tooltip, Table } from "antd";
import { withRouter, Link } from "react-router-dom";
import moment from "moment";
import { ModalDelete } from "../../../utils/Action/function";
import useAuth from "../../../hooks/useAuth";

function TableAction(props) {
  const {
    data,
    setReload,
    rolModulo: { consulta, modifica, borrar },
    loading, moduloAbierto,
  } = props;
  const [dataSourceAct, setDataSourceAct] = useState([]);
  const {
    user: { user },
  } = useAuth();

  useEffect(() => {
    setDataSourceAct(data); // Se inicializa el array de organizaciones en el hook
  }, [data]);

  //Función para eliminar una promoción
  const deleted = (accion, e, user) => {
    e.preventDefault();
    ModalDelete(accion, setReload, user);
  };
  //Se definen las columnas de la tabla de promociones.
  const columns = [
    {
      title: "#",
      dataIndex: "indice",
      key: "indice",
      width: 60,
      align: "center",
    },
    {
      title: "Medio de comunicación",
      dataIndex: "cmediosComunicacion.medio",
      key: "1",
    },
    {
      title: "Fecha de realización",
      dataIndex: "fechaRealizacion",
      key: "fechaRealizacion",
      render: (record) => moment(record).format("DD/MM/YYYY"), //Formato a la fecha
    },
    {
      title: "Descripción",
      dataIndex: "descripcion",
      key: "",
      width: 600,
    },
    {
      title: "Acciones",
      // width: "180px",
      fixed: "right",
      render: (record) => (
        <>
          {consulta && (
            <Tooltip title="Visualizar">
              <Link
                to={{
                  pathname: "/admin/accion/modificar",
                  data: record,
                  status: true,
                }}
              >
                <button className="btn-circle btn_secundario">
                  <Icon type="eye" theme="filled" />
                </button>
              </Link>
            </Tooltip>
          )}{" "}
          {modifica && moduloAbierto && (
            <Tooltip title="Modificar">
              <Link
                to={{
                  pathname: "/admin/accion/modificar",
                  data: record,
                  status: false,
                }}
              >
                <button className="btn-circle btn_secundario">
                  <Icon type="edit" theme="filled" />
                </button>
              </Link>
            </Tooltip>
          )}
          {borrar && moduloAbierto && (
            <Tooltip title="Eliminar">
              <button
                className="btn-circle btn_secundario"
                onClick={(e) => deleted(record, e, user)}
              >
                <Icon type="delete" />
              </button>
            </Tooltip>
          )}
        </>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={dataSourceAct}
      loading={loading}
      locale={{ emptyText: "Sin registros" }}
      scroll={{ x: true }}
    />
  );
}

export default withRouter(TableAction);
