import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Form, Row, Col, message, Button,Radio } from "antd";
import useAuth from "../../../hooks/useAuth";
import { rol_captura, rol_cau } from "../../../utils/Accreditation/roles";
import { Ast, sendDocuments } from "../../../utils/Accreditation/functions";
import TableRequest from "../../../components/Admin/Documents/Table";
import "./Documents.scss";
import { getSolicitudesAcreditadasDocs } from "../../../api/accreditation";

function Accreditation(props) {
    const [rolModulo] = useState({ captura: false, modifica: false, consulta: false, descarga: false, borrar: false, });
    const { proceso, setReload, setLoader, setSaved, moduloAbierto } = props;
    const { user: { user, rolUsuario } } = useAuth();
    const [data, setData] = useState([]); // Lista con la información de las solicitudes por acreditar
    const [requests, setRequests] = useState({ list: [], loading: false });
    const [tipoDocumento, setTipoDocumento] = useState(0);
    const [tipoFirma, setTipoFirma] = useState(0);
    const [reloadTable, setReloadTable] = useState(false);

    useEffect(() => {
        setReloadTable(false);
        if (proceso.distrito !== undefined && proceso.distrito !== null) {
            if (!rol_captura.includes(rolUsuario)) {
                setReload(true);
            }
            getSolicitudesAcreditadasDocs(proceso.proceso.idProcesoElectoral, proceso.proceso.idDetalleProceso, 
                proceso.estado.idEstado, proceso.distrito.idDistrito).then((res) => {
                  const dataSource = [];
                  if (res.data.solicitudesAcreditadasDocs !== null && res.data.solicitudesAcreditadasDocs.length > 0) {
                    res.data.solicitudesAcreditadasDocs.map((reg, index) => {
                      return dataSource.push({ key: index, indice: index + 1, ...reg, });
                    });
                  }
                  setData(dataSource);
                  setRequests({ list: dataSource, loading: false });          
              });
        }
        setTipoDocumento(0);
        setTipoFirma(0);
        setReload(false);        
    }, [proceso, reloadTable, rolUsuario, setReload]);

    const onChangeRadioD = (e) => {
        setTipoDocumento(e.target.value);
    };
    
      const onChangeRadioF = (e) => {
        setTipoFirma(e.target.value);
    };

    const addChanges = (event) => {
        event.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                const datos = [];
                for (let i = 0; i < data.length; i++) {
                    if (data[i].seleccionado) {
                        data[i].usuario = user.sub;
                        datos.push(data[i]);
                    }
                }
                if (datos.length > 0) {
                    values.usuario = user.sub;
                    values.idProcesoElectoral = proceso.proceso.idProcesoElectoral;
                    values.idDetalleProceso = proceso.proceso.idDetalleProceso;
                    values.idEstadoDistrito = proceso.estado.idEstado;
                    values.idDistritoFederal = proceso.distrito.idDistrito;
                    values.totalAcreditadas = datos.length;
                    values.listaAcreditados = datos;
                    values.tipoDocumento = tipoDocumento;
                    values.tipoFirma = tipoFirma;
                    if (!rol_cau.includes(rolUsuario)) {
                        sendDocuments(values, setLoader, setReload, setSaved, tipoDocumento, setReloadTable);                        
                    }                    
                } else {
                    message.info("Se requiere seleccionar al menos una solicitud para continuar con la generación de documentos de acreditación.");
                }
            } else {
                message.info("Los datos con ( * ) son requeridos.");
            }
        });
    };
    

    function DatosAcreditacion() {
        return (
            <div>
            <Row className="rowListRequests">
                <Col span={24}>
                    <h3>Lista de solicitudes acreditadas</h3>
                </Col>
            </Row>
            <Row className="rowTableRequests">
                <Col span={24}>
                    <div>
                        <TableRequest
                        data={data}              
                        rolModulo={rolModulo}
                        loading={requests.loading}
                        setReload={setReload}
                        proceso={proceso}
                        setLoader={setLoader}
                        setSaved={setSaved}
                        />
                    </div>
                </Col>
            </Row>              
            <Row>
                <Col span={24} style={{ paddingTop: "20px" }}>
                    <Form.Item style={{ textAlign: "center" }}>
                        <Button type="primary" style={{ width: 250 }} onClick={addChanges} disabled={!moduloAbierto}>
                        Generar Documentos
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </div>
        );  
    };

    return (
        <div>
            <Row className="rowListRequests">
                <Col span={12}>
                    <Ast /> Documentos a generar
                </Col>
            </Row>
            <Row>
                <Col span={12} className="colSpace">
                    <Radio.Group value={tipoDocumento} onChange={onChangeRadioD}>
                        <Radio value={0}>Constancia de acreditación</Radio>
                        <Radio value={1}>Gafete</Radio>
                        <Radio value={2}>Ambos</Radio>
                    </Radio.Group>
                </Col>
            </Row>
            <Row className="rowListRequests">
                <Col span={12}>
                    <Ast /> Firma en el documento
                </Col>
            </Row>
            <Row>
                <Col span={12} className="colSpace">
                    <Radio.Group value={tipoFirma} onChange={onChangeRadioF}>
                        <Radio value={0}>Con firma</Radio>
                        <Radio value={1}>Sin firma</Radio>
                    </Radio.Group>
                </Col>
            </Row>
            <Form>
                { DatosAcreditacion() }
            </Form>                   
        </div>
    );
}
const AccreditationForm = Form.create()(Accreditation);
export default withRouter(AccreditationForm);