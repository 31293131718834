import { Button, Icon, Row, Modal, Col } from "antd";
import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";
import Introduccion from "./StepsCP/Intro";
import Lesson1 from "./StepsCP/Lesson1";
import Lesson2 from "./StepsCP/Lesson2";
import Encuesta from "./StepsCP/Encuesta";
import { ACT_FINAL, ACT_VALORACION, CAL_MIN } from "../../../../utils/constanst";
import { getCuestionario, reqCuestionario, saveQualification } from "../../../../api/courses";
import "./Course.scss";

function CourseCP(props) {
    const { history } = props;
    const { constancia } = props.location;
    //Se obtiene la información del ciudadano
    const {
        ciudadano: { ciudadano },
    } = useAuth();
    const [current, setCurrent] = useState({ steps: 0 }); //Se inicializa el contador de las pantallas
    const [url, setUrl] = useState({});
    const [resultado, setResultado] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({ error: false, message: "", calificacion: null });

    useEffect(() => {
        if(ciudadano) {
            window.scrollTo(0, 0); // para posicionar el scroll al inicio
            getCuestionario(0, ACT_FINAL)
            .then((res) => {
                setUrl(res.data);
            })
            .catch((e) => {
                console.log(e);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (resultado !== undefined && resultado !== null) {
            setLoading(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultado]);

    //Componentes de cada pantalla
    const steps = [
        {
            content: (
                <Introduccion />
            ),
        },
        {
            content: (
                <Lesson1 />
            ),
        },
        {
            content: (
                <Lesson2 />
            ),
        },
        {
            content: (
                <Encuesta />
            ),
        },
    ];

    //Función que regresa a la pantalla anterior
    const prev = () => {
        setCurrent({
        steps: current.steps - 1,
        }); //Cambia de pantalla
    };

    //Función que cambia a la siguiente pantalla
    const next = () => {
        setCurrent({
        steps: current.steps + 1,
        }); //Cambia de pantalla
    };

    //Función que manda a contestar la evaluación
    const evaluacion = () => {
        //Se manda el url + usuario en base64
        url.url && window.open(url.url + "_~" + setBase64(ciudadano.sub), "_blank"); //Se abre en una nueva pestaña
    };

    //Función que retorna una cadena en base64
    const setBase64 = (data) => {
        let buff = new Buffer(data);
        return buff.toString("base64");
    };

    //Funcón que obtiene el hash de la url de cuestionarios
    const getHash = () => {
        return url.url.split("/").pop();
    };

    //Función que muestra la calificación
    const calificacion = () => {
        setResultado();
        reqCuestionario(ciudadano.sub, getHash())
        .then((res) => {
            // GUARDAMOS LA CALIFICACION
            const { calificacion } = res.data; //Destructuring para obtener la calificación
            if (calificacion >= CAL_MIN) {
                saveQualification(ciudadano.id, 0, 10, calificacion, ciudadano.sub)
                .then((res) => {
                const { data } = res;
                if (data.code !== 200) {
                    console.log("Error al guardar en la BD Obs");
                    setError({ error: true, message: data.causa, calificacion: null, });
                } else {
                    setError({
                        error: false,
                        message:
                          "Sí lo deseas puedes realizar de nuevo la evaluación.",
                        calificacion: calificacion,
                    });
                }
                })
                .catch((e) => {
                    console.log("Excepción DB Obs", e);
                    if(e.toString().trim()==="Error OBS001"){
                        setLoading(false);
                        setError({
                            error: true,
                            message:
                            "Te informamos que las sesiones se cierran después de 60 minutos, deberás iniciar sesión de nuevo para continuar con tu trabajo en el portal.",
                        });
                    }else{
                        setLoading(false);
                        setError({
                            error: true,
                            message:
                            "Te informamos que las sesiones se cierran después de 60 minutos, deberás iniciar sesión de nuevo para continuar con tu trabajo en el portal.",
                        });
                    }
               
                
            });
            } else {
                setError({
                    error: false,
                    message:
                      `Es necesario obtener una calificación mínima de ${CAL_MIN}, por favor realiza de nuevo la evaluación.`,
                    calificacion: calificacion,
                });
            } 
            setResultado(`Obtuviste una calificación de ${res.data.calificacion} en la evaluación`);           
        })
        .catch((e) => {
          console.log("Error al consumir el web service de cuestionarios", e);
          setResultado("Realiza la evaluación del curso");
          setError({
            error: true,
            message:
              "Debes contestar la evaluación para finalizar el curso en línea.",
            calificacion: null,
          });
        });
    };

    //Función que manda a contestar la evaluación
    const encuesta = () => {
        console.log("vamos a la encuesta");
        getCuestionario(0, ACT_VALORACION)
            .then((res) => {
                window.open(res.data.url + "_~" + setBase64(ciudadano.sub), "_blank"); //Se abre en una nueva pestaña
            })
        .catch((e) => {
                console.log(e);
        });
    };

    function modalCalificacion() {
        return (
        <div>
            <Modal
            title={<strong>{resultado}</strong>}
            visible={loading}
            footer={null}
            className="modalCourseCP"
            onCancel={() => setLoading(false)}
            >
            <div>
                <Row>
                    <Col span={24} style={{ paddingTop: "20px", paddingRight: "20px" }}>
                        <div style={{ textAlign: "left" }}>
                            <p>{error.message}</p>
                        </div>
                    </Col>
                </Row>
                {error.calificacion === null && (
                    <div style={{ textAlign: "center" }}>
                        <Button onClick={() => setLoading(false)} type="default">
                            Aceptar
                        </Button>
                    </div>
                )}
                {error.calificacion !== null && error.calificacion >= CAL_MIN && (
                    <div>
                        <Row>
                            <Col span={8} style={{ paddingTop: "20px", paddingRight: "20px" }}>
                                <div style={{ textAlign: "center" }}>
                                    <Button type="default" style={{height: "auto" }} onClick={() => history.push("/ciudadano/inicio")}>
                                        Seguir con el proceso de acreditación
                                    </Button>
                                </div>
                            </Col>
                            <Col span={8} style={{ paddingTop: "20px", paddingRight: "20px" }}>
                                <div style={{ textAlign: "center" }}>
                                <Button type="primary" style={{height: "78px" }} htmlType="submit" onClick={() => {setLoading(false); evaluacion();}} >
                                    Repetir evaluación
                                </Button>
                                </div>
                            </Col>
                            <Col span={8} style={{ paddingTop: "20px", paddingRight: "20px" }}>
                                <div style={{ textAlign: "center" }}>
                                <Button type="primary" style={{height: "auto" }} htmlType="submit" onClick={() => {setLoading(false); next();}} >
                                    Encuesta de valoración del curso
                                </Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                )}  
                {error.calificacion !== null && error.calificacion < CAL_MIN && (
                    <div>
                        <Row>
                            <Col span={12} style={{ paddingTop: "20px", paddingRight: "20px" }}>
                                <div style={{ textAlign: "center" }}>
                                    <Button type="default" style={{height: "58px" }} onClick={() => setLoading(false)}>
                                        Entendido
                                    </Button>
                                </div>
                            </Col>
                            <Col span={12} style={{ paddingTop: "20px", paddingRight: "20px" }}>
                                <div style={{ textAlign: "center" }}>
                                <Button type="primary" style={{height: "auto" }} htmlType="submit" onClick={() => {setLoading(false); evaluacion();}} >
                                    Repetir evaluación
                                </Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                )}  
            </div>
            </Modal>
        </div>
        );
    };

    return(
        <div className="interioresPortal">
            <Link to={"/ciudadano/inicio"} className="back">
                <Icon type="play-circle" theme="filled" rotate="180" /> Regresar
            </Link>
            
            <div style={{ marginTop: 50 }}>
                {steps[current.steps].content}
            </div>

            <Row style={{ textAlign: "center", marginTop: 50 }}>
                {current.steps === 0 && (
                    <div>
                        <Link to={{ pathname: "/ciudadano/materiales-consulta-popular" }}>
                            <Button>
                                Materiales de consulta
                            </Button>
                        </Link>
                        <Button onClick={() => next()} type="default">
                            Siguiente
                        </Button>
                    </div>
                )}
                {current.steps > 0 && current.steps < steps.length - 2 && (
                    <div>
                        <Button onClick={() => prev()} type="default">
                            Anterior
                        </Button>
                        <Button onClick={() => next()} type="default">
                            Siguiente
                        </Button>
                    </div>
                )}
                {current.steps === steps.length - 2 && !constancia && (
                    <div>
                        <Button onClick={() => prev()} type="default">
                            Anterior
                        </Button>
                        <button onClick={() => evaluacion()} type="default">
                            Contestar evaluación 
                        </button>
                        <button onClick={() => calificacion()} type="default">
                            Mostrar calificación
                        </button>
                        {modalCalificacion()}   
                    </div>
                )}
                {current.steps === steps.length - 2 && constancia && (
                    <div>
                        <Button onClick={() => prev()} type="default">
                            Anterior
                        </Button>
                        <button onClick={() => evaluacion()} type="default">
                            Contestar evaluación 
                        </button>
                        <button onClick={() => calificacion()} type="default">
                            Mostrar calificación
                        </button>
                        <button onClick={() => next()} type="default">
                            Encuesta de valoración del curso
                        </button>
                        {modalCalificacion()}   
                    </div>
                )}
                {current.steps === steps.length - 1 && (
                    <div>
                        <button onClick={() => history.push("/ciudadano/inicio")} type="default">
                            Seguir con el proceso de acreditación
                        </button>
                        <button onClick={() => encuesta()} type="default">
                            Cuestionario de valoración
                        </button> 
                    </div>
                )}
            </Row>
        </div>
    );

}

export default withRouter(CourseCP);