export const rol_captura_asistencia = [
  "OBSERVADORES11.ADMIN.PARAM.CAPTURA.OC",
  "OBSERVADORES11.ADMIN.CAPTURA.OC",
  "OBSERVADORES11.CAU.OC", //Cau
  "OBSERVADORES11.CAPTURA.JL",
  "OBSERVADORES.CAPTURA.OPLE.JL",
  "OBSERVADORES11.CAPTURA.JD",
  "OBSERVADORES.CAPTURA.JSP", //Cuenta generica
];

export const rol_consulta_asistencia = [
  "OBSERVADORES11.ADMIN.PARAM.CAPTURA.OC",
  "OBSERVADORES11.ADMIN.CAPTURA.OC",
  "OBSERVADORES11.CONSEJERO.OC",
  "OBSERVADORES11.CONSULTA.OC",
  "OBSERVADORES11.CAU.OC", //Cau
  "OBSERVADORES11.CAPTURA.JL",
  "OBSERVADORES11.CONSULTA.JL",
  "OBSERVADORES.CAPTURA.OPLE.JL",
  "OBSERVADORES.CONSULTA.OPLE.JL",
  "OBSERVADORES11.CAPTURA.JD",
  "OBSERVADORES11.CONSULTA.JD",
  "OBSERVADORES.CAPTURA.JSP", //Cuenta generica
];

export const rol_cau_asistencia = [
  "OBSERVADORES11.CAU.OC", //Cau
];

export const rol_captura_jl_asistencia = [
  "OBSERVADORES11.CAPTURA.JL",
  "OBSERVADORES.CAPTURA.OPLE.JL",
];
