import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as ImgConvocatoria } from "../../../../assets/img/Portal/convocatoriaHome.svg";
import { ReactComponent as ImgObservador } from "../../../../assets/img/Portal/conoceObsHome.svg";
import { ReactComponent as ImgCalendario } from "../../../../assets/img/Portal/calendarioHome.svg";
import { ReactComponent as ImgDoc } from "../../../../assets/img/Portal/docsReqHome.svg";
import Questions from "./Questions";
import Links from "./Links";
import "../Portal.scss";
import moment from "moment";
import { getEstadisticasPortal } from "../../../../api/estadisticas";
//import {saveAs} from  "file-saver";

export default function Home() {
  const [datos, setDatos] = useState({});

  const UltimaActualizacion = () => {
    const fechaHora = new Date();
    moment.locale("es");
    const fecha = moment(fechaHora).format("DD/MM/YYYY");
    return <div style={{ fontSize: "12px", marginBottom: "16px" }}>Actualizado {fecha}</div>;
  };

  //Obtenemos los datos de la estadistica del portal
  useEffect(() => {
    getEstadisticasPortal().then((res) => {
      setDatos(res.data.estadisticaPortal);
    });
  }, []);

  /*
  const descargaGuia = () => {
    saveAs(process.env.PUBLIC_URL+"/materiales_cp/GuiaRatificar.pdf", "Guía para ratificar solicitud");
  }
  */

  return (
    <>
      <Row>
        {/*
        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
          <div  className="card-menu-home aviso" style={{paddingBottom: "25px"}}>
          <Icon type="alert" theme="filled" style={{color: "#ff8c8c"}}/>
            <b>Aviso</b>
            <p>
              Consulta aquí <Link to={"/portal/materiales-capacitacion"} className="link">los materiales de capacitación</Link> de la ----.
            </p>
            <p style={{marginBottom: "0px"}}>
              Si participaste como observador en el proceso 2020-2021, consulta la guía para ratificar tu solicitud para participar como observador de la -----. 
              <button
                    className="btnDescargar material"
                    style={{ marginLeft: "0px", marginBottom: "0px" }}
                    onClick={(e) => descargaGuia()}
                >
                    <Icon type="download" />
                    Guía para ratificar solicitud
              </button>
            </p>
          </div>
        </Col>
        */}
      </Row>
      <Row gutter={[24, 16]} className="rellenaHeight">
        <Col xxl={6} xl={6} lg={12} md={12} sm={12} xs={24}>
          <Link to={"/portal/convocatoria"}>
            <div className="card-menu-home">
              <center>
                <ImgConvocatoria />
                <p>
                  Consulta la <b>convocatoria</b>
                </p>
              </center>
            </div>
          </Link>
        </Col>
        <Col xxl={6} xl={6} lg={12} md={12} sm={12} xs={24}>
          <Link to={"/portal/observador"}>
            <div className="card-menu-home">
              <center>
                <ImgObservador />
                <p>
                  Conoce qué es un <b>observador</b>
                </p>
              </center>
            </div>
          </Link>
        </Col>
        <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
          <div className="card-menu-home">
            <Row>
              <Row>
                <b>Total de solicitudes registradas mediante este portal</b>
              </Row>
              <Row>
                <UltimaActualizacion />
              </Row>
            </Row>
            <Row gutter={20}>
              <Col xxl={20} xl={20} lg={20} md={20} sm={24} xs={24}>
                <p className="subTitulo">Individuales</p>
                <p className="canCiudadano">{datos.portal}</p>
              </Col>
{/*               <Col xxl={14} xl={16} lg={14} md={14} sm={24} xs={24}>
                <p className="subTitulo">Miembros de organización</p>
                <p className="canOrg">{datos.organizacion}</p>
              </Col> */}
            </Row>
          </div>
        </Col>
      </Row>
      <Row gutter={[24, 24]} className="sectionMenu rellenaHeight">
        <Col xxl={16} xl={16} lg={16} md={16} sm={24} xs={24}>
          <Questions />
        </Col>
        <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
          <div className="card-menu-home limite-registro">
            <Row>
              <div>
                <ImgCalendario />
              </div>
              <div>
                Fecha límite de registro
                {/*AQUÍ ESTA LA FECHA LIMITE DE REGISTRO DEL INICIO USER */}
                <p style={{marginBottom: "0"}}><span>{datos.fecha}</span></p>
                <span style={{fontSize: "12px"}}>Tiempo del centro (Ciudad de México, México)</span>
              </div>
            </Row>
            <Row>
              <div>
                Documentos y requisitos<br />
                <Link to={"/portal/requisitos"} className="link">
                  Consulta el listado
                </Link>
              </div>
              <div>
                <ImgDoc />
              </div>
            </Row>
          </div>
        </Col>
      </Row>
      <Links />
    </>
  );
}
