import React, { useEffect, useRef } from "react";
import { GuiaMaterial, MatApoyo } from "../Generals";

export default function Lesson4({ data, setLoading }) {
  const isMounted = useRef(true);
  //Se obtiene el Entidad seleccionada del store de redux
  //const { idEntidad, entidad } = useSelector((state) => state.entidad);
  //const {material,} = useSelector((state) => state.material);


  //Para desmontar el componente
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <div className="texto_parrafo">
      <p>
        Ahora que ya conoces las actividades que se realizan en el Proceso Electoral, es muy importante que sepas cómo el 
        INE promueve los derechos humanos en materia político-electoral y garantiza su ejercicio en igualdad de condiciones, 
        sin ningún tipo de discriminación, ya sea por identidad de género, orientación sexual, expresión de género, apariencia, 
        edad, cualquier tipo de discapacidad, origen étnico, situación migratoria o por cualquier otra condición.
      </p>
      <p>
        Por ello se han aprobado e implementado los siguientes instrumentos aplicables en todos los procesos electorales y 
        mecanismos de participación ciudadana:
      </p>
      <p>
        <ol>
          <li>El Protocolo para la Inclusión de las Personas con Discapacidad como funcionarios y funcionarias de Mesas Directivas de Casilla, 
            al que llamaremos <b>Protocolo de discapacidad.</b>
          </li>
          <li>El Protocolo para adoptar las medidas tendientes a garantizar a las personas trans el ejercicio del voto en igualdad de condiciones 
            y sin discriminación en todos los tipos de elección y mecanismos de participación ciudadana, al que llamaremos <b>Protocolo trans.</b>
          </li>
          <li>Las medidas para garantizar el voto y la participación de las personas en igualdad de condiciones y sin discriminación.</li>
        </ol>
      </p>
      <p>
        Te invitamos a revisar los Protocolos de inclusión que están disponibles en el material de consulta de este curso.
      </p>
      <p>
        Observa los siguientes videos en los que se explican las medidas de inclusión que se implementan en el Proceso Electoral. 
      </p>
      {data.length && <MatApoyo array={[data[0]]} />}
      {data.length && <MatApoyo array={[data[1]]} />}
      {data.length && <MatApoyo array={[data[2]]} />}
      <p>
        A continuación, revisa el siguiente material de apoyo para reforzar lo que aprendiste. 
      </p>
      {data.length && <MatApoyo array={[data[3]]} />}
      <p>
        Para profundizar tus conocimientos sobre las medidas de inclusión durante el Proceso Electoral puedes revisar el {" "}
          <GuiaMaterial setLoading={setLoading}>
          CAPÍTULO 5 DE TU GUÍA TEMÁTICA PARA CAPACITAR A LAS Y LOS OBSERVADORES ELECTORALES  
          </GuiaMaterial>{" "}que está disponible en los materiales de consulta de este curso. 
      </p>
      <p>
        ¡Bien! Concluiste esta lección. No olvides realizar la actividad autoevaluable correspondiente para continuar con la siguiente lección.
      </p>
    </div>
  );
}
