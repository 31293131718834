import React, { useState } from "react";
import { Layout, Button, Icon, Modal } from "antd";
import { Route, Switch, Link } from "react-router-dom";
import Header from "../components/User/Header/Header";
import Footer from "../components/User/Footer/Footer";
import { ReactComponent as LogoHeader } from "../assets/img/logoINE_bco.svg";
import useAuth from "../hooks/useAuth";
import { logoutCiudadano } from "../api/auth";
import UpdatePass from "../components/User/UpdatePass/UpdatePass";

const LayoutPortal = (props) => {
  const { routes } = props;
  const { history: { location: { pathname }, }, } = props;
  const { Content } = Layout;
  const {
    ciudadano: { ciudadano },
  } = useAuth();
  const [visibleModificaPass, setVisibleModificaPass] = useState(false);

  const logoutUser = () => {
    const status = logoutCiudadano();
    if (status) {
      window.location.href = "/"; //Se redirecciona al home
    }
  };    

  const showModalModificaPass = () => {
    setVisibleModificaPass(true);
  };

  const handleOk = (e) => {
    setVisibleModificaPass(false);
  };

  const handleCancel = (e) => {
    setVisibleModificaPass(false);
  };

  return (
    <Layout style={{ minHeight: "100vh", background: "#FFFFFF" }}>
      <Header Logo={LogoHeader} ciudadano={ciudadano} />
      <div className="barraUsuario">
        {ciudadano && (
          <div>
            Hola{" "}
            <strong>
              {ciudadano.nombreObservador
                ? ciudadano.nombreObservador
                : ciudadano.sub}{" "}
            </strong>
            <Link to={pathname} style={{marginLeft: "14px"}} type="link" onClick={showModalModificaPass}>
              Modificar contraseña
            </Link>
            <Button onClick={logoutUser} className="btnCerrarsesion">
              <Icon type="logout" />
              Cerrar sesión
            </Button>
          </div>
        )}
      </div>
      <Content style={{ padding: "0 50px", marginTop: "10px" }}>
        {/**Contenido, se vuelven a iterar las rutas para poder navegar entre ellas */}
        <LoadRoutes routes={routes} />
      </Content>
      <Modal visible={visibleModificaPass} onOk={handleOk} onCancel={handleCancel} footer={null}
          className="modalLoginRegistro">
        <center><h1>Modifica tu contraseña</h1></center>
        <UpdatePass visibleModificaPass={visibleModificaPass}
          setVisibleModificaPass={setVisibleModificaPass} ciudadano={ciudadano} />
      </Modal>
      <Footer />
    </Layout>
  );
};

//Función que itera las rutas individualmente
function LoadRoutes({ routes }) {
  return (
    <Switch>
      {routes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          component={route.component}
        />
      ))}
    </Switch>
  );
}

export default LayoutPortal;
