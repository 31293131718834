//import { Icon } from "antd";
import React, { useEffect, useRef } from "react";
import { getIntroduction } from "../../../../api/courses";
import { GuiaMaterial, MatApoyoLeccion1 } from "../Generals";

export default function Lesson1({ data, setLoading }) {
  //const [intro, setIntro] = useState({});
  //const { testimonioObservador } = intro;
  const isMounted = useRef(true);
  // let copyArray = data;
  // let array = copyArray.length && [data[0], data[1]];

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); // para posicionar el scroll al inicio
    getIntroduction()
      .then((res) => {
        //isMounted.current && setIntro(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  /*function download(file) {
    let a = document.createElement("a"); //Create <a>
    a.href = `data:application/pdf;base64,${file}`; //Image Base64 Goes here
    a.download = `Testimonio – Informe de una observadora.pdf`; //File name Here
    a.click(); //Downloaded file
  }*/

  return (
    <>
      <p>
        Te damos la más cordial bienvenida a tu curso: ¡La Observación Electoral! PEL 2021-2022. ¡Vamos a comenzar con tu capacitación! 
      </p>
      <p>
        Te invitamos a ver el siguiente video en el que te explicamos qué es la observación electoral y la gran importancia que tiene tu participación como observador u observadora dentro de este proceso electoral.
      </p>
      {data.length && <MatApoyoLeccion1 array={[data[0], data[1]]} />}
      <br />
      <br />
      <div className="texto_parrafo">
        <p >
          Para profundizar tus conocimientos sobre la observación electoral
          puedes revisar el{" "}
          <GuiaMaterial setLoading={setLoading}>
            CAPÍTULO 1 DE TU GUÍA TEMÁTICA PARA CAPACITAR A LAS Y LOS
            OBSERVADORES ELECTORALES
          </GuiaMaterial>{" "}
          que está disponible en los materiales de consulta de este curso.
        </p>
        <p >
            ¡Muy Bien! Ahora que tienes un panorama general de la observación electoral y los principios que la rigen,
            realiza la actividad de autoevaluación para reforzar tu aprendizaje. Después podrás continuar con la siguiente lección.
        </p>
      </div>
    </>
  );
}
