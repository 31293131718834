import React, { useEffect, useRef } from "react";
//import { useSelector } from "react-redux";
/*import { useText} from "../../../../hooks/useLesson5";
import { array } from "../../../../utils/CourseOnline/lesson5";
import { capitalizeWord } from "../../../Generals/functions";*/
import { GuiaMaterial, MatApoyo } from "../Generals";
/*import {
  getOrgano,
} from "../../../../utils/CourseOnline/Util";*/

export default function Lesson5({  data, setLoading }) {
  const isMounted = useRef(true);
  //Se obtiene el Entidad seleccionada del store de redux
  //const { idEntidad, entidad } = useSelector((state) => state.entidad);
  /*const {
    material,
  } = useSelector((state) => state.material);*/

  /*const { parrafo, diputacion, numConsejo, res, consejo1, consejo2, consejo3, estado } = useText(
    material ? id : idEntidad
  );*/


  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  /*const getList = () =>
    array.find((entity) => entity.idEntidad === getIdEntity());*/

  /*const getDistrtito = (id) => {
    console.log("Entidad");
    console.log(id);
    return (id === 9 || id === 13) ? "distritales" : "distritales y municipales";
  };*/

  /*const getTextEntity = () => {
    return material ? entidadMat : entidad;
  };*/

  /*const getIdEntity = () => {
    return material ? id : idEntidad;
  };*/

 /* const getTextBoleta = () =>{
    const entities = [1, 13, 23, 12, 5, 10, 11];
    if (entities.includes(getIdEntity())) {
      return "la boleta para la elección local es aprobada";
    } else {
      return "las boletas para las elecciones locales son aprobadas";
    }  
  }*/

  return (
    <div className="texto_parrafo">
      <p>
        Los <b>delitos electorales</b> son aquellas acciones u omisiones que lesionan o ponen en peligro el adecuado desarrollo 
        de la función electoral y atentan contra las características del voto que debe ser universal, libre, directo, 
        personal, secreto e intransferible, descritas y sancionadas por la Ley General en Materia de Delitos Electorales.
      </p>
      <p>
        Entre los que se encuentran:
      </p>
      <p>
        <ul>
          <li>La <b>compra del voto</b>, que se refiere al desvío de recursos públicos por parte de autoridades gubernamentales o partidos políticos 
            para pagar la orientación del voto.
          </li>
          <li>La <b>coacción del voto</b> es cuando una persona, grupo o institución ejerce presiones de cualquier tipo sobre las o los ciudadanos 
            para limitar u orientar la emisión de su voto.
          </li>
        </ul>
      </p>
      <p>
        Además de las anteriores, hay otras conductas que se encuentran tipificadas como delitos electorales.
      </p>
      <p>
        En el siguiente material de apoyo podrás conocer algunos de los delitos electorales.
      </p>
      {data.length && <MatApoyo array={[data[0]]} />}
      <p>
        Cualquier persona que incurra en alguna o varias de estas conductas estaría cometiendo un delito electoral, 
        además existen tipos penales que se han reservado para personas que tienen una calidad especial, por lo que 
        la sanción a quienes realicen alguna de las conductas antes enlistadas dependerá del delito cometido. 
      </p>
      <p>
        A continuación, revisa el material de apoyo para conocer los sujetos activos de un delito electoral.
      </p>
      {data.length && <MatApoyo array={[data[1]]} />}
      <p>
        A aquella persona que incurra o cometa algún delito electoral se le impondrán sanciones como:
      </p>
      <ul>
        <li>La pérdida del derecho al voto activo y pasivo.</li>
        <li>La suspensión de sus derechos políticos.</li>
        <li>Multa y privación de la libertad.</li>
      </ul>
      <p>
        Revisa el siguiente material donde podrás conocer las características de la denuncia de un delito electoral.
      </p>
      {data.length && <MatApoyo array={[data[2]]} />}
      <p>
        <b>Para el tema de violencia política, te invitamos a ver el siguiente video:</b>
      </p>
      {data.length && <MatApoyo array={[data[3]]} />}
      <p>
        Como puedes ver la observación electoral desempeña un papel fundamental en la prevención y erradicación de los delitos electorales, dado que: 
      </p>
      <ul>
        <li style={{ listStyleType: "none" }}>a) Tiene un efecto de disuasión en quienes se interesan en llevar a cabo prácticas irregulares o delictivas.</li> 
        <li style={{ listStyleType: "none" }}>b) Permite obtener información sobre las características de las formas de manipulación del voto.</li>
        <li style={{ listStyleType: "none" }}>c) Proporciona insumos para comprender el fenómeno y diseñar políticas públicas para prevenirlo y erradicarlo.</li>
        <li style={{ listStyleType: "none" }}>d) Genera confianza y credibilidad en las instituciones y en los procesos electorales.</li>
        <li style={{ listStyleType: "none" }}>e) Contribuye a fortalecer la democracia y la paz social.</li>
      </ul>
      <p>
        Es importante que frente a cualquier conducta que pudiera constituir un delito electoral, <b><span className="underline">se denuncie</span></b> ante la Fiscalía 
        Especializada en Delitos Electorales o ante la autoridad electoral local correspondiente.
      </p>
      <p>
        Las y los observadores contribuyen a la certeza y a que todo marche conforme a derecho, lo cual implica la responsabilidad 
        de denunciar si se presencia un delito electoral, una situación de violencia política contra las mujeres en razón de género 
        o cualquier situación que no se realice conforme a la ley.
      </p>
      <p>
        No olvides consultar el {" "}
          <GuiaMaterial setLoading={setLoading}>
            CAPÍTULO 7 DE TU GUÍA TEMÁTICA PARA CAPACITAR A LAS Y LOS OBSERVADORES ELECTORALES
          </GuiaMaterial>{" "} que está disponible en los materiales de consulta de este curso, así como el 
          siguiente material de apoyo para que refuerces tus conocimientos. 
      </p>
      {data.length && <MatApoyo array={[data[4]]} />}
      <p>
        <b>¡Felicidades!</b> Has concluido tus lecciones, ya que conoces la importancia de tu labor ayúdanos a evitar que se cometan delitos 
        electorales <b>¡Denuncia cualquier delito que presencies!, ¡tu aportación cuenta, no basta con observar, hay que actuar!</b>
      </p>
      <p>
        Ahora ya puedes realizar la actividad de evaluación de esta lección. 
      </p>
      <br />
    </div>
  );
}
