export const rol_captura = [
  "OBSERVADORES11.ADMIN.PARAM.CAPTURA.OC",
];

export const rol_consulta = [
  "OBSERVADORES11.ADMIN.PARAM.CAPTURA.OC",
];

export const rol_modifica = [
  "OBSERVADORES11.ADMIN.PARAM.CAPTURA.OC",
];
