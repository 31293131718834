import { basePath } from "./config";
import axios from "axios";
import { getAccessTokenApi } from "./auth";

export function getReporteSolicitudes(idProceso, idDetalle, idEstado, idDisritoFed) {
  const url = `${basePath}/reportes/solicitudes`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    { idProceso: idProceso, idDetalle: idDetalle, idEstado: idEstado, idDisritoFed: idDisritoFed },
    { headers: { Authorization: jwt, Parametrizacion: idProceso } }
  );
}

export function getReporteSolicitudesJD(idProceso, idDetalle, idEstado, idDisritoFed) {
  const url = `${basePath}/reportes/solicitudesJD`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    { idProceso: idProceso, idDetalle: idDetalle, idEstado: idEstado, idDisritoFed: idDisritoFed },
    { headers: { Authorization: jwt, Parametrizacion: idProceso } }
  );
}

export function getReporteDetalleSolicitudes(idProceso, idDetalle, idEstado, idDisritoFed) {
  const url = `${basePath}/reportes/detalleSolicitudes`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    { idProceso: idProceso, idDetalle: idDetalle, idEstado: idEstado, idDisritoFed: idDisritoFed  },
    { headers: { Authorization: jwt, Parametrizacion: idProceso } }
  );
}


export function getReporteOrg(idProceso, idDetalle) {
  const url = `${basePath}/reportes/organizaciones`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    { idProceso: idProceso, idDetalle: idDetalle },
    { headers: { Authorization: jwt, Parametrizacion: idProceso } }
  );
}

export function getReporteSolicitudesOrg(proceso, detalle) {
  const url = `${basePath}/reportes/solicitudesOrg`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    { proceso: proceso, detalle: detalle },
    { headers: { Authorization: jwt, Parametrizacion: proceso  } }
  );
}


export function getReporteActions(idProceso, idDetalle, idEstado) {
  const url = `${basePath}/reportes/acciones`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    { idProceso: idProceso, idDetalle: idDetalle, idEstado: idEstado },
    { headers: { Authorization: jwt, Parametrizacion: idProceso } }
  );
}

export function getReporteActionsJD(idProceso, idDetalle, idEstado, idDistrito) {
  const url = `${basePath}/reportes/accionesJD`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    { idProceso: idProceso, idDetalle: idDetalle, idEstado: idEstado, idDistrito: idDistrito },
    { headers: { Authorization: jwt, Parametrizacion: idProceso  } }
  );
}

export function getReporteCursos(proceso, detalle, estado, distrito, modalidad, listado, participante, desde, hasta, todos) {
  const url = `${basePath}/reportes/cursos`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    { proceso: proceso, detalle: detalle, estado: estado, distrito: distrito, 
      modalidad: modalidad, listado: listado, participante: participante, desde: desde, hasta: hasta, todos: todos },
    { headers: { Authorization: jwt, Parametrizacion: proceso  } }
  );
}

export function getDownload() {
  const url = `${basePath}/reportes/solicitud`;
  const jwt = getAccessTokenApi();
  return axios.post(url, {}, {
    headers: {
      "Content-Type": "application/json",
      Authorization: jwt,
    },
    responseType: "blob",
  });
}

export function getFechaInicioTransp(idProceso, parametro, idDetalle) {
  const url = `${basePath}/reportes/obtenFechaInicioTransp`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    { fechaInicio: parametro, idProceso:idProceso, idDetalle:idDetalle },
    { headers: { Authorization: jwt, Parametrizacion: idProceso } }
  );
}

export function getReporteTransparencia(idProceso, idDetalle, idEstado, idDistrito, fechaInicial, fechaFinal) {
  const url = `${basePath}/reportes/transparencia`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    { idProceso: idProceso, idDetalle: idDetalle, idEstado: idEstado, idDistrito: idDistrito,
      fechaInicial: fechaInicial, fechaFinal: fechaFinal },
    { headers: { Authorization: jwt, Parametrizacion: idProceso } }
  );
}

export function getReporteInformes(proceso, detalle, estado, distrito) {
  const url = `${basePath}/reportes/informes`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    { proceso: proceso, detalle: detalle, estado: estado, distrito: distrito },
    { headers: { Authorization: jwt, Parametrizacion: proceso  } }
  );
}

export function getDownloadInforme(idObservador) {
  const url = `${basePath}/reportes/informes/descarga`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    { idObservador:idObservador },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: jwt,
        Parametrizacion: "0,0",
      },
      responseType: "blob",
    }
  );
}

export function downloadConstanciaPre(idObservador) {
  const url = `${basePath}/reportes/descargarConstPre`;
  const jwt = getAccessTokenApi();
  return axios.post(url, {idObservador: idObservador},{
    headers: {
      "Authorization": jwt,
      "Content-Type": "application/json", 
    },
    responseType: "blob",
  });
}
