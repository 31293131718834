import React, { useEffect, useState, useRef } from "react";
import { Form, Input, Row, Col, message } from "antd";
import { Select, Tooltip, Radio, DatePicker } from "antd";
import { nombres, required, apellidos, ocupacion } from "../../../utils/rules";
import { soloDigitos, radioButton } from "../../../utils/rules";
import { claveElector, seccion, inputTel } from "../../../utils/rules";
import cred_mod_C from "../../../assets/img/Examples/cred_mod_C.gif";
import cred_mod_C_secc from "../../../assets/img/Examples/cred_mod_C_secc.gif";
import { Ast, valueUpperCase } from "../../../utils/Request/functions";
import { getState, getStudies } from "../../../api/requestPub";
import { txtNombre } from "../../../utils/regex";
import { formatDate } from "../../../utils/constanst";
import { disabledDate } from "../../../utils/Request/functions";
import {
  age,
  getEdad,
  getFechaNacimiento,
  getSexo,
} from "../../Generals/functions";
import { clave } from "./Foreign/regex";
import moment from 'moment';

export default function Data(props) {
  //Se obtienen las funciones por props
  const { getFieldDecorator, handleChange, datos, disabledObs, observador } = props;
  const { setDatos, form } = props;
  const { Option } = Select;
  const [studies, setStudies] = useState([]);
  const isMounted = useRef(true);
  const [select, setSelect] = useState([]); //Hook para almacenar los estados

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    //Obtiene las escolaridades
    getStudies()
      .then((res) => {
        isMounted.current && setStudies(res.data);
      })
      .catch((error) => {
        console.log("Ocurrio un error", error);
      });
  }, []);

  useEffect(() => {
    if (datos.tipoObservador === 1) {
      //Obtiene los estados para el extranjero
      getState()
        .then((res) => {
          if (isMounted.current) {
            setSelect(res.data);
          }
        })
        .catch((error) => {
          console.log("Ocurrio un error", error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //if (disabledObs && observador !== undefined && observador !== null) {
    if (observador.fechaNacimiento !== undefined && observador.fechaNacimiento !== null) {
      var momentFecha = moment(observador.fechaNacimiento);
      form.setFieldsValue({
        paterno: observador.apellidoPaterno,
        materno: observador.apellidoMaterno,
        nombre: observador.nombre,
        elector: observador.claveElector,
        seccion: observador.seccion ? observador.seccion.toString() : "", //Para extranjeros viene nulo
        sexo: observador.sexo,
        fechaNac: momentFecha,
        edad: getEdad(momentFecha),
        ocupacion: observador.ocupacion,
        telefono: observador.telefono,
        celular: observador.celular,
      });
      datos.paterno = observador.apellidoPaterno;
      datos.materno = observador.apellidoMaterno;
      datos.nombre = observador.nombre;
      datos.elector = observador.claveElector;
      datos.seccion = observador.seccion;
      datos.sexo = observador.sexo;
      datos.fechaNac = momentFecha;
      datos.edad = getEdad(momentFecha);
      datos.ocupacion = observador.ocupacion;
      datos.telefono = observador.telefono;
      datos.celular = observador.celular;
      if(observador.seccion !== null) {
        if (observador.seccion.toString().length < 4) {
          var faltante = 4 - observador.seccion.toString().length;
          var final = observador.seccion.toString();
          for (let i = 0; i < faltante; i++) {
            final = "0" + final;
          }
          props.form.setFieldsValue({
            seccion: final,
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabledObs, observador]);

  useEffect(() => {
    //if (disabledObs && studies.length > 0) {
    if (observador.cEscolaridades !== undefined && observador.cEscolaridades !== null && studies.length > 0) {
      for (let i = 0; i < studies.length; i++) {
        if (studies[i].idEscolaridad === observador.cEscolaridades.idEscolaridad) {
          form.setFieldsValue({
            //escolaridad: studies[i].escolaridad,
            escolaridad: studies[i].idEscolaridad.toString(),
          });
          datos.escolaridad = studies[i].idEscolaridad;
          datos.escolaridadTexto = studies[i].escolaridad;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabledObs, studies]);

  useEffect(() => {
    if (observador !== undefined && observador !== null && observador.extranjero !== undefined && observador.extranjero !== null && select.length > 0) {
      for (let i = 0; i < select.length; i++) {
        if (select[i].id.idEstado === observador.extranjero.idEstado) {
          datos.entidad = select[i].nombreEstado;
          datos.idEntidad = select[i].id.idEstado;
          form.setFieldsValue({
            //entidad: select[i].nombreEstado,
            entidad: select[i].id.idEstado.toString(),
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [select, observador]);


  //Obtiene el id y el nombre del estado
  const getStates = (value, event) => {
    //Se almacena la información de ambos estados en el hook principal
    setDatos({
      ...datos,
      entidad: event.props.children,
      idEntidad: value,
    });
  };
  //Obtiene el idEscolaridad y la descipción
  const getGrade = (value, event) => {
    //Se almacena la información de ambos estados en el hook principal
    setDatos({
      ...datos,
      escolaridadTexto: event.props.children,
      escolaridad: value,
    });
  };
  //función que calcula el sexo, fecha de nacimiento y la edad a partir de la clave de elector
  const calculateAge = (e) => {
    const {
      target: { value },
    } = e;
    //Valida los 18 caracteres de la clave
    if (value.length === 18 && clave.test(value.toUpperCase())) {
      //Se calculan los 3 campos
      let sexoCiudadano = getSexo(value.toUpperCase());
      let fechaCiudadano = getFechaNacimiento(value);
      let edadCiudadano = getEdad(fechaCiudadano);
      //Valida si la fecha y la edad son válidas
      if (fechaCiudadano.isValid() && !isNaN(edadCiudadano) && edadCiudadano>16) {
        //Los pinta en los inputs correspondientes
        form.setFieldsValue({
          sexo: sexoCiudadano,
          fechaNac: fechaCiudadano,
          edad: edadCiudadano.toString(),
        });
        //Se almacena la información de ambos estados en el hook principal
        setDatos({
          ...datos,
          elector: value.toUpperCase(),
          sexo: sexoCiudadano,
          fechaNac: fechaCiudadano.format("YYYY-MM-DD"),
          edad: edadCiudadano.toString(),
        });
      }else if (fechaCiudadano.isValid() && !isNaN(edadCiudadano) && edadCiudadano<17) {
        message.info(
          "La clave de elector es inválida"
        );
        //Los pinta en los inputs correspondientes
        form.setFieldsValue({
          sexo: "",
          fechaNac: "",
          edad:"",
        });
        //Se almacena la información de ambos estados en el hook principal
        setDatos({
          ...datos,
          elector: "",
          sexo: "",
          fechaNac: "",
          edad: "",
        });
      } else {
        message.info(
          "Confirme que la fecha de nacimiento cuente con el formato AA/MM/DD dentro de la clave de elector."
        );
      }
    }
  };

  return (
    <Form>
      <h3>Datos personales</h3>
      <Ast /> Al menos un apellido es obligatorio
      <Row gutter={24}>
        <Col span={8} xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
          <Form.Item label="Apellido paterno">
            {/* <div>Apellido paterno</div> */}
            {getFieldDecorator("paterno", {
              initialValue: datos.paterno,
              rules: apellidos,
              getValueFromEvent: (event) => {
                return txtNombre.test(event.target.value)
                  ? event.target.value.replace("-", "")
                  : event.target.value.slice(0, -1);
              },
            })(
              <Input
                //placeholder="Apellido paterno"
                onChange={handleChange("paterno")}
                maxLength={40}
                disabled={disabledObs}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={8} xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
          <Form.Item label="Apellido materno">
            {/* <div>Apellido materno</div> */}
            {getFieldDecorator("materno", {
              initialValue: datos.materno,
              rules: apellidos,
              getValueFromEvent: (event) => {
                return txtNombre.test(event.target.value)
                  ? event.target.value.replace("-", "")
                  : event.target.value.slice(0, -1);
              },
            })(
              <Input
                //placeholder="Apellido materno"
                onChange={handleChange("materno")}
                maxLength={40}
                disabled={disabledObs}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={8} xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
          <Form.Item label="Nombre(s)">
            {getFieldDecorator("nombre", {
              initialValue: datos.nombre,
              rules: nombres,
              getValueFromEvent: (event) => {
                return txtNombre.test(event.target.value)
                  ? event.target.value.replace("-", "")
                  : event.target.value.slice(0, -1);
              },
            })(
              <Input
                //placeholder="Nombre(s)"
                onChange={handleChange("nombre")}
                maxLength={50}
                disabled={disabledObs}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={8} xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
          <Form.Item label="Clave de elector">
            <Tooltip
              title={<img src={cred_mod_C} alt="cre" width={350} />}
              placement="bottom"
              overlayStyle={{ maxWidth: 800 }}
              trigger="focus"
            >
              {getFieldDecorator("elector", {
                rules: claveElector,
                initialValue: datos.elector,
                getValueFromEvent: valueUpperCase,
              })(
                <Input
                  // placeholder="AAAAAA 000000 00 A 000"
                  onChange={calculateAge}
                  maxLength={18}
                  disabled={disabledObs}
                />
              )}
            </Tooltip>
          </Form.Item>
        </Col>
        {/* Se muetra el componente de acuerdo al tipo de observador  */}
        {datos.tipoObservador === 0 ? (
          <Col span={8} xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
            <Form.Item style={{ width: 100 }} label="Sección">
              <Tooltip
                title={<img src={cred_mod_C_secc} alt="cre" width={350} />}
                placement="bottom"
                overlayStyle={{ maxWidth: 800 }}
                trigger="focus"
              >
                {getFieldDecorator("seccion", {
                  initialValue: datos.seccion,
                  rules: seccion,
                  getValueFromEvent: (event, previo) => {
                    return soloDigitos.test(event.target.value)
                      ? event.target.value
                      : previo;
                  },
                })(
                  <Input
                    //placeholder="0000"
                    onChange={handleChange("seccion")}
                    maxLength={4}
                    disabled={disabledObs}
                  />
                )}
              </Tooltip>
            </Form.Item>
          </Col>
        ) : (
          <Col span={8} xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
            <Form.Item label="Entidad / Lugar de nacimiento (Entidad del domicilio o referencia de la credencial de elector)">
              {getFieldDecorator("entidad", {
                initialValue: datos.entidad,
                rules: required,
              })(
                <Select
                  showSearch
                  optionFilterProp="children"
                  placeholder="Selecciona"
                  onSelect={(value, e) => getStates(value, e)}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  disabled={disabledObs}
                >
                  {select.map((item) => (
                    <Option key={item.id.idEstado}>{item.nombreEstado}</Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row gutter={24}>
        <Col span={5}>
          <Form.Item label="Sexo">
            {getFieldDecorator("sexo", {
              rules: radioButton,
              //initialValue: datos.tipoObservador,
            })(
              <Radio.Group onChange={handleChange("sexo")} disabled={disabledObs}>
                <Radio value={"M"}>Mujer</Radio>
                <Radio value={"H"}>Hombre</Radio>
              </Radio.Group>
            )}
          </Form.Item>
        </Col>
        <Col span={4} xxl={4} xl={4} lg={4} md={4} sm={24} xs={24}>
          <Form.Item label="Fecha de nacimiento">
            {getFieldDecorator("fechaNac", {
              rules: required,
              //initialValue: data.fechaLimite,
            })(
              <DatePicker
                format={formatDate}
                showToday={false}
                disabledDate={disabledDate}
                onChange={handleChange("fechaNac")}
                disabled={disabledObs}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={2} xxl={2} xl={2} lg={2} md={2} sm={24} xs={24}>
          <Form.Item label="Edad">
            {getFieldDecorator("edad", {
              initialValue: datos.edad,
              rules: [
                { required: true, message: "Dato requerido." },
                { message: "Caracteres inválidos.", pattern: /^[0-9]+$/ },
                { validator: age },
              ],
              getValueFromEvent: (event, previo) => {
                return soloDigitos.test(event.target.value)
                  ? event.target.value
                  : previo;
              },
            })(
              /* <Input
                //placeholder="1234567896"
                onChange={handleChange("edad")}
                maxLength={2}
                disabled={disabledObs}
              /> */
              <Input
                //placeholder="1234567896"
                onChange={handleChange("edad")}
                maxLength={2}
                disabled={true}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={8} xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
          <Form.Item label="Escolaridad">
            {getFieldDecorator("escolaridad", {
              initialValue: datos.escolaridad,
              rules: required,
            })(
              <Select
                showSearch
                optionFilterProp="children"
                placeholder="Selecciona"
                onSelect={(value, e) => getGrade(value, e)}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                disabled={disabledObs}
              >
                {studies.map((item) => (
                  <Option key={item.idEscolaridad}>{item.escolaridad}</Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={8} xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
          <Form.Item label="Ocupación">
            {getFieldDecorator("ocupacion", {
              initialValue: datos.ocupacion,
              rules: ocupacion,
              getValueFromEvent: (event) => {
                return txtNombre.test(event.target.value)
                  ? event.target.value.replace("-", "")
                  : event.target.value.slice(0, -1);
              },
            })(
              <Input
                onChange={handleChange("ocupacion")}
                maxLength={40}
                disabled={disabledObs}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={8} xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
          <Tooltip
            title="10 dígitos para números de México"
            placement="bottomLeft"
            trigger="focus"
          >
            <Form.Item label="Teléfono fijo">
              {getFieldDecorator("telefono", {
                initialValue: datos.telefono,
                rules: inputTel,
                getValueFromEvent: (event) => {
                  return soloDigitos.test(event.target.value)
                    ? event.target.value
                    : event.target.value.slice(0, -1);
                },
              })(
                <Input
                  //placeholder="1234567896"
                  onChange={handleChange("telefono")}
                  maxLength={datos.tipoObservador === 0 ? 10 : 15}
                  disabled={disabledObs}
                />
              )}
            </Form.Item>
          </Tooltip>
        </Col>
        <Col span={8} xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
          <Tooltip
            title="10 dígitos para números de México"
            placement="bottomLeft"
            trigger="focus"
          >
            <Form.Item label="Celular">
              {getFieldDecorator("celular", {
                initialValue: datos.celular,
                rules: inputTel,
                getValueFromEvent: (event, previo) => {
                  return soloDigitos.test(event.target.value)
                    ? event.target.value
                    : event.target.value.slice(0, -1);
                },
              })(
                <Input
                  //placeholder="1234567896"
                  onChange={handleChange("celular")}
                  maxLength={datos.tipoObservador === 0 ? 10 : 15}
                  disabled={disabledObs}
                />
              )}
            </Form.Item>
          </Tooltip>
        </Col>
      </Row>
    </Form>
  );
}
