import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, Redirect, withRouter } from "react-router-dom";
import { Button, Col, Row } from "antd";
import { Back } from "../../../../components/User/CourseOnline/Generals";
import { MenuLessons } from "../../../../components/User/CourseOnline/Menu";
import introImg from "../../../../assets/img/CourseOnline/introduccion.png";
import tituloImg from "../../../../assets/img/CourseOnline/titulo.jpg";
import { Title } from "../../../../components/User/CourseOnline/Title";
import "./Courses.scss";
import { getNumber } from "../../../../components/Generals/functions";

/**
 * Pantalla 2
 * Componentes que muestra la pantalla de introducción al inicar el curso de una entidad
 * path: /ciudadano/introduccion
 */
function Introduccion(props) {
  const { idEntidad, entidad } = useSelector((state) => state.entidad);
  const { loading } = useSelector((state) => state.menu);
  const { temario } = useSelector((state) => state.menu);
  const row = 18;
  const xxL = 6;

  useEffect(() => {
    window.scrollTo(0, 0); // para posicionar el scroll al inicio
  }, []);

  // const openPdf = (file) => {
  //   window.open("data:application/pdf;base64, " + encodeURIComponent(file));
  // };
  const countLessons = () => {
    let lessons = 0;
    temario.forEach((item) => {
      if (item.tipoLeccion === 1) {
        lessons += 1;
      }
    });
    return getNumber(lessons);
  };

  //console.log(numberLesson);
  if (!idEntidad) {
    return <Redirect to="/ciudadano/capacitacion" />;
  }
  return (
    <>
      <Back path="/ciudadano/capacitacion" />
      <Title entitie={entidad} lesson={"Presentación del curso"} />
      <Row gutter={24} className="material">
        <Col span={row} xxl={row} xl={row} lg={row} md={row} sm={24} xs={24}>
          <div className="texto_parrafo">
            <img src={tituloImg} alt="img" className="imagen" />
            <br />
            <p>
              En este curso identificarás las características y las particularidades de la elección 
              local de la entidad en donde llevarás a cabo la observación electoral. Conocerás las 
              actividades que se realizan en cada una de las etapas del Proceso Electoral, quiénes 
              las realizan y cómo deben llevarse a cabo. Estas actividades las podrás observar al 
              momento de desempeñar tus funciones.
            </p>
            <p>
              Tu curso está integrado por <b>{countLessons()}</b> lecciones. En cada una encontrarás 
              la información necesaria y los materiales didácticos para tu capacitación, al finalizar 
              cada lección habrá una actividad autoevaluable y al concluir el curso, un ejercicio final. 
              Deberás realizar todas las autoevaluaciones para obtener tu constancia de capacitación. 
              En cualquier momento podrás consultar tu Guía temática, así como la normatividad electoral 
              vigente para el desarrollo de los procesos electorales locales.
            </p>
            <p>
              Recuerda que tu participación como observador u observadora electoral es fundamental, 
              y su importancia se refleja en cada etapa del Proceso Electoral.
            </p>
            <p>
              <b>Agradecemos tu interés de ser testigo y formar parte activa de la
              democracia del país.</b>
            </p>
            <img src={introImg} alt="img" className="imagen" />
          </div>
          <Link to={{ pathname: "/ciudadano/lecciones" }} disabled={loading}>
            <Button
              type="primary"
              disabled={loading}
              style={{ marginBottom: 30 }}
            >
              Ir a la lección 1
            </Button>
          </Link>
        </Col>
        <Col span={xxL} xxl={xxL} xl={xxL} lg={xxL} md={xxL} sm={24} xs={24}>
          {/* Se manda null el index para que no muestre seleccionado ninguna lección */}
          <MenuLessons index={null} />
        </Col>
      </Row>
    </>
  );
}

export default withRouter(Introduccion);
