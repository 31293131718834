import React, { useState, useEffect } from "react";
import { Table, Button, Input, Select, Tooltip, Icon, message } from "antd";
import { withRouter } from "react-router-dom";
import { downloadConstanciaPre } from "../../../api/reports";

function TableCourses(props) {
    const { data, loading, setEntidad, setJunta, tipoModalidad, tipoListado, 
        tipoParticipante, entidad, junta } = props;
    const [dataSource, setDataSource] = useState([]);
    //Hook de busqueda
    const [valNombre, setValNombre] = useState(""); 
    const [valImparte, setValImparte] = useState(""); 
    const [valFechaImparte, setValFechaImparte] = useState("");
    const [valEdoRevision, setValEdoRevision] = useState("");  
    const [valAvances, setValAvances] = useState("");  
    const { Option } = Select;
    
    useEffect(() => {
        setDataSource(data);
    }, [data]);

    function cambioEntidad(record) {
        setEntidad({id: record.idEntidad, nombre: record.entidad});
        setJunta({id:null, nombre:null});
    }

    // filtro input
    const FilterByName = (
        <>
          Nombre
          <Input
            placeholder="Nombre"
            valNombre={valNombre}
            onChange={(e) => {
              const currValue = e.target.value;
              setValNombre(currValue);
              const filteredData = data.filter((entry) =>
                entry.nombreObservador
                  .toString()
                  .toLowerCase()
                  .includes(currValue.toLowerCase())
              );
              setDataSource(filteredData);
            }}
          />
        </>
    );

    const FilterByImparte = (
        <>
          Curso impartido por
          <br />
          <Select style={{ width: 130 }} optionFilterProp="children" placeholder="Todos" value={valImparte}
            onChange={(value) => {
              const currValue = value;
              setValImparte(currValue);
              const filteredData = data.filter((entry) =>
                entry.imparteInstitucion
                  .toString()
                  .toLowerCase()
                  .includes(currValue.toLowerCase())
                );
                setDataSource(filteredData);
            }}
          >
            <Option value="">Todos</Option>
            <Option value="INE">INE</Option>
            <Option value="OPL">OPL</Option>
            <Option value="Organización">Organización</Option>
          </Select>
        </>
    );

    const FilterByDateImparte = (
        <>
          Fecha de impartición
          <Input
            placeholder="Fecha de impartición"
            valFechaImparte={valFechaImparte}
            onChange={(e) => {
              const currValue = e.target.value;
              setValFechaImparte(currValue);
              const filteredData = data.filter((entry) =>
                entry.fechaImparte
                  .toString()
                  .toLowerCase()
                  .includes(currValue.toLowerCase())
              );
              setDataSource(filteredData);
            }}
          />
        </>
    );

    const FilterByEdoImparte = (
        <>
          Estado de la revisión
          <br />
          <Select style={{ width: 130 }} optionFilterProp="children" placeholder="Todos" value={valEdoRevision}
            onChange={(value) => {
              const currValue = value;
              setValEdoRevision(currValue);
              const filteredData = data.filter((entry) =>
                entry.estatus
                  .toString()
                  .toLowerCase()
                  .includes(currValue.toLowerCase())
                );
                setDataSource(filteredData);
            }}
          >
            <Option value="">Todos</Option>
            <Option value="Validada">Validada</Option>
            <Option value="Sin revisión">Sin revisión</Option>
            <Option value="Con observaciones">Con observaciones</Option>
          </Select>
        </>
    );

    const FilterByAvances = (
        <>
          Avance del curso en línea
          <br />
          <Select style={{ width: 130 }} optionFilterProp="children" placeholder="Todos" value={valAvances}
            onChange={(value) => {
              const currValue = value;
              setValAvances(currValue);
              const filteredData = data.filter((entry) =>
                entry.avance
                  .toString()
                  .toLowerCase()
                  .includes(currValue.toLowerCase())
                );
                setDataSource(filteredData);
            }}
          >
            <Option value="">Todos</Option>
            <Option value="Sin iniciar">Sin iniciar</Option>
            <Option value="En capacitación">En capacitación</Option>
            <Option value="Curso concluido">Curso concluido</Option>
          </Select>
        </>
    );

    const columnasPresencial = [
        {
            title: "Entidad",
            children: [
                {
                    title: "",
                    dataIndex: "entidad",
                    key: "entidad",
                    render: (text, record) => <Button className="buttonReports" type="link" 
                    onClick={(e) => cambioEntidad(record)} >{text}</Button>
                },
            ]
        },
        {
            title: "Cursos a impartir por",
            children: [
                {
                    title: "JL",
                    dataIndex: "impartidoLocal",
                    key: "impartidoLocal",
                    align:"center",
                },
                {
                    title: "JD",
                    dataIndex: "impartidoJunta",
                    key: "impartidoJunta",
                    align:"center",
                },
                {
                    title: "OPL",
                    dataIndex: "impartidoOPL",
                    key: "impartidoOPL",
                    align:"center",
                },
                {
                    title: "Organización",
                    dataIndex: "impartidoORG",
                    key: "impartidoORG",
                    align:"center",
                },
            ]
        },
        {
            title: "Total a impartir",
            dataIndex: "impartidoTotal",
            key: "impartidoTotal",
            align:"center",
        },
        {
            title: "Solicitudes inscritas en curso",
            children: [
                {
                    title: "JL",
                    dataIndex: "inscritoLocal",
                    key: "inscritoLocal",
                    align:"center",
                },
                {
                    title: "JD",
                    dataIndex: "inscritoJunta",
                    key: "inscritoJunta",
                    align:"center",
                },
                {
                    title: "OPL",
                    dataIndex: "inscritoOPL",
                    key: "inscritoOPL",
                    align:"center",
                },
                {
                    title: "Organización",
                    dataIndex: "inscritoORG",
                    key: "inscritoORG",
                    align:"center",
                },
            ]
        },
        {
            title: "Total inscritos",
            dataIndex: "inscritoTotal",
            key: "inscritoTotal",
            align:"center",
        },
        {
            title: "Solicitudes capacitadas en curso",
            children: [
                {
                    title: "JL",
                    dataIndex: "capacitadoLocal",
                    key: "capacitadoLocal",
                    align:"center",
                },
                {
                    title: "JD",
                    dataIndex: "capacitadoJunta",
                    key: "capacitadoJunta",
                    align:"center",
                },
                {
                    title: "OPL",
                    dataIndex: "capacitadoOPL",
                    key: "capacitadoOPL",
                    align:"center",
                },
                {
                    title: "Organización",
                    dataIndex: "capacitadoORG",
                    key: "capacitadoORG",
                    align:"center",
                },
            ]
        },
        {
            title: "Total capacitados",
            dataIndex: "capacitadoTotal",
            key: "capacitadoTotal",
            align:"center",
        },
    ]

    const columnasPresencialEntidad = [
        {
            title: "Distrito",
            children: [
                {
                    title: "",
                    dataIndex: "distrito",
                    key: "distrito",
                    render: (text, record) => <Button className="buttonReports" type="link" 
                    onClick={() => setJunta({id: record.idDistrito, nombre: record.distrito})} >{text}</Button>
                },
            ]
        },
        {
            title: "Cursos a impartir por",
            children: [
                {
                    title: "Junta ejecutiva",
                    dataIndex: "impartidoJunta",
                    key: "impartidoJunta",
                    align:"center",
                },
                {
                    title: "OPL",
                    dataIndex: "impartidoOPL",
                    key: "impartidoOPL",
                    align:"center",
                },
                {
                    title: "Organización",
                    dataIndex: "impartidoORG",
                    key: "impartidoORG",
                    align:"center",
                },
            ]
        },
        {
            title: "Total a impartir",
            dataIndex: "impartidoTotal",
            key: "impartidoTotal",
            align:"center",
        },
        {
            title: "Solicitudes inscritas en curso",
            children: [
                {
                    title: "Junta ejecutiva",
                    dataIndex: "inscritoJunta",
                    key: "inscritoJunta",
                    align:"center",
                },
                {
                    title: "OPL",
                    dataIndex: "inscritoOPL",
                    key: "inscritoOPL",
                    align:"center",
                },
                {
                    title: "Organización",
                    dataIndex: "inscritoORG",
                    key: "inscritoORG",
                    align:"center",
                },
            ]
        },
        {
            title: "Total inscritos",
            dataIndex: "inscritoTotal",
            key: "inscritoTotal",
            align:"center",
        },
        {
            title: "Solicitudes capacitadas en curso",
            children: [
                {
                    title: "Junta ejecutiva",
                    dataIndex: "capacitadoJunta",
                    key: "capacitadoJunta",
                    align:"center",
                },
                {
                    title: "OPL",
                    dataIndex: "capacitadoOPL",
                    key: "capacitadoOPL",
                    align:"center",
                },
                {
                    title: "Organización",
                    dataIndex: "capacitadoORG",
                    key: "capacitadoORG",
                    align:"center",
                },
            ]
        },
        {
            title: "Total capacitados",
            dataIndex: "capacitadoTotal",
            key: "capacitadoTotal",
            align:"center",
        },
    ]

    const columnasPresencialEntidadInscritos = [
        {
            title: FilterByName,
            dataIndex: "nombreObservador",
            key: "nombreObservador",
        },
        {
            title: "Sexo",
            dataIndex: "sexo",
            key: "sexo",
        },
        {
            title: FilterByImparte,
            dataIndex: "imparteInstitucion",
            key: "imparteInstitucion",
        },
        {
            title: FilterByDateImparte,
            dataIndex: "fechaImparte",
            key: "fechaImparte",
        },
        {
            title: "Hora de inicio",
            dataIndex: "horaInicio",
            key: "horaInicio",
        },
        {
            title: "Hora de término",
            dataIndex: "horaTermino",
            key: "horaTermino",
        },
        {
            title: "Persona que imparte el curso",
            dataIndex: "impartePersona",
            key: "impartePersona",
        },
        {
            title: "Cargo de la persona que imparte el curso",
            dataIndex: "cargo",
            key: "cargo",
        },
        {
            title: "Sede del curso",
            dataIndex: "sede",
            key: "sede",
        },
        {
            title: "Fecha en que completó el curso",
            dataIndex: "fechaAsistencia",
            key: "fechaAsistencia",
        },
        {
            title: "Entidades a observar",
            dataIndex: "entidadesObservar",
            key: "entidadesObservar",
        },
        {
            title: "Constancia de asistencia",
            align: "center",
            render: (record) => (
                <>
                    {record.fechaAsistencia && (
                        <Tooltip title="Descargar constancia">
                            <button className="btn_descarga_const" onClick={() => downloadConst(record)}>
                                <Icon type="file-pdf" />
                            </button>
                        </Tooltip>
                    )}
                </>
            ),
        },
        {
            title: "Plataforma",
            dataIndex: "plataforma",
            key: "plataforma",
        }
    ]

    //Función que permite descargar la constancia de acreditación
    const downloadConst = (dataRow) => {
        downloadConstanciaPre(dataRow.idObservador).then((res) => {
            if (res.data) {
                var urlCreator = window.URL || window.webkitURL || window.mozURL || window.msURL;
                const url = urlCreator.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `ConstanciaPresencial.pdf`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            }
        }).catch((error) => message.error("El archivo no se encuentra disponible"));
    };

    const columnasPresencialEntidadNoInscritos = [
        {
            title: FilterByName,
            dataIndex: "nombreObservador",
            key: "nombreObservador",
        },
        {
            title: FilterByEdoImparte,
            dataIndex: "estatus",
            key: "estatus",
        },
        {
            title: "Correo electrónico",
            dataIndex: "correo",
            key: "correo",
        },
        {
            title: "Teléfono",
            dataIndex: "telefono",
            key: "telefono",
        },
    ]

    const columnasPresencialDistritoNoInscritos = [
        {
            title: FilterByName,
            dataIndex: "nombreObservador",
            key: "nombreObservador",
        },
        {
            title: FilterByEdoImparte,
            dataIndex: "estatus",
            key: "estatus",
        },
        {
            title: "Correo electrónico",
            dataIndex: "correo",
            key: "correo",
        },
        {
            title: "Teléfono",
            dataIndex: "telefono",
            key: "telefono",
        },
    ]

    const columnasPresencialDistrito = [
        {
            title: "Fecha de impartición",
            dataIndex: "fechaImparte",
            key: "fechaImparte",
        },
        {
            title: "Hora de inicio",
            dataIndex: "horaInicio",
            key: "horaInicio",
        },
        {
            title: "Hora de término",
            dataIndex: "horaTermino",
            key: "horaTermino",
        },
        {
            title: FilterByImparte,
            dataIndex: "imparteInstitucion",
            key: "imparteInstitucion",
        },
        {
            title: "Persona que imparte el curso",
            dataIndex: "impartePersona",
            key: "impartePersona",
        },
        {
            title: "Cargo de la persona que imparte el curso",
            dataIndex: "cargo",
            key: "cargo",
        },
        {
            title: "Sede del curso",
            dataIndex: "sede",
            key: "sede",
        },
        {
            title: "Mujeres inscritas",
            dataIndex: "inscritoMujeres",
            key: "inscritoMujeres",
            align:"center",
        },
        {
            title: "Hombres inscritos",
            dataIndex: "inscritoHombres",
            key: "inscritoHombres",
            align:"center",
        },
        {
            title: "Total inscritos",
            dataIndex: "inscritoTotal",
            key: "inscritoTotal",
            align:"center",
        },
        {
            title: "Mujeres asistentes",
            dataIndex: "capacitadoMujeres",
            key: "capacitadoMujeres",
            align:"center",
        },
        {
            title: "Hombres asistentes",
            dataIndex: "capacitadoHombres",
            key: "capacitadoHombres",
            align:"center",
        },
        {
            title: "Total asistentes",
            dataIndex: "capacitadoTotal",
            key: "capacitadoTotal",
            align:"center",
        },
    ];

    const columnasEnLineaNacional = [
        {
            title: "Entidad",
            dataIndex: "entidad",
            key: "entidad",
            render: (text, record) => <Button className="buttonReports" type="link"
            onClick={(e) => cambioEntidad(record)}  >{text}</Button>
        },
        {
            title: "Mujeres inscritas",
            dataIndex: "inscritoMujeres",
            key: "inscritoMujeres",
            align:"center",
        },
        {
            title: "Hombres inscritos",
            dataIndex: "inscritoHombres",
            key: "inscritoHombres",
            align:"center",
        },
        {
            title: "Total inscritos",
            dataIndex: "inscritoTotal",
            key: "inscritoTotal",
            align:"center",
        },
        {
            title: "Mujeres capacitadas",
            dataIndex: "capacitaMujeres",
            key: "capacitaMujeres",
            align:"center",
        },
        {
            title: "Hombres capacitados",
            dataIndex: "capacitaHombres",
            key: "capacitaHombres",
            align:"center",
        },
        {
            title: "Total capacitados",
            dataIndex: "capacitaTotal",
            key: "capacitaTotal",
            align:"center",
        },
    ];

    const columnasEnLineaEntidad = [
        {
            title: "Distrito",
            dataIndex: "distrito",
            key: "distrito",
            render: (text, record) => <Button className="buttonReports" type="link"
                    onClick={() => setJunta({id: record.idDistrito, nombre: record.distrito})} >{text}</Button>
        },
        {
            title: "Mujeres inscritas",
            dataIndex: "inscritoMujeres",
            key: "inscritoMujeres",
            align:"center",
        },
        {
            title: "Hombres inscritos",
            dataIndex: "inscritoHombres",
            key: "inscritoHombres",
            align:"center",
        },
        {
            title: "Total inscritos",
            dataIndex: "inscritoTotal",
            key: "inscritoTotal",
            align:"center",
        },
        {
            title: "Mujeres capacitadas",
            dataIndex: "capacitaMujeres",
            key: "capacitaMujeres",
            align:"center",
        },
        {
            title: "Hombres capacitados",
            dataIndex: "capacitaHombres",
            key: "capacitaHombres",
            align:"center",
        },
        {
            title: "Total capacitados",
            dataIndex: "capacitaTotal",
            key: "capacitaTotal",
            align:"center",
        },
    ];

    const columnasDistritoLinea = [
        {
            title: FilterByName,
            dataIndex: "nombreObservador",
            key: "nombreObservador",
        },
        {
            title: "Sexo",
            dataIndex: "sexo",
            key: "sexo",
        },
        {
            title: "Fecha de validación de la solicitud",
            dataIndex: "fechaValidacion",
            key: "fechaValidacion",
        },
        {
            title: FilterByAvances,
            dataIndex: "avance",
            key: "avance",
        },
        {
            title: "Última lección que completó",
            dataIndex: "ultimaLeccion",
            key: "ultimaLeccion",
        },
        {
            title: "Fecha en que completó la última lección",
            dataIndex: "fechaLeccion",
            key: "fechaLeccion",
        },
        {
            title: "Entidad del último curso tomado",
            dataIndex: "ultimaEntidad",
            key: "ultimaEntidad",
        },
        {
            title: "Fecha en que completó el curso",
            dataIndex: "fechaTermino",
            key: "fechaTermino",
        },
        {
            title: "Entidades a observar",
            dataIndex: "entidadesObservar",
            key: "entidadesObservar",
        },
    ];

    function tipoTabla() {
        if (entidad.id === null) {
            if (tipoModalidad === 0) {
                return (
                    <Table
                        columns={columnasPresencial}
                        dataSource={dataSource}
                        loading={loading}
                        locale={{ emptyText: "Sin registros" }}
                        size="small"
                        scroll={{ x: true }}
                    />
                );
            } else {
                return (
                    <Table
                        columns={columnasEnLineaNacional}
                        dataSource={dataSource}
                        loading={loading}
                        locale={{ emptyText: "Sin registros" }}
                        size="small"
                        scroll={{ x: true }}
                    />
                );
            }
        } else {
            if (entidad.id !== null && junta.id === null) {
                if (tipoModalidad === 1) {
                    return (
                        <Table
                            columns={columnasEnLineaEntidad}
                            dataSource={dataSource}
                            loading={loading}
                            locale={{ emptyText: "Sin registros" }}
                            size="small"
                            scroll={{ x: true }}
                        />
                    );
                } else {
                    if (tipoListado === 0) {
                        return (
                            <Table
                                columns={columnasPresencialEntidad}
                                dataSource={dataSource}
                                loading={loading}
                                locale={{ emptyText: "Sin registros" }}
                                size="small"
                                scroll={{ x: true }}
                            />
                        );
                    } else {
                        if (tipoParticipante === 0) {
                            return (
                                <Table
                                    columns={columnasPresencialEntidadInscritos}
                                    dataSource={dataSource}
                                    loading={loading}
                                    locale={{ emptyText: "Sin registros" }}
                                    size="small"
                                    scroll={{ x: true }}
                                />
                            );
                        } else {
                            return (
                                <Table
                                    columns={columnasPresencialEntidadNoInscritos}
                                    dataSource={dataSource}
                                    loading={loading}
                                    locale={{ emptyText: "Sin registros" }}
                                    size="small"
                                    scroll={{ x: true }}
                                />
                            );
                        }                        
                    }
                }
            } else {
                if (tipoModalidad === 0) {
                    if (tipoListado === 0) {
                        return (
                            <Table
                                columns={columnasPresencialDistrito}
                                dataSource={dataSource}
                                loading={loading}
                                locale={{ emptyText: "Sin registros" }}
                                size="small"
                                scroll={{ x: true }}
                            />
                        );
                    } else {
                        if (tipoParticipante === 0) {
                            return (
                                <Table
                                    columns={columnasPresencialEntidadInscritos}
                                    dataSource={dataSource}
                                    loading={loading}
                                    locale={{ emptyText: "Sin registros" }}
                                    size="small"
                                    scroll={{ x: true }}
                                />
                            );
                        } else {
                            return (
                                <Table
                                    columns={columnasPresencialDistritoNoInscritos}
                                    dataSource={dataSource}
                                    loading={loading}
                                    locale={{ emptyText: "Sin registros" }}
                                    size="small"
                                    scroll={{ x: true }}
                                />
                            );
                        }
                    }
                } else {
                    return (
                        <Table
                            columns={columnasDistritoLinea}
                            dataSource={dataSource}
                            loading={loading}
                            locale={{ emptyText: "Sin registros" }}
                            size="small"
                            scroll={{ x: true }}
                        />
                    );
                }
            }
        }
    }

    return (
        <div>{tipoTabla()}</div>        
    );
}

export default withRouter(TableCourses);