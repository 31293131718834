import { Button, Col, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as LogoRevision } from "../../../../assets/img/Portal/revisionSolicitud.svg";

export default function Notice(props) {
  const {
    ciudadano, sinRequisitos
  } = props;

  return (
    <div className="home mensaje">
      <Row gutter={24}>
        <Col xxl={18} xl={18} lg={18} md={16} sm={24} xs={24}>
        {!sinRequisitos ? (
          <>
          <h1>Tus datos están en revisión</h1>
          <p>
            Se te informará el resultado de la revisión mediante el portal y por
            correo electrónico.
          </p>
          {ciudadano.id && (
            <Link to={{ pathname: "/ciudadano/solicitud/modificar", state: true, modificar: true }}>
              <Button type="primary" style={{width:200}}>Modificar solicitud</Button>
            </Link>
          )}
          </>
          ):(
            <>
              <h1>Tu solicitud no completó con los requisitos</h1>
              <p style={{ fontSize: 18 }}>
              Si tienes alguna duda comunícate a tu junta dónde realizaste el registro.
              </p>
            </>
          )}
        </Col>
        <Col xxl={6} xl={6} lg={6} md={8} sm={0} xs={0}>
          <LogoRevision className="logo" />
        </Col>
      </Row>
    </div>
  );
}