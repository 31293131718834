import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
//import {getAyuntamiento,getAyuntamiento1,} from "../../../../utils/CourseOnline/Util";
import { GuiaMaterial, MatApoyo } from "../Generals";
import { useLesson2 } from "../../../../hooks/useLesson2";
import { capitalizeWord } from "../../../Generals/functions";

export default function Lesson2({ data, setLoading }) {
  const isMounted = useRef(true);
  //Se obtiene el Entidad seleccionada del store de redux
  const { entidad, idEntidad } = useSelector((state) => state.entidad);
  //State-Redux

  const { 
    material,
    entidadMat: {entidad: entidadMat },
  } = useSelector((state) => state.material);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  if(idEntidad!==undefined){
    localStorage.setItem('idEntidad', idEntidad);
  }

  useEffect(() => {
    window.scrollTo(0, 0); // para posicionar el scroll al inicio
  }, []);

 /*const getIdEntity = () => {
    return material ? id : idEntidad;
  };*/
  const getTextEntity = () => {
    return material ? entidadMat : entidad;
  };
  const GetTextParrafo= (idParrafo) => {
    return (
      <span  dangerouslySetInnerHTML={{__html: useLesson2(parseInt(window.localStorage.getItem('idEntidad')), idParrafo).parrafo}}></span>)
  };

  const GetTextParrafoLista= (idParrafo) => {
    return useLesson2(parseInt(window.localStorage.getItem('idEntidad')), idParrafo).parrafo
  };

  function listaCargos() {
    let texto=GetTextParrafoLista(3);
    let texto2=GetTextParrafoLista(4);
    if(texto!==""&&texto2!==""){
      return (
      <ul>
        <li><span  dangerouslySetInnerHTML={{__html: texto}}></span></li>
        <li><span  dangerouslySetInnerHTML={{__html: texto2}}></span></li>
      </ul>
      );
    }else if(texto!==""&&texto2===""){
      return (
      <ul>
        <li><span  dangerouslySetInnerHTML={{__html: texto}}></span></li>
      </ul>
      );
    }else if(texto===""&&texto2!==""){
      return (
      <ul>
        <li><span  dangerouslySetInnerHTML={{__html: texto2}}></span></li>
      </ul>
      );
    }else{
      return (
        <div></div>
      );
    }
  };

  return (
    <>
      <div className="texto_parrafo">
        <p>
          En el Sistema Electoral Mexicano la voluntad de la ciudadanía es un elemento fundamental, 
          pues a través de ella se hace posible la representación popular, por lo que las personas 
          que hayan cumplido 18 años y tengan un modo honesto de vivir adquieren la calidad de 
          ciudadanas o ciudadanos; esta calidad otorga derechos y obligaciones.
        </p>
        <p>
          El votar constituye simultáneamente un derecho y una obligación de la ciudadanía y forma 
          parte de los derechos político-electorales reconocidos como Derechos Humanos.
        </p>
        <p>
          Por eso, debes tener presente qué se requiere para poder votar. En el siguiente material 
          de apoyo te decimos cuáles son esos requisitos y las características del voto.
        </p>
        {data.length && (<MatApoyo array={[data[0]]}/>)}
        <p>
          El Sistema Electoral Mexicano es el conjunto de actores, normas y procedimientos involucrados en el 
          Proceso Electoral, encaminados a la integración de las y los integrantes de los poderes 
          Ejecutivo y Legislativo de la Unión y de las entidades federativas, y la renovación periódica de sus integrantes.
        </p>
        <p>
          Uno de los actores que intervienen en el Proceso Electoral son las autoridades electorales. 
          De acuerdo con su ámbito territorial de competencia se clasifican en: autoridades 
          electorales federales y autoridades electorales locales.
        </p>
        <p>
          En los siguientes videos te presentamos a las autoridades electorales, 
          su naturaleza, estructura y principales atribuciones.
        </p>
        {data.length && <MatApoyo array={[data[1]]} />}
        {data.length && <MatApoyo array={[data[2]]} />}
        <p>
          El próximo 5 de junio de 2022, en el estado de {capitalizeWord(getTextEntity())} se celebrarán elecciones locales. 
          ¿Ya sabes que cargos se elegirán en la Jornada Electoral?  
          En {capitalizeWord(getTextEntity())} se elegirá {GetTextParrafo(1)}.
        </p>
        <p>
          {GetTextParrafo(2)}
        </p>
        {listaCargos()}
          <p>Para comprender cómo {GetTextParrafo(5)} es importante que conozcas el <span className="bold">marco geográfico electoral local</span>, 
            el cual se refiere al espacio geográfico que determina la distribución de las y los ciudadanos que pueden votar en 
            el territorio que comprende el estado de {capitalizeWord(getTextEntity())} y que sirve de base para la planeación 
            y organización de los procesos electorales.
          </p>
        <p>
          Se consideran, además de las secciones electorales y la entidad federativa donde se elegirá el cargo, los Municipios y
          los Distritos Electorales {GetTextParrafo(6)}.
        </p>
        <p>
          Los detalles del Sistema Electoral Mexicano los puedes consultar en el{" "}
          <GuiaMaterial setLoading={setLoading}>
            CAPÍTULO 2 DE TU GUÍA TEMÁTICA PARA CAPACITAR A LAS Y LOS OBSERVADORES ELECTORALES
          </GuiaMaterial>{" "}
          y sus respectivos anexos que se encuentran a tu disposición en el material de consulta de este curso.
        </p>
        <p>
          Además de las autoridades electorales que revisamos, otros actores que 
          participan en el Proceso Electoral son los partidos políticos y las candidaturas independientes. 
        </p>
        <p>
          Los partidos políticos, son entidades de interés público, con personalidad jurídica, con registro ante el INE o 
          ante los OPL, con derecho a participar en las elecciones para cargos de elección popular a nivel federal, estatal 
          y municipal. Gozan de derechos, prerrogativas y obligaciones que establece la ley. 
        </p>
        <p>
          Los partidos políticos tienen fines específicos establecidos en la ley, revisa el material de apoyo para que los conozcas.
        </p>
        {data.length && <MatApoyo array={[data[3]]} />}
        <p>
          Existen partidos políticos <b>nacionales</b> y <b>locales</b>. Los partidos políticos nacionales son aquellos que se registraron ante el 
          INE y se encuentran facultados para participar en los procesos electorales federales, es decir, en la elección de la 
          persona titular de la Presidencia de la República, Diputaciones y Senadurías del Congreso de la Unión, así como en los 
          procesos locales de Gubernatura, Diputaciones Locales y Ayuntamientos. Mientras que los partidos políticos locales con 
          registro ante el {GetTextParrafo(7)} tienen la facultad de participar únicamente en los procesos electorales estatales, distritales y 
          municipales, es decir, en la elección de: Gubernatura, Diputaciones Locales y Ayuntamientos.
        </p>
        <p>
          A nivel nacional hay registrados 7 partidos políticos. Mientras que a nivel local en {capitalizeWord(getTextEntity())} {GetTextParrafo(8)}. 
          Los partidos políticos tienen derechos y obligaciones, 
          revisa el siguiente material para conocerlos.
        </p>
        {data.length && <MatApoyo array={[data[4]]} />}
        <p>
          Los partidos políticos pueden participar en el Proceso Electoral postulando candidaturas de forma individual o a 
          través de coaliciones. {GetTextParrafo(9)}.
        </p>
        <p>
          Revisa el siguiente material para conocer las características de las coaliciones.
        </p>
        {data.length && <MatApoyo array={[data[5]]} />}
        <p>
          ¿Sabes que es una precampaña, el registro de candidaturas y las campañas electorales?
        </p>
        <p>
          Una <b>precampaña</b> es el conjunto de actos que realizan los partidos políticos, sus militantes 
          y las y los precandidatos a cargos de elección popular, durante los procesos internos de selección de candidaturas.
        </p>
        <p>
          Posterior a la precampaña se <b>registran candidaturas</b> de partidos políticos a los cargos de elección popular, 
          el partido político, {GetTextParrafo(10)} postulante deberá presentar y obtener el registro de la 
          plataforma electoral que sus candidatos y candidatas sostendrán a lo largo de las campañas políticas, 
          para lo cual deberán acreditar que realizaron los procesos internos establecidos.
        </p>
        <p>
          Una vez registradas las candidaturas se llevan a cabo las <b>campañas electorales</b>, que son un conjunto de actividades 
          que realizan {GetTextParrafo(11)} y las y los candidatos 
          registrados para obtener el voto de la ciudadanía. Se consideran actos de campaña todas las reuniones públicas, 
          asambleas, marchas y en general aquellos actos en que las y los candidatos o voceros de los partidos políticos se 
          dirigen al electorado para promover sus candidaturas.
        </p>
        <p>
          El día de la Jornada Electoral y durante los tres días anteriores NO se permite realizar actos públicos 
          de campaña, de propaganda o de proselitismo.
        </p>
        <p>
          Revisa tu Guía temática para conocer los periodos de precampaña, registro de candidaturas y campañas electorales de la elección local.
        </p>
        <p>
          Como vimos anteriormente, uno de los derechos de los partidos políticos es recibir financiamiento, el cual se divide en <b>público</b> y <b>privado</b>. Puedes revisar las características de cada tipo en el siguiente material de apoyo.
        </p>
         {data.length && <MatApoyo array={[data[6]]} />}
         <p>
          Ahora conoceremos la figura de <b>candidaturas independientes</b> y sus características:
         </p>
         <p>
          La <b>Candidatura Independiente</b>, se refiere a la o el ciudadano mexicano que obtiene por 
          parte de la autoridad electoral su registro para una candidatura a un cargo de elección 
          popular, sin necesidad de que un partido político lo postule.
         </p>
         <p>
          Los procesos de selección de Candidaturas Independientes comprenden las siguientes etapas: 
         </p>
         <ol>
            <li>La <b>emisión de la Convocatoria</b>, por parte del INE y de los OPL.</li>
            <li>Los <b>Actos previos al registro</b>, es decir, la manifestación de la 
              intención de participar como candidato o candidata independiente.</li>
            <li>La <b>Obtención de apoyo ciudadano</b>, la cual se hará a partir del día siguiente de 
              la fecha en que obtengan la calidad de aspirantes a una candidatura independiente. 
              El apoyo ciudadano es el respaldo de la ciudadanía inscrita en la Lista Nominal a 
              una persona que busca obtener una candidatura independiente.  Podrán realizar actos 
              para recabar el porcentaje de apoyo ciudadano requerido, por ejemplo, a través de 
              reuniones públicas, asambleas o marchas.
            </li>
            <li>En el <b>Registro de Candidaturas</b>, las y los ciudadanos que aspiren a participar 
              como candidatas o candidatos independientes deberán presentar su solicitud de registro y documentación requerida.
            </li>
          </ol>
         <p>
          Revisa el siguiente material de apoyo para conocer los derechos y obligaciones de las 
          candidaturas independientes, también revisa tu Guía temática para conocer los periodos y características del registro.
         </p>
          {data.length && <MatApoyo array={[data[7]]} />}
         <p>
          Es importante que sepas que tanto los partidos políticos como las candidaturas independientes tienen <b>derecho de acceso a la radio y la televisión</b>.
         </p>
         <p>
         <b>El INE</b> es la única autoridad encargada de la administración del tiempo que corresponde al Estado en radio y televisión para garantizar el ejercicio 
          de las prerrogativas y derechos en esta materia que la Constitución y la ley otorgan a los partidos políticos y candidaturas independientes. 
          También se encarga de la administración del tiempo que corresponde al Estado destinado a los fines propios del Instituto y a 
          los de otras autoridades electorales.
         </p>
         <p>
          Para terminar, los partidos políticos, sus coaliciones, las candidaturas, incluyendo a las y los candidatos independientes, 
          tienen la obligación de informar sobre el origen, monto, destino y aplicación de los recursos que reciben, para lo cual la <b>Fiscalización a partidos políticos y a candidaturas independientes</b> está a cargo del Consejo General del INE por conducto de su 
          Comisión de Fiscalización, que a su vez es apoyada por la Unidad Técnica de Fiscalización, instancia que ejerce las facultades de 
          supervisión, seguimiento y control técnico. 
         </p>
         <p>
         Los detalles de los partidos políticos y las candidaturas independientes los puedes consultar en el{" "}
          <GuiaMaterial setLoading={setLoading}>
            CAPÍTULO 3 DE TU GUÍA TEMÁTICA PARA CAPACITAR A LAS Y LOS OBSERVADORES ELECTORALES 
          </GuiaMaterial>{" "}
         que se encuentra disponible en el material de consulta de este curso.
         </p>
         <p>
          Antes de realizar la actividad respectiva consulta el siguiente material de apoyo para reforzar lo que aprendiste. 
         </p>
         {data.length && <MatApoyo array={[data[8]]} />}
        <p>
          ¡Felicidades, has concluido con esta lección! A continuación, realiza la actividad 
          de autoevaluación para avanzar a la siguiente lección.
        </p>
      </div>
    </>
  );
}
