import moment from "moment";

const dateFormat = "DD/MM/YYYY";

export function getSexo(claveElector) {
  if (claveElector.slice(14, 15) === "M") {
    return "M";
  }
  return "H";
}

export function getFechaNacimiento(claveElector) {
  const anio = claveElector.slice(6, 8);
  const mes = claveElector.slice(8, 10);
  const dia = claveElector.slice(10, 12);
  var fecha;
  // empleando la regla de fechas de microsoft excel, last update: 2020
  if (parseInt(anio) >= 0 && parseInt(anio) <= 29) {
    fecha = `${dia}/${mes}/20${anio}`;
  }
  if (parseInt(anio) >= 30 && parseInt(anio) <= 99) {
    fecha = `${dia}/${mes}/19${anio}`;
  }
  return moment(fecha, dateFormat);
}

export function getEdad(nacimiento) {
  //variable moment format 'DD/MM/YYYY'
  const fechaNacimiento = nacimiento.toDate();
  const hoy = new Date();
  let edad = hoy.getFullYear() - fechaNacimiento.getFullYear();
  const mes = hoy.getMonth() - fechaNacimiento.getMonth();
  if (mes < 0 || (mes === 0 && hoy.getDate() < fechaNacimiento.getDate())) {
    edad--;
  }
  return edad;
}

//Funcion que valida que sea mayor de edad
export const age = (rule, value, callback) => {
  if (value < 17 && value !== "") {
    callback("Edad inválida.");
  } else {
    callback();
  }
};

//Funcion que valida que sea mayor de edad en las observaciones del portal público
export const agePortal = (rule, value, callback) => {
  if (value < 17 && value !== "") {
    callback("Edad inválida, revisa tu Clave de elector.");
  } else {
    callback();
  }
};

export function esJuntaLocal(rol, distrito) {
  if (
    rol.substring(rol.lastIndexOf(".") + 1, rol.lenght) === "JL" &&
    distrito !== 0
  ) {
    return true;
  }
  return false;
  // eslint-disable-next-line react-hooks/exhaustive-deps
}

export function disabledDate(current) {
  return current > moment().endOf("day");
}

export const capitalizeWord = (text) => {
  // if (text) {
     if (text === "CIUDAD DE MÉXICO") {
      return "Ciudad de México";
     }
//Se agrega caso para tratar el nombre del estado de Mexico 

if(text === "MÉXICO"){
return "el Estado de México";
}else{
  return text
    .toLowerCase()
    .trim()
    .split(" ")
    .map((v) => v[0].toUpperCase() + v.substr(1))
    .join(" ");
}


  // }
  // }
};

export function emptyCache() {
  if ("caches" in window) {
    caches.keys().then((names) => {
      // Delete all the cache files
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    //window.location.reload();
  } else {
    console.log("No cache");
  }
}

export function getNumber(num) {
  switch (num) {
    case 4:
      return "cuatro";
    case 5:
      return "cinco";
    case 6:
      return "seis";
    case 7:
      return "siete";
    case 8:
      return "ocho";
    case 9:
      return "nueve";

    default:
      return "";
  }
}

export function validaModuloAbierto(idModulo) {
  let moduloAbierto = true;
  let infoMenu = JSON.parse(localStorage.getItem("INFO_MENU"));
  for (let i = 0; i < infoMenu.listMenu.length; i++) {
    for (let x = 0; x < infoMenu.listMenu[i].subMenus.length; x++) {
      for (let y = 0; y < infoMenu.listMenu[i].subMenus[x].modulos.length; y++) {
        if(infoMenu.listMenu[i].subMenus[x].modulos[y].idModulo === idModulo) {
          moduloAbierto = infoMenu.listMenu[i].subMenus[x].modulos[y].idAccion === 1 ? true : false;
        }
      }
    }
  }
  return moduloAbierto;
}
