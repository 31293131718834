import { Alert, Button, Icon, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom"
import { getEstadoSolicitudes } from "../../../api/status";
import TableInbox from "../../../components/Admin/Status/TableInbox";
import { descargaExcelEstadoSolicitudes } from "../../../components/Admin/Status/xls";
import { validaModuloAbierto } from "../../../components/Generals/functions";
import useAuth from "../../../hooks/useAuth";
import { MOD_ESTADO_SOL } from "../../../utils/constanst";
import { rol_captura_estatus, rol_captura_jl_estatus, rol_cau_estatus } from "../../../utils/Status/rolesEstatus";

function Status(props) {
    const { proceso } = props;
    const { alertConfirmacion } = props.location;
    const {
        user: { rolUsuario }, //Se obtiene el rol del usuario
    } = useAuth();
    const [ estadoSolicitudes, setEstadoSolicitudes] = useState({
        info: [],
        loading: false,
    });
    const [rolModulo, setRolModulo] = useState({}); //Hook para almacenar los permisos
    const isMounted = useRef(true); //Referencia que permite verificar si el componente esta visible
    const [moduloAbierto, setModuloAbierto] = useState(false); //Para validar si el módulo esa abierto o cerrado
    let idProceso, idDetalle, idEstado, idDistrito;
    //Se verica que este seleccionado un proceso electoral
    if(proceso.proceso) {
        idProceso = proceso.proceso.idProcesoElectoral;
        idDetalle = proceso.proceso.idDetalleProceso;
        idEstado = proceso.estado ? proceso.estado.idEstado : 0;
        idDistrito = proceso.distrito ? proceso.distrito.idDistrito : 0;
    }
    //useEffect que valida que el componente este seleccionado de lo contrario cancela cualquier subcripción
    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);
    //useEffect que valida el rol del usuario
    useEffect(() => {
        //Valida los permisos que tienen el usuario
        let captura = false;
        let cau = false;
        let rolJL = false;
        //Devuelve un true si cuenta con los permisos
        captura = rol_captura_estatus.includes(rolUsuario);
        cau = rol_cau_estatus.includes(rolUsuario);
        if(idDistrito !== 0) {
            rolJL = rol_captura_jl_estatus.includes(rolUsuario);
        }
        isMounted.current && setRolModulo(() => ({ captura, cau, rolJL })); //Se almacenan en el hook
    }, [rolUsuario, idDistrito]);

    useEffect(() => {
        if(idProceso) {
            setModuloAbierto(validaModuloAbierto(MOD_ESTADO_SOL));
            setEstadoSolicitudes({ loading:true }); //Mostrar el loading en la tabla
            getEstadoSolicitudes(idProceso, idDetalle, idEstado, idDistrito).then((res) => {
                if(isMounted.current) { //Se verifica que continue en el componente
                    const dataSource = [];
                    res.data.estatusSolicitud.map((req, index) => {
                        return dataSource.push({
                            key: index,
                            indice: index + 1,
                            ...req,
                        });
                    });
                    setEstadoSolicitudes({ info:dataSource, loading:false });
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    }, [idProceso, idDetalle, idEstado, idDistrito]);

    const downloadExcel = () => {
        descargaExcelEstadoSolicitudes("Estado solicitudes", "Reporte del estado general de las solicitudes",
            estadoSolicitudes.info);
    }

    function validaBoton() {
        var band = true;
        if(estadoSolicitudes.info !== undefined && estadoSolicitudes.info !== null) {
            if(estadoSolicitudes.info.length > 0) {
                band = false;
                return band;
            } else
                return band;
        }
        return band;
    }

    return(
        <div>
            <Row>
                <h1>Estado de las solicitudes</h1>
            </Row>
            <Row>
                {alertConfirmacion && <Alert message="Cambio de estado aplicado correctamente" 
                    type="success" showIcon closable/>}
            </Row>
            <Row style={{ marginTop:"10px"}}>
                <div style={{ fontWeight:"bold", fontSize:"14px" }}>
                    Descarga el listado:
                    <Button onClick={() => downloadExcel()} className="btn_descarga_reporte" type="link"
                            disabled={validaBoton()}>
                        <Icon type="file-excel" />Excel
                    </Button>
                </div>
            </Row>
            <Row>
                <h5>Listado de solicitudes</h5>
            </Row>
            <Row>
                <TableInbox data={estadoSolicitudes.info}
                    loading={estadoSolicitudes.loading}
                    rolModulo={rolModulo}
                    proceso={proceso}
                    moduloAbierto={moduloAbierto}
                />
            </Row>
        </div>
    );
}

export default withRouter(Status);
