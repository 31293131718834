//import { Col, Row } from "antd";
import React, { useEffect, useRef } from "react";
import { GuiaMaterial, MatApoyo } from "../Generals";
//import { RenderDocument } from "../Material";
import { capitalizeWord } from "../../../Generals/functions";
import { useSelector } from "react-redux";
import { useLesson3 } from "../../../../hooks/useLesson3";


export default function Lesson3({ data, setLoading }) {
  const isMounted = useRef(true);
  
    //Se obtiene el Entidad seleccionada del store de redux
    const { entidad, idEntidad } = useSelector((state) => state.entidad);

  const getTextEntity = () => {
    return material ? entidadMat : entidad;
  };

  const { 
    material,
    entidadMat: {entidad: entidadMat },
  } = useSelector((state) => state.material);

  if(idEntidad!==undefined){
    localStorage.setItem('idEntidad', idEntidad);
  }

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const GetTextParrafo= (idParrafo) => {
    return (
      <span  dangerouslySetInnerHTML={{__html: useLesson3(parseInt(window.localStorage.getItem('idEntidad')), idParrafo).parrafo}}></span>)
  };

  function listaCargos() {
    let texto=GetTextParrafo(3);
    if(texto!==""){
      return (
      <ol>
        {texto}
      </ol>);
    }else{
      return (
        <ol>
          <li>Preparación de la Elección</li>
          <li>Jornada Electoral </li>
        </ol>);
    }
  };

    function listaCargos2() {
    let texto=GetTextParrafo(19);
    if(texto!==""){
      return (
      <ol>
        <li>{texto}</li> 
        <li>Cómputo de 0 a 20 casillas en el Pleno del Consejo.</li>
        <li>Cómputo en paralelo, más de 20 paquetes (cotejo de actas en el Pleno del Consejo y recuento parcial en grupos de trabajo).</li>
        <li>Cómputo con recuento total al final.</li>
      </ol>);
    }else{
      return (
        <ol>
          <li>Cómputo de 0 a 20 casillas en el Pleno del Consejo.</li>
          <li>Cómputo en paralelo, más de 20 paquetes (cotejo de actas en el Pleno del Consejo y recuento parcial en grupos de trabajo).</li>
          <li>Cómputo con recuento total al final.</li>
        </ol>);
    }
  };

  return (
    <div className="texto_parrafo">
      <p>
        Comencemos por definir que un Proceso Electoral es el conjunto de actos realizados por los órganos y las autoridades electorales, 
        los partidos políticos y la ciudadanía, que tienen por objeto la renovación periódica de quienes integran los 
        poderes Ejecutivo y Legislativo, así como de las y los integrantes de los Ayuntamientos. 
      </p>
      <p> 
        Recuerda que el próximo domingo 5 de junio de 2022, se {GetTextParrafo(1)} en {capitalizeWord(getTextEntity())}, 
        para elegir {GetTextParrafo(2)}.
      </p>
      <p>
        <p>
          Las etapas que integran el Proceso Electoral Local en {capitalizeWord(getTextEntity())} son:
        </p>
        {listaCargos()}
      </p>
      <p>
        En esta lección conocerás con mayor detalle en qué consiste cada etapa del Proceso Electoral 
      </p>
      <p>
        <b>Primera etapa: Preparación de la elección</b>
      </p>
      <p>
        Comienza con la sesión pública que celebra el {GetTextParrafo(4)} en la cual se declara el inicio formal del 
        Proceso Electoral y concluye al iniciarse la Jornada Electoral.
      </p>
      <p>
        <p>En esta etapa se llevan a cabo las siguientes actividades:</p>
        <p>
          <ul type="a" className="alfabetico">
            <li style={{ listStyleType: "none" }}>
              <b>a) Integración de los órganos electorales;</b> en {capitalizeWord(getTextEntity())} se instalarán {GetTextParrafo(5)} que 
              iniciarán sus sesiones a más tardar {GetTextParrafo(6)}. Mientras que el Consejo Local del INE en {capitalizeWord(getTextEntity())} se instalará a más 
              tardar el 30 de septiembre de 2021, en tanto que los {GetTextParrafo(7)} Consejos Distritales del INE en {capitalizeWord(getTextEntity())} se 
              instalarán a más tardar el 30 de noviembre de 2021.
            </li>
            <li style={{ listStyleType: "none" }}>
              <b>b) Actualización del Padrón Electoral;</b> el INE realizará dos campañas de actualización, la permanente 
              y la intensa, para invitar a la ciudadanía a inscribirse o actualizar sus datos en el Padrón Electoral y 
              la Lista Nominal con la finalidad de que obtengan su Credencial para Votar, a través de la reposición, inscripción o reimpresión de esta.
            </li>
            <li style={{ listStyleType: "none" }}>
              <b>c) Revisión de la Lista Nominal de Electores;</b> el INE validará la Lista Nominal que se utilizará el día de la 
              Jornada Electoral determinando el número final de las y los ciudadanos que podrán ejercer su voto. Para lo 
              cual las bases de datos son revisadas y verificadas por los partidos políticos.
            </li>
            <li style={{ listStyleType: "none" }}>
              <b>d) Actividades de los partidos políticos y candidaturas independientes;</b> entre ellas encontramos los procesos internos de selección, 
              las precampañas, el registro de candidaturas y las campañas electorales.
            </li>
            <li style={{ listStyleType: "none" }}>
              <b>e) Procedimiento de integración de las Mesas Directivas de Casilla;</b> el Consejo General del INE al azar selecciona un mes del 
              calendario y una letra del alfabeto que sirven de base para elegir a quienes serán las y los funcionarios de casilla, 
              después las Juntas Distritales Ejecutivas realizan un primer sorteo para obtener un universo de ciudadanas y ciudadanos a 
              quienes las y los Capacitadores-Asistentes Electorales visitan, para invitarlos a participar, confirmar que cumplen con los 
              requisitos establecidos en la ley electoral y capacitarlos; posteriormente las mismas Juntas Distritales llevan a cabo un segundo 
              sorteo con base en el listado de la ciudadanía que externó su aceptación para formar parte de las Mesas Directivas de Casilla y cumplió 
              con los requisitos legales; a las y los ciudadanos que resultaron nuevamente sorteados se les entrega su nombramiento con el cargo que 
              desempeñarán durante la Jornada Electoral y se les capacita para que identifiquen las actividades que realizarán el día de los comicios.
            </li>
            <li style={{ listStyleType: "none" }}>
              <b>f) Ubicación de las casillas;</b> la <b>casilla</b> es el lugar donde la ciudadanía acude a ejercer su derecho al voto. 
              Existen cuatro tipos: la <b>básica</b> que se instala en cada sección electoral; <b>la contigua</b> que se instala cuando el 
              número de personas electoras de una sección es superior a 750; la <b>extraordinaria</b>, la cual se instala cuando las 
              condiciones de vías de comunicación, geográficas o socioculturales de una sección son de difícil acceso y es 
              difícil para toda la ciudadanía acudir a una misma casilla; y la casilla <b>especial</b>, que se instala para que 
              puedan votar las y los electores que se encuentran en tránsito. Las casillas deben instalarse en lugares de fácil y libre acceso.
            </li>
            <li style={{ listStyleType: "none" }}>
              <b>g) Registro de representantes ante Mesas Directivas de Casilla;</b> las y los representantes ante la casilla son ciudadanas y 
              ciudadanos registrados por un Partido Político o por una Candidatura Independiente en las Mesas Directivas de Casilla 
              para vigilar, proteger, otorgar certeza y legalidad a las actividades que realizan las y los funcionarios de la Mesa 
              Directiva en la Casilla, cuidando los intereses del partido o candidatura que representan.
            </li>
            <li style={{ listStyleType: "none" }}>
              <b>h) Preparación de la documentación y material electoral;</b> la documentación electoral se refiere a todos los documentos 
              que se utilizan el día de la Jornada Electoral, por ejemplo: actas, boletas y cuadernillos de Listas Nominales; 
              mientras que el material electoral lo constituyen todos los elementos físicos que se requieren en las casillas, 
              como son las urnas y canceles. {GetTextParrafo(8)}.
            </li>
          </ul>
        </p>
      </p>
      <p>
        Hemos terminado lo relativo a la etapa de preparación del Proceso Electoral, si quieres profundizar sobre estas actividades, 
        te invitamos a revisar el {" "}
          <GuiaMaterial setLoading={setLoading}>
            CAPÍTULO 4 DE TU GUÍA TEMÁTICA PARA CAPACITAR A LAS Y LOS OBSERVADORES ELECTORALES 
          </GuiaMaterial>{" "}, que se encuentra disponible en los materiales de consulta de este curso.
      </p>
      <p>
        <b>Segunda etapa: La Jornada Electoral</b>
      </p>
      <p>
        <b>La Jornada Electoral</b> es el día en que las y los ciudadanos votamos para elegir a nuestros representantes y gobernantes. 
        En esta ocasión, el domingo 5 de junio se instalarán las <b>casillas</b> y se integrarán las Mesas Directivas para recibir, 
        contar y registrar los votos de {GetTextParrafo(9)}.
      </p>
      <p>
        Las Mesas Directivas de Casilla se integran con <b>7 funcionarios y/o funcionarias</b>, revisa el siguiente material de apoyo 
        para conocer los cargos de quienes conforman la casilla.
      </p>
      {data.length && <MatApoyo array={[data[0]]} />}
      <p>
        <p>Durante la Jornada Electoral las y los ciudadanos participarán como:</p>
        <p>
          <ul>
            <li>Personas electoras.</li>
            <li>Funcionarias y funcionarios de casilla.</li>
            <li>Representantes de Partido Político y de Candidatura Independiente.</li>
            <li>Observadores y observadoras electorales. </li>
          </ul>
        </p>
      </p>
      <p>
        <p>Además, dentro de la casilla también podrás encontrar a:</p>
        <p>
          <ul>
            <li>Capacitadores y Capacitadoras-Asistentes electorales del INE y, en su caso, del OPL.</li> 
            <li>Supervisores o Supervisoras Electorales.</li>
            <li>Personal del INE y del OPL.</li>
            <li>Acompañantes de personas con discapacidad que funjan como funcionarias de casilla o que acudan como electoras.</li>
          </ul>
        </p>
      </p>
      <p>
        En caso necesario, para resguardar el orden la o el Presidente de la casilla puede pedir el apoyo de fuerzas de seguridad pública. 
        Además, puede permitir el acceso de jueces/zas, Notarios/as Públicos/as y/o de personal de la Oficialía Electoral.
      </p>
      <p>
        Afuera de la casilla podrán estar personas encuestadoras que preguntan a las y los electores que salen, por quién votaron. 
        Estas personas siempre deben estar debidamente <b>identificadas</b> y tienen prohibido hacer actos de proselitismo o de presión sobre la ciudadanía.
      </p>
      <p>
        <b>Para conocer las actividades que se realizan durante la Jornada Electoral te invitamos a ver el siguiente video.</b>
      </p>
      {data.length && <MatApoyo array={[data[1]]} />}
      <p>
        Ahora que ya sabes lo que sucede durante la Jornada Electoral, es momento de pasar a la siguiente etapa:
      </p>
      <p>
        <b>Tercera etapa: {GetTextParrafo(10)}.</b>
      </p>
      <p>
        Inicia con la remisión de los paquetes electorales, documentación y expedientes electorales a {GetTextParrafo(11)} y 
        concluye con {GetTextParrafo(12)}.
      </p>
      <p>
        Las actividades que las autoridades electorales realizan en esta etapa están orientadas a dar a conocer con certeza, 
        transparencia y con la mayor rapidez posible los resultados obtenidos en {GetTextParrafo(13)}, por lo que se llevan a cabo las acciones siguientes:
      </p>
      <p>
        <ul>
          <li style={{ listStyleType: "none" }}>
            <b>a) Conteo rápido</b>: Procedimiento estadístico diseñado para estimar con oportunidad las tendencias de los resultados finales de una elección, 
            a partir de una muestra probabilística de los resultados de actas de escrutinio y cómputo de las casillas electorales, 
            cuyo tamaño y composición se establecen previamente. Este procedimiento solo se realiza en la elección de {GetTextParrafo(14)} conforme 
            al Reglamento de Elecciones. Las conclusiones se presentan la noche de la Jornada Electoral. Los resultados del 
            Conteo rápido son una tendencia, por lo cual, bajo ningún motivo pueden considerarse como una constancia de triunfo.
          </li>
          <li style={{ listStyleType: "none" }}>
            <b>b) Programa de Resultados Electorales Preliminares (PREP).</b> Es el 
            mecanismo para informar oportunamente los resultados preliminares y <b>no definitivos</b> de {GetTextParrafo(15)}; a través 
            de la captura, digitalización y publicación de los datos asentados en las actas de escrutinio y 
            cómputo de las casillas, de las cuales generalmente la primera copia se destina al PREP y se le conoce como Acta PREP.
          </li>
          <li style={{ listStyleType: "none" }}>
            <b>c) Información preliminar de resultados.</b> Es 
              el procedimiento mediante el cual los {GetTextParrafo(16)} en 
              sesión permanente, consultan los resultados contenidos en el Acta de Escrutinio y Cómputo 
              conforme se vayan recibiendo los paquetes electorales.
          </li>
          <br/>
          <p>
            Las reglas y procedimientos de cada una de estas actividades las puedes consultar en el{" "}
            <GuiaMaterial setLoading={setLoading}>
              CAPÍTULO 4 DE TU GUÍA TEMÁTICA PARA CAPACITAR A LAS Y LOS OBSERVADORES ELECTORALES 
            </GuiaMaterial>{""}, que se encuentra disponible en los materiales de consulta de este curso.
          </p>
          {parseInt(window.localStorage.getItem('idEntidad'))!==13 && (
            <li style={{ listStyleType: "none" }}>
              <b>d) Cómputos Electorales de la elección</b>. Consisten en sumar los resultados anotados en cada Acta de Escrutinio y Cómputo 
              de las casillas y, en su caso, en el recuento de votos de algunas casillas o de todas cuando existan los supuestos 
              que la ley contempla para ello.
            </li>
            )}
        </ul>
      </p>
      {parseInt(window.localStorage.getItem('idEntidad'))!==13 && (
            <div>
      <p>
        Los órganos facultados para llevar a cabo los cómputos son:
      </p>
      <p>
        {GetTextParrafo(17)}
      </p>
      <p>
        <p>Para realizar los cómputos se siguen los pasos que a continuación se mencionan:</p>
        <p>
          <ol>
            <li>Actos previos.</li> 
            <li>Reunión de trabajo.</li>
            <li>Sesión extraordinaria en el órgano facultado.</li>
            <li>Sesión {GetTextParrafo(18)} de cómputo.</li>
          </ol>
        </p>
      </p>
      <p>
        <p>Existen dos tipos de recuento, el <b>total</b> y el <b>parcial</b>, tomando en cuenta los tipos de recuento de la votación, 
        para llevar a cabo el cómputo se pueden presentar los siguientes escenarios:</p>
        <p>
          {listaCargos2()}
        </p>
      </p>
      <p>
        Los pasos para realizar los cómputos de la elección los puedes consultar a detalle en el {" "}
        <GuiaMaterial setLoading={setLoading}>
          CAPÍTULO 4 DE TU GUÍA TEMÁTICA PARA CAPACITAR A LAS Y LOS OBSERVADORES ELECTORALES 
        </GuiaMaterial>{""}, que esta disponible en los materiales de consulta de este curso. 
      </p>
      {GetTextParrafo(20)}
      {GetTextParrafo(21)}
      {GetTextParrafo(22)}
      {GetTextParrafo(23)}
      <br/>
      <p></p>
      <p>
        ¿Sabes qué son los medios de impugnación?
      </p>
      <p>
        Son el conjunto de <b>recursos y juicios</b> que tienen derecho a interponer las y los ciudadanos, los partidos políticos 
        y las candidaturas independientes en contra de los actos y resoluciones de las autoridades electorales, cuando 
        estos actos, a criterio de quienes se inconforman, presentan deficiencias o errores.
      </p>
      <p>
        Estos sirven para <b>modificar, revocar</b> o <b>anular</b> los actos y las resoluciones en materia electoral que 
        no se apeguen a las normas jurídicas.
      </p>
      <p>
        Los medios locales de impugnación que tienen por objeto resolver las controversias que surjan respecto de la 
        elección de {GetTextParrafo(24)}, se encuentran regulados en {GetTextParrafo(25)} y se resuelven ante el {GetTextParrafo(26)}.
      </p>
      <p>
        <p>
          Por otra parte, la <b>nulidad</b> es la sanción derivada de la violación a la legislación en materia electoral, 
          teniendo distintas consecuencias jurídicas por lo que, atendiendo a estos efectos, <b>la nulidad</b> puede darse en dos niveles:
        </p>
        <p>
          <ol>
            <li><b>Nulidad de la votación recibida en la casilla:</b> La votación de la casilla anulada no es tomada en cuenta 
              para el cómputo final de los votos, las causas tienen que ver con el incumplimiento de los procedimientos 
              establecidos en la ley respecto de la instalación de la casilla, recepción de la votación, cómputo de los 
              votos y el traslado de los paquetes electorales.
            </li>
            <li><b>Nulidad de una elección:</b> Implica que la elección pierde validez y tendrá que reponerse con la celebración 
              de una elección extraordinaria, debido a irregularidades que se han presentado durante cualquiera de las 
              etapas del proceso electoral y que constituyen violaciones a alguno de los principios constitucionales.
            </li>
          </ol>
        </p>
      </p>
      <p>
        Para profundizar en los temas de las causales de nulidad de la elección y las causales de nulidad de la votación 
        recibida en las casillas consulta el {" "}
        <GuiaMaterial setLoading={setLoading}>
          CAPÍTULO 6 DE TU GUÍA TEMÁTICA PARA CAPACITAR A LAS Y LOS OBSERVADORES ELECTORALES 
        </GuiaMaterial>{" "} que se encuentra disponible en los materiales de consulta de este curso.
      </p>
      <p>
        A continuación, revisa el siguiente material de apoyo para reforzar lo que aprendiste en esta lección.
      </p>
      {data.length && <MatApoyo array={[data[2]]} />}
      <br />
      <p>
        ¡Excelente! Has concluido esta lección, te recordamos responder la actividad autoevaluable 
        correspondiente para continuar con la próxima lección.
      </p>
            </div>
      )}
      {parseInt(window.localStorage.getItem('idEntidad'))===13 && (
        <div>
          {GetTextParrafo(20)}
          <p><b>Los cómputos electorales</b> Consisten en sumar los resultados anotados en cada Acta de Escrutinio y Cómputo de las casillas y, en su caso, en el recuento de votos de algunas casillas o de todas cuando existan los supuestos que la ley contempla para ello.</p>
          <p>
            Los órganos facultados para llevar a cabo los cómputos son:
          </p>
          <p>
            {GetTextParrafo(17)}
          </p>
          <p>Para realizar los cómputos se siguen los pasos que a continuación se mencionan:</p>
          <p>
            <ol>
              <li>Actos previos.</li> 
              <li>Reunión de trabajo.</li>
              <li>Sesión extraordinaria en el órgano facultado.</li>
              <li>Sesión {GetTextParrafo(18)} de cómputo.</li>
            </ol>
          </p>
          <p>
        <p>Existen dos tipos de recuento, el <b>total</b> y el <b>parcial</b>, tomando en cuenta los tipos de recuento de la votación, 
          para llevar a cabo el cómputo se pueden presentar los siguientes escenarios:</p>
          <p>
            {listaCargos2()}
          </p>
        </p>
        <p>
          Los pasos para realizar los cómputos de la elección los puedes consultar a detalle en el {" "}
          <GuiaMaterial setLoading={setLoading}>
            CAPÍTULO 4 DE TU GUÍA TEMÁTICA PARA CAPACITAR A LAS Y LOS OBSERVADORES ELECTORALES 
          </GuiaMaterial>{""}, que esta disponible en los materiales de consulta de este curso. 
        </p>
        {GetTextParrafo(22)}
        {GetTextParrafo(21)}
        {GetTextParrafo(23)}
        <p>
          ¿Sabes qué son los medios de impugnación?
        </p>
        <p>
          Son el conjunto de <b>recursos y juicios</b> que tienen derecho a interponer las y los ciudadanos, los partidos políticos 
          y las candidaturas independientes en contra de los actos y resoluciones de las autoridades electorales, cuando 
          estos actos, a criterio de quienes se inconforman, presentan deficiencias o errores.
        </p>
        <p>
          Estos sirven para <b>modificar, revocar</b> o <b>anular</b> los actos y las resoluciones en materia electoral que 
          no se apeguen a las normas jurídicas.
        </p>
        <p>
          Los medios locales de impugnación que tienen por objeto resolver las controversias que surjan respecto de la 
          elección de {GetTextParrafo(24)}, se encuentran regulados en {GetTextParrafo(25)} y se resuelven ante el {GetTextParrafo(26)}.
        </p>
        <p>
        <p>
          Por otra parte, la <b>nulidad</b> es la sanción derivada de la violación a la legislación en materia electoral, 
          teniendo distintas consecuencias jurídicas por lo que, atendiendo a estos efectos, <b>la nulidad</b> puede darse en dos niveles:
        </p>
        <p>
          <ol>
            <li><b>Nulidad de la votación recibida en la casilla:</b> La votación de la casilla anulada no es tomada en cuenta 
              para el cómputo final de los votos, las causas tienen que ver con el incumplimiento de los procedimientos 
              establecidos en la ley respecto de la instalación de la casilla, recepción de la votación, cómputo de los 
              votos y el traslado de los paquetes electorales.
            </li>
            <li><b>Nulidad de una elección:</b> Implica que la elección pierde validez y tendrá que reponerse con la celebración 
              de una elección extraordinaria, debido a irregularidades que se han presentado durante cualquiera de las 
              etapas del proceso electoral y que constituyen violaciones a alguno de los principios constitucionales.
            </li>
          </ol>
        </p>
      </p>
      <p>
        Para profundizar en los temas de las causales de nulidad de la elección y las causales de nulidad de la votación 
        recibida en las casillas consulta el {" "}
        <GuiaMaterial setLoading={setLoading}>
          CAPÍTULO 6 DE TU GUÍA TEMÁTICA PARA CAPACITAR A LAS Y LOS OBSERVADORES ELECTORALES 
        </GuiaMaterial>{" "} que se encuentra disponible en los materiales de consulta de este curso.
      </p>
      <p>
        A continuación, revisa el siguiente material de apoyo para reforzar lo que aprendiste en esta lección.
      </p>
      {data.length && <MatApoyo array={[data[2]]} />}
      <br />
      <p>
        ¡Excelente! Has concluido esta lección, te recordamos responder la actividad autoevaluable 
        correspondiente para continuar con la próxima lección.
      </p>
        </div>
      )}
      
    </div>
  );
}
