import React, { useState, useEffect } from "react";
import { Input, Icon, Tooltip, Table, Modal, DatePicker, Form, Row, Col, Button, message } from "antd";
import { withRouter } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import moment from 'moment';
import { required, inputs } from "../../../utils/Accreditation/rules";
import { rol_cau } from "../../../utils/Accreditation/roles";
import { Ast, update, deleteObs } from "../../../utils/Accreditation/functions";
import "./Accreditation.scss";

function AcredTable(props) {
  const { user: { user, rolUsuario } } = useAuth();
  const { getFieldDecorator } = props.form; //Método para validar los inputs
  const { data, rolModulo: { modifica, borrar }, loading, setReload, moduloAbierto, fechaLimite } = props;
  const dateFormat = 'DD/MM/YYYY';
  const [value, setValue] = useState(""); //Hook para el filtrado de nombre
  const [valClave, setValClave] = useState(""); //Hook para el filtrado de clave
  const [valFolio, setValFolio] = useState(""); //Hook para el filtrado de folio
  const [valSesion, setValSesion] = useState(""); //Hook para el filtrado de número de sesión
  const [valAcuerdo, setValAcuerdo] = useState(""); //Hook para el filtrado de número de acuerdo
  const [dataSource, setDataSource] = useState([]); //hook para almacenar el array
  const [visibleModal, setVisibleModal] = useState(false);
  const [solicitud, setSolicitud] = useState();
  const [datos, setDatos] = useState({});

  useEffect(() => {
    setDataSource(data);
  }, [data]);

  useEffect(() => {
    if (!visibleModal) {
      setSolicitud();
    }
  }, [visibleModal]);

  useEffect(() => {
    if (solicitud !== undefined) {
      props.form.setFieldsValue({
        numSesion: solicitud.numeroSesion,
        numAcuerdo: solicitud.numeroAcuerdo,
        fechaSesion: moment(solicitud.fechaSesion, dateFormat),
      });
      setDatos({ nombre: solicitud.nombreSolicitante, clave: solicitud.claveElector, folio: solicitud.folio });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [solicitud]);

  function modificarSolicitud() {
    props.form.validateFields((err, values) => {
        if (!err) {
          if (!rol_cau.includes(rolUsuario)) {
            solicitud.numeroSesion = values.numSesion;
            solicitud.numeroAcuerdo = values.numAcuerdo;
            solicitud.fechaSesion = values.fechaSesion.format(dateFormat);
            solicitud.usuario = user.sub;
            update( solicitud, setReload, setVisibleModal );
          }
        } else {
            message.info("Los datos con ( * ) son requeridos.");
        }
    });
  } 

  function disabledDate(current) {
    return current > moment(fechaLimite, dateFormat).endOf('day');
  }

  function modalModifica() {
    return (
    <div>
        <Modal
        visible={visibleModal}
        footer={null}
        width={500}
        mask={false}
        onCancel={() => setVisibleModal(false)}
        >
        <div>
            <Row>
              <Col span={24}>
                  <strong>{datos.nombre}</strong><br />
                  <strong>{datos.clave}</strong><br />
                  Folio: <strong>{datos.folio}</strong><br/><br/>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Ast /> Número de sesión
                <Tooltip title="Formato 00/ORD/MM-AAAA">
                <Form.Item>
                    {getFieldDecorator("numSesion", { rules: inputs })(
                        <Input maxLength={20}  />
                    )}
                </Form.Item>
                </Tooltip>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Ast /> Número de acuerdo
                <Form.Item>
                    {getFieldDecorator("numAcuerdo", { rules: inputs })(
                        <Input maxLength={30}  />
                    )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Ast /> Fecha de la sesión
                <Form.Item>
                  {getFieldDecorator("fechaSesion", { rules: required })(
                    <DatePicker format={dateFormat} disabledDate={disabledDate} />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
            <Col span={12} style={{ paddingTop: "20px", paddingRight: "20px" }}>
                <Form.Item style={{ textAlign: "right" }}>
                <Button type="default" onClick={() => setVisibleModal(false)}>
                    Cancelar
                </Button>
                </Form.Item>
            </Col>
            <Col span={12} style={{ paddingTop: "20px", paddingRight: "20px" }}>
                <Form.Item style={{ textAlign: "left" }}>
                <Button
                    type="primary"
                    htmlType="submit"
                    onClick={() => modificarSolicitud()}
                >
                    Guardar
                </Button>
                </Form.Item>
            </Col>
            </Row>
        </div>
        </Modal>
    </div>
    );
  };

  function modalElimina(record) {
    const { confirm } = Modal;
    var message = <div>
      <p>
        Estás a punto de <strong>desacreditar</strong> la siguiente solicitud. El estado de la solicitud
                    se establecerá como "Pendiente".<br /><br />
        <strong>{record.nombreSolicitante}</strong><br />
        <strong>{record.claveElector}</strong><br />
                    Folio: <strong>{record.folio}</strong><br /><br />
                    ¿Deseas continuar?
      </p>
    </div>
    confirm({
      title: message,
      width: "500px",
      icon: null,
      okText: "Desacreditar solicitud",
      cancelText: "Cancelar",
      cancelButtonProps: { className: "ant-btn-default" },
      okButtonProps: { style: { width: 200 } },
      className: "modalDesacredita",
      onOk() {
        if (!rol_cau.includes(rolUsuario)) {
          deleteObs( record.idObservador, record.correo, record.folio, user.sub, setReload );
        }
      },
      onCancel() { },
    });
  }


  //Función que filtra los registros por el nombre
  const FilterByNameInput = (
    <>
      Nombre del solicitante
      <Input
        placeholder="Nombre del solicitante"
        value={value}
        onChange={(e) => {
          const currValue = e.target.value;
          setValue(currValue);
          const filteredData = data.filter((entry) =>
            entry.nombreSolicitante
              .toString()
              .toLowerCase()
              .includes(currValue.toLowerCase())
          );
          setDataSource(filteredData);
        }}
      />
    </>
  );
  //Función que filtra los registros por clave de elector
  const FilterByClaveInput = (
    <>
      Clave de Elector
      <Input
        placeholder="Clave de Elector"
        valClave={valClave}
        onChange={(e) => {
          const currValue = e.target.value;
          setValClave(currValue);
          const filteredData = data.filter((entry) =>
            entry.claveElector
              .toString()
              .toLowerCase()
              .includes(currValue.toLowerCase())
          );
          setDataSource(filteredData);
        }}
      />
    </>
  );
  //Función que filtra los registros por folio
  const FilterByFolioInput = (
    <>
      Folio
      <Input
        placeholder="Folio"
        valFolio={valFolio}
        onChange={(e) => {
          const currValue = e.target.value;
          setValFolio(currValue);
          const filteredData = data.filter((entry) =>
            entry.folio
              .toString()
              .toLowerCase()
              .includes(currValue.toLowerCase())
          );
          setDataSource(filteredData);
        }}
      />
    </>
  );
  //Función que filtra los registros por número de sesión
  const FilterBySesionInput = (
    <>
      Número de sesión
      <Input
        placeholder="Número de sesión"
        valSesion={valSesion}
        onChange={(e) => {
          const currValue = e.target.value;
          setValSesion(currValue);
          const filteredData = data.filter((entry) =>
            entry.numeroSesion
              .toString()
              .toLowerCase()
              .includes(currValue.toLowerCase())
          );
          setDataSource(filteredData);
        }}
      />
    </>
  );
  //Función que filtra los registros por número de acuerdo
  const FilterByAcuerdoInput = (
    <>
      Número de acuerdo
      <Input
        placeholder="Número de acuerdo"
        valAcuerdo={valAcuerdo}
        onChange={(e) => {
          const currValue = e.target.value;
          setValAcuerdo(currValue);
          const filteredData = data.filter((entry) =>
            entry.numeroAcuerdo
              .toString()
              .toLowerCase()
              .includes(currValue.toLowerCase())
          );
          setDataSource(filteredData);
        }}
      />
    </>
  );

  //Se definen las columnas de la tabla de Solicitudes acreditadas
  const columns = [
    {
      title: "#",
      dataIndex: "indice",
      key: "indice",
      width: 60,
      align: "center",
    },
    {
      title: FilterByNameInput,
      dataIndex: "nombreSolicitante",
      key: "nombreSolicitante",
    },
    {
      title: FilterByClaveInput,
      dataIndex: "claveElector",
      key: "claveElector",
    },
    {
      title: FilterByFolioInput,
      dataIndex: "folio",
      key: "folio",
    },
    {
      title: "Fecha en que completó el curso",
      dataIndex: "fechaCurso",
      key: "fechaCurso",
    },
    {
      title: "Fecha de aprobación de la acreditación",
      dataIndex: "fechaAcreditacion",
      key: "fechaAcreditacion",
    },
    {
      title: FilterBySesionInput,
      dataIndex: "numeroSesion",
      key: "numeroSesion",
    },
    {
      title: "Fecha de la sesión",
      dataIndex: "fechaSesion",
      key: "fechaSesion",
    },
    {
      title: FilterByAcuerdoInput,
      dataIndex: "numeroAcuerdo",
      key: "numeroAcuerdo",
    },
    {
      title: "Correo electrónico",
      dataIndex: "correo",
      key: "correo",
    },
    {
      title: "Teléfono",
      dataIndex: "telefono",
      key: "telefono",
    },
    {
      title: "Tipo de solicitud",
      dataIndex: "tipoSolicitud",
      key: "tipoSolicitud",
    },
    {
      title: "Origen de la solicitud",
      dataIndex: "origenSolicitud",
      key: "origenSolicitud",
    },
    {
      title: "Procedencia de la solicitud",
      dataIndex: "procedencia",
      key: "procedencia",
    },
    {
      title: "Ratificación",
      dataIndex: "ratificado",
      key: "ratificado",
    },
    {
      title: "Acciones",
      fixed: "right",
      align: "center",
      render: (record) => (
        <>
          {modifica && (
            <Tooltip title="Modificar acreditación">
              <button
                style={{ marginLeft: "0px" }}
                className="btn-circle btn_secundario"
                onClick={() => {setVisibleModal(true); setSolicitud(record);}}
                disabled={!moduloAbierto}
              >
                <Icon type="edit" theme="filled" />
              </button>
            </Tooltip>
          )}{" "} 
          {borrar && (
            <Tooltip title="Desacreditar">
              <button
                style={{ marginLeft: "0px", width: "125px" }}
                //className="ant-btn-default"
                onClick={(e) => modalElimina(record)}
                disabled={!moduloAbierto}
              > Desacreditar
              </button>
            </Tooltip>
          )}
          {modalModifica()} 
        </>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      locale={{ emptyText: "Sin registros" }}
      scroll={{ x: true }}
    />
  );
}
const TableRequests = Form.create()(AcredTable);
export default withRouter(TableRequests);
