import React, { useState, useEffect } from "react";
import "./Proceso_Menu.scss";
import { Popover, Select, Icon } from "antd";
import useAuth from "../../hooks/useAuth";
import axios from "axios";
import { basePath } from "../../api/config"; //cambiaInfoMenu

const { Option } = Select;

export default function Header(props) {
  const url = `${basePath}/cambiaInfoMenu`;
  const { history, setMenuSelect } = props;
  const {
    user: {
      idSistema,
      idEstado,
      idDistrito,
      idMunicipio,
      ambito,
      rolUsuario,
      versionUsuario,
    },
  } = useAuth();

  let { infoMenu, setProceso, proceso } = props;
  let infoMenuGeografico = {
    infoMenu,
    idSistema,
    idEstado,
    idDistrito,
    idMunicipio,
    ambito,
    rolUsuario,
    versionUsuario,
  };
  const [infoMenuHook, setInfoMenuHook] = useState();
  const [tipoGeografico, setTipoGeografico] = useState();

  useEffect(() => {
    //if (infoMenu && versionUsuario !== "OC") {
    if (infoMenu) {
      //Se almacena la información cuando el procesos y la entidad ya vienen por defecto
      setProceso({
        proceso: infoMenu.detalleSelec,
        estado: infoMenu.estadoSelec,
        distrito: infoMenu.distritoFedSelec,
      });
      if (
        infoMenu.estadoSelec !== undefined && infoMenu.estadoSelec !== null &&
        infoMenu.estadoSelec.idEstado !== 0 
      ) {
        // PARA JUNTA LOCAL POR DEFAULT
        setProceso({
          proceso: infoMenu.detalleSelec,
          estado: infoMenu.estadoSelec,
          distrito: infoMenu.distritoFedSelec ? infoMenu.distritoFedSelec : infoMenu.listaDistritosFed[0],
        });
      }
      props.funcion(infoMenu.listMenu); //Se muestra el menú
      setTipoGeografico(obtieneTipoLista());
    }
    setInfoMenuHook(infoMenu);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoMenu]);

  const seleccionaDetalle = async (detalleSelec) => {
    infoMenu = {
      ...infoMenuHook,
    };
    infoMenu.detalleSelec = detalleSelec.key ? null : detalleSelec;
    limpiaNivelOC();
    infoMenuGeografico.infoMenu = infoMenu;
    if (!detalleSelec.key) {
      infoMenuGeografico.tipoCambioGeografico = 1;
      const response = await axios.post(url, infoMenuGeografico);
      infoMenu = response.data.infoMenu;
    }
    localStorage.setItem("INFO_MENU", JSON.stringify(infoMenu));
    setInfoMenuHook(infoMenu);
    props.funcion(infoMenu.listMenu);
    //Se obtiene el proceso electoral
    setProceso({
      proceso: infoMenu.detalleSelec,
      estado: undefined,
      distrito: undefined,
    }); //
    //Quita la selección del menú
    setMenuSelect([]);
    //Redirección al home
    //history.push("/admin/home");
    history.location.pathname!=="/admin/home"&&history.push("/admin/home");
  };

  const seleccionaEstado = async (estadoSelec) => {
    infoMenu = {
      ...infoMenuHook,
    };
    infoMenu.estadoSelec = estadoSelec.key ? null : estadoSelec;

    limpiaNivelJL();

    infoMenuGeografico.infoMenu = infoMenu;
    if (!estadoSelec.key) {
      infoMenuGeografico.tipoCambioGeografico = 2;
      const response = await axios.post(url, infoMenuGeografico);
      infoMenu = response.data.infoMenu;
      setTipoGeografico(obtieneTipoLista());
      props.funcion(infoMenu.listMenu);
      //console.log(infoMenu);
      setInfoMenuHook(infoMenu);
      if (infoMenu.estadoSelec.idEstado !== 0) {
        setProceso({
          ...proceso,
          estado: infoMenu.estadoSelec,
          distrito: infoMenu.listaDistritosFed[0],
        }); // PARA JUNTA LOCAL POR DEFAULT
      } else {
        setProceso({
          ...proceso,
          estado: infoMenu.estadoSelec,
          distrito: undefined,
        });
      }
    } else {
      seleccionaDetalle(infoMenu.detalleSelec);
    }
    localStorage.setItem("INFO_MENU", JSON.stringify(infoMenu));
    //Quita la selección del menú
    setMenuSelect([]);
    //Redirección al home
    //history.push("/admin/home");
    history.location.pathname!=="/admin/home"&&history.push("/admin/home");
  };

  const seleccionaDistMun = async (distMun) => {
    infoMenu = {
      ...infoMenuHook,
    };
    infoMenu[tipoGeografico.nombreSelect] = distMun.key ? null : distMun;

    limpiaNivelJD();

    infoMenuGeografico.infoMenu = infoMenu;
    if (!distMun.key) {
      infoMenuGeografico.tipoCambioGeografico = 3;
      const response = await axios.post(url, infoMenuGeografico);
      infoMenu = response.data.infoMenu;
      props.funcion(infoMenu.listMenu);
      setInfoMenuHook(infoMenu);
      setProceso({ ...proceso, distrito: infoMenu.distritoFedSelec }); //Se obtiene el distrito
    } else {
      seleccionaEstado(infoMenu.estadoSelec);
    }
    localStorage.setItem("INFO_MENU", JSON.stringify(infoMenu));
    //Quita la selección del menú
    setMenuSelect([]);
    //Redirección al home
    //history.push("/admin/home");
    history.location.pathname!=="/admin/home"&&history.push("/admin/home");
  };

  const limpiaNivelOC = () => {
    infoMenu = {
      ...infoMenu,
      estadoSelec: null,
      listaEstados: null,
      distritoFedSelec: null,
      listaDistritosFed: null,
      distritoLocSelec: null,
      listaDistritosLoc: null,
      municipioSelec: null,
      listaMunicipios: null,
      listMenu: null,
    };
  };

  const limpiaNivelJL = () => {
    infoMenu = {
      ...infoMenu,
      distritoFedSelec: null,
      listaDistritosFed: null,
      distritoLocSelec: null,
      listaDistritosLoc: null,
      municipioSelec: null,
      listaMunicipios: null,
      listMenu: null,
    };
  };

  const limpiaNivelJD = () => {
    infoMenu = {
      ...infoMenu,
      listMenu: null,
    };
  };

  const obtieneTipoLista = () => {
    let tipoGeograficoAux;
    if (infoMenu) {
      if (infoMenu.listaDistritosFed) {
        tipoGeograficoAux = {
          nombreLista: "listaDistritosFed",
          nombreID: "idDistrito",
          nombreGeografia: "nombreDistrito",
          nombreSelect: "distritoFedSelec",
        };
      } else if (infoMenu.listaDistritosLoc) {
        tipoGeograficoAux = {
          nombreLista: "listaDistritosLoc",
          nombreID: "idDistrito",
          nombreGeografia: "nombreDistrito",
          nombreSelect: "distritoLocSelec",
        };
      } else if (infoMenu.listaMunicipios) {
        tipoGeograficoAux = {
          nombreLista: "listaMunicipios",
          nombreID: "idMunicipio",
          nombreGeografia: "nombreMunicipio",
          nombreSelect: "municipioSelec",
        };
      } else {
        tipoGeograficoAux = null;
      }
    }

    return tipoGeograficoAux;
  };

  const getNombreDistMun = () => {
    return `${
      infoMenuHook[tipoGeografico.nombreSelect][tipoGeografico.nombreID]
    } - 
    ${
      infoMenuHook[tipoGeografico.nombreSelect][tipoGeografico.nombreGeografia]
    }`;
  };

  const getNombreDistMunPop = () => {
    return ` - ${
      infoMenuHook[tipoGeografico.nombreSelect][tipoGeografico.nombreID]
    } 
    ${
      infoMenuHook[tipoGeografico.nombreSelect][tipoGeografico.nombreGeografia]
    }`;
  };

  function Menu_Usuario() {
    return (
      <div>
        <div className="etiqueta">Proceso Electoral</div>
        <Select
          defaultValue={
            infoMenuHook && infoMenuHook.detalleSelec
              ? infoMenuHook.detalleSelec.descripcion
              : "Selecciona"
          }
          style={{ width: 280 }}
          disabled={!(versionUsuario === "OC" || versionUsuario === "JSP")}
        >
          <Option value="-1" onClick={seleccionaDetalle}>
            {" "}
            Selecciona{" "}
          </Option>
          {infoMenuHook &&
            infoMenuHook.listaDetalles &&
            infoMenuHook.listaDetalles.map((detalle, i) => (
              <Option onClick={seleccionaDetalle.bind(this, detalle)} key={i}>
                {detalle.descripcion}
              </Option>
            ))}
        </Select>
        <p> </p>

        <div className="etiqueta">Entidad</div>
        <Select
          defaultValue={
            infoMenuHook && infoMenuHook.estadoSelec
              ? infoMenuHook.estadoSelec.nombreEstado
              : "Selecciona"
          }
          style={{ width: 280 }}
          disabled={
            !(
              infoMenuHook &&
              infoMenuHook.listaEstados &&
              (versionUsuario === "OC" || versionUsuario === "JSP")
            )
          }
        >
          <Option value="-1" onClick={seleccionaEstado}>
            {" "}
            Selecciona{" "}
          </Option>
          {infoMenuHook &&
            infoMenuHook.listaEstados &&
            infoMenuHook.listaEstados.map((estado) => (
              <Option
                onClick={seleccionaEstado.bind(this, estado)}
                key={estado.idEstado}
              >
                {estado.nombreEstado}
              </Option>
            ))}
        </Select>
        <p> </p>

        <div className="etiqueta">Distrito</div>
        <Select
          defaultValue={
            tipoGeografico && infoMenuHook[tipoGeografico.nombreSelect]
              ? getNombreDistMun()
              : proceso.estado !== undefined && proceso.estado !== null && proceso.estado.idEstado !== 0
              ? "0 - JUNTA LOCAL"
              : "Selecciona" // PARA JUNTA LOCAL POR DEFAULT
          }
          style={{ width: 280 }}
          disabled={
            !(
              tipoGeografico &&
              infoMenuHook[tipoGeografico.nombreLista] &&
              (versionUsuario === "OC" || versionUsuario === "JL" || versionUsuario === "JSP")
            )
          }
        >
          <Option value="-1" onClick={seleccionaDistMun}>
            {" "}
            Selecciona{" "}
          </Option>
          {tipoGeografico &&
            infoMenuHook[tipoGeografico.nombreLista] &&
            infoMenuHook[tipoGeografico.nombreLista].map((distMun) => (
              <Option
                onClick={seleccionaDistMun.bind(this, distMun)}
                key={distMun[tipoGeografico.nombreID]}
              >
                {distMun[tipoGeografico.nombreID]} -{" "}
                {distMun[tipoGeografico.nombreGeografia]}
              </Option>
            ))}
        </Select>
      </div>
    );
  }

  const content = () => (
    // eslint-disable-next-line react/jsx-pascal-case
    <Menu_Usuario />
  );

  return (
    <div className="procesoContainer">
      <Popover
        content={content()}
        trigger={!(versionUsuario === "JD") ? "click" : ""}
      >
        <div className="proceso">
          <div>
            <span>
              {infoMenuHook && infoMenuHook.detalleSelec
                ? infoMenuHook.detalleSelec.descripcion
                : "OFICINAS CENTRALES"}
            </span>
          </div>
          <div>
            <span>
              {infoMenuHook && infoMenuHook.estadoSelec && (
                <>
                  <Icon type="environment" theme="filled" />{" "}
                  {infoMenuHook.estadoSelec.nombreEstado}
                </>
              )}
            </span>
            <span>
              {tipoGeografico &&
                infoMenuHook[tipoGeografico.nombreSelect] &&
                getNombreDistMunPop()}
            </span>
          </div>
        </div>
      </Popover>
    </div>
  );
}
