import React, { useState, useEffect } from "react";
import { Button, Icon, Row, Select, Table, Tooltip, Modal, Input } from "antd";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import { eliminarAsistencia } from "../../../utils/Attendance/functionsAttend";

function CoursesForAttendance(props) {
    const {
        data,
        loading,
        rolModulo,
        proceso,
        user,
        setReload, moduloAbierto
    } = props;
    const [dataSource, setDataSource] = useState([]); //hook para almacenar el array
    const [dataSourceCopy, setDataSourceCopy] = useState([]); //hook copia para almacenar el array (filtros tabla)
    const [mostrarCurso, setMostrarCurso] = useState(0);
    const [filtroFecha, setfiltroFecha] = useState("");
    const [filtroNombreInst, setfiltroNombreInst] = useState("");
    const [filtroHoraInicio, setfiltroHoraInicio] = useState("");
    const [filtroCursoImpar, setfiltroCursoImpar] = useState("");

    useEffect(() => {
        setDataSource(data);
        setDataSourceCopy(data);
    }, [data]);

    const FilterByFecha = (
        <>
            Fecha de impartición
            <Input
                placeholder="dd/mm/aaaa"
                value={filtroFecha}
                onChange={(e) => {
                    const currValue = e.target.value;
                    setfiltroFecha(currValue);
                    const filteredData = dataSourceCopy.filter((entry) =>
                        entry.fechaCurso.includes(currValue)
                        && entry.nombreInstructor.toLowerCase().includes(filtroNombreInst.toLowerCase())
                        && moment(entry.horaInicial).format("HH:mm").includes(filtroHoraInicio)
                        && entry.impartidoPor.toLowerCase().includes(filtroCursoImpar.toLowerCase())
                    );
                    setDataSource(filteredData);
                }}
            />
        </>
    );

    const FilterByNombreInst = (
        <>
            Persona que impartió el curso
            <Input
                placeholder="Persona que impartió el curso"
                value={filtroNombreInst}
                onChange={(e) => {
                    const currValue = e.target.value;
                    setfiltroNombreInst(currValue);
                    const filteredData = dataSourceCopy.filter((entry) =>
                        entry.nombreInstructor.toLowerCase().includes(currValue.toLowerCase())
                        && entry.fechaCurso.includes(filtroFecha)
                        && moment(entry.horaInicial).format("HH:mm").includes(filtroHoraInicio)
                        && entry.impartidoPor.toLowerCase().includes(filtroCursoImpar.toLowerCase())
                    );
                    setDataSource(filteredData);
                }}
            />
        </>
    );

    const FilterByHoraInicial = (
        <>
            Hora de inicio
            <Input
                placeholder="hh:mm"
                value={filtroHoraInicio}
                onChange={(e) => {
                    const currValue = e.target.value;
                    setfiltroHoraInicio(currValue);
                    const filteredData = dataSourceCopy.filter((entry) =>
                        moment(entry.horaInicial).format("HH:mm").includes(currValue)
                        && entry.nombreInstructor.toLowerCase().includes(filtroNombreInst.toLowerCase())
                        && entry.fechaCurso.includes(filtroFecha)
                        && entry.impartidoPor.toLowerCase().includes(filtroCursoImpar.toLowerCase())
                    );
                    setDataSource(filteredData);
                }}
            />
        </>
    );

    const FilterByCursoImpartido = (
        <>
            Curso impartido por
            <br />
            <Select style={{ width: 150 }} optionFilterProp="children" placeholder="Todos" value={filtroCursoImpar}
                onChange={(value) => {
                    const currValue = value;
                    setfiltroCursoImpar(currValue);
                    const filteredData = dataSourceCopy.filter((entry) =>
                        entry.impartidoPor.toLowerCase().includes(currValue.toLowerCase())
                        && entry.fechaCurso.includes(filtroFecha)
                        && entry.nombreInstructor.toLowerCase().includes(filtroNombreInst.toLowerCase())
                        && moment(entry.horaInicial).format("HH:mm").includes(filtroHoraInicio)
                    );
                    setDataSource(filteredData);
                }}
            >
                <Select.Option value="">Todos</Select.Option>
                <Select.Option value="INE">INE</Select.Option>
                <Select.Option value="Organización">Organización</Select.Option>
                {/*<Select.Option value="OPL">OPL</Select.Option>*/}
            </Select>
        </>
    );

    //Se definen las columnas
    const columnas = [
        {
            title: "#",
            dataIndex: "indice",
        },
        {
            title: FilterByFecha,
            dataIndex: "fechaCurso",
            width: 200
        },
        {
            title: FilterByNombreInst,
            dataIndex: "nombreInstructor",
        },
        {
            title: FilterByHoraInicial,
            dataIndex: "horaInicial",
            width: 150,
            render: (record) => moment(record).format("HH:mm"),//Formato a la hora
        },
        {
            title: FilterByCursoImpartido,
            dataIndex: "impartidoPor",
        },
        {
            title: "Asistentes al curso",
            dataIndex: "asistentes"
        },
        {
            title: "Personas inscritas",
            dataIndex: "inscritos",
        },
        {
            title: "Usuario que capturó",
            dataIndex: "usuario",
        },
        {
            title: "Acciones",
            fixed: "right",
            align: "center",
            //width: 200,
            render: (record) => (
                <>
                    {record.idEstatusCurso !== 4 && rolModulo.captura && (
                        <Link to={{ pathname:"/admin/asistencia/capturar",
                                curso:record, proceso:proceso, rolModulo:rolModulo }}
                                disabled={record.inscritos === 0 || rolModulo.rolJL || !moduloAbierto ? true : false}>
                            <Button type="default" style={{ width: 175 }}
                                    disabled={record.inscritos === 0 || rolModulo.rolJL || !moduloAbierto ? true : false}>
                                Registrar asistencia
                            </Button>
                        </Link>
                    )}
                    {record.idEstatusCurso !== 4 && !rolModulo.captura && (
                        <Button type="default" disabled={true} style={{ width: 175 }}>
                            Registrar asistencia
                        </Button>
                    )}
                    {record.idEstatusCurso === 4 && (
                        <Tooltip title="Visualizar">
                            <Link to={{ pathname:"/admin/asistencia/consultar",
                                    curso:record, proceso:proceso, rolModulo:rolModulo}}>
                                <button className="btn-circle btn_secundario">
                                    <Icon type="eye" />
                                </button>
                            </Link>
                        </Tooltip>
                    )}{" "}
                    {record.idEstatusCurso === 4 && rolModulo.captura && !rolModulo.rolJL && moduloAbierto && (
                        <Tooltip title="Modificar">
                            <Link to={{ pathname:"/admin/asistencia/modificar",
                                    curso:record, proceso:proceso, rolModulo:rolModulo }}>
                                <button className="btn-circle btn_secundario">
                                    <Icon type="edit" />
                                </button>
                            </Link>
                        </Tooltip>
                    )}{" "}
                    {record.idEstatusCurso === 4 && rolModulo.captura && !rolModulo.rolJL && moduloAbierto && (
                        <Tooltip title="Eliminar">
                            <button className="btn-circle btn_secundario" style={{ marginLeft: "0px" }}
                                    onClick={(e) => eliminaAsistencia(record)} disabled={rolModulo.cau || !record.eliminar}>
                                <Icon type="delete" />
                            </button>
                        </Tooltip>
                    )}
                </>
            ),
        }
    ];

    function eliminaAsistencia(record) {
        record.usuario = user.sub;
        let titleText = <div>Estás apunto de <strong>eliminar</strong> la asistencia del curso
            <strong> {record.impartidoPor}</strong> impartido por <strong>{record.nombreInstructor} </strong>
            el <strong>{record.fechaCurso}</strong>.</div>
        let contentText = <div>
                <div><strong>Esta acción no se puede deshacer.</strong></div>
                <div style={{ paddingTop: "10px" }}>¿Deseas continuar?</div>
            </div>
        Modal.confirm({
            title: titleText,
            icon: null,
            content: contentText,
            okText: "Eliminar asistencia",
            cancelText: "Cancelar",
            okButtonProps: { style: { width: 175 } },
            onOk() {
                eliminarAsistencia(record, proceso.proceso.idProcesoElectoral, setReload);
            },
            onCancel() {}
        });

    }

    function filtroCursos(value) {
        setMostrarCurso(value);
        setfiltroFecha("");
        setfiltroNombreInst("");
        setfiltroHoraInicio("");
        setfiltroCursoImpar("");
        if(value === 4) { //Con asistencia registrada
            const filteredData = data.filter((entry) =>
                entry.idEstatusCurso
                    .toString()
                    .includes(value.toString())
            );
            setDataSource(filteredData);
            setDataSourceCopy(filteredData);
        } else if(value === 1) { //Sin asistencia registrada
            const filteredData = data.filter((entry) =>
                !entry.idEstatusCurso
                    .toString()
                    .includes("4")
            );
            setDataSource(filteredData);
            setDataSourceCopy(filteredData);
        } else { //Todos
            setDataSource(data);
            setDataSourceCopy(data);
        }
    }

    return (
      <>
        <Row style={{ marginTop:"20px"}}>
            <h5>Mostrar cursos</h5>
            <Select value={mostrarCurso} placeholder="Todos" style={{ width: 210 }}
                    onChange={filtroCursos}>
                <Select.Option value={0}>Todos</Select.Option>
                <Select.Option value={4}>Con asistencia registrada</Select.Option>
                <Select.Option value={1}>Sin asistencia registrada</Select.Option>
            </Select>
        </Row>
        <Row style={{ marginTop:"10px"}}>
            <Table columns={columnas}
                dataSource={dataSource}
                loading={loading}
                locale={{ emptyText: "Sin registros" }}
                scroll={{ x:true }}
            />
        </Row>
      </>
    );
}

export default withRouter(CoursesForAttendance);
