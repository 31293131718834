import {basePath} from './config';
import axios from 'axios';
import {getAccessTokenApi} from './auth';

export function getEstadisticasPortal(){
    const url = `${basePath}/portal/estadisticas`;
    return axios.post(url, {}, {});
}

export function getEstadisticasLogin(){
    const url = `${basePath}/login/estadisticas`;
    return axios.post(url, {}, {});
}

export function getEstadisticasHome(idProceso, idDetalle, idEstado, idDistrito){
    const jwt = getAccessTokenApi();
    const url = `${basePath}/home/estadisticas`;
    return axios.post(url, {
        idProceso: idProceso,
        idDetalle: idDetalle,
        idEstado: idEstado,
        idDistrito: idDistrito,
    }, {headers: { Authorization: jwt }});
}
