import React, { useEffect, useState } from 'react';
import { withRouter, Link } from "react-router-dom";
import { Row, Icon, Col, Button } from 'antd';
import TableActions from '../../../../components/Admin/Reports/TableActions';
import { getReporteActions, getReporteActionsJD } from "../../../../api/reports";
import { rol_jl_reporte_acciones } from "../../../../utils/Reports/roles";
import useAuth from '../../../../hooks/useAuth';
import moment from "moment";
import { saveExcelActions } from '../../../../api/xls';
import "../../Reports/Reports.scss";

function Actions(props) {

    const { proceso } = props;
    const { registroTabla, etiqueta } = props.location;
    const [reportActions, setReporteActions] = useState({
        info: [],
        loading: false
    });
    const [nombreEstadoEtiqueta, setNombreEstadoEtiqueta] = useState([]);
    const {
        user: { rolUsuario },
    } = useAuth();
    const [usuarioJL, setUsuarioJL] = useState([]);

    useEffect(() => {
        setUsuarioJL(rol_jl_reporte_acciones.includes(rolUsuario));
        setReporteActions({ loading: true });
        if (registroTabla !== undefined && registroTabla !== null) {
            //Registros de la tabla del reporte 
            const idProceso = proceso.proceso.idProcesoElectoral;
            const idDetalle = proceso.proceso.idDetalleProceso;
            const idEstado = registroTabla.idEstadoopl;
            if (registroTabla.idDistritoopl !== undefined && registroTabla.idDistritoopl !== null) {
                if (registroTabla.idDistritoopl !== 0) {
                    const idDistritoF = registroTabla.idDistritoopl;
                    getReporteActionsJD(idProceso, idDetalle, idEstado, idDistritoF).then((res) => {
                        const dataSource = [];
                        res.data.acciones.map((prom, index) => {
                            return dataSource.push({
                              key: index,
                              indice: index + 1,
                              ...prom,
                            });
                        });
                        setReporteActions({ info: dataSource, loading: false });
                    }).catch((error) => {
                        console.log(error);
                    });
                } else {
                    getReporteActionsJD(idProceso, idDetalle, idEstado, 0).then((res) => {
                        const dataSource = [];
                        res.data.acciones.map((prom, index) => {
                            return dataSource.push({
                              key: index,
                              indice: index + 1,
                              ...prom,
                            });
                        });
                        setReporteActions({ info: dataSource, loading: false });
                    }).catch((error) => {
                        console.log(error);
                    });
                }
            } else {
                getReporteActions(idProceso, idDetalle, idEstado).then((res) => {
                    const dataSource = [];
                        res.data.cAcciones.map((prom, index) => {
                            return dataSource.push({
                              key: index,
                              indice: index + 1,
                              ...prom,
                            });
                        });
                    setReporteActions({ info: dataSource, loading: false });
                }).catch((error) => {
                    console.log(error);
                });
            }
        } else {
            //Carga información dependiendo del menú geografico o el valor de la etiqueta (navegación) 
            if (etiqueta !== undefined && etiqueta !== null) {
                const idProceso = proceso.proceso.idProcesoElectoral;
                const idDetalle = proceso.proceso.idDetalleProceso;
                const idEstado = etiqueta;
                getReporteActions(idProceso, idDetalle, idEstado).then((res) => {
                    const dataSource = [];
                        res.data.cAcciones.map((prom, index) => {
                            return dataSource.push({
                              key: index,
                              indice: index + 1,
                              ...prom,
                            });
                        });
                    setReporteActions({ info: dataSource, loading: false });
                    setNombreEstadoEtiqueta(res.data.cAcciones[0].nombreEstadoopl);
                }).catch((error) => {
                    console.log(error);
                });
            } else {
                if (proceso.distrito !== undefined && proceso.distrito !== null) {
                    if (proceso.distrito.idDistrito !== 0) {
                        const idProceso = proceso.proceso.idProcesoElectoral;
                        const idDetalle = proceso.proceso.idDetalleProceso;
                        const idEstado = proceso.estado.idEstado;
                        const idDistritoF = proceso.distrito.idDistrito;
                        getReporteActionsJD(idProceso, idDetalle, idEstado, idDistritoF).then((res) => {
                            const dataSource = [];
                            res.data.acciones.map((prom, index) => {
                                return dataSource.push({
                                key: index,
                                indice: index + 1,
                                ...prom,
                                });
                            });
                            setReporteActions({ info: dataSource, loading: false });
                        }).catch((error) => {
                            console.log(error);
                        });
                    } else {
                        const idProceso = proceso.proceso.idProcesoElectoral;
                        const idDetalle = proceso.proceso.idDetalleProceso;
                        const idEstado = proceso.estado.idEstado;
                        getReporteActions(idProceso, idDetalle, idEstado).then((res) => {
                            const dataSource = [];
                            res.data.cAcciones.map((prom, index) => {
                                return dataSource.push({
                                key: index,
                                indice: index + 1,
                                ...prom,
                                });
                            });
                            setReporteActions({ info: dataSource, loading: false });
                        }).catch((error) => {
                            console.log(error);
                        });
                    }
                } else if(proceso.proceso) {
                    let idProceso = proceso.proceso.idProcesoElectoral;
                    let idDetalle = proceso.proceso.idDetalleProceso;
                    let idEstado = 0;
                    if(proceso.estado !== undefined && proceso.estado !== null) {
                        idEstado = proceso.estado.idEstado;
                    }
                    getReporteActions(idProceso, idDetalle, idEstado).then((res) => {
                        const dataSource = [];
                        res.data.cAcciones.map((prom, index) => {
                            return dataSource.push({
                              key: index,
                              indice: index + 1,
                              ...prom,
                            });
                        });
                        setReporteActions({ info: dataSource, loading: false });
                    }).catch((error) => {
                        console.log(error);
                    })
                }
            }
        }
        // eslint-disable-next-line
    }, [proceso, registroTabla, etiqueta]);

    //Etiqueta Vinculo 
    function EtiquetaVinculo() {
        if (registroTabla !== undefined && registroTabla !== null) {
            if (registroTabla.idDistritoopl !== undefined && registroTabla.idDistritoopl !== null) {
                const nombreEstado = registroTabla.nombreEstadoopl;
                const idEstadoEtiqueta = registroTabla.idEstadoopl;
                if (registroTabla.idDistritoopl !== 0) {
                    const nombreDistrito = registroTabla.cabeceraDistrital;
                    if (usuarioJL) {
                        return (
                            <div>
                                Nacional | <Link to={{
                                    pathname: "/admin/reportes/acciones",
                                    etiqueta: idEstadoEtiqueta
                                }}>{nombreEstado}</Link> | {nombreDistrito}
                            </div>
                        );
                    } else {
                        return (
                            <div>
                                <Link to={{
                                    pathname: "/admin/reportes/acciones",
                                    etiqueta: 0
                                }}> Nacional </Link> | <Link to={{
                                    pathname: "/admin/reportes/acciones",
                                    etiqueta: idEstadoEtiqueta
                                }}>{nombreEstado}</Link> | {nombreDistrito}
                            </div>
                        );
                    }
                } else {
                    if (usuarioJL) {
                        return (
                            <div>Nacional | <Link to={{ pathname:"/admin/reportes/acciones",
                            etiqueta:idEstadoEtiqueta}}>{nombreEstado}</Link> | JUNTA LOCAL </div>
                        );
                    } else {
                        return (
                            <div><Link to={{
                                pathname: "/admin/reportes/acciones",
                                etiqueta: 0
                            }}>Nacional</Link> | <Link to={{ pathname:"/admin/reportes/acciones",
                                etiqueta:idEstadoEtiqueta}}>{ nombreEstado}</Link> | JUNTA LOCAL </div>
                        );
                    }
                }
            } else if (registroTabla.idEstadoopl !== undefined && registroTabla.idEstadoopl !== null) {
                if (registroTabla.idEstadoopl !== 0) {
                    const nombreEstado = registroTabla.nombreEstadoopl;
                    return (
                        <div><Link to={{
                            pathname: "/admin/reportes/acciones",
                            etiqueta: 0
                        }}>Nacional</Link> | { nombreEstado}</div>
                    );
                } else {
                    return (
                        <div>Nacional</div>
                    );
                }
            } else {
                return (
                    <div>Nacional</div>
                );
            }
        } else {
            if (etiqueta !== undefined && etiqueta !== null) {
                if (etiqueta !== 0) {
                    if (usuarioJL) {
                        return (
                            <div>Nacional | { nombreEstadoEtiqueta}</div>
                        );
                    } else {
                        return (
                            <div><Link to={{
                                pathname: "/admin/reportes/acciones",
                                etiqueta: 0
                            }}>Nacional</Link> | { nombreEstadoEtiqueta}</div>
                        );
                    }
                } else {
                    return (
                        <div>Nacional</div>
                    );
                }
            } else {
                if (proceso.distrito !== undefined && proceso.distrito !== null) {
                    const nombreEstado = proceso.estado.nombreEstado;
                    if (proceso.distrito.idDistrito !== 0) {
                        const nombreDistrito = proceso.distrito.idDistrito + '. ' + proceso.distrito.nombreDistrito;
                        return (
                            <div>Nacional | { nombreEstado} | { nombreDistrito}</div>
                        );
                    } else {
                        return (
                            <div>Nacional | { nombreEstado}</div>
                        );
                    }
                } else if (proceso.estado !== undefined && proceso.estado !== null) {
                    if (proceso.estado.idEstado !== 0) {
                        const nombreEstado = proceso.estado.nombreEstado;
                        return (
                            <div>Nacional | { nombreEstado}</div>
                        );
                    } else {
                        return (
                            <div>Nacional</div>
                        );
                    }
                } else {
                    return (
                        <div>Nacional</div>
                    );
                }
            }
        }
    }

    const UltimaActualizacion = () => {
        const fechaHora = new Date();
        moment.locale("es");
        const fecha = moment(fechaHora).format("DD/MMMM/YYYY");
        const hora = moment(fechaHora).format("HH:mm:ss");
        return (
            <div style={{ fontSize: "12px", position: "absolute", marginTop: "20px", right: "0" }}>
                <Icon type="calendar" /> {fecha} <Icon type="clock-circle" /> {hora}
            </div>
        );
    }

    const downloadExcel = () => {
        saveExcelActions("Acciones de Difusión", "Reporte de Acciones de difusión", reportActions.info);
    }

    const AccionesRec = () => {
        var total = 0;
        if (reportActions.info !== undefined && reportActions.info !== null && reportActions.info.length > 0) {
            if (reportActions.info[0].medio !== undefined && reportActions.info[0].medio != null) {
                total = reportActions.info.length;
            } else {
                let totaline = 0;
                let totalopl = 0;
                reportActions.info.forEach((e) => {
                    totaline += e.totaline;
                    totalopl += e.totalopl;
                });
                total = totaline + totalopl;
            }
        }
        return (
            <div style={{ position: "absolute", right: "0", fontSize: "24px", fontWeight: "bold" }}>
                {total}
            </div>
        );
    }

    function validaBoton() {
        var band = true;
        if(reportActions.info !== undefined && reportActions.info !== null) {
            if(reportActions.info.length > 0) {
                band = false;
                return band;
            } else
                return band;
        }
        return band;
    }

    return (
        <div>
            <Row>
                <Link to={"/admin/reportes"} className="back">
                    <Icon type="play-circle" theme="filled" rotate="180" /> Volver a
                    "Reportes"
                </Link>
            </Row>
            <Row style={{ marginTop: "20px" }}>
                <Col span={12}>
                    <h2>Acciones de difusión</h2>
                </Col>
                <Col span={12}>
                    <div style={{ float: "right", fontWeight: "bold", fontSize: "12px" }}>
                        Última actualización
                    </div>
                    <UltimaActualizacion />
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <EtiquetaVinculo />
                </Col>
                <Col span={12}>
                    <AccionesRec />
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <div style={{ fontWeight: "bold", fontSize: "14px" }}>
                        Descarga el reporte:&nbsp;&nbsp;
                    <Button onClick={() => downloadExcel()} className="btn_descarga_reporte" type="link"
                            disabled={validaBoton()}>
                        <Icon type="file-excel" /> Excel
                    </Button>
                    </div>
                </Col>
                <Col span={12}>
                    <div style={{ position:"absolute", right:"0" }}>
                        Acciones de difusión recibidas
                    </div>
                </Col>

            </Row>
            <Row style={{ marginTop: "5px" }}>
                <TableActions data={reportActions.info}
                    loading={reportActions.loading}
                    proceso={proceso}
                    registroTabla={registroTabla}
                    etiqueta={etiqueta}
                />
            </Row>
        </div>
    );
}

export default withRouter(Actions);
