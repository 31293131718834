import React from 'react';
import "./GeneralHome.scss";

const RequestSinProceso = (props) => {

    const {conteo} = props;

    return (
        <div className="cardMain">
            <strong><p className="solSinProceso">{conteo}</p></strong>
            <p>Solicitudes recibidas por Entidades sin Proceso Electoral</p>
        </div>
    );
}

export default RequestSinProceso;