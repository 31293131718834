import { basePath } from "./config";
import axios from "axios";
import { getAccessTokenApi } from "./auth";


export function save(file) {
    const url = `${basePath}/gluster`;
    const jwt = getAccessTokenApi();
    return axios.post(url, file, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: jwt,
      },
    });
  }

  export function getDownloadSolicitud(cadena) {
    const url = `${basePath}/descargaPublica`;
    return axios.post(
      url,
      { cadena },
      {
        headers: {
          "Content-Type": "application/json",
        },
        responseType: "blob",
      }
    );
  }

  export function generarLinks() {
    const url = `${basePath}/generarLink`;
    const jwt = getAccessTokenApi();
    return axios.post(
        url,
        {  },
        { headers: { Authorization: jwt } }
    );
  }

  export function generarSolicitudes() {
    const url = `${basePath}/solicitud/generarSolicitudesR`;
    const jwt = getAccessTokenApi();
    return axios.post(
        url,
        {  },
        { headers: { Authorization: jwt } }
    );
  }

  export function enviarCorreos() {
    const url = `${basePath}/notificacionCursos`;
    const jwt = getAccessTokenApi();
    return axios.post(
        url,
        {  },
        { headers: { Authorization: jwt } }
    );
  }