import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import Loading from "../../Generals/Loading";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "../../../hooks/useAuth";
import { saveQualification } from "../../../api/courses";
import { ACT_FINAL, ACT_VALORACION, CAL_MIN } from "../../../utils/constanst";
import { reqCuestionario } from "../../../api/courses";
import { lastLesson } from "../../../redux/actions/course";

export default function ModalOnline(props) {
  const { close, idLeccion, setCurrent, index, sendQuiz, url } = props;
  const { idEntidad } = useSelector((state) => state.entidad); //Se obtiene el id de la entidad seleccionada store-Redux
  const { modal } = useSelector((state) => state.modal); //Se obtiene el valor del store-Redux
  const [loading, setLoading] = useState(false);
  const [cal, setCal] = useState(0);//Hook para almacenar la calificación
  const [error, setError] = useState({ error: false, message: "" });
  const dispatch = useDispatch(); //Se declara el dispatch para ejecutar las acciones de redux
  const {
    ciudadano: {
      ciudadano: { id, sub },
    },
  } = useAuth();

  useEffect(() => {
    if (modal) {//Al abrir el modal se ejecuta lo siguiente
      setLoading(true);//De habilita el loading de cargando...      
      //Se hace petición para obtener la calificación del cuestionario
      reqCuestionario(sub, getHash())
        .then((res) => {
          const { calificacion } = res.data; //Destructuring para obtener la calificación
          //Se guarda la calificación en la DB de observadores con los siguientes datos
          saveQualification(id, idEntidad, idLeccion, calificacion, sub)
            .then((res) => {
              const { data } = res;
              if (data.code !== 200) {
                setError({ error: true, message: data.causa });
                setLoading(false);
              } else {
                setCal(calificacion); //Para mostrar la calificación en el modal
                setError({ error: false, message: "" });
                setLoading(false); //Se quita el loading
              }
            })
            .catch((e) => {
              console.log("Excepción DB Obs", e);
              if(e.toString().trim()==="Error OBS001"){
                setLoading(false);
                setError({
                    error: true,
                    message:
                    "Te informamos que las sesiones se cierran después de 60 minutos, deberás iniciar sesión de nuevo para continuar con tu trabajo en el portal.",
                });
            }else{
                setLoading(false);
                setError({
                    error: true,
                    message:
                    "Te informamos que las sesiones se cierran después de 60 minutos, deberás iniciar sesión de nuevo para continuar con tu trabajo en el portal.",
                });
            }
            });
        })
        .catch((e) => {
          console.log("Error al consumir el web service de cuestionarios", e);
          setLoading(false);
          setError({
            error: true,
            message:
              "Favor de contestar la evaluación para continuar con la siguiente lección.",
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  //Función que cambia a la siguiente lección
  const next = () => {
    close();//Cerrar el modal
    setCurrent(index + 1); //Cambia a la siguiente lección
    dispatch(lastLesson(index + 1)); //Se actualiza la ultima lección en redux
  };

  //Funcón que obtiene el hash de la url de cuestionarios
  const getHash = () => {
    return url.split("/").pop();
  };

  //Función que obtiene el texto de acuerdo a la lección en la que se encuentre el ciudadano
  const getText = () => {
    switch (idLeccion) {
      case ACT_FINAL:
        return `Ejercicio final: Resultado`;
      case ACT_VALORACION:
        return `Valoración del curso`;
      default:
        return `Lección ${index + 1}: Resultado de la actividad`;
    }
  };

  //Función que muestra el texto en los botones para avanzar en a la siguiente lección
  const getTextButtonNext = () => {
    switch (idLeccion) {
      case ACT_FINAL:
        return `Ir a la valoración del curso`;
      case 5:
          return `Ejercicio final`;
      default:
        return `Siguiente lección`;
    }
  };

  return (
    <Modal
      title={modal && getText()}
      visible={modal}
      onCancel={close}
      footer={false}
      maskClosable={false}
    >
      {loading ? (
        <Loading />
      ) : (
        <>
          {/* {false ? ( */}
          {error.error ? (
            <>
              <p>{error.message}</p>
              {/* <p>Error</p> */}
              <div style={{ marginTop: 30 }}>
                <Button
                  type="primary"
                  onClick={(e) => {
                    close();
                  }}
                >
                  Aceptar
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className="site-card-border-less-wrapper">
                Obtuviste una calificación de <b>{cal}</b> en la actividad
              </div>
              {idLeccion ===10 && (
                <div>
                <p style={{ marginTop: 30 }}>Podrás generar tu constancia en el apartado <span style={{color: '#dc2594' }}>“Documentos de proceso”.</span></p>
                </div>
              )}
              {idLeccion === ACT_FINAL && cal < CAL_MIN ? (
                <p style={{ marginTop: 30 }}>
                  Lo sentimos no pasaste el examen con la calificación mínima de
                  7. Favor de intentarlo nuevamente.
                </p>
              ) : (
                <>
                {idLeccion ===5 && (
                <div>
                <p style={{ marginTop: 30 }}>
                    Sí lo deseas puedes realizar de nuevo la actividad, o bien puedes continuar con el ejercicio final.
                  </p>
                </div>
                )}
                {idLeccion < 5 && (
                <div>
                <p style={{ marginTop: 30 }}>
                  Sí lo deseas puedes realizar de nuevo la actividad, o bien puedes continuar con la siguiente lección.
                  </p>
                </div>
                )}
                {idLeccion ===10 && (
                <div>
                <p style={{ marginTop: 30 }}>
                  Sí lo deseas puedes realizar de nuevo la actividad, o calificar el curso.
                  </p>
                </div>
                )}
                  
                </>
              )}


              <div style={{ marginTop: 30 }}>
                <Button
                  style={{ width: "auto" }}
                  type="default"
                  onClick={(e) => {
                    sendQuiz();
                  }}
                >
                  Reintentar actividad
                </Button>
                {idLeccion !== ACT_VALORACION && (
                  <Button
                    type="primary"
                    onClick={(e) => {
                      next();
                    }}
                    style={{ width: "auto" }}
                    disabled={idLeccion === ACT_FINAL && cal < CAL_MIN}
                  >
                    {getTextButtonNext()}
                  </Button>
                )}
              </div>
            </>
          )}
        </>
      )}
    </Modal>
  );
}
