//Layout
//Se creo un Layout principal para que solo se manden a llamar las paginas y muestren su contenido
import LayoutAdmin from "../layouts/LayoutAdmin";
import LayoutPublic from "../layouts/LayoutPublic";
import LayoutPortal from "../layouts/LayoutPortal";

//Pages Admin
import HomeAdmin from "../pages/Admin/Home/HomeAdmin";
import AdminLogin from "../pages/Admin/Login/Login";
import Error404 from "../pages/Error404";
import Convocation from "../pages/Admin/Convocation/Convocation";
import FormConvocation from "../components/Admin/Convocation/Form";
import Organitation from "../pages/Admin/Organitation/Organitation";
import FormOrganitation from "../components/Admin/Organitation/Organitation";
import Requests from "../pages/Admin/Register/Requests";
import FormRegisterAdmin from "../components/Admin/Register/Form";
import Validations from "../pages/Admin/Validation/Validations";
import Validation from "../pages/Admin/Validation/Validation";
import Console from "../pages/Admin/Console/Console";
import Action from "../pages/Admin/Action/Action";
import FormAction from "../components/Admin/Action/Action";
import ReportsAdmin from "../pages/Admin/Reports/Reports";
import ReportRequests from "../pages/Admin/Reports/Requests/Requests";
import Courses from "../pages/Admin/Courses/Courses";
import FormCourses from "../components/Admin/Courses/Form";
import ReportOrg from "../pages/Admin/Reports/Organizations/Organizations";
import ReportSolOrg from "../pages/Admin/Reports/Organizations/RequestsOrg";
import ReportActions from "../pages/Admin/Reports/Actions/Actions";
import Attendance from "../pages/Admin/Attendance/Attendance";
import AttendanceCapture from "../pages/Admin/Attendance/AttendanceCapture";
import AttendanceQuery from "../pages/Admin/Attendance/AttendanceQuery";
import AttendanceUpdate from "../pages/Admin/Attendance/AttendanceUpdate";
import AccreditationAdmin from "../pages/Admin/Accreditation/Accreditation";
import ReportsCourses from "../pages/Admin/Reports/Courses/Courses";
import Documents from "../pages/Admin/Documents/Documents";
import DetailRequests from "../pages/Admin/Reports/DetailRequests/DetailRequests";
import Gluster from "../pages/Admin/Others/Gluster";
import Status from "../pages/Admin/Status/Status";
import StatusChange from "../pages/Admin/Status/StatusChange";
import ReportTrans from "../pages/Admin/Reports/Transparency/Transparency";
import ReportsInformation from "../pages/Admin/Reports/Information/Information";
import AdminInformation from "../pages/Admin/Information/Information";
import AdminInformationRegister from "../components/Admin/Information/Form";
import ConfigFechas from "../pages/Admin/ConfigFechas/ConfigFechas";
import Notifications from "../pages/Admin/Notifications/Notifications";
//Pages Portal
import HomePortal from "../pages/Public/Portal/Home";
import ConvocationPortal from "../pages/Public/Portal/Convocation";
import RequirementsPortal from "../pages/Public/Portal/Requirements";
import TrainingPortal from "../pages/Public/Portal/Training";
import Information from "../pages/Public/Citizen/Information/Information";
import Observations from "../pages/Public/Portal/Observations";
import ObservadorPortal from "../pages/Public/Portal/Observador";
import Normatividad from "../pages/Public/Portal/Normatividad";
import Glosario from "../pages/Public/Portal/Glosario";
import Acreditacion from "../pages/Public/Portal/Acreditacion";
import Doc from "../pages/Public/Portal/Doc";
import ContentMaterials from "../components/User/Public/SectionMaterials/ContentMaterials";
//Cursos en línea
import Training from "../pages/Public/Citizen/CourseOnline/Training";
import TrainingCP from "../pages/Public/Citizen/CourseOnlineCP/CourseDescription";
import CourseCP from "../pages/Public/Citizen/CourseOnlineCP/Course";
import Introduction from "../pages/Public/Citizen/CourseOnline/Introduction";
import Lessons from "../pages/Public/Citizen/CourseOnline/Lessons";
import Material from "../pages/Public/Citizen/CourseOnline/Material";
//Curso Consulta Popular
import CourseConsultaPop from "../pages/Public/Citizen/CourseConsultaPopular/Intro";
import Lesson1 from "../pages/Public/Citizen/CourseConsultaPopular/Lesson1";
import Lesson2 from "../pages/Public/Citizen/CourseConsultaPopular/Lesson2";
import MaterialesCP from "../pages/Public/Citizen/CourseConsultaPopular/MaterialesCP";
//Pages Public
import Home from "../pages/Public/Citizen/Home/Home";
import Materials from "../pages/Public/Citizen/Materials";
import Requirements from "../pages/Public/Citizen/Requirements";
import Register from "../pages/Public/Citizen/Request/Request";
import ConfirmPass from "../pages/Public/Portal/ConfirmPass";
import Recovery from "../pages/Public/Portal/Recovery";
import Decline from "../pages/Public/Citizen/Decline/Decline"
//Permisos de consulta
import { rol_consulta } from "../utils/Validations/roles";
import { rol_consulta as consultaCon } from "../utils/Convocation/roles";
import { rol_consulta as consultaCOrg } from "../utils/Organitation/roles";

//Se crea un array con todas las rutas a utilizar
const routes = [
  {
    path: "/admin",
    component: LayoutAdmin,
    exact: false,
    routes: [
      {
        path: "/admin/home",
        component: HomeAdmin,
        exact: true,
      },
      {
        path: "/admin", //capcha
        component: AdminLogin,
        exact: true,
      },
      {
        path: "/admin/convocatoria",
        component: Convocation,
        exact: true,
        permissions: consultaCon,
      },
      {
        path: "/admin/convocatoria/registro",
        component: FormConvocation,
        exact: true,
      },
      {
        path: "/admin/convocatoria/modificar",
        component: FormConvocation,
        exact: true,
      },
      {
        path: "/admin/organizacion",
        component: Organitation,
        exact: true,
        permissions: consultaCOrg,
      },
      {
        path: "/admin/organizacion/registro",
        component: FormOrganitation,
        exact: true,
      },
      {
        path: "/admin/organizacion/modificar",
        component: FormOrganitation,
        exact: true,
      },
      {
        path: "/admin/organizacion/consulta",
        component: FormOrganitation,
        exact: true,
      },
      {
        path: "/admin/solicitudes",
        component: Requests,
        exact: true,
      },
      {
        path: "/admin/solicitudes/registro",
        component: FormRegisterAdmin,
        exact: true,
      },
      {
        path: "/admin/solicitudes/modifica",
        component: FormRegisterAdmin,
        exact: true,
      },
      {
        path: "/admin/solicitudes/consulta",
        component: FormRegisterAdmin,
        exact: true,
      },
      {
        path: "/admin/validaciones",
        component: Validations,
        exact: true,
        permissions: rol_consulta,
      },
      {
        path: "/admin/validacion/solicitud",
        component: Validation,
        exact: true,
      },
      {
        path: "/admin/consola",
        component: Console,
        exact: true,
      },
      {
        path: "/admin/accion",
        component: Action,
        exact: true,
      },
      {
        path: "/admin/accion/registro",
        component: FormAction,
        exact: true,
      },
      {
        path: "/admin/accion/modificar",
        component: FormAction,
        exact: true,
      },
      {
        path: "/admin/reportes",
        component: ReportsAdmin,
        exact: true,
      },
      {
        path: "/admin/reportes/solicitudes",
        component: ReportRequests,
        exact: true,
      },
      {
        path: "/admin/cursos",
        component: Courses,
        exact: true,
      },
      {
        path: "/admin/cursos/registro",
        component: FormCourses,
        exact: true,
      },
      {
        path: "/admin/cursos/consulta",
        component: FormCourses,
        exact: true,
      },
      {
        path: "/admin/cursos/modifica",
        component: FormCourses,
        exact: true,
      },
      {
        path: "/admin/reportes/organizaciones",
        component: ReportOrg,
        exact: true,
      },
      {
        path: "/admin/reportes/solicitudesOrg",
        component: ReportSolOrg,
        exact: true,
      },
      {
        path: "/admin/reportes/acciones",
        component: ReportActions,
        exact: true,
      },
      {
        path: "/admin/asistencia",
        component: Attendance,
        exact: true,
      },
      {
        path: "/admin/asistencia/capturar",
        component: AttendanceCapture,
        exact: true,
      },
      {
        path: "/admin/asistencia/consultar",
        component: AttendanceQuery,
        exact: true,
      },
      {
        path: "/admin/asistencia/modificar",
        component: AttendanceUpdate,
        exact: true,
      },
      {
        path: "/admin/acreditacion",
        component: AccreditationAdmin,
        exact: true,
      },
      {
        path: "/admin/reportes/cursos",
        component: ReportsCourses,
        exact: true,
      },
      {
        path: "/admin/documentos",
        component: Documents,
        exact: true,
      },
      {
        path: "/admin/reportes/detalleSolicitudes",
        component: DetailRequests,
        exact: true,
      },
      {
        path: "/admin/gluster",
        component: Gluster,
        exact: true,
      },
      {
        path: "/admin/estadoSolicitud",
        component: Status,
        exact: true,
      },
      {
        path: "/admin/estadoSolicitud/modificar",
        component: StatusChange,
        exact: true,
      },
      {
        path: "/admin/reportes/transparencia",
        component: ReportTrans,
        exact: true,
      },
      {
        path: "/admin/reportes/informes",
        component: ReportsInformation,
        exact: true,
      },
      {
        path: "/admin/informes",
        component: AdminInformation,
        exact: true,
      },
      {
        path: "/admin/informes/registro",
        component: AdminInformationRegister,
        exact: true,
      },
      {
        path: "/admin/informes/modifica",
        component: AdminInformationRegister,
        exact: true,
      },
      {
        path: "/admin/informes/consulta",
        component: AdminInformationRegister,
        exact: true,
      },
      {
        path: "/admin/configFechas",
        component: ConfigFechas,
        exact: true,
      },
      {
        path: "/admin/notificaciones",
        component: Notifications,
        exact: true,
      },
      {
        component: Error404,
      },
    ],
  },
  {
    path: "/",
    component: HomePortal,
    exact: true,
    routes: [],
  },
  {
    path: "/ciudadano",
    component: LayoutPublic,
    exact: false,
    routes: [
      {
        path: "/ciudadano/inicio",
        component: Home,
        exact: true,
      },
      {
        path: "/ciudadano/requisitos",
        component: Requirements,
        exact: true,
      },
      {
        path: "/ciudadano/informes",
        component: Information,
        exact: true,
      },
      {
        path: "/ciudadano/materiales",
        component: Materials,
        exact: true,
      },
      {
        path: "/ciudadano/solicitud",
        component: Register,
        exact: true,
      },
      {
        path: "/ciudadano/solicitud/modificar",
        component: Register,
        exact: true,
      },
      {
        path: "/ciudadano/solicitud/consultar",
        component: Register,
        exact: true,
      },
      {
        path: "/ciudadano/observaciones",
        component: Observations,
        exact: true,
      },
      {
        path: "/ciudadano/capacitacion",
        component: Training,
        exact: true,
      },
      {
        path: "/ciudadano/capacitacionCP",
        component: TrainingCP,
        exact: true,
      },
      {
        path: "/ciudadano/capacitacionCP/curso",
        component: CourseCP,
        exact: true,
      },
      {
        path: "/ciudadano/introduccion",
        component: Introduction,
        exact: true,
      },
      {
        path: "/ciudadano/lecciones",
        component: Lessons,
        exact: true,
      },
      {
        path: "/ciudadano/material",
        component: Material,
        exact: true,
      },
      {
        path: "/ciudadano/declinar",
        component: Decline,
        exact: true,
      },
      {
        path: "/ciudadano/curso-consulta-popular",
        component: CourseConsultaPop,
        exact: true,
      },
      {
        path: "/ciudadano/curso-leccion1",
        component: Lesson1,
        exact: true,
      },
      {
        path: "/ciudadano/curso-leccion2",
        component: Lesson2,
        exact: true,
      },
      {
        path: "/ciudadano/materiales-consulta-popular",
        component: MaterialesCP,
        exact: true,
      },
      {
        component: Error404,
      },
    ],
  },
  {
    path: "/portal",
    component: LayoutPortal,
    exact: false,
    routes: [
      {
        path: "/portal/convocatoria",
        component: ConvocationPortal,
        exact: true,
      },
      {
        path: "/portal/observador",
        component: ObservadorPortal,
        exact: true,
      },
      {
        path: "/portal/requisitos",
        component: RequirementsPortal,
        exact: true,
      },
      {
        path: "/portal/materiales",
        component: TrainingPortal,
        exact: true,
      },
      {
        path: "/portal/confirm",
        component: ConfirmPass,
        exact: true,
      },
      {
        path: "/portal/normatividad",
        component: Normatividad,
        exact: true,
      },
      {
        path: "/portal/recovery",
        component: Recovery,
        exact: true,
      },
      {
        path: "/portal/glosario",
        component: Glosario,
        exact: true,
      },
      {
        path: "/portal/acreditacion",
        component: Acreditacion,
        exact: true,
      },
      {
        path: "/portal/doc",
        component: Doc,
        exact: true,
      },
      {
        path: "/portal/materiales-capacitacion",
        component: ContentMaterials,
        exact: true,
      },
      {
        component: Error404,
      },
    ],
  },
];

export default routes;
