import React, { useState,useEffect } from "react";
import { Form, Button, Modal, Radio } from "antd";
//Componentes Generales
// import TypeRequest from "../../../../components/User/Steps/TypeReq";
import Documents from "../../../../components/User/Steps/Documents";
import Data from "../../../../components/User/Steps/Data";
import Adress from "../../../../components/User/Steps/Adress";
import Additional from "../../../../components/User/Steps/Additional";
//Componentes Extranjero
import Resident from "../../../../components/User/Steps/Foreign/Resident";
import ForAdress from "../../../../components/User/Steps/Foreign/Adress";
//Funciones
import { ModalConfirm, Ast, ModalPreRegister } from "../../../../utils/Request/functions";
import { generaFolio } from "../../../../utils/Request/functions";
import useAuth from "../../../../hooks/useAuth";
import { lastName } from "../../../../utils/Request/functions";
import { Redirect, withRouter } from "react-router-dom";
import { Entities } from "../../../../components/User/Steps/Entities";
import { radioButton } from "../../../../utils/rules";
import { getDatosObservador } from "../../../../api/requestPub";
import "../../../../components/User/Steps/Steps.scss";
import { ID_DOC_CRED_FRENTE, ID_DOC_CRED_VUELTA, ID_DOC_FOTOGRAFIA, MSN_GUARDAR, ORIGEN_PORTAL_PUBLICO } from "../../../../utils/constanst";
import {
  getEdad,
  getFechaNacimiento
} from "../../../../components/Generals/functions";

function Request(props) {
  const {
    history,
    setLoader,
    location: { state, modificar },
  } = props; //Para utilizar la función push
  const [datos, setDatos] = useState({}); //Hook(Principal) para almacenar todos los datos del formulario
  const [files, setFiles] = useState({}); //Hook para almacenar las imagenes
  const { getFieldDecorator } = props.form; //Método para validar los inputs
  const [disabled, setDisabled] = useState({ num: false, btnSave: false }); //Hook para desactivar los campos de número exterior e interior y mostrar el modal de las fotografias
  const [disabledObs, setDisabledObs] = useState(false);
  const [observador, setObservador] = useState([]);
  const [datosCP, setDatosCP] = useState({});
  //const [banderaCP, setBanderaCP] = useState(false);

  const {
    ciudadano: { ciudadano, detalle },
  } = useAuth();
  const [juntas, setJuntas] = useState({ juntas: [], estados: [] });

  useEffect(() => {
    setLoader({ loading: true, message: MSN_GUARDAR }); //Se activa el loader
    if (ciudadano.id !== undefined && ciudadano.id !== null) {
      // ver si se hará precarga de la información y traer los datos del PEF
      getDatosObservador(ciudadano.id).then((res) => {
        //console.log(res.data);
        if (res.data.observador !== undefined && res.data.observador !== null && res.data.observador.idObservador !== null) {
          if(modificar) {
            setDisabledObs(false);
          } else {
            if(modificar === null || modificar === undefined) {
              history.push("/ciudadano/inicio"); //Redirecciona a la pantalla principal
            } else {
              setDisabledObs(true);
            }
          }
          datos.tipoObservador = res.data.observador.tipoObservador;
          props.form.setFieldsValue({
            tipoObservador: res.data.observador.tipoObservador,
          });
          //getProcess();
          datosCP.idProcesoAnterior = res.data.observador.idProcesoElectoral;
          datosCP.idDetalleAnterior = res.data.observador.idDetalleProceso;
          datosCP.idProcesoActual = datos.idProceso;
          datosCP.idDetalleActual =  datos.idDetalle;
          datosCP.idObservador = res.data.observador.idObservador;
          datosCP.documentosObs = res.data.observador.documentosObs;
          datosCP.origenSolicitud = ORIGEN_PORTAL_PUBLICO;
          datosCP.estadoJunta = res.data.observador.idEstadoJunta;
          datosCP.distritoJunta = res.data.observador.idDistritoFederal;
          setDatosCP(datosCP);
          //setBanderaCP(true);
          setObservador(res.data.observador);
        }
        setLoader({ loading: false, message: "" }); //Se desactiva el loader
      });
    } else {
      setLoader({ loading: false, message: "" }); //Se desactiva el loader
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Se valida que acceda desde la pantalla principal
  if (state === undefined) {
    return (
      <>
        <Redirect to="/ciudadano/inicio" />
      </>
    );
  }

  
  //Función que va almacenando la información de cada input
  const handleChange = (input) => (event) => {
    if (input === "fechaNac") {
      event != null
        ? setDatos({ ...datos, [input]: event.format("YYYY-MM-DD") })
        : setDatos({ ...datos, [input]: null });
      let edadCiudadano = getEdad(event);
      props.form.setFieldsValue({
        edad: edadCiudadano.toString(),
      });
      setDatos({
        ...datos,
        edad: edadCiudadano.toString(),
      });
    } else {
      setDatos({ ...datos, [input]: event.target.value }); //Se almacena la información en el hook
    }
  };

  //Función que limpia el hook datos cada vez que se cambia el lugar de residencia
  const handleChangeType = (input) => (event) => {
    setDatos({});
    setFiles({});
    setJuntas({ juntas: [], estados: [] });
    props.form.resetFields();
    setDatos({ [input]: event.target.value }); //Se almacena la información en el hook
  };

  //Función que alamacena la información de lo select
  const changeSelect = (input) => (event) => {
    setDatos({ ...datos, [input]: event });
  };

  //Función que almacena la información de los checkbox
  const changeCheckbox = (input) => (event) => {
    setDatos({ ...datos, [input]: event.target.checked });
    if (input === "numero") {
      //Valida si el el checkbox llamado numero
      if (event.target.checked) {
        props.form.setFieldsValue({
          //Limpia los inputs de numero exterior e interior
          numeroExt: (datos.numeroExt = ""),
          numeroInt: (datos.numeroInt = ""),
        }); //Obtiene la información y la almacena
        setDatos({ ...datos, [input]: event.target.checked });
        setDisabled({ num: true }); //Bloquea los inputs
      } else {
        setDisabled({ num: false }); //Habilita los inputs
      }
    }
  };
  //Función que regresa a la pagina de tipo de solicitud
  const back = () => {
    history.push("/ciudadano/inicio");
  };
  //Función que obtiene los datos del formulario
  const saveRequest = () => { 
    props.form.validateFields((err, values) => {
      if (values.codigoPostal !== undefined && values.codigoPostal !== null && values.codigoPostal !== "" && Number(values.codigoPostal) === 0) {
        Modal.warning({
          title: "Atención",
          content: "Para poder continuar se requiere ingresar la sección electoral, favor de validar.",
        });
      } else {
        if (!err) {
          let fechaCiudadano = getFechaNacimiento(values.elector.toUpperCase());
          let edadCiudadano = getEdad(fechaCiudadano);
          if(edadCiudadano<17){
            Modal.warning({
              title: "Atención",
              content: "La clave de elector es incorrecta.",
            });
            return;
          }
          let name = lastName(datos.paterno, datos.materno);
          if (!name) {
            //Se verifica que al menos tenga un apellido
            Modal.warning({
              title: "Atención",
              content: "Al menos un apellido es obligatorio.",
            });
            return;
          }
          if (!files.credAnverso || !files.credReverso || !files.foto) {
            Modal.warning({
              title: "Atención",
              content: "Documentación personal requerida.",
            });
            return;
          } else {
            if (datos.entObservar === 0) {
              datos.entidades = [datos.idEntidad];
            }
            // Se fija la modalidad en presencial para la --
            //datos.capacitacion = 2;
            datos.idCuenta = ciudadano.idCuenta; //Se obtie el idCuenta del ciudadano
            datos.correo = ciudadano.sub; //Se obtie el correo del ciudadano
            datos.idCuenta = ciudadano.idCuenta; //Se obtie el idCuenta del ciudadano
            datos.correo = ciudadano.sub; //Se obtie el correo del ciudadano
            datos.elector = datos.elector.toUpperCase();
            getTipoObservador(values);
            getProcess(); //Se obtiene los datos de la función
            setDisabled({ btnSave: true, loading: true }); //Se deshabilita el botón de guardar

            // Nombre entidad de la junta de seguimiento
            for (let i = 0; i < juntas.estados.length; i++) {
              if (datos.entidadJunta === juntas.estados[i].id.idEstado) {
                datos.conEntidad = juntas.estados[i].nombreEstado;
              }
            }
            // Fecha de nacimiento
            datos.fechaNac = values.fechaNac.format("YYYY-MM-DD");
            //console.log(JSON.stringify(datos));
            //console.log(JSON.stringify(observador));
            ModalConfirm(datos, files, history, setDisabled, setLoader, modificar, observador, ciudadano); //Se muestra el alert
          }
        }
      }
    });
  };

  //Función temporal para el registro de un observador del PEF para la CP
  const savePreRequest = () => {
    //Validación de que haya carga correcta de los documentos/imagenes 
    let validacionDoc = true;
    for(let i = 0; i < datosCP.documentosObs.length; i++) {
      switch(datosCP.documentosObs[i].cTiposDocumento.idTipoDocumento) {
        case ID_DOC_CRED_FRENTE:
        case ID_DOC_CRED_VUELTA:
        case ID_DOC_FOTOGRAFIA:
          if(datosCP.documentosObs[i].urlImagen === null) {
            validacionDoc = false; //No paso validación de los documentos/imagenes
          }
          break;
        default:
          break;
      }
    }
    if(validacionDoc) {
      props.form.validateFields((err, values) => {
        if (!err) {
          //getProcess(); //Se obtiene los datos de la función
          setDisabled({ btnSave: true, loading: true }); //Se deshabilita el botón de guardar
          getProcess();
          datosCP.idProcesoActual = datos.idProceso;
          datosCP.idDetalleActual =  datos.idDetalle;
          datosCP.distritoJuntaActualizar = datos.juntaEjecutiva;
          datosCP.estadoJunta = datos.entidadJunta;
          datosCP.edad = parseInt(props.form.getFieldValue("edad"));
          ModalPreRegister(datosCP, history, setDisabled, setLoader); //Se muestra el alert
        } else {
          Modal.warning({
            title: "Atención",
            content: "Para poder continuar se requiere ingresar los datos faltantes, favor de validar.",
          });
        }
      });
    } else {
      Modal.warning({
        title: "Atención",
        content: "Hay un problema con la carga de los documentos requeridos. Favor de contactar a tu Junta Ejecutiva.",
      });
    }
  };

  //Función temporal que obtiene el proceso, detalle y el folio
  const getProcess = () => {
    /* datos.idProceso = detalle[0].idProcesoElectoral;
    datos.idDetalle = detalle[0].idDetalleProceso;
    datos.nombreProceso = detalle[0].nombreProceso; */
    for (let i = 0; i < detalle.length; i++) { //PARA PROCESOS LOCALES
      if(detalle[i].idEstado === datos.entidadJunta) {
        datos.idProceso = detalle[i].idProcesoElectoral;
        datos.idDetalle = detalle[i].idDetalleProceso;
        datos.nombreProceso = detalle[i].nombreProceso;
        datos.folio = generaFolio(detalle[i]);
      }
    }
  };
  //Función que le asigna los valores al hook
  const getTipoObservador = (values) => {
    datos.paterno = values.paterno && values.paterno.trim();
    datos.materno = values.materno && values.materno.trim();
    datos.nombre = values.nombre.trim();
    datos.ocupacion = values.ocupacion && values.ocupacion.trim();
    datos.celular = values.celular;
    datos.telefono = values.telefono;
    //Extranjero
    if (datos.tipoObservador === 1) {
      datos.estadoExt = values.estadoExt && values.estadoExt.trim();
      datos.ciudad = values.ciudad && values.ciudad.trim();
    }
  };
  //Componente que renderiza los botones de Guardar y regresar
  const ButtonSave = () => {
    return (
      <Form.Item style={{ textAlign: "center" }}>
        <Button
          type="default"
          onClick={() => back()}
          disabled={disabled.btnSave}
        >
          Regresar
        </Button>
        {" "}
        {!disabledObs && (observador.idObservador === undefined || observador.idObservador === null) && (<Button
          type="primary"
          onClick={() => saveRequest()}
          //style={{ width: 350 }}
          className="btnSave"
          disabled={!datos.compromiso || !datos.comCorreo || disabled.btnSave}
        >
          Guardar y enviar a validación
        </Button>)}
        {disabledObs && (modificar === undefined || modificar === null) && (<Button
          type="primary"
          onClick={() => savePreRequest()}
          //style={{ width: 350 }}
          className="btnSave"
        >
          Confirmar datos del Observador
        </Button>)}
        {modificar && (<Button
          type="primary"
          onClick={() => saveRequest()}
          //style={{ width: 350 }}
          className="btnSave"
        >
          Modificar datos del Observador
        </Button>)}
      </Form.Item>
    );
  };

  return (
    <div style={{ marginTop: 50 }}>
      <h1>Solicitud</h1>
      <hr />
      <p style={{ marginTop: 30 }}>
        Los datos marcados con ( <Ast /> ) son obligatorios
      </p>
      <h3>
        <Ast /> Lugar de residencia
      </h3>
      <Form.Item>
        {getFieldDecorator("tipoObservador", {
          rules: radioButton,
          initialValue: datos.tipoObservador,
        })(
          <Radio.Group onChange={handleChangeType("tipoObservador")} disabled={disabledObs}>
            <Radio value={0}>Territorio mexicano</Radio>
            <Radio value={1}>Extranjero</Radio>
          </Radio.Group>
        )}
      </Form.Item>
      {/**Se habilita el tipo de formulario si es nacional o extranjero  */}
      {datos.tipoObservador === 0 && (
        <>
          {/* <TypeRequest
            handleChange={handleChange} //Se envia la funcion
            datos={datos} //se manda el hook de los valores
            getFieldDecorator={getFieldDecorator} //Se envia para validar los inputs
            form={props.form}
            setDatos={setDatos}
          /> */}
          <Data
            handleChange={handleChange} //Se envia la funcion
            datos={datos} //se manda el hook de los valores
            getFieldDecorator={getFieldDecorator} //Se envia para validar los inputs
            changeSelect={changeSelect} //Función del select
            form={props.form}
            setDatos={setDatos}
            disabledObs={disabledObs}
            observador={observador}
          />
          <Adress
            handleChange={handleChange} //Se envia la funcion
            datos={datos} //se manda el hook de los valores
            getFieldDecorator={getFieldDecorator} //Se envia para validar los inputs
            changeCheckbox={changeCheckbox}
            disabled={disabled} //Para habilitar los inputs
            setDatos={setDatos}
            form={props.form}
            setJuntas={setJuntas}
            juntas={juntas}
            disabledObs={disabledObs}
            observador={observador}
          />
          <Additional
            handleChange={handleChange}
            datos={datos}
            getFieldDecorator={getFieldDecorator}
            changeSelect={changeSelect}
            form={props.form}
            juntas={juntas}
            setJuntas={setJuntas}
            disabledObs={disabledObs}
            observador={observador}
            modificar={modificar}
          />
          <Entities
            handleChange={handleChange}
            datos={datos}
            getFieldDecorator={getFieldDecorator}
            setDatos={setDatos}
            form={props.form}
            disabledObs={disabledObs}
            observador={observador}
          />
          <Documents
            setFiles={setFiles}
            files={files}
            getFieldDecorator={getFieldDecorator}
            datos={datos}
            changeCheckbox={changeCheckbox}
            setDisabled={setDisabled}
            formVal={props.form}
            disabledObs={disabledObs}
            observador={observador}
          />
          {/* Boton de Guardar-Regresar - Se reutiliza el componente  */}
          <ButtonSave />
        </>
      )}

      {props.form.getFieldValue("tipoObservador") === 1 && (
        <>
          {/*Componente solo para extrnjeros */}
          <Resident
            handleChange={handleChange} //Se envia la funcion
            datos={datos} //se manda el hook de los valores
            getFieldDecorator={getFieldDecorator} //Se envia para validar los inputs
            changeSelect={changeSelect} //Función del select
            setDatos={setDatos}
            disabledObs={disabledObs}
            observador={observador}
            form={props.form}
          />
          {/* Data - Se reutiliza el componente  */}
          <Data
            handleChange={handleChange} //Se envia la funcion
            datos={datos} //se manda el hook de los valores
            getFieldDecorator={getFieldDecorator} //Se envia para validar los inputs
            changeSelect={changeSelect} //Función del select
            setDatos={setDatos}
            form={props.form}
            disabledObs={disabledObs}
            observador={observador}
          />
          {/*Componente solo para extranjeros-Dirección */}
          <ForAdress
            setJuntas={setJuntas}
            handleChange={handleChange} //Se envia la funcion
            datos={datos} //se manda el hook de los valores
            getFieldDecorator={getFieldDecorator} //Se envia para validar los inputs
            changeCheckbox={changeCheckbox}
            disabled={disabled} //Para habilitar los inputs
            disabledObs={disabledObs}
            observador={observador}
            form={props.form}
          />
          {/*  Additional- Se reutiliza el componente  */}
          <Additional
            handleChange={handleChange}
            datos={datos}
            getFieldDecorator={getFieldDecorator}
            changeSelect={changeSelect}
            form={props.form}
            juntas={juntas}
            setJuntas={setJuntas}
            disabledObs={disabledObs}
            observador={observador}
            modificar={modificar}
          />
          {/*  Entities- Se reutiliza el componente  */}
          <Entities
            handleChange={handleChange}
            datos={datos}
            getFieldDecorator={getFieldDecorator}
            setDatos={setDatos}
            form={props.form}
            disabledObs={disabledObs}
            observador={observador}
          />
          {/*  Documents - Se reutiliza el componente  */}
          <Documents
            setFiles={setFiles}
            files={files}
            getFieldDecorator={getFieldDecorator}
            datos={datos}
            changeCheckbox={changeCheckbox}
            setDisabled={setDisabled}
            formVal={props.form}
            disabledObs={disabledObs}
            observador={observador}
          />
          {/* Boton de Guardar-Regresar - Se reutiliza el componente  */}
          <ButtonSave />
        </>
      )}
    </div>
  );
}

export default withRouter(Form.create()(Request));
