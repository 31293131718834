import { basePath } from "./config";
import axios from "axios";
import { getAccessTokenApi } from "./auth";

const TIPO_SEDE = 1;
const TIPO_INTEGRANTE = [1];
const TIPO_STATUS = [1];

export function getDownload(idObservador) {
  const url = `${basePath}/registroJuntas/descarga`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    { idObservador: idObservador },
    {
      headers: {
        Authorization: jwt, 
        "Content-Type": "application/json",
      },
      responseType: "blob",
    }
  );
}

export function updateDocs(idObservador, idProceso, idDetalle) {
  const url = `${basePath}/registroJuntas/updateDocs`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    { idObservador: idObservador, idProceso: idProceso, idDetalle:idDetalle },
    {
      headers: {
        Authorization: jwt, 
        "Content-Type": "application/json",
      }
    }
  );
}

export function getRequests(proceso, listaDetalles) {  
  const url = `${basePath}/registroJuntas/tabla`;
  const jwt = getAccessTokenApi();
  return axios.post(url, { procesoSeleccionado: proceso, procesoNoSeleccionado: listaDetalles },{
    headers: { Authorization: jwt },
  });
}

export function getOrganizaciones(proceso, listaDetalles) {
    const url = `${basePath}/registroJuntas/organizaciones`;
    const jwt = getAccessTokenApi();
    return axios.post(url, { procesoSeleccionado: proceso, procesoNoSeleccionado: listaDetalles },{
      headers: { Authorization: jwt },
    });
}

export function getJuntas(idEstado, idJunta) {
  const url = `${basePath}/sesiones/juntaEjecutiva`;
  const jwt = getAccessTokenApi();
  return axios.post(url, { idEstado: idEstado, idDistritoFederal: idJunta, tipoSede: TIPO_SEDE },{
    headers: { Authorization: jwt }
  });
}

export function getJuntasEjecutivas(idEstado) {
  const url = `${basePath}/sesiones/juntaEjecutiva`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    { idEstado: idEstado, tipoSede: TIPO_SEDE },
    {
      headers: { Authorization: jwt, Origen: "portal", Parametrizacion: "0,0" },
    }
  );
}

export function getJuntasEjecutivasTablasLoc(idEstado) {
  const url = `${basePath}/sesiones/juntaEjecutivaTablasLoc`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    { idEstado: idEstado, tipoSede: TIPO_SEDE },
    {
      headers: { Authorization: jwt, Origen: "portal", Parametrizacion: "0,0" },
    }
  );
}

export function getEntidadesSeguimiento() {
  /*
  const url = `${basePath}/sesiones/juntaEstado`; //Por definir url
  const jwt = getAccessTokenApi();
  return axios.post(url, { },{
    headers: { Authorization: jwt }
  });
*/
  const url = `${basePath}/registroJuntas/entidadesSeguimiento`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    {},
    {
      headers: { Authorization: jwt },
    }
  );
}

export function getFuncionarios(idProceso, idDetalle, idEstado, idJunta ) {
  const url = `${basePath}/sesiones/funcionarios`;
  const jwt = getAccessTokenApi();
  return axios.post(url, { id_proceso: idProceso, id_detalle: idDetalle, tipoIntegrante: TIPO_INTEGRANTE, idEstado: idEstado, idDistritoFederal: idJunta,
                           estatus: TIPO_STATUS },{
    headers: { "Authorization": jwt }
  }); 
}

export function getPaises() {
  const url = `${basePath}/registroJuntas/paises`;
  const jwt = getAccessTokenApi();
  return axios.post( url, {}, { headers: { Authorization: jwt }});
}

export function getEntidades(idProceso, idDetalle) {
    const url = `${basePath}/registroJuntas/entidades`;
    const jwt = getAccessTokenApi();
    return axios.post(url, {idProceso: idProceso, idDetalle: idDetalle}, {headers: { Authorization: jwt }});
}

export function getEntidadesObservar(proceso) {
  const url = `${basePath}/registroJuntas/entidadesObservar`;
  const jwt = getAccessTokenApi();
  return axios.post(url, {
    idProceso: proceso.proceso.idProcesoElectoral,
    idDetalle: proceso.proceso.idDetalleProceso
  }, {headers: { Authorization: jwt }});
}

export function getEscolaridades() {
    const url = `${basePath}/solicitud/escolaridad`;
    const jwt = getAccessTokenApi();
    return axios.post(url, {}, {
      headers: { Authorization: jwt },
    });
}

export function getMunicipios(id, idProceso, idDetalle) {
    const url = `${basePath}/registroJuntas/municipios`;
    const jwt = getAccessTokenApi();
    return axios.post(url, {idEstado: id, idProceso: idProceso, idDetalle:idDetalle}, {headers: { Authorization: jwt }});
}

export function validaClaveMail(values, modulo) {
    const url = `${basePath}/registroJuntas/validaClaveMail`;
    const jwt = getAccessTokenApi();
    return axios.post(url, { values: values, modulo: modulo }, {headers: { Authorization: jwt }});
}

export function saveRegister(register) {
   const url = `${basePath}/registroJuntas/guardar`;
   const jwt = getAccessTokenApi();
   return axios.post(url, register, {
     headers: {
       "Content-Type": "multipart/form-data",
       "Authorization": jwt,
       "Parametrizacion": "16"
     },
   });
}
  
export function updateRegister(register) {
   const url = `${basePath}/registroJuntas/actualizar`;
   const jwt = getAccessTokenApi();
   return axios.post(url, register, {
    headers: {
      "Content-Type": "multipart/form-data",
      "Authorization": jwt
    },
  });
}

export function getByIdRegister(id, idProceso, idDetalle) {
    const url = `${basePath}/registroJuntas/id`;
    const jwt = getAccessTokenApi();
    return axios.post(url, { idObservador: id, idProceso: idProceso, idDetalle: idDetalle },{
      headers: { Authorization: jwt }
    });
}

export function deleteRegister(idObservador) {
  const url = `${basePath}/registroJuntas/eliminar`;
  const jwt = getAccessTokenApi();
  return axios.post(url, { idObservador: idObservador },{
    headers: { "Authorization": jwt }
  });
}

export function validaCursoIniciado(idObservador, modalidad) {
  const url = `${basePath}/registroJuntas/validaCursoIniciado`;
  const jwt = getAccessTokenApi();
  return axios.post(url, { idObservador: idObservador, modalidad: modalidad }, {headers: { Authorization: jwt }});
}

export function getFechaLimite(idProcesoElectoral, idDetalleProceso) {
  const url = `${basePath}/registroJuntas/fechaCierre`;
  const jwt = getAccessTokenApi();
  return axios.post(url, { idProcesoElectoral: idProcesoElectoral, idDetalleProceso: idDetalleProceso }, {headers: { Authorization: jwt }});
} 

export function getIdObsProcesoAnterior(claveElector) {
  const url = `${basePath}/registroJuntas/obtenIdObsProcesoAnterior`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    { claveElector: claveElector },
    { headers: { Authorization: jwt } }
  );
}

export function confirmaObsProcesoAnterior(datosCP, idProceso) {
  const url = `${basePath}/registroJuntas/confirmaObsProcesoAnterior`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url, datosCP,
    { headers: { Authorization: jwt, Parametrizacion: idProceso } }
  );
}

export function obtenDetallesPorProceso(idProceso) {
  const url = `${basePath}/registroJuntas/obtenDetallesPorProceso`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    { idProceso: idProceso },
    { headers: { Authorization: jwt } }
  );
}
