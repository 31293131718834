import { Alert, Button, Col, Form, Icon, message, Row, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { getCatalogoMotivos } from "../../../api/status";
import useAuth from "../../../hooks/useAuth";
import { Ast, actualizaEstado } from "../../../utils/Status/functionsStatus";

function StatusChange(props) {
    const { history } = props;
    const {
        solicitud, rolModulo
    } = props.location;
    const { getFieldDecorator } = props.form;
    const { user: { user } } = useAuth();
    const [catalogoMotivosDenegada, setCatalogoMotivosDenegada] = useState([]);
    const [catalogoMotivosCancelada, setCatalogoMotivosCancelada] = useState([]);
    const [muestraMotivoComboDenegada, setMuestraMotivoComboDenegada] = useState(false);
    const [muestraMotivoComboCancelada, setMuestraMotivoComboCancelada] = useState(false);
    const [muestraMotivoCajaTexto, setMuestraMotivoCajaTexto] = useState(false);
    const [ botonDeshabilitado, setBotonDeshabilitado ] = useState(false);

    useEffect(() => {
        if(solicitud) {
            let motivo = "Denegada";
            getCatalogoMotivos(motivo).then((res) => {
                setCatalogoMotivosDenegada(res.data.motivosSolicitud);
            });
            motivo = "Cancelada";
            getCatalogoMotivos(motivo).then((res) => {
                setCatalogoMotivosCancelada(res.data.motivosSolicitud);
            });
        }
    }, [solicitud]);

    //Redirecciona a la pantalla pricipal en caso de que no exista información en la variable curso
    if(!solicitud) {
        return <Redirect to={{pathname:"/admin/estadoSolicitud", alertConfirmacion:true}} />;
    }
    
    //function CambioEstadosPermitidos() {
    const CambioEstadosPermitidos = () => {
        switch(solicitud.idEstatusSolicitud) {
            case 0: //Pendiente
                return (
                    <div>
                        {getFieldDecorator("nuevoIdEstadoSol", {
                            rules: [{ required: true, message: "Dato requerido" }],
                        })(
                            <Select placeholder="Selecciona" style={{ width: 250 }} onChange={muestraMotivo}>
                                <Select.Option value={2}>Denegada</Select.Option>
                                <Select.Option value={3}>Declinada</Select.Option>
                                <Select.Option value={6}>No completó los requisitos</Select.Option>
                            </Select>
                        )}
                    </div>
                );
            case 1: //Registrado en otro sistema
                return (
                    <div>
                        {getFieldDecorator("nuevoIdEstadoSol", {
                            rules: [{ required: true, message: "Dato requerido" }],
                        })(
                            <Select placeholder="Selecciona" style={{ width: 250 }} onChange={muestraMotivo}>
                                <Select.Option value={0}>Pendiente</Select.Option>
                                <Select.Option value={2}>Denegada</Select.Option>
                                <Select.Option value={3}>Declinada</Select.Option>
                                <Select.Option value={6}>No completó los requisitos</Select.Option>
                            </Select>
                        )}
                    </div>
                );
            case 2: //Denegada
                for (let i = 0; i < solicitud.estadoCruce.cruce.length; i++) { //Validación de cruces
                    if(solicitud.estadoCruce.cruce[i].id !== 1) { //Tiene observaciones
                        return (
                            <div>
                                {getFieldDecorator("nuevoIdEstadoSol", {
                                    rules: [{ required: true, message: "Dato requerido" }],
                                })(
                                    <Select placeholder="Selecciona" style={{ width: 250 }} onChange={muestraMotivo}>
                                        <Select.Option value={0}>Pendiente</Select.Option>
                                        <Select.Option value={1}>Registrado en otro sistema</Select.Option>
                                    </Select>
                                )}
                            </div>
                        );
                    } else { //No tiene observaciones
                        return (
                            <div>
                                {getFieldDecorator("nuevoIdEstadoSol", {
                                    rules: [{ required: true, message: "Dato requerido" }],
                                })(
                                    <Select placeholder="Selecciona" style={{ width: 250 }} onChange={muestraMotivo}>
                                        <Select.Option value={0}>Pendiente</Select.Option>
                                    </Select>
                                )}
                            </div>
                        );
                    }
                }
                break;
            case 3: //Declinada
            if(solicitud.estadoCruce.cruce.length > 0){
                for (let i = 0; i < solicitud.estadoCruce.cruce.length; i++) { //Validación de cruces
                    if(solicitud.estadoCruce.cruce[i].id !== 1) { //Tiene observaciones
                        return (
                            <div>
                                {getFieldDecorator("nuevoIdEstadoSol", {
                                    rules: [{ required: true, message: "Dato requerido" }],
                                })(
                                    <Select placeholder="Selecciona" style={{ width: 250 }} onChange={muestraMotivo}>
                                        <Select.Option value={0}>Pendiente</Select.Option>
                                        <Select.Option value={1}>Registrado en otro sistema</Select.Option>
                                    </Select>
                                )}
                            </div>
                        );
                    } else { //No tiene observaciones
                        return (
                            <div>
                                {getFieldDecorator("nuevoIdEstadoSol", {
                                    rules: [{ required: true, message: "Dato requerido" }],
                                })(
                                    <Select placeholder="Selecciona" style={{ width: 250 }} onChange={muestraMotivo}>
                                        <Select.Option value={0}>Pendiente</Select.Option>
                                    </Select>
                                )}
                            </div>
                        );
                    }
                }
            }else{
                return (
                    <div>
                        {getFieldDecorator("nuevoIdEstadoSol", {
                            rules: [{ required: true, message: "Dato requerido" }],
                        })(
                            <Select placeholder="Selecciona" style={{ width: 250 }} onChange={muestraMotivo}>
                                <Select.Option value={0}>Pendiente</Select.Option>
                            </Select>
                        )}
                    </div>
                );
            }
                break;
            case 4: //Aprobada
                return (
                    <div>
                        {getFieldDecorator("nuevoIdEstadoSol", {
                            rules: [{ required: true, message: "Dato requerido" }],
                        })(
                            <Select placeholder="Selecciona" style={{ width: 250 }} onChange={muestraMotivo}>
                                <Select.Option value={5}>Cancelada</Select.Option>
                            </Select>
                        )}
                    </div>
                );
            case 5: //Cancelada
                return (
                    <div>
                        {getFieldDecorator("nuevoIdEstadoSol", {
                            rules: [{ required: true, message: "Dato requerido" }],
                        })(
                            <Select placeholder="Selecciona" style={{ width: 250 }} onChange={muestraMotivo}>
                                <Select.Option value={4}>Aprobada</Select.Option>
                            </Select>
                        )}
                    </div>
                );
            case 6: //No completó los requisitos
                for (let i = 0; i < solicitud.estadoCruce.cruce.length; i++) { //Validación de cruces
                    if(solicitud.estadoCruce.cruce[i].id !== 1) { //Tiene observaciones
                        return (
                            <div>
                                {getFieldDecorator("nuevoIdEstadoSol", {
                                    rules: [{ required: true, message: "Dato requerido" }],
                                })(
                                    <Select placeholder="Selecciona" style={{ width: 250 }} onChange={muestraMotivo}>
                                        <Select.Option value={0}>Pendiente</Select.Option>
                                        <Select.Option value={1}>Registrado en otro sistema</Select.Option>
                                    </Select>
                                )}
                            </div>
                        );
                    } else { //No tiene observaciones
                        return (
                            <div>
                                {getFieldDecorator("nuevoIdEstadoSol", {
                                    rules: [{ required: true, message: "Dato requerido" }],
                                })(
                                    <Select placeholder="Selecciona" style={{ width: 250 }} onChange={muestraMotivo}>
                                        <Select.Option value={0}>Pendiente</Select.Option>
                                    </Select>
                                )}
                            </div>
                        );
                    }
                }
                break;
            default:
                return (
                    <div>Error al definir el estado de la solicitud</div>
                );
        }
    }

    function muestraMotivo(value) {
        switch(value) {
            case 0: //Pendiente
                setMuestraMotivoComboDenegada(false);
                setMuestraMotivoComboCancelada(false);
                for (let i = 0; i < solicitud.estadoCruce.cruce.length; i++) { //Validación de cruces
                    if(solicitud.estadoCruce.cruce[i].id !== 1) {
                        setMuestraMotivoCajaTexto(true);
                        break;
                    } else {
                        setMuestraMotivoCajaTexto(false);
                        break;
                    }
                }
                break;
            case 2: //Denegada
                setMuestraMotivoComboDenegada(true);
                setMuestraMotivoComboCancelada(false);
                setMuestraMotivoCajaTexto(false);
                break;
            case 5: //Cancelada
                setMuestraMotivoComboDenegada(false);
                setMuestraMotivoComboCancelada(true);
                setMuestraMotivoCajaTexto(false);
                break;
            default: //Para los que no tienen que seleccionar nada de justificación o combo
                setMuestraMotivoComboDenegada(false);
                setMuestraMotivoComboCancelada(false);
                setMuestraMotivoCajaTexto(false);
                break;
        }
    }

    const modificaEstado = (event) => {
        event.preventDefault();
        props.form.validateFields((err, values) => {
            if(!err) {
                setBotonDeshabilitado(true);
                let idMotivo = null;
                let justificacion = null;
                if(values.nuevoIdMotivo !== undefined) {
                    idMotivo = values.nuevoIdMotivo;
                }
                if(values.nuevaJustificacion) {
                    justificacion = values.nuevaJustificacion;
                }
                actualizaEstado(solicitud.idObservador, values.nuevoIdEstadoSol, idMotivo, justificacion, user.sub,
                    solicitud.idProceso, history);
            } else {
                message.info("Los datos con ( * ) son requeridos.");
            }
        });
    };

    return (
        <div>
            <Form onSubmit={modificaEstado}>
                <Row>
                    <Link to={"/admin/estadoSolicitud"} className="back">
                        <Icon type="play-circle" theme="filled" rotate="180" /> Volver a 
                        "Estado de las solicitudes"
                    </Link>
                </Row>
                <Row style={{ marginTop:"20px"}}>
                    <h1>Cambiar estado</h1>
                </Row>
                <Row>
                    <p>Los datos con ( <Ast /> ) son requeridos</p>
                </Row>
                <Row>
                    Nombre: <strong>{solicitud.nombreCompleto}</strong><br />
                    Clave de elector: <strong>{solicitud.claveElector}</strong><br />
                    Folio: <strong>{solicitud.folio}</strong><br /><br />
                    Estado actual: <strong>{solicitud.descripcion}</strong>
                </Row>
                <Row style={{ marginTop:"20px"}}>
                    <Ast /> Estado a asignar <br />
                    <CambioEstadosPermitidos />
                </Row>
                <Row style={{ marginTop:"20px"}}>
                    {muestraMotivoComboDenegada && (
                        <div>
                            <Form.Item>
                                <Ast /> Motivo de denegación <br />
                                {getFieldDecorator("nuevoIdMotivo", {
                                    rules: [{ required: true, message: "Dato Requerido" }],
                                })(
                                    <Select placeholder="Selecciona" style={{ width: 600 }} optionFilterProp="children">
                                        {catalogoMotivosDenegada.map((item) => (
                                            <Select.Option key={item.idMotivo} style={{ whiteSpace:"normal" }}>
                                                {item.motivo}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </div>
                    )}
                    {muestraMotivoComboCancelada && (
                        <div>
                            <Form.Item>
                                <Ast /> Motivo de cancelación <br />
                                {getFieldDecorator("nuevoIdMotivo", {
                                    rules: [{ required: true, message: "Dato Requerido" }],
                                })(
                                    <Select placeholder="Selecciona" style={{ width: 600 }} optionFilterProp="children">
                                        {catalogoMotivosCancelada.map((item) => (
                                            <Select.Option key={item.idMotivo} style={{ whiteSpace:"normal" }}>
                                                {item.motivo}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </div>
                    )}
                    {muestraMotivoCajaTexto && (
                        <div>
                            <Form.Item>
                                <Alert type="info" showIcon
                                    message="La solicitud tiene observaciones con otros sistemas, 
                                    favor de ingresar la justificación del cambio o seleccione otro estado."/>
                                <Ast /> Justifica el cambio <br />
                                {getFieldDecorator("nuevaJustificacion", {
                                    rules: [{ required: true, message: "Dato Requerido" }],
                                })(
                                    <TextArea rows={4} maxLength={600} style={{ width: 600 }} />
                                )}
                            </Form.Item>
                        </div>
                    )}
                </Row>
                <Row style={{ marginTop:"10px" }}>
                    <Col span={12} style={{ paddingRight:"10px" }}>
                        <Form.Item style={{ textAlign:"right" }}>
                            <Button type="default">
                                <Link to={"/admin/estadoSolicitud"}>Cancelar</Link>
                            </Button>
                        </Form.Item>
                    </Col>
                    <Col span={12} style={{ paddingLeft:"10px" }}>
                        <Form.Item style={{ textAlign:"left" }}>
                            <Button type="primary" htmlType="submit" disabled={botonDeshabilitado || rolModulo.cau}
                                    onClick={() => {}} >
                                Cambiar estado
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

const StatusChangeForm = Form.create()(StatusChange);
export default withRouter(StatusChangeForm);
