import React, { useState } from "react";
import { Col, Row } from "antd";
import { withRouter } from "react-router-dom";
import { useEffect } from "react";
import { getEntitiesOnline } from "../../../../api/courses";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanlastLesson,
  cleanMenu,
  entities,
} from "../../../../redux/actions/course";
import useAuth from "../../../../hooks/useAuth";
import {
  RenderEntities,
  Back,
} from "../../../../components/User/CourseOnline/Generals";
import "./Courses.scss";
import Loading from "../../../../components/Generals/Loading";
import { RenderCards } from "../../../../components/User/CourseOnline/Cards";
import { cleanEntity, cleanEntityMat } from "../../../../redux/actions/entity";
import tituloImg from "../../../../assets/img/CourseOnline/titulo.jpg";

/**
 * Pantalla 1 - Pantalla principal de los cursos en línea
 * Componente que muestra la introducción a los cursos en línea
 * path: /ciudadano/capacitacion
 */
function Training(props) {
  const { history } = props;
  const row = 18;
  const dispatch = useDispatch(); //dispatch para ejecutar la acción del reducer
  const { entidades } = useSelector((state) => state.entidades); //Se obtienen las entidades del state
  const [loading, setLoading] = useState(true);
  //Se obtiene la información del ciudadano
  const {
    ciudadano: {
      ciudadano: { id },
    },
  } = useAuth();

  useEffect(() => {
    if (id) {
      window.scrollTo(0, 0); // para posicionar el scroll al inicio
      //Se obtiene la entidad o entidades a observar por parte del ciudadano
      getEntitiesOnline(id)
        .then((res) => {
          // console.log(res.data);
          dispatch(entities(res.data.entidades));
          setLoading(false);
        })
        .catch((e) => {
          console.log("Error", e);
          setLoading(false);
        });      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //Se limpian las variables del storte-Redux
    dispatch(cleanMenu());
    dispatch(cleanEntity());
    dispatch(cleanlastLesson());
    dispatch(cleanEntityMat());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Back path="/ciudadano/inicio" />
      <h1 style={{ marginTop: 30 }}>Capacitación en línea</h1>
      <hr />
      <div style={{ marginTop: 20 }}>
        <Row gutter={24} className="texto_parrafo">
          <Col span={row} xxl={row} xl={row} lg={row} md={row} sm={24} xs={24}>
            <img src={tituloImg} alt="img" className="imagen" />
            <br/>
            <p style={{ textAlign: "justify" }}>
              Mediante esta capacitación conocerás el marco general del sistema electoral y la organización de la elección local de:
            </p>
            {/* Entidad o entidades en forma de lista */}
            <RenderEntities entidades={entidades} />
            <p>
              Podrás realizar las actividades desde tu computadora, laptop, tableta o celular y avanzar a tu propio ritmo, 
              tomar descansos y retomar el curso en la lección donde te quedaste.
            </p>
            <p>
              El curso contiene material audiovisual, asegúrate de contar con un dispositivo que te permita reproducir audio como altavoces o audífonos.
            </p>
            <p>
              La capacitación es un requisito que forma parte del procedimiento para poder obtener tu acreditación como observadora u observador electoral.
            </p>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={row} xxl={row} xl={row} lg={row} md={row} sm={24} xs={24}>
            <div className="texto_parrafo">
              <h2>Modo de evaluación</h2>
              {/*<p>
                En cada lección del curso encontrarás explicaciones generales y
                distintos materiales de apoyo.
              </p>*/}
              {entidades.length > 1 && (
                <p>
                  Debido a que las particularidades de las elecciones locales son distintas de una entidad a otra, 
                  deberás realizar el curso correspondiente a cada entidad en la que desees desempeñar tu función, 
                  sin embargo, existen lecciones que no será necesario que desarrolles, únicamente aquellas en las que hay diferencias.
                </p>
              )}
              <p>
                Al finalizar cada lección habrá una actividad de autoevaluación, la cual deberás contestar para avanzar 
                a la siguiente lección. Podrás responder las actividades de autoevaluación de las lecciones las veces 
                que consideres necesarias pues están diseñadas para reforzar tu aprendizaje.
              </p>
              <p>
                Para concluir el curso deberás realizar un ejercicio final, el cual podrás responder las veces que 
                consideres necesarias. Tendrás que obtener una calificación mínima de 7 para obtener tu constancia de capacitación.
              </p>
              {/* Loading - Muestra las entidades en el componente de Card con su respectiva ilustración */}
              {loading ? (
                <Loading />
              ) : (
                <RenderCards
                  entidades={entidades}
                  idObservador={id}
                  history={history}
                />
              )}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default withRouter(Training);
