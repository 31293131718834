import React, { useEffect, useState } from "react";
import { Button, Icon, Row } from "antd";
import { Link, Redirect, withRouter } from "react-router-dom";
import moment from "moment";
import CursoIne from "../../../assets/img/Attendance/cursoINE_icon.svg";
import CursoOPL from "../../../assets/img/Attendance/cursoOPL_icon.svg";
import CasaIcon from "../../../assets/img/Attendance/casa_icon.svg";
import EdificioINE from "../../../assets/img/Attendance/edificioINE_icon.svg";
import { getAsistenciaCurso } from "../../../api/attendance";
import TableAttendance from "../../../components/Admin/Attendance/TableAttendance";
import { validaModuloAbierto } from "../../../components/Generals/functions";
import { MOD_REGISTRO_ASIST } from "../../../utils/constanst";

function AttendanceQuery(props) {
    const {
        curso, proceso, rolModulo
    } = props.location;
    const [ asistenciaCurso, setAsistenciaCurso ] = useState({
        info: [],
        loading: false,
    });
    const [moduloAbierto, setModuloAbierto] = useState(false); //Para validar si el módulo esa abierto o cerrado

    useEffect(() => {
        if(curso) {
            setModuloAbierto(validaModuloAbierto(MOD_REGISTRO_ASIST));
            setAsistenciaCurso({ loading:true }); //Mostrar el loading en la tabla
            getAsistenciaCurso(proceso.proceso.idProcesoElectoral, proceso.proceso.idDetalleProceso,
                    curso.idCurso).then((res) => {
                        const dataSource = [];
                        res.data.voParticipantesCurso.map((req, index) => {
                            return dataSource.push({
                                key: index,
                                indice: index + 1,
                                ...req,
                            });
                        });
                setAsistenciaCurso({ info:dataSource, loading:false });
            }).catch((error) => {
                console.log(error);
            });
        }
    }, [curso, proceso]);

    //Redirecciona a la pantalla pricipal en caso de que no exista información en la variable curso
    if(!curso) {
        return <Redirect to={"/admin/asistencia"} />;
    }

    const CursoImpartidoPor = () => {
        switch(curso.institucion) {
            case 0: //Impartido por el INE
                return (
                    <div>
                        <h5>Curso&nbsp;&nbsp;&nbsp;&nbsp;<img src={CursoIne} alt="cursoIne"/>&nbsp;{curso.impartidoPor}</h5>
                    </div>
                );
            case 1: //Impartido por el OPL
                return (
                    <div>
                        <h5>Curso&nbsp;&nbsp;&nbsp;&nbsp;<img src={CursoOPL} alt="cursoOPL"/>&nbsp;{curso.impartidoPor}</h5>
                    </div>
                );
            case 2: //Impartido por la Organización
                return (
                    <div>
                        <h5>
                            Curso&nbsp;&nbsp;&nbsp;&nbsp;<Icon type="team" />&nbsp;{curso.impartidoPor}
                        </h5>
                    </div>
                );
            default:
                return (
                    <div>
                        <h5>Curso&nbsp;&nbsp;&nbsp;&nbsp;{curso.impartidoPor}</h5>
                    </div>
                );
        }
    }

    const CursoDomicilio = () => {
        if(curso.sede === 0) { //Domicilio de la junta
            return (
                <div>
                    en&nbsp;&nbsp;<img src={EdificioINE} alt="edificioINE"/> {curso.descSede}
                </div>
            );
        } else { //Otro domicilio
            return (
                <div>
                    en&nbsp;&nbsp;<img src={CasaIcon} alt="casaIcon"/> {curso.descSede}
                </div>
            );
        }
    }

    return(
        <div>
            <Row>
                <Link to={"/admin/asistencia"} className="back">
                    <Icon type="play-circle" theme="filled" rotate="180" /> Volver a 
                    "Registrar de asistencia"
                </Link>
            </Row>
            <Row style={{ marginTop:"20px"}}>
                <h2>Consultar asistencia</h2>
            </Row>
            <Row style={{ marginTop:"10px"}}>
                <CursoImpartidoPor />
            </Row>
            <Row>
                Impartido el&nbsp;&nbsp;{curso.fechaCurso}&nbsp;
                a las&nbsp;&nbsp;{moment(curso.horaInicial).format("HH:mm")}&nbsp;
                por&nbsp;&nbsp;{curso.nombreInstructor}
            </Row>
            <Row>
                <CursoDomicilio />
            </Row>
            <Row style={{ marginTop:"10px"}}>
                <h5>Asistentes al curso</h5>
            </Row>
            <Row>
                <TableAttendance data={asistenciaCurso.info}
                    loading={asistenciaCurso.loading}
                />
            </Row>
            <Row style={{ textAlign:"center" }}>
                <Button type="primary" style={{ width: 175 }} disabled={!rolModulo.captura || rolModulo.rolJL || !moduloAbierto}>
                    <Link to={{ pathname:"/admin/asistencia/modificar",
                            curso:curso, proceso:proceso, rolModulo:rolModulo}}>
                        Modificar asistencia
                    </Link>
                </Button>
            </Row>
        </div>
    );
}

export default withRouter(AttendanceQuery);
