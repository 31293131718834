import React from "react";
import { Row, Col } from "antd";
import { ReactComponent as LogoRevision } from "../../../../assets/img/Portal/revisionSolicitud.svg";
import { ReactComponent as LogoPresencialEspera } from "../../../../assets/img/Portal/solVerificada_img.svg";
import { ReactComponent as LogoCursoAprobado } from "../../../../assets/img/Portal/cursoAprobado.svg";

export function NoObservation(props) {
  /* const {
    ciudadano,
  } = props; */

  return (
    <div className="home mensaje">
      <Row gutter={24}>
        <Col xxl={18} xl={18} lg={18} md={16} sm={24} xs={24}>
          <h1>Tus datos corregidos están en revisión</h1>
          <p style={{ fontSize: 18 }}>
            Se te informará el resultado de la revisión mediante el portal y por
            correo electrónico.
          </p>
          {/* {ciudadano.id && (
            <Link to={{ pathname: "/ciudadano/solicitud/modificar", state: true, modificar: true }}>
              <Button type="primary" style={{width:200}}>Modificar solicitud</Button>
            </Link>
          )} */}
        </Col>
        <Col xxl={6} xl={6} lg={6} md={8} sm={0} xs={0}>
          <LogoRevision className="logo" />
        </Col>
      </Row>
    </div>
  );
}

export function PresencialEspera() {
  return (
    <div className="home mensaje">
      <Row gutter={24}>
        <Col xxl={18} xl={18} lg={18} md={16} sm={24} xs={24}>
          <h1>Tu solicitud ha sido verificada</h1>
          <p style={{ fontSize: 18 }}>
            Puedes continuar con tu proceso de acreditación.
          </p>
          <p style={{ fontSize: 18 }}>
            Se te notificará por este medio y por correo electrónico la fecha y hora del curso de capacitación.
          </p>
        </Col>
        <Col xxl={6} xl={6} lg={6} md={8} sm={0} xs={0}>
          <LogoPresencialEspera className="logo" />
        </Col>
      </Row>
    </div>
  );
}

export function FinalizaCurso() {
  return (
    <div className="home mensaje">
      <Row gutter={24}>
        <Col xxl={18} xl={18} lg={18} md={16} sm={24} xs={24}>
          <h1>Has aprobado tu curso de capacitación</h1>
          <p style={{ fontSize: 18 }}>
          Al cumplir con todos los requisitos, el Consejo Local o Distrital determinará la aprobación de tu solicitud, 
          por lo que serás notificado y podrás descargar tus documentos de acreditación desde este Portal.
          </p>
          {/*<p style={{ fontSize: 18 }}>
          Si no existe un registro en los otros sistemas recibirás tu acreditación mediante la Junta Ejecutiva Local o Distrital encargado de tu seguimiento.
          </p>*/}
        </Col>
        <Col xxl={6} xl={6} lg={6} md={8} sm={0} xs={0}>
          <LogoCursoAprobado className="logo" />
        </Col>
      </Row>
    </div>
  );
}
