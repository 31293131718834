import React, { useState, useEffect } from "react";
import { Table } from "antd";
import { withRouter, Link } from "react-router-dom";
import moment from "moment";

function TableActions(props) {
  const {
    data, //Información del reporte de acciones
    loading,
    proceso,
    registroTabla,
    etiqueta,
  } = props;

  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    setDataSource(data);
  }, [data]);

  //Filtro JD
  /*     const FilterByFecha = (
            <>
                Fecha de realización
                <Input
                    placeholder="Fecha"
                    value={valueFec}
                    onChange={(e) => {
                        const currValue = e.target.value;
                        setValueFec(currValue);
                        const filteredData = data.filter((entry) =>
                            entry.fechaPromocion
                                .toString()
                                .toLowerCase()
                                .includes(currValue.toLowerCase())
                        );
                        setDataSource(filteredData);
                    }}
                />
            </>
        ); */

  //Definición de las columnas de nivel OC
  const columnasOC = [
    {
      title: "Entidad",
      dataIndex: "nombreEstadoopl",
      key: "nombreEstadoopl",
      width: 200,
      fixed: "left",
      render: (text, record) => (
        <Link
          to={{
            pathname: "/admin/reportes/acciones",
            registroTabla: record,
          }}
        >
          {text}
        </Link>
      ),
    },
    {
      title: "Total INE",
      dataIndex: "totaline",
      key: "totaline",
      width: 100,
    },
    {
      title: "Total OPL",
      dataIndex: "totalopl",
      key: "totalopl",
      width: 100,
    },
    {
      title: "Boletín de Prensa",
      children: [
        {
          title: "INE",
          dataIndex: "bPrensaine",
          key: "bPrensaine",
          width: 100,
        },
        {
          title: "OPL",
          dataIndex: "bPrensaopl",
          key: "bPrensaopl",
          width: 100,
        },
      ],
    },
    {
      title: "Cartel",
      children: [
        {
          title: "INE",
          dataIndex: "carteline",
          key: "carteline",
          width: 100,
        },
        {
          title: "OPL",
          dataIndex: "cartelopl",
          key: "cartelopl",
          width: 100,
        },
      ],
    },
    {
      title: "Colocación de mantas",
      children: [
        {
          title: "INE",
          dataIndex: "cMantaine",
          key: "cMantaine",
          width: 100,
        },
        {
          title: "OPL",
          dataIndex: "cMantaopl",
          key: "cMantaopl",
          width: 100,
        },
      ],
    },
    {
      title: "Colocación de stand",
      children: [
        {
          title: "INE",
          dataIndex: "cStandine",
          key: "cStandine",
          width: 100,
        },
        {
          title: "OPL",
          dataIndex: "cStandopl",
          key: "cStandopl",
          width: 100,
        },
      ],
    },
    {
      title: "Conferencias",
      children: [
        {
          title: "INE",
          dataIndex: "conferenciasine",
          key: "conferenciasine",
          width: 100,
        },
        {
          title: "OPL",
          dataIndex: "conferenciasopl",
          key: "conferenciasopl",
          width: 100,
        },
      ],
    },
    {
      title: "Contacto personal",
      children: [
        {
          title: "INE",
          dataIndex: "cPersonaline",
          key: "cPersonaline",
          width: 100,
        },
        {
          title: "OPL",
          dataIndex: "cPersonalopl",
          key: "cPersonalopl",
          width: 100,
        },
      ],
    },
    {
      title: "Correo electrónico",
      children: [
        {
          title: "INE",
          dataIndex: "correoine",
          key: "correoine",
          width: 100,
        },
        {
          title: "OPL",
          dataIndex: "correoopl",
          key: "correoopl",
          width: 100,
        },
      ],
    },
    {
      title: "Internet",
      children: [
        {
          title: "INE",
          dataIndex: "internetine",
          key: "internetine",
          width: 100,
        },
        {
          title: "OPL",
          dataIndex: "internetopl",
          key: "internetopl",
          width: 100,
        },
      ],
    },
    {
      title: "Invitación telefónica",
      children: [
        {
          title: "INE",
          dataIndex: "invTelefonicaine",
          key: "invTelefonicaine",
          width: 100,
        },
        {
          title: "OPL",
          dataIndex: "invTelefonicaopl",
          key: "invTelefonicaopl",
          width: 100,
        },
      ],
    },
    {
      title: "Oficio de invitación",
      children: [
        {
          title: "INE",
          dataIndex: "ofiInvitacionine",
          key: "ofiInvitacionine",
          width: 100,
        },
        {
          title: "OPL",
          dataIndex: "ofiInvitacionopl",
          key: "ofiInvitacionopl",
          width: 100,
        },
      ],
    },
    {
      title: "Otro",
      children: [
        {
          title: "INE",
          dataIndex: "otroine",
          key: "otroine",
          width: 100,
        },
        {
          title: "OPL",
          dataIndex: "otroopl",
          key: "otroopl",
          width: 100,
        },
      ],
    },
    {
      title: "Página del INE",
      children: [
        {
          title: "INE",
          dataIndex: "pIneine",
          key: "pIneine",
          width: 100,
        },
        {
          title: "OPL",
          dataIndex: "pIneopl",
          key: "pIneopl",
          width: 100,
        },
      ],
    },
    {
      title: "Perifoneo",
      children: [
        {
          title: "INE",
          dataIndex: "perifoneoine",
          key: "perifoneoine",
          width: 100,
        },
        {
          title: "OPL",
          dataIndex: "perifoneoopl",
          key: "perifoneoopl",
          width: 100,
        },
      ],
    },
    {
      title: "Pláticas de sensibilización",
      children: [
        {
          title: "INE",
          dataIndex: "pSensibilizacionine",
          key: "pSensibilizacionine",
          width: 100,
        },
        {
          title: "OPL",
          dataIndex: "pSensibilizacionopl",
          key: "pSensibilizacionopl",
          width: 100,
        },
      ],
    },
    {
      title: "Publicación de convocatoria",
      children: [
        {
          title: "INE",
          dataIndex: "pConvocatoriaine",
          key: "pConvocatoriaine",
          width: 100,
        },
        {
          title: "OPL",
          dataIndex: "pConvocatoriaopl",
          key: "pConvocatoriaopl",
          width: 100,
        },
      ],
    },
    {
      title: "Radio Local",
      children: [
        {
          title: "INE",
          dataIndex: "rLocaline",
          key: "rLocaline",
          width: 100,
        },
        {
          title: "OPL",
          dataIndex: "rLocalopl",
          key: "rLocalopl",
          width: 100,
        },
      ],
    },
    {
      title: "Redes Sociales",
      children: [
        {
          title: "INE",
          dataIndex: "redSocialine",
          key: "redSocialine",
          width: 100,
        },
        {
          title: "OPL",
          dataIndex: "redSocialopl",
          key: "redSocialopl",
          width: 100,
        },
      ],
    },
    {
      title: "Telegrama ciudadano",
      children: [
        {
          title: "INE",
          dataIndex: "tCiudadanoine",
          key: "tCiudadanoine",
          width: 100,
        },
        {
          title: "OPL",
          dataIndex: "tCiudadanoopl",
          key: "tCiudadanoopl",
          width: 100,
        },
      ],
    },
    {
      title: "Televisión",
      children: [
        {
          title: "INE",
          dataIndex: "televisionine",
          key: "televisionine",
          width: 100,
        },
        {
          title: "OPL",
          dataIndex: "televisionopl",
          key: "televisionopl",
          width: 100,
        },
      ],
    },
    {
      title: "Volanteo",
      children: [
        {
          title: "INE",
          dataIndex: "volanteoine",
          key: "volanteoine",
          width: 100,
        },
        {
          title: "OPL",
          dataIndex: "volanteoopl",
          key: "volanteoopl",
          width: 100,
        },
      ],
    },
    {
      title: "Rueda de prensa",
      children: [
        {
          title: "INE",
          dataIndex: "ruedaPreINE",
          key: "ruedaPreINE",
          width: 100,
        },
        {
          title: "OPL",
          dataIndex: "ruedaPreOPL",
          key: "ruedaPreOPL",
          width: 100,
        },
      ],
    },
    {
      title: "Tríptico",
      children: [
        {
          title: "INE",
          dataIndex: "tripticoINE",
          key: "tripticoINE",
          width: 100,
        },
        {
          title: "OPL",
          dataIndex: "tripticoOPL",
          key: "tripticoOPL",
          width: 100,
        },
      ],
    },
  ];

  //Definición de las columnas de nivel JL
  const columnasJL = [
    {
      title: "Junta Ejecutiva",
      dataIndex: "cabeceraDistrital",
      key: "cabeceraDistrital",
      width: 200,
      fixed: "left",
      render: (text, record) => (
        <Link
          to={{
            pathname: "/admin/reportes/acciones",
            registroTabla: record,
          }}
        >
          {text}
        </Link>
      ),
    },
    {
      title: "Total INE",
      dataIndex: "totaline",
      key: "totaline",
      width: 100,
    },
    {
      title: "Total OPL",
      dataIndex: "totalopl",
      key: "totalopl",
      width: 100,
    },
    {
      title: "Boletín de Prensa",
      children: [
        {
          title: "INE",
          dataIndex: "bPrensaine",
          key: "bPrensaine",
          width: 100,
        },
        {
          title: "OPL",
          dataIndex: "bPrensaopl",
          key: "bPrensaopl",
          width: 100,
        },
      ],
    },
    {
      title: "Cartel",
      children: [
        {
          title: "INE",
          dataIndex: "carteline",
          key: "carteline",
          width: 100,
        },
        {
          title: "OPL",
          dataIndex: "cartelopl",
          key: "cartelopl",
          width: 100,
        },
      ],
    },
    {
      title: "Colocación de mantas",
      children: [
        {
          title: "INE",
          dataIndex: "cMantaine",
          key: "cMantaine",
          width: 100,
        },
        {
          title: "OPL",
          dataIndex: "cMantaopl",
          key: "cMantaopl",
          width: 100,
        },
      ],
    },
    {
      title: "Colocación de stand",
      children: [
        {
          title: "INE",
          dataIndex: "cStandine",
          key: "cStandine",
          width: 100,
        },
        {
          title: "OPL",
          dataIndex: "cStandopl",
          key: "cStandopl",
          width: 100,
        },
      ],
    },
    {
      title: "Conferencias",
      children: [
        {
          title: "INE",
          dataIndex: "conferenciasine",
          key: "conferenciasine",
          width: 100,
        },
        {
          title: "OPL",
          dataIndex: "conferenciasopl",
          key: "coneerenciasopl",
          width: 100,
        },
      ],
    },
    {
      title: "Contacto personal",
      children: [
        {
          title: "INE",
          dataIndex: "cPersonaline",
          key: "cPersonaline",
          width: 100,
        },
        {
          title: "OPL",
          dataIndex: "cPersonalopl",
          key: "cPersonalopl",
          width: 100,
        },
      ],
    },
    {
      title: "Correo electrónico",
      children: [
        {
          title: "INE",
          dataIndex: "correoine",
          key: "correoine",
          width: 100,
        },
        {
          title: "OPL",
          dataIndex: "correoopl",
          key: "correoopl",
          width: 100,
        },
      ],
    },
    {
      title: "Internet",
      children: [
        {
          title: "INE",
          dataIndex: "internetine",
          key: "internetine",
          width: 100,
        },
        {
          title: "OPL",
          dataIndex: "internetopl",
          key: "internetopl",
          width: 100,
        },
      ],
    },
    {
      title: "Invitación telefónica",
      children: [
        {
          title: "INE",
          dataIndex: "invTelefonicaine",
          key: "invTelefonicaine",
          width: 100,
        },
        {
          title: "OPL",
          dataIndex: "invTelefonicaopl",
          key: "invTelefonicaopl",
          width: 100,
        },
      ],
    },
    {
      title: "Oficio de invitación",
      children: [
        {
          title: "INE",
          dataIndex: "ofiInvitacionine",
          key: "ofiInvitacionine",
          width: 100,
        },
        {
          title: "OPL",
          dataIndex: "ofiInvitacionopl",
          key: "ofiInvitacionopl",
          width: 100,
        },
      ],
    },
    {
      title: "Otro",
      children: [
        {
          title: "INE",
          dataIndex: "otroine",
          key: "otroine",
          width: 100,
        },
        {
          title: "OPL",
          dataIndex: "otroopl",
          key: "otroopl",
          width: 100,
        },
      ],
    },
    {
      title: "Página del INE",
      children: [
        {
          title: "INE",
          dataIndex: "pIneine",
          key: "pIneine",
          width: 100,
        },
        {
          title: "OPL",
          dataIndex: "pIneopl",
          key: "pIneopl",
          width: 100,
        },
      ],
    },
    {
      title: "Perifoneo",
      children: [
        {
          title: "INE",
          dataIndex: "perifoneoine",
          key: "perifoneoine",
          width: 100,
        },
        {
          title: "OPL",
          dataIndex: "perifoneoopl",
          key: "perifoneoopl",
          width: 100,
        },
      ],
    },
    {
      title: "Pláticas de sensibilización",
      children: [
        {
          title: "INE",
          dataIndex: "pSensibilizacionine",
          key: "pSensibilizacionine",
          width: 100,
        },
        {
          title: "OPL",
          dataIndex: "pSensibilizacionopl",
          key: "pSensibilizacionopl",
          width: 100,
        },
      ],
    },
    {
      title: "Publicación de convocatoria",
      children: [
        {
          title: "INE",
          dataIndex: "pConvocatoriaine",
          key: "pConvocatoriaine",
          width: 100,
        },
        {
          title: "OPL",
          dataIndex: "pConvocatoriaopl",
          key: "pConvocatoriaopl",
          width: 100,
        },
      ],
    },
    {
      title: "Radio Local",
      children: [
        {
          title: "INE",
          dataIndex: "rLocaline",
          key: "rLocaline",
          width: 100,
        },
        {
          title: "OPL",
          dataIndex: "rLocalopl",
          key: "rLocalopl",
          width: 100,
        },
      ],
    },
    {
      title: "Redes Sociales",
      children: [
        {
          title: "INE",
          dataIndex: "redSocialine",
          key: "redSocialine",
          width: 100,
        },
        {
          title: "OPL",
          dataIndex: "redSocialopl",
          key: "redSocialopl",
          width: 100,
        },
      ],
    },
    {
      title: "Telegrama ciudadano",
      children: [
        {
          title: "INE",
          dataIndex: "tCiudadanoine",
          key: "tCiudadanoine",
          width: 100,
        },
        {
          title: "OPL",
          dataIndex: "tCiudadanoopl",
          key: "tCiudadanoopl",
          width: 100,
        },
      ],
    },
    {
      title: "Televisión",
      children: [
        {
          title: "INE",
          dataIndex: "televisionine",
          key: "televisionine",
          width: 100,
        },
        {
          title: "OPL",
          dataIndex: "televisionopl",
          key: "televisionopl",
          width: 100,
        },
      ],
    },
    {
      title: "Volanteo",
      children: [
        {
          title: "INE",
          dataIndex: "volanteoine",
          key: "volanteoine",
          width: 100,
        },
        {
          title: "OPL",
          dataIndex: "volanteoopl",
          key: "volanteoopl",
          width: 100,
        },
      ],
    },
    {
      title: "Rueda de prensa",
      children: [
        {
          title: "INE",
          dataIndex: "ruedaPreINE",
          key: "ruedaPreINE",
          width: 100,
        },
        {
          title: "OPL",
          dataIndex: "ruedaPreOPL",
          key: "ruedaPreOPL",
          width: 100,
        },
      ],
    },
    {
      title: "Tríptico",
      children: [
        {
          title: "INE",
          dataIndex: "tripticoINE",
          key: "tripticoINE",
          width: 100,
        },
        {
          title: "OPL",
          dataIndex: "tripticoOPL",
          key: "tripticoOPL",
          width: 100,
        },
      ],
    },
  ];
  //Definición de las columnas de nivel JD
  const columnasJD = [
    {
      title: "#",
      dataIndex: "indice",
      key: "indice",
      width: 60,
      align: "center",
    },
    {
      title: "Medio de Comunicación",
      dataIndex: "medio",
      key: "medio",
    },
    {
      title: "Fecha de realización",
      dataIndex: "fechaRealizacion",
      key: "fechaRealizacion",
      render: (record) => moment(record).format("DD/MM/YYYY"), //Formato a la fecha
    },
    {
      title: "Realizada por",
      dataIndex: "rol",
      key: "rol",
    },
    {
      title: "Grupo al que va dirigido la acción",
      dataIndex: "dgrupo",
      key: "dgrupo",
    },
    {
      title: "Descripción",
      dataIndex: "descripcion",
      key: "descripcion",
    },
  ];

  //Tabla a renderizar
  const TipoTabla = () => {
    if (registroTabla !== undefined && registroTabla !== null) {
      if (
        registroTabla.idDistritoopl !== undefined &&
        registroTabla.idDistritoopl !== null
      ) {
        if (registroTabla.idDistritoopl !== 0) {
          return (
            <Table
              columns={columnasJD}
              dataSource={dataSource}
              loading={loading}
              locale={{ emptyText: "Sin registros" }}
              size="small"
            />
          );
        } else {
          return (
            <Table
              columns={columnasJD}
              dataSource={dataSource}
              loading={loading}
              locale={{ emptyText: "Sin registros" }}
              size="small"
              scroll={{ x: 1700 }}
            />
          );
        }
      } else if (
        registroTabla.idEstadoopl !== undefined &&
        registroTabla !== null
      ) {
        if (registroTabla.idEstadoopl !== 0) {
          return (
            <Table
              columns={columnasJL}
              dataSource={dataSource}
              loading={loading}
              locale={{ emptyText: "Sin registros" }}
              size="small"
              scroll={{ x: 1700 }}
            />
          );
        } else {
          return (
            <Table
              columns={columnasOC}
              dataSource={dataSource}
              loading={loading}
              locale={{ emptyText: "Sin registros" }}
              size="small"
              scroll={{ x: 1700 }}
            />
          );
        }
      } else {
        return (
          <Table
            columns={columnasOC}
            dataSource={dataSource}
            loading={loading}
            locale={{ emptyText: "Sin registros" }}
            size="small"
            scroll={{ x: 1700 }}
          />
        );
      }
    } else {
      if (etiqueta !== undefined && etiqueta !== null) {
        if (etiqueta !== 0) {
          return (
            <Table
              columns={columnasJL}
              dataSource={dataSource}
              loading={loading}
              locale={{ emptyText: "Sin registros" }}
              size="small"
              scroll={{ x: 1700 }}
            />
          );
        } else {
          return (
            <Table
              columns={columnasOC}
              dataSource={dataSource}
              loading={loading}
              locale={{ emptyText: "Sin registros" }}
              size="small"
              scroll={{ x: 1700 }}
            />
          );
        }
      } else {
        if (proceso.distrito !== undefined && proceso.distrito !== null) {
          if (proceso.distrito.idDistrito !== 0) {
            return (
              <Table
                columns={columnasJD}
                dataSource={dataSource}
                loading={loading}
                locale={{ emptyText: "Sin registros" }}
                size="small"
              />
            );
          } else {
            return (
              <Table
                columns={columnasJL}
                dataSource={dataSource}
                loading={loading}
                locale={{ emptyText: "Sin registros" }}
                size="small"
                scroll={{ x: 1700 }}
              />
            );
          }
        } else if (proceso.estado !== undefined && proceso.estado !== null) {
          if (proceso.estado.idEstado !== 0) {
            return (
              <Table
                columns={columnasJL}
                dataSource={dataSource}
                loading={loading}
                locale={{ emptyText: "Sin registros" }}
                size="small"
                scroll={{ x: 1700 }}
              />
            );
          } else {
            return (
              <Table
                columns={columnasOC}
                dataSource={dataSource}
                loading={loading}
                locale={{ emptyText: "Sin registros" }}
                size="small"
                scroll={{ x: 1700 }}
              />
            );
          }
          //} else if (proceso.proceso !== undefined && proceso.proceso !== null) {
        } else {
          return (
            <Table
              columns={columnasOC}
              dataSource={dataSource}
              loading={loading}
              locale={{ emptyText: "Sin registros" }}
              size="small"
              scroll={{ x: 1700 }}
            />
          );
        }
      }
    }
  };

  return (
    <div>
      <TipoTabla />
    </div>
  );
}

export default withRouter(TableActions);
