import { Button, Icon } from "antd";
import React, { useState, useEffect } from "react";
import { getMaterial } from "../../../api/courses";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../Generals/Loading";
import { ACT_FINAL, ACT_VALORACION } from "../../../utils/constanst";
import MainMaterial from "./MainMaterial";
import { setBlock } from "../../../redux/actions/entity";
// import ReactPlayer from "react-player";
import Viewers from "../../Generals/Viewer";

/**
 * Componente que muestra los material de acuerdo a la lección y a la entidad.
 * @param {*} array- lecciones
 */
export default function Material({ lesson, setLoading }) {
  const { idLeccion } = lesson;
  //Hook para almacenar la lista de materiales
  const [material, setMaterial] = useState({ loading: false, data: [] });
  //Se obtiene el idEntidad seleccionada del store de redux
  const { idEntidad } = useSelector((state) => state.entidad);
  const { loading, data } = material; // Destructuring del hook-materiales
  const dispatch = useDispatch();

  useEffect(() => {
    if (idLeccion && idLeccion !== ACT_FINAL && idLeccion !== ACT_VALORACION) {
      // console.log("Peticion", idLeccion);
      window.scrollTo(0, 0); // para posicionar el scroll al inicio
      setMaterial({ loading: true, data: [] }); //Se muestra el loading de cargando
      dispatch(setBlock(true));
      //Se hace la petición para obtener los materiales
      getMaterial(idEntidad, idLeccion)
        .then((res) => {
          setMaterial({ loading: false, data: res.data.material });
          dispatch(setBlock(false));
        })
        .catch((e) => {
          setMaterial({ loading: false, data: [] });
          dispatch(setBlock(true));
          console.log("Error Materiales", e);
        });
    } else {
      setMaterial({ data: [] });
      dispatch(setBlock(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idEntidad, idLeccion]);

  const Text = ({ idLesson }) => {
    if (idLesson !== ACT_FINAL && idLesson !== ACT_VALORACION) {
      return (
        <>
          <h1>Contenido de la lección</h1>
        </>
      );
    }
    return null;
  };

  return (
    <div>
      {idLeccion !== ACT_FINAL && idLeccion !== ACT_VALORACION ? (
        <>
          <h1>Introducción</h1>
          {idLeccion === 8 && idEntidad === 13 ?(
            <>
            <p>En esta lección te damos a conocer el sistema de medios de impugnación en materia electoral, es decir su interposición, resolución e impacto en los procesos electorales tanto federales como locales y autoridades que los conocen y resuelven. Así como las causales de nulidad en la elección federal y local.</p>
            </>
          ): (
            <>
            <p className="texto_parrafo">{lesson.introduccion}</p>
            </>
          )}
          <h1>Objetivo de aprendizaje</h1>
          <p className="texto_parrafo">{lesson.objetivo}</p>
          <Text idLesson={idLeccion} />
          {loading ? (
            <Loading />
          ) : (
            data.length && (
              <MainMaterial
                idLesson={idLeccion} //Se manda la lección
                data={data} //EL material
                setLoading={setLoading} //El loading para bloquear la pantalla
              />
            )
          )}
        </>
      ) : (
        <>
          <p>{lesson.introduccion}</p>
          {idLeccion === ACT_VALORACION && <p>¡Gracias por tu colaboración!</p>}
        </>
      )}
    </div>
  );
}

/**
 * Componente que muestra material de la lección
 * @param {*} doc - Material
 */
export const RenderDocument = ({ doc, number, getNumber }) => {
  switch (doc.tipoMaterial) {
    case 1:
      return <RenderVideo file={doc} />;
      //return <div><h1>Proximanete contarás con el video</h1></div>
    case 2:
      return <RenderPDF file={doc} />;
    case 3:
      return <RenderImg file={doc} />;
    default:
      return <RenderOther file={doc} number={number} getNumber={getNumber} />;
  }
};

/**
 * Componente que muestra el material si es tipo video.
 * @param {*} document- Material
 */
export const RenderVideo = ({ file }) => {
  return (
    <>
      {/* <MatApoyo array={[{ material: file.material }]} /> */}
      <div className="matTitle">Material de apoyo</div>
      <p className="matApoyo" style={{ textAlign: "left" }}>
        {file.material}
      </p>
      <iframe
        title={file.idMaterial}
        src={file?.url}
        width="100%"
        height="500"
        style={{ border: "none" }}
      />

      {/*Se repoducen los videos con la librería ReactPlayer*/}
      {/* <ReactPlayer className="video" url={file?.url} controls /> */}
    </>
  );
};
/**
 * Componente que muestra el material si es tipo imagen.
 * @param {*} document- Material
 */
const RenderImg = ({ file }) => {
  const image = [{ src: `data:image/jpeg;base64,${file.url}` }];
  const { modal } = useSelector((state) => state.modal); //Se obtiene el valor del store-Redux
  return (
    <>
      <div className="matTitle">Material de apoyo</div>
      <span className="matApoyo">{file.material}</span>
      {/* Componente para mostrar las imagen en el Viewer */}
      <div style={modal ? { pointerEvents: "none", opacity: "0.4" } : {}}>
        <div id={file.idMaterial} className="visorImage" />
        <Viewers container={file.idMaterial} image={image} />
      </div>

      {/* <img
        src={`data:image/jpeg;base64,${file.url}`}
        alt={file.idMaterial}
        className="imgMaterial"
      /> */}
    </>
  );
};

/**
 * Componente que muestra el material si es tipo pdf.
 * @param {*} document- Material
 */
const RenderPDF = ({ file }) => {
  function download(file, name) {
    let a = document.createElement("a"); //Create <a>
    a.href = `data:application/pdf;base64,${file}`; //Image Base64 Goes here
    a.download = `${name.trim()}.pdf`; //File name Here
    a.click(); //Downloaded file
  }
  return (
    <>
      <div className="matTitle">Material de apoyo</div>
      <p className="matApoyo" style={{ textAlign: "left" }}>
        {file.material}
      </p>
      <embed
        src={`data:application/pdf;base64,${file.url}`}
        type="application/pdf"
        width="100%"
        height="700px"
      />
      <button
        className="btnDescargar"
        style={{ marginLeft: "0px" }}
        onClick={(e) => download(file.url, file.material)}
      >
        Descargar {file.material} <Icon type="download" />
      </button>
    </>
  );
};

const RenderOther = ({ file, number, getNumber }) => {
  function download(file, name) {
    let a = document.createElement("a"); //Create <a>
    a.href = `data:application/vnd.ms-powerpoint;base64,${file}`; //Image Base64 Goes here
    a.download = `${name.trim()}.pptx`; //File name Here
    // console.log(`${name.trim()}.pptx`);
    a.click(); //Downloaded file
    /**
     * vnd.mspowerpoint
    let nameFile = `${name}.pptx`; //File name Here
    a.href = `data:application/vnd.ms-powerpoint;base64,${file}`; //Image Base64 Goes here
    a.download = nameFile.trim(); //File name Here
     */
  }
  return (
    <>
      {/* <MatApoyo array={[{ material: file.material }]} /> */}
      <div className="matTitle">Material de apoyo</div>
      <p className="matApoyo">
        {number && getNumber} {file.material}
      </p>
      <Button
        style={{ width: "auto" }}
        type="default"
        onClick={(e) => download(file.url, file.material)}
      >
        <Icon type="download" /> Descargar material
      </Button>
    </>
  );
};
