import React, { useEffect } from "react";
//import { radioButton } from "../../../utils/rules";
//import { Radio, Row, Form, Checkbox } from "antd";
import { useState } from "react";
import { getEntities } from "../../../api/requestPub";
import "./Steps.scss";

export const Entities = (props) => {
  const { /* getFieldDecorator ,*/ datos, /* handleChange ,*/ form, /* setDatos ,*/ disabledObs, observador } = props;
  const [entities, setEntities] = useState([]);

  useEffect(() => {
    //if (disabledObs && observador !== undefined && observador !== null) {
    if (observador.tipoEntidad !== undefined && observador.tipoEntidad !== null) {
      datos.entObservar = observador.tipoEntidad;
      form.setFieldsValue({
        entObservar: observador.tipoEntidad,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabledObs, observador]);

  useEffect(() => {
    //if (disabledObs && observador !== undefined && observador !== null && observador.entidadesObservar !== undefined 
    if (observador !== undefined && observador !== null && observador.entidadesObservar !== undefined 
      && observador.entidadesObservar !== null && entities.length > 0) {
        var entidadSeleccionada = [];
        for (let i = 0; i < observador.entidadesObservar.length; i++) {
          if (observador.entidadesObservar[i].checked) {
            entidadSeleccionada.push(observador.entidadesObservar[i].value);
          }
        }
        form.setFieldsValue({
          entidades: entidadSeleccionada,
        });
        datos.entidades = entidadSeleccionada;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entities, observador]);


  useEffect(() => {
    datos.entObservar = 1; //PARA EL PEL 2022 SE FORZA A TENER ESTA OPCIÓN PRESELECCIONADA
    if (datos.entObservar === 1) {
      getEntities(datos.idProceso,datos.idDetalle)
        .then((res) => {
          setEntities(res.data);
        })
        .catch((e) => {
          console.log("Error al obtener las entidades");
        });
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datos.entObservar]);

  //Función que obtiene las entidades seleccionadas
  /* function onChange(checkedValues) {
    setDatos((d) => ({ ...d, entidades: checkedValues }));
  } */

  return (
    <div>
      {/* <h3 style={{ marginTop: 30 }}>Entidades a observar</h3>
      <Row gutter={24}>
        <Form.Item label="¿En qué entidad o entidades deseas observar la Jornada Electoral">
          {getFieldDecorator("entObservar", {
            initialValue: datos.entObservar,
            rules: radioButton,
          })(
            <Radio.Group onChange={handleChange("entObservar")} disabled={disabledObs}>
              {/* <Radio value={0}>
                Sólo en la entidad de mi credencial
                <div className="textRadio">
                  (Tu credencial corresponde a {datos.entidad}).
                </div>
              </Radio> */}
              {/* <Radio value={1} style={{ marginLeft: 50 }}>
                En una o más entidades distintas
                <div className="textRadio">
                  <p></p>
                </div>
              </Radio>
            </Radio.Group>
          )}
        </Form.Item>
      </Row>
      {form.getFieldValue("entObservar") === 1 && (
        <Form.Item>
          {getFieldDecorator("entidades", {
            rules: radioButton,
          })(<Checkbox.Group options={entities} onChange={onChange} disabled={disabledObs}/>)}
        </Form.Item>
      )} */}
    </div>
  );
};
