import { useEffect, useState } from "react";

export const useLesson2 = (idEntidad, idParrafo) => {
  const [lesson, setLesson] = useState({});

  useEffect(() => {
    switch (idEntidad) {
      //completa aguscalientes
      case 1:
        switch (idParrafo) {
          case 1:
            setLesson({
              parrafo:"Gubernatura",
            });
            break;
          case 2:
            setLesson({
              parrafo:"El cargo que se elegirá se designará mediante el principio de: <b>Mayoría Relativa</b>",
            });
            break;
          case 3:
            setLesson({
              parrafo:"<b>Mayoría Relativa:</b> cuando gana el partido o candidatura que obtiene el mayor número de votos en determinada área geográfica.",
            });
            break;
          case 4:
            setLesson({
              parrafo:"",
            });
            break;
          case 5:
            setLesson({
              parrafo:"se implementa este principio",
            });
            break;
          case 6:
            setLesson({
              parrafo:"Locales y la Circunscripción electoral de la entidad",
            });
            break;
          case 7:
            setLesson({
              parrafo:"Instituto Estatal Electoral de Aguascalientes (IEE)",
            });
            break;
          case 8:
            setLesson({
              parrafo:"hay registrado 1 partido político",
            });
            break;
          case 9:
            setLesson({
              parrafo:"También pueden participar mediante candidaturas comunes",
            });
            break;
          case 10:
            setLesson({
              parrafo:"coalición o candidatura común",
            });
            break;
          case 11:
            setLesson({
              parrafo:"los partidos políticos, las coaliciones o candidaturas comunes",
            });
            break;
          default:
            return setLesson({});
        }
        break;
      //Completa Durango
      case 10:
        switch (idParrafo) {
          case 1:
            setLesson({
              parrafo:"Gubernatura y Ayuntamientos",
            });
            break;
          case 2:
            setLesson({
              parrafo:"Los cargos que se elegirán se designarán mediante dos principios: <b>Mayoría Relativa</b> y <b>Representación Proporcional:</b>",
            });
            break;
          case 3:
            setLesson({
              parrafo:"<b>Mayoría Relativa:</b> cuando gana el partido o candidatura que obtiene el mayor número de votos en determinada área geográfica.",
            });
            break;
          case 4:
            setLesson({
              parrafo:"<b>Representación Proporcional:</b> es la asignación de cargos con base en el porcentaje de votación general obtenida por cada partido político en una región geográfica.",
            });
            break;
          case 5:
            setLesson({
              parrafo:"se implementan estos principios",
            });
            break;
          case 6:
            setLesson({
              parrafo:"Locales",
            });
            break;
          case 7:
            setLesson({
              parrafo:"Instituto Electoral y de Participación Ciudadana del Estado de Durango (IEPC)",
            });
            break;
          case 8:
            setLesson({
              parrafo:"hay registrado 1 partido político",
            });
            break;
          case 9:
            setLesson({
              parrafo:"También pueden participar mediante candidaturas comunes",
            });
            break;
          case 10:
            setLesson({
              parrafo:"coalición o candidatura común",
            });
            break;
          case 11:
            setLesson({
              parrafo:"los partidos políticos, las coaliciones o candidaturas comunes",
            });
            break;
          default:
            return setLesson({});
        }
        break;
      //Completo Hidalgo
      case 13:
        switch (idParrafo) {
          case 1:
            setLesson({
              parrafo:"Gubernatura",
            });
            break;
          case 2:
            setLesson({
              parrafo:"El cargo que se elegirá se designará mediante el principio de: <b>Mayoría Relativa</b>",
            });
            break;
          case 3:
            setLesson({
              parrafo:"<b>Mayoría Relativa:</b> cuando gana el partido o candidatura que obtiene el mayor número de votos en determinada área geográfica.",
            });
            break;
          case 4:
            setLesson({
              parrafo:"",
            });
            break;
          case 5:
            setLesson({
              parrafo:"se implementa este principio",
            });
            break;
          case 6:
            setLesson({
              parrafo:"Federales y  Locales de la entidad",
            });
            break;
          case 7:
            setLesson({
              parrafo:"Instituto Estatal Electoral de Hidalgo (IEEH)",
            });
            break;
          case 8:
            setLesson({
              parrafo:"hay registrado 1 partido político",
            });
            break;
          case 9:
            setLesson({
              parrafo:"También pueden participar mediante candidaturas comunes",
            });
            break;
          case 10:
            setLesson({
              parrafo:"coalición o candidatura común",
            });
            break;
          case 11:
            setLesson({
              parrafo:"los partidos políticos, las coaliciones o candidaturas comunes",
            });
            break;
          default:
            return setLesson({});
        }
        break;
      //completa oaxaca
      case 20:
        switch (idParrafo) {
          case 1:
            setLesson({
              parrafo:"Gubernatura",
            });
            break;
          case 2:
            setLesson({
              parrafo:"El cargo que se elegirá se designará mediante el principio de: <b>Mayoría Relativa</b>",
            });
            break;
          case 3:
            setLesson({
              parrafo:"<b>Mayoría Relativa:</b> cuando gana el partido o candidatura que obtiene el mayor número de votos en determinada área geográfica.",
            });
            break;
          case 4:
            setLesson({
              parrafo:"",
            });
            break;
          case 5:
            setLesson({
              parrafo:"se implementa este principio",
            });
            break;
          case 6:
            setLesson({
              parrafo:"Locales y la Circunscripción electoral de la entidad",
            });
            break;
          case 7:
            setLesson({
              parrafo:"Instituto Estatal Electoral y de Participación Ciudadana de Oaxaca (IEEPCO)",
            });
            break;
          case 8:
            setLesson({
              parrafo:"hay registrados 2 partidos políticos",
            });
            break;
          case 9:
            setLesson({
              parrafo:"También pueden participar mediante candidaturas comunes",
            });
            break;
          case 10:
            setLesson({
              parrafo:"coalición o candidatura común ",
            });
            break;
          case 11:
            setLesson({
              parrafo:"los partidos políticos, las coaliciones o candidaturas comunes",
            });
            break;
          default:
            return setLesson({});
        }
        break;
      //completa Quintana Roo
      case 23:
        switch (idParrafo) {
          case 1:
            setLesson({
              parrafo:"Gubernatura y Diputaciones",
            });
            break;
          case 2:
            setLesson({
              parrafo:"Los cargos que se elegirán se designarán mediante dos principios: <b>Mayoría Relativa</b> y <b>Representación Proporcional:</b>",
            });
            break;
          case 3:
            setLesson({
              parrafo:"<b>Mayoría Relativa:</b> cuando gana el partido o candidatura que obtiene el mayor número de votos en determinada área geográfica.",
            });
            break;
          case 4:
            setLesson({
              parrafo:"<b>Representación Proporcional:</b> es la asignación de cargos con base en el porcentaje de votación general obtenida por cada partido político en una región geográfica.",
            });
            break;
          case 5:
            setLesson({
              parrafo:"se implementan estos principios",
            });
            break;
          case 6:
            setLesson({
              parrafo:"Federales y  Locales y la Circunscripción electoral de la entidad",
            });
            break;
          case 7:
            setLesson({
              parrafo:"Instituto Electoral de Quintana Roo (IEQROO)",
            });
            break;
          case 8:
            setLesson({
              parrafo:"existen 2 partidos políticos registrados ante el IEQROO",
            });
            break;
          case 9:
            setLesson({
              parrafo:"También pueden participar mediante candidaturas comunes",
            });
            break;
          case 10:
            setLesson({
              parrafo:"coalición o candidatura común",
            });
            break;
          case 11:
            setLesson({
              parrafo:"los partidos políticos, las coaliciones o candidaturas comunes",
            });
            break;
          default:
            return setLesson({});
        }
        break;
      //completa tamaulipas
      case 28:
        switch (idParrafo) {
          case 1:
            setLesson({
              parrafo:"Gubernatura",
            });
            break;
          case 2:
            setLesson({
              parrafo:"El cargo que se elegirá se designará mediante el principio de: <b>Mayoría Relativa</b>",
            });
            break;
          case 3:
            setLesson({
              parrafo:"<b>Mayoría Relativa:</b> cuando gana el partido o candidatura que obtiene el mayor número de votos en determinada área geográfica.",
            });
            break;
          case 4:
            setLesson({
              parrafo:"",
            });
            break;
          case 5:
            setLesson({
              parrafo:"se implementa este principio",
            });
            break;
          case 6:
            setLesson({
              parrafo:"Locales y la Circunscripción electoral de la entidad",
            });
            break;
          case 7:
            setLesson({
              parrafo:"Instituto Electoral de Tamaulipas (IETAM)",
            });
            break;
          case 8:
            setLesson({
              parrafo:"actualmente no existen partidos políticos locales registrados ante el IETAM",
            });
            break;
          case 9:
            setLesson({
              parrafo:"También pueden participar mediante candidaturas comunes",
            });
            break;
          case 10:
            setLesson({
              parrafo:"coalición o candidatura común",
            });
            break;
          case 11:
            setLesson({
              parrafo:"los partidos políticos, las coaliciones o candidaturas comunes",
            });
            break;
          default:
            return setLesson({});
        }
        break;
      default:
        return setLesson({});
    }
  }, [idEntidad,idParrafo]);
  return lesson;
};