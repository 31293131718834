import React from "react";
import { message, Icon, Modal } from "antd";
import { OBS_CRED_FRENTE, OBS_CRED_VUELTA, OBS_FOTOGRAFIA, OBS_DIF_PERSONA,
         OBS_NOMBRE, OBS_CLAVE_ELEC, OBS_DIRECCION, MSN_GUARDAR } from "../constanst";
import { updateChanges } from "../../api/requestPub";
import { declinaSolicitudPortal } from "../../api/status";

export const Ast = () =>  {
    return <span style={{ color: "#f5222d" }}>*</span>;
}

//Función que valida el tipo de imagen y tamaño.
export function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("Solo archivos en formato JPG/PNG!");
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M || isLt2M === 0) {
      message.error("¡Peso máximo de 5MB!");
      return false;
    }
    const nameFile = validateName(file.name);
    if (!nameFile) {
      message.error(
        "El nombre de archivo " +
          file.name +
          " es inválido. El nombre del documento no debe contener caracteres especiales."
      );
      return false;
    }
    return isJpgOrPng && isLt2M && nameFile;
}

function validateName(e) {
    var patron = /^[0-9a-zA-Z_-\s]+.[jpg/JPG/jpeg/JPEG/png/PNG]+$/;
    if (e.match(patron)) {
      return true;
    } else {
      return false;
    }
}

//Función que muestra la imagen en el input
export function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
}
//Boton que muestra la animación de cargando
export const uploadButton = loading => (
    <>
      <Icon type={loading ? "loading" : "plus"} />
      <div className="ant-upload-text">Cargar</div>
    </>
);

export function unlockSections(observaciones, setSecNombres, setSecClave, 
                              setSecDireccion, setSecCredFrente, setSecCredVuelta, setSecFotografia) {
  if (observaciones !== null) {
    observaciones.some(v => { 
      if (v.id.idObservacion === OBS_CRED_FRENTE) {
        setSecCredFrente({inhabilita:false, message:v.cobservaciones.observacion});
      }
      if (v.id.idObservacion === OBS_CRED_VUELTA) {
        setSecCredVuelta({inhabilita:false, message:v.cobservaciones.observacion});
      }
      if (v.id.idObservacion === OBS_FOTOGRAFIA) {
        setSecFotografia({inhabilita:false, message:v.cobservaciones.observacion});
      }
      if (v.id.idObservacion === OBS_DIF_PERSONA) {
        setSecFotografia({inhabilita:false, message:v.cobservaciones.observacion}); 
      }
      if (v.id.idObservacion === OBS_NOMBRE) {
        setSecNombres({inhabilita:false, message:v.observaciones}); 
      }
      if (v.id.idObservacion === OBS_CLAVE_ELEC) {
        setSecClave({inhabilita:false, message:v.observaciones});
      }
      if (v.id.idObservacion === OBS_DIRECCION) {
        setSecDireccion({inhabilita:false, message:v.observaciones});
      }
      return (null);
    });
  } 
}

export function validaApellidos(apellido, apellido2) {
  if (apellido || apellido2) {
    return true;
  }
  return false;
}

export function sendObservations(values, docFoto, docFrente, docVuelta, history, setButton, original, setLoader) {
const { confirm } = Modal;
  confirm({
     title:
       "Tus datos corregidos serán revisados, el resultado se te notificará mediante el portal y por correo electrónico.",
     icon: null,
     okText: "Enviar a revisión",
     cancelText: "Cancelar",
     cancelButtonProps: { className: "ant-btn-default" },
     onOk() {
      setLoader({ loading: true, message: MSN_GUARDAR }); //Se activa el loader
      const formData = new FormData();
      if (docFoto !== undefined) {
        formData.append("docFoto", docFoto);
      } else {
        formData.append("docFoto", null);
      }
      if (docFrente !== undefined) {
        formData.append("docFrente", docFrente);
      } else {
        formData.append("docFrente", null);
      }
      if (docVuelta !== undefined) {
        formData.append("docVuelta", docVuelta);
      } else {
        formData.append("docVuelta", null);
      }
      formData.append(
        "solicitud",
        new Blob([JSON.stringify(values)], { type: "application/json" })
      );
      formData.append(
        "original",
        new Blob([JSON.stringify(original)], { type: "application/json" })
      );
      update(formData, history, setButton, setLoader)
    },
    onCancel() {
      setButton(false);
    }
  });
}

//Actualiza la solicitud atendiendo las observaciones
function update(register, history, setButton, setLoader) {
  updateChanges(register).then(res => {
    if (res.data.code === 200) {
      setLoader({ loading: false, message: "" }); //Se desactiva el loader
      history.push("/ciudadano/inicio");
    } else {
      setLoader({ loading: false, message: "" }); //Se desactiva el loader
      Modal.info({
        title: res.data.code === 400 ? "¡ATENCIÓN!" : "¡LO SENTIMOS!",
        content: <p>{res.data.causa}</p>
      });
      setButton(false);
    }
  });
}

export const calulateNewHeight = (
  originalWidth,
  originalHeight,
  targetWidth
) => {
  const ratio = originalHeight / originalWidth;
  return ratio * targetWidth;
};

export function confirmaDeclinacion(correo, history, setBotonDeshabilitado, setLoader) {
  let titleText = <div>Lamentamos mucho que no desees continuar con el proceso.</div>;
  Modal.confirm({
    title: titleText,
    icon: null,
    content: "¿Estás seguro de realizar la declinación de tu solicitud?",
    okText: "Sí, declinar mi solicitud",
    cancelText: "Cancelar",
    okButtonProps: { style: { width: 200 } },
    onOk() {
      setLoader({ loading: true, message: MSN_GUARDAR }); //Se activa el loader
      declinaSolicitud(correo, history, setLoader);
    },
    onCancel() {
      setBotonDeshabilitado(false);
    }
  });
}

function declinaSolicitud(correo, history, setLoader) {
  declinaSolicitudPortal(correo).then((res) => {
    if(res.data.code === 200) {
      setLoader({ loading: false, message: "" }); //Se desactiva el loader
      history.push("/ciudadano/inicio");
    } else {
      setLoader({ loading: false, message: "" }); //Se desactiva el loader
      Modal.error({
        title: "Atención",
        content: res.data.message,
        onOk() {
          history.push("/ciudadano/inicio");
        }
      });
    }
  }).catch((err) => {
    console.log(err);
    setLoader({ loading: false, message: "" }); //Se desactiva el loader
    Modal.error({
      title: "Error",
      content: "Ocurrió un error, intentelo más tarde.",
      onOk() {
        history.push("/ciudadano/inicio");
      },
    });
  });
}
