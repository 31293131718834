import React, { useEffect, useRef } from "react";
import { Form, Row, Col, Select, Radio, Alert } from "antd";
import { required } from "../../../utils/rules";
import "./Steps.scss";
import { getByJuntaTablasLoc, getEntidadesSeg, getFuncionarios, getState } from "../../../api/requestPub";
import { adress } from "../../../utils/Request/functions";
import { radioButton } from "../../../utils/rules";
import { FUNCIONARIO_CONSEJERO } from "../../../utils/constanst";

export default function Additional(props) {
  //Se obtienen las funciones por props
  const {
    handleChange,
    getFieldDecorator,
    datos,
    changeSelect,
    form,
    setJuntas,    
    juntas, disabledObs, observador, modificar
  } = props; 
  const { Option } = Select;
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (datos.tipoObservador === 0) {
      if (datos.entidadJunta) {
        isMounted.current && getJuntas(datos.entidadJunta);
      } else {
        getEntidadesSeg().then((res) => {
          setJuntas((val) => ({ ...val, estados: res.data }));
        }).catch((error) => {
          console.log("Ocurrio un error", error);
        });
      }
    } else {
      if(datos.entidadJunta) {
        isMounted.current && getJuntas(datos.entidadJunta);
      } else {
        getEntidadesSeg().then((res) => {
          setJuntas((val) => ({ ...val, estados: res.data }));
        }).catch((error) => {
          console.log("Ocurrio un error", error);
        });
      }
    }
    //datos.capacitacion = 2; //Temporal en lo que se define el curso en línea
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datos.entidadJunta]);

  useEffect(() => {
    if (datos.entidadJunta && datos.juntaEjecutiva) {
      //se obtiene el distrito de la junta ejecutiva seleccionada
      let distrito = datos.juntaEjecutiva.split("|");
      //se manda el idEstado y el idDistrito para obtener el nombre del funcionario
      isMounted.current &&
        getFuncionario(parseInt(datos.entidadJunta), parseInt(distrito[0]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datos.entidadJunta, datos.juntaEjecutiva]);

  useEffect(() => {
    //if (disabledObs && observador !== undefined && observador !== null) {
    if (observador.idEstadoJunta !== undefined && observador.idEstadoJunta !== null) {
      getState()
      .then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].id.idEstado === observador.idEstadoJunta) {
            form.setFieldsValue({
              //entidadJunta: res.data[i].nombreEstado
              entidadJunta: res.data[i].id.idEstado
            });
            datos.entidadJunta = observador.idEstadoJunta;
            //Se carga en el combo de Entidad de seguimiento la entidad junta de la BD, esto por si ya cerró el registro y no
            //aparezca el idEntidadJunta, se solventa de esta forma al ya no poder modificar el entidad/junta de seguimiento
            setJuntas((val) => ({ ...val, estados: res.data }));
            datos.conEntidad = res.data[i].nombreEstado;
          }
        }
      })
      .catch((error) => {
        console.log("Ocurrio un error", error);
      });
      getByJuntaTablasLoc(observador.idEstadoJunta)
        .then((res) => {
          for (let i = 0; i < res.data.domicilios.length; i++) {
            if (res.data.domicilios[i].idDistritoFederal === observador.idDistritoFederal) {
              //const juntaResponsable = `${res.data.domicilios[i].nombreSede} ${res.data.domicilios[i].idDistritoFederal} - ${res.data.domicilios[i].direccion}`;
              const juntaValue = `${res.data.domicilios[i].idDistritoFederal}|${res.data.domicilios[i].nombreSede}|${res.data.domicilios[i].direccion}`;
              form.setFieldsValue({
                //juntaEjecutiva: juntaResponsable
                juntaEjecutiva: juntaValue
              });
              datos.juntaEjecutiva = juntaValue
              getFuncionario(parseInt(observador.idEstadoJunta), parseInt(res.data.domicilios[i].idDistritoFederal));
            }
          }
          setJuntas((val) => ({ ...val, juntas: res.data.domicilios }));
        })
        .catch((e) => {
          console.log(e);
        }); 
      form.setFieldsValue({
        capacitacion: observador.modalidad
      });
      datos.capacitacion = observador.modalidad;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabledObs, observador]);

  /**
   *
   * @param {*} idEntidad
   * Función que obtiene las juntas ejecutivas a partir del estado seleccionado
   */
  const getJuntas = (idEntidad) => {
    idEntidad &&
      getByJuntaTablasLoc(idEntidad)
        .then((res) => {
          setJuntas((val) => ({ ...val, juntas: res.data.domicilios }));
        })
        .catch((e) => {
          console.log(e);
        });
  };
  //////////////////////////////OBTIENE EL PRESIDENTE/CONSERO Y LA DIRECCIÓN DEL MISMO /////////////////////////////////
  /**
   *
   * @param {*} idEntidad
   * @param {*} idDistrito
   * Función que obtiene el nombre del funcionario del estado y junta seleccionada
   */
  const getFuncionario = (idEntidad, idDistrito) => {
    getFuncionarios(observador.idProcesoElectoral, observador.idDetalleProceso, idEntidad, idDistrito)
      .then((res) => {
        //Se filtra la respuesta para obtener el nombre del funcionario con el idPuesto 1
        const fun = getByFuncionario(
          FUNCIONARIO_CONSEJERO,
          res.data.integrantes
        );
        //Se valida que el funcionario exista
        if (fun) {
          //Destructuring del nombre del funcionario
          const {
            primerApellidoIntegrante,
            segundoApellidoIntegrante,
            nombreIntegrante,
          } = fun;
          //Se concate el nombre del funcionari y se almacena en el hook de datos
          datos.consejero = `${nombreIntegrante} ${primerApellidoIntegrante} ${segundoApellidoIntegrante}`;
          //Se obtiene la dirección del funcionario
        } else {
          //Se limpia el nombre del consejero en caso de que se haya almacenado información anteriormente
          datos.consejero = "";
        }
        const dir = getAdressFun(idEntidad, idDistrito);
        //Valida si es una junta local o distrital
        datos.conDistrito = idDistrito === 0 ? "Local" : "Distrital";
        //se valida que tenga información
        if (dir) {
          //Se almacenan los datos en el hook
          datos.conNumero = dir.idDistritoFederal;
          datos.conMunicipio = dir.nombreMunicipio;
          //datos.conEntidad = dir.nombreEstado; Se habilitará con el servicio de sesiones
        }
      })
      .catch((e) => {
        console.log(e);
        datos.consejero = "";
      });
  };

  //Función que obtiene el id y el nombre del estado de la junta seleccionada
  const getStateAdditional = (value, event) => {
    datos.entidadJunta = value; // El id del combo de la entidad junta
    datos.conEntidad = event.props.children; //Nombre del estado seleccionado
    getJuntas(value); //Se carga nuevamente el combo con los datos correspondientes
    //Se limpia el combo de junta ejecutiva
    form.setFieldsValue({ juntaEjecutiva: undefined });
    datos.juntaEjecutiva = undefined;
    //Para PEL2022 que desaparece la sección de etidades a observar 
    var entidadSeleccionada = [];
    entidadSeleccionada.push(value);
    datos.entidades = entidadSeleccionada;
  };

  /**
   *
   * @param {*} idEstado
   * @param {*} idDistrito
   * Función que retorna el json que concida con el idEstado y el idDistrito
   */
  const getAdressFun = (idEstado, idDistrito) =>
    //Busca en el arreglo de juntas
    juntas.juntas.find(
      (junta) =>
        junta.idDistritoFederal === idDistrito && junta.idEstado === idEstado
    );

  /**
   *
   * @param {*} idPuesto
   * @param {*} array
   * Función que recibe un JsonArray para buscar el funcionario de acuerdo al idPiuesto enviado
   */
  const getByFuncionario = (idPuesto, array) => {
    if (array) {
      return array.find((array) => array.idPuesto === idPuesto);
    }
    return undefined;
  };

  return (
    <Form style={{ marginTop: 30 }}>
      <h3>Datos adicionales</h3>
      <Row gutter={24}>
        {/* <Col span={8} xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}> */}
        <Form.Item label="Modalidad para la capacitación">
          {/*<Alert message={<p style={{ marginBottom: "0px" }}> Para la ---- podrás tomar el curso de manera presencial o de manera virtual a través de alguna herramienta o plataforma (Zoom, Google Meet, Microsoft Teams o algún otro).</p>} type="info" showIcon />*/}
        </Form.Item>
        {<Form.Item label=" ¿Cómo deseas tomar el curso de capacitación?">
          {getFieldDecorator("capacitacion", {
            initialValue: datos.capacitacion,
            rules: radioButton,
          })(
            <Radio.Group onChange={handleChange("capacitacion")} disabled={disabledObs}>
              <Radio value={1}>
                En línea
                <div className="textRadio">
                  (disponible desde PC o smartphone con conexión a internet).
                </div>
              </Radio>
              <Radio value={2} style={{ marginLeft: 50 }}>
                Presencial o virtual a través de alguna herramienta o plataforma
                <div className="textRadio">
                  (Zoom, Google Meet, Microsoft Teams o algún otro).
                </div>
              </Radio>
            </Radio.Group>
          )}
          </Form.Item>}
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <h3>Entidad a observar</h3>
        </Col>
        <Col span={8} xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
          <Form.Item label="Entidad">
            {getFieldDecorator("entidadJunta", {
              //initialValue: datos.idEntidad && parseInt(datos.idEntidad),
              rules: required,
            })(
              <Select
                placeholder="Selecciona"
                //onChange={getStateAdditional}
                onSelect={(value, e) => getStateAdditional(value, e)}
                showSearch
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                disabled={disabledObs || modificar}
              >
                {juntas.estados.map((item, i) => (
                  <Option key={i} value={item.id.idEstado}>
                    {item.nombreEstado}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={8} xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
          <Form.Item label="Junta que dará seguimiento a tu solicitud">
            {getFieldDecorator("juntaEjecutiva", {
              initialValue: datos.juntaEjecutiva,
              rules: required,
            })(
              <Select
                placeholder="Selecciona"
                onChange={changeSelect("juntaEjecutiva")}
                disabled={disabledObs || modificar}
              >
                {juntas.juntas.map((item, i) => (
                  <Option
                    value={
                      item.idDistritoFederal +
                      "|" +
                      item.nombreSede +
                      "|" +
                      item.direccion
                    }
                    key={i}
                  >
                    {item.nombreSede} {item.idDistritoFederal} -{" "}
                    {item.direccion}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>

        <Col span={8} xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
          {form.getFieldValue("juntaEjecutiva") && (!disabledObs || !modificar) && (
            <Form.Item label="Dirección de la junta ejecutiva ">
              {adress(form.getFieldValue("juntaEjecutiva"))}
            </Form.Item>
          )}
        </Col>
      </Row>
      <Row>
          <Col span={24} style={{ marginBottom: "30px" }}>
            <Alert message={<p style={{ marginBottom: "0px" }}>
              En caso de querer observar más de una entidad es necesario registrar una nueva cuenta con un correo electrónico distinto.</p>}
              type="info" showIcon 
            />
          </Col>
        </Row>
    </Form>
  );
}
