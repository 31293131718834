/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import { Form, Input, Button, Modal, Icon } from "antd";
import { basePath } from "../../../api/config";
import axios from "axios";

const UpdatePass = (props) => {
  const { getFieldDecorator } = props.form;
  const { visibleModificaPass, setVisibleModificaPass, ciudadano } = props;
  const [ botonDeshabilitado, setBotonDeshabilitado ] = useState(false);

  useEffect(() => {
    if (!visibleModificaPass) {
      props.form.resetFields();
    }
  }, [props.form, visibleModificaPass]);

  //Funciones para comparar y validar que las dos contraseñas son iguales
  const compareToFirstPassword = (rule, value, callback) => {
    const { form } = props;
    if (value && value !== form.getFieldValue("newPassword")) {
      callback("¡Las contraseñas no coinciden!");
    } else {
      callback();
    }
  };

  function error(mensaje) {
    Modal.error({
      title: "Modificación de contraseña",
      content: (
        <div>
          <p>{mensaje}</p>
        </div>
      ),
      okText: "Entendido",
    });
  }

  function sucess() {
    Modal.success({
      icon: false,
      okButtonProps: { style: { display: "block", width: "100%" } },
      content: (
        <div>
          <p style={{textAlign:"center", fontSize:"20px"}}><b>La contraseña se modificó correctamente</b></p>
          <span style={{ textAlign: "center" }}>
            <Icon
              type="check-circle"
              style={{
                color: "#E149A4",
                fontSize: "60px",
                textAlign: "center",
                display: "block",
              }}
            />
          </span>
          <br />
          <p>Enviamos un correo a {ciudadano.sub} para notificar el cambio de contraseña.</p>
          <p>
          Te recomendamos revisar la <strong>bandeja de entrada, spam o correo no deseado</strong> desde tu correo electrónico.
          </p>
        </div>
      ),
      okText: "Entendido",
    });
  }

  const updatePass = (e) => {
    e.preventDefault();
    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        values.idCuenta = ciudadano.idCuenta;
        values.usuario = ciudadano.sub;
        setBotonDeshabilitado(true);
        const url = `${basePath}/modificaPassword`;
        axios.post(url, values).then((response) => {
          if(response.data.code === 200) {
            setVisibleModificaPass(false);
            sucess();
          } else {
            setVisibleModificaPass(false);
            error(response.data.message);
          }
          setBotonDeshabilitado(false);
        });
      }
    });
  };

  return (
    <>
      <Form onSubmit={updatePass}>
        <Form.Item label="Contraseña actual">
          {getFieldDecorator("password", {
            rules: [
              { required: true, message: "Dato requerido" },
            ],
          })(
            <Input.Password placeholder="Contraseña actual" />
          )}
        </Form.Item>
        <Form.Item label="Contraseña nueva" hasFeedback style={{ marginBottom:"0px"}}>
          {getFieldDecorator("newPassword", {
            rules: [
              { required: true, message: "Dato requerido" },
              {
                message: "Ingresa una contraseña válida",
                pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!(){}@¡#,.:;^\\¿/?$%&=*_\-+=[\]|]).{11,}/,
              },
            ],
          })(
            <Input.Password placeholder="Contraseña actual" />
          )}
        </Form.Item>
        <p style={{ fontSize: "14px" }}>
          Debe contener al menos 11 caracteres e incluir al menos un carácter especial (@¡!#,.:;^()\¿/?$%&=*_-|+[]),
          una letra mayúscula, una letra minúscula y un número.
        </p>
        <Form.Item label="Confirma tu contraseña" hasFeedback>
          {getFieldDecorator("confirmNewPass", {
            rules: [
              { required: true, message: "Dato requerido" },
              { validator: compareToFirstPassword },
            ],
          })(<Input.Password placeholder="Confirmar contraseña" />)}
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: "100%" }} disabled={botonDeshabilitado}>
            Modificar
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

const registerForm = Form.create()(UpdatePass);
export default registerForm;
