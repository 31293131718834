import React, { useState, useEffect } from "react";
import { Table, Button, Input, Tooltip, Icon, message } from "antd";
import { withRouter } from "react-router-dom";
import { getDownloadInforme } from "../../../api/reports";


function TableInformation(props) {
    const { data, loading, setEntidad, setJunta, entidad, junta, setLoader } = props;
    const [dataSource, setDataSource] = useState([]);
    //Hook de busqueda
    const [valNombre, setValNombre] = useState(""); 
    const [valClave, setValClave] = useState(""); 
    
    useEffect(() => {
        setDataSource(data);
    }, [data]);

    function cambioEntidad(record) {
        //setEntidad({id: record.idEntidad, nombre: record.entidad});
        setEntidad({id: record.idEstado, nombre: record.nombreEstado});
        setJunta({id:null, nombre:null});
    }

    // filtro input
    const FilterByName = (
        <>
          Nombre del Observador
          <Input
            placeholder="Nombre"
            valNombre={valNombre}
            onChange={(e) => {
              const currValue = e.target.value;
              setValNombre(currValue);
              const filteredData = data.filter((entry) =>
                entry.nombreObservador
                  .toString()
                  .toLowerCase()
                  .includes(currValue.toLowerCase())
              );
              setDataSource(filteredData);
            }}
          />
        </>
    );

    const FilterByClave = (
        <>
          Clave de elector
          <Input
            placeholder="Clave de elector"
            valNombre={valClave}
            onChange={(e) => {
              const currValue = e.target.value;
              setValClave(currValue);
              const filteredData = data.filter((entry) =>
                entry.claveElector
                  .toString()
                  .toLowerCase()
                  .includes(currValue.toLowerCase())
              );
              setDataSource(filteredData);
            }}
          />
        </>
    );

    const download = (dataRow) => {
        console.log(dataRow);
        setLoader({ loading: true, message: "Cargando..." }); 
        getDownloadInforme(dataRow.idObservador).then((res) => {
            setLoader({ loading: false, message: "" }); 
            if (res.data) {
                const link = document.createElement("a");
                link.href = URL.createObjectURL(
                  new Blob([res.data], { type: "application/pdf" })
                );
                link.setAttribute("download", "Informe.pdf");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        })
        .catch((error) => {
            setLoader({ loading: false, message: "" });
            message.error("El archivo no se encuentra disponible");
        });
    };

    const columnasOC = [
        {
            title: "Estado",
            dataIndex: "nombreEstado",
            key: "nombreEstado",
            fixed: "left",
            render: (text, record) => <Button className="buttonReports" type="link"
                    onClick={(e) => cambioEntidad(record)}  >{text}</Button>
        },
        {
            title: "Distrito",
            dataIndex: "nombreDistrito",
            key: "nombreDistrito",
        },
        {
            title: FilterByName,
            dataIndex: "nombreObservador",
            key: "nombreObservador",
            //fixed: 'left',
            width: 300,
        },
        {
            title: FilterByClave,
            dataIndex: "claveElector",
            key: "claveElector",
            width: 200,
        },
        {
            title: "Folio",
            dataIndex: "folio",
            key: "folio",
        },
        {
            title: "Tipo de Solicitud",
            dataIndex: "tipoSolicitud",
            key: "tipoSolicitud",
            width: 150,
        },
        {
            title: "Agrupación",
            dataIndex: "agrupacion",
            key: "agrupacion",
            width: 150,
        },
        {
            title: "Jornada Electoral en la que participó",
            dataIndex: "proceso",
            key: "proceso",
            width: 150,
        },
        {
            title: "Etapa: Preparación de la elección",
            children: [
                {
                    title: "Entidad",
                    dataIndex: "entidadE1",
                    key: "entidadE1",
                    width: 150,
                },
                {
                    title: "Municipio",
                    dataIndex: "municipioE1",
                    key: "municipioE1",
                    width: 150,
                },
                {
                    title: "Distrito Federal",
                    dataIndex: "distritoFederalE1",
                    key: "distritoFederalE1",
                    width: 150,
                },
                {
                    title: "Distrito Local",
                    dataIndex: "distritoLocalE1",
                    key: "distritoLocalE1",
                    width: 150,
                },
                {
                    title: "Incidentes",
                    dataIndex: "incidentesE1",
                    key: "incidentesE1",
                    width: 200,
                },
                {
                    title: "Actividades",
                    dataIndex: "actividadesE1",
                    key: "actividadesE1",
                    width: 200,
                },
                {
                    title: "Propuesta",
                    dataIndex: "propuestaE1",
                    key: "propuestaE1",
                    width: 200,
                },
            ]
        },
        {
            title: "Etapa: Jornada Electoral",
            children: [
                {
                    title: "Entidad",
                    dataIndex: "entidadE2",
                    key: "entidadE2",
                    width: 150,
                },
                {
                    title: "Municipio",
                    dataIndex: "municipioE2",
                    key: "municipioE2",
                    width: 150,
                },
                {
                    title: "Distrito Federal",
                    dataIndex: "distritoFederalE2",
                    key: "distritoFederalE2",
                    width: 150,
                },
                {
                    title: "Distrito Local",
                    dataIndex: "distritoLocalE2",
                    key: "distritoLocalE2",
                    width: 200,
                },
                {
                    title: "Incidentes",
                    dataIndex: "incidentesE2",
                    key: "incidentesE2",
                    width: 200,
                },
                {
                    title: "Actividades",
                    dataIndex: "actividadesE2",
                    key: "actividadesE2",
                    width: 200,
                },
                {
                    title: "Propuesta",
                    dataIndex: "propuestaE2",
                    key: "propuestaE2",
                    width: 200,
                },
            ]
        },
        {
            title: "Etapa: Resultados y declaración de validez de la elección",
            children: [
                {
                    title: "Entidad",
                    dataIndex: "entidadE3",
                    key: "entidadE3",
                    width: 150,
                },
                {
                    title: "Municipio",
                    dataIndex: "municipioE3",
                    key: "municipioE3",
                    width: 150,
                },
                {
                    title: "Distrito Federal",
                    dataIndex: "distritoFederalE3",
                    key: "distritoFederalE3",
                    width: 150,
                },
                {
                    title: "Distrito Local",
                    dataIndex: "distritoLocalE3",
                    key: "distritoLocalE3",
                    width: 150,
                },
                {
                    title: "Incidentes",
                    dataIndex: "incidentesE3",
                    key: "incidentesE3",
                    width: 200,
                },
                {
                    title: "Actividades",
                    dataIndex: "actividadesE3",
                    key: "actividadesE3",
                    width: 200,
                },
                {
                    title: "Propuesta",
                    dataIndex: "propuestaE3",
                    key: "propuestaE3",
                    width: 200,
                },
            ]
        },
        {
            title: "Etapa: Dictamen y declaraciones de validez de la elección",
            children: [
                {
                    title: "Entidad",
                    dataIndex: "entidadE4",
                    key: "entidadE4",
                    width: 150,
                },
                {
                    title: "Municipio",
                    dataIndex: "municipioE4",
                    key: "municipioE4",
                    width: 150,
                },
                {
                    title: "Distrito Federal",
                    dataIndex: "distritoFederalE4",
                    key: "distritoFederalE4",
                    width: 150,
                },
                {
                    title: "Distrito Local",
                    dataIndex: "distritoLocalE4",
                    key: "distritoLocalE4",
                    width: 150,
                },
                {
                    title: "Incidentes",
                    dataIndex: "incidentesE4",
                    key: "incidentesE4",
                    width: 200,
                },
                {
                    title: "Actividades",
                    dataIndex: "actividadesE4",
                    key: "actividadesE4",
                    width: 200,
                },
                {
                    title: "Propuesta",
                    dataIndex: "propuestaE4",
                    key: "propuestaE4",
                    width: 200,
                },
            ]
        },
        {
            title: "Información Electoral",
            children: [
                {
                    title: "En materia de",
                    dataIndex: "materiales",
                    key: "materiales",
                    width: 200,
                },
                {
                    title: "Detalle",
                    dataIndex: "detalle",
                    key: "detalle",
                    width: 200,
                },
            ]
        },
        {
            title: "Medio de Captura",
            dataIndex: "medioCaptura",
            key: "medioCaptura",
            width: 150,
        },
        {
            title: "Fecha de elaboración",
            dataIndex: "fechaInforme",
            key: "fechaInforme",
            width: 150,
        },
        {
            title: "Acciones",
            fixed: "right",
            align: "center",
            width: 100,
            render: (record) => (
              <>
                <Tooltip title="Descargar informe">
                    <button 
                      style={{ marginLeft: "0px" }}
                      className="btn-circle btn_secundario"
                      onClick={(e) => download(record)}
                    >
                      <Icon type="download" />
                    </button>
                </Tooltip>
              </>
            ),
          },
    ]

    const columnasJL = [
        {
            title: "Distrito",
            dataIndex: "nombreDistrito",
            key: "nombreDistrito",
            fixed: 'left',
            render: (text, record) => <Button className="buttonReports" type="link"
                    onClick={() => setJunta({id: record.idDistrito, nombre: record.nombreDistrito})} >{text}</Button>
        },
        {
            title: FilterByName,
            dataIndex: "nombreObservador",
            key: "nombreObservador",
            //fixed: 'left',
            width: 300,
        },
        {
            title: FilterByClave,
            dataIndex: "claveElector",
            key: "claveElector",
            width: 200,
        },
        {
            title: "Folio",
            dataIndex: "folio",
            key: "folio",
        },
        {
            title: "Tipo de Solicitud",
            dataIndex: "tipoSolicitud",
            key: "tipoSolicitud",
            width: 150,
        },
        {
            title: "Agrupación",
            dataIndex: "agrupacion",
            key: "agrupacion",
            width: 150,
        },
        {
            title: "Jornada Electoral en la que participó",
            dataIndex: "proceso",
            key: "proceso",
            width: 150,
        },
        {
            title: "Etapa: Preparación de la elección",
            children: [
                {
                    title: "Entidad",
                    dataIndex: "entidadE1",
                    key: "entidadE1",
                    width: 150,
                },
                {
                    title: "Municipio",
                    dataIndex: "municipioE1",
                    key: "municipioE1",
                    width: 150,
                },
                {
                    title: "Distrito Federal",
                    dataIndex: "distritoFederalE1",
                    key: "distritoFederalE1",
                    width: 150,
                },
                {
                    title: "Distrito Local",
                    dataIndex: "distritoLocalE1",
                    key: "distritoLocalE1",
                    width: 150,
                },
                {
                    title: "Incidentes",
                    dataIndex: "incidentesE1",
                    key: "incidentesE1",
                    width: 200,
                },
                {
                    title: "Actividades",
                    dataIndex: "actividadesE1",
                    key: "actividadesE1",
                    width: 200,
                },
                {
                    title: "Propuesta",
                    dataIndex: "propuestaE1",
                    key: "propuestaE1",
                    width: 200,
                },
            ]
        },
        {
            title: "Etapa: Jornada Electoral",
            children: [
                {
                    title: "Entidad",
                    dataIndex: "entidadE2",
                    key: "entidadE2",
                    width: 150,
                },
                {
                    title: "Municipio",
                    dataIndex: "municipioE2",
                    key: "municipioE2",
                    width: 150,
                },
                {
                    title: "Distrito Federal",
                    dataIndex: "distritoFederalE2",
                    key: "distritoFederalE2",
                    width: 150,
                },
                {
                    title: "Distrito Local",
                    dataIndex: "distritoLocalE2",
                    key: "distritoLocalE2",
                    width: 200,
                },
                {
                    title: "Incidentes",
                    dataIndex: "incidentesE2",
                    key: "incidentesE2",
                    width: 200,
                },
                {
                    title: "Actividades",
                    dataIndex: "actividadesE2",
                    key: "actividadesE2",
                    width: 200,
                },
                {
                    title: "Propuesta",
                    dataIndex: "propuestaE2",
                    key: "propuestaE2",
                    width: 200,
                },
            ]
        },
        {
            title: "Etapa: Resultados y declaración de validez de la elección",
            children: [
                {
                    title: "Entidad",
                    dataIndex: "entidadE3",
                    key: "entidadE3",
                    width: 150,
                },
                {
                    title: "Municipio",
                    dataIndex: "municipioE3",
                    key: "municipioE3",
                    width: 150,
                },
                {
                    title: "Distrito Federal",
                    dataIndex: "distritoFederalE3",
                    key: "distritoFederalE3",
                    width: 150,
                },
                {
                    title: "Distrito Local",
                    dataIndex: "distritoLocalE3",
                    key: "distritoLocalE3",
                    width: 150,
                },
                {
                    title: "Incidentes",
                    dataIndex: "incidentesE3",
                    key: "incidentesE3",
                    width: 200,
                },
                {
                    title: "Actividades",
                    dataIndex: "actividadesE3",
                    key: "actividadesE3",
                    width: 200,
                },
                {
                    title: "Propuesta",
                    dataIndex: "propuestaE3",
                    key: "propuestaE3",
                    width: 200,
                },
            ]
        },
        {
            title: "Etapa: Dictamen y declaraciones de validez de la elección",
            children: [
                {
                    title: "Entidad",
                    dataIndex: "entidadE4",
                    key: "entidadE4",
                    width: 150,
                },
                {
                    title: "Municipio",
                    dataIndex: "municipioE4",
                    key: "municipioE4",
                    width: 150,
                },
                {
                    title: "Distrito Federal",
                    dataIndex: "distritoFederalE4",
                    key: "distritoFederalE4",
                    width: 150,
                },
                {
                    title: "Distrito Local",
                    dataIndex: "distritoLocalE4",
                    key: "distritoLocalE4",
                    width: 150,
                },
                {
                    title: "Incidentes",
                    dataIndex: "incidentesE4",
                    key: "incidentesE4",
                    width: 200,
                },
                {
                    title: "Actividades",
                    dataIndex: "actividadesE4",
                    key: "actividadesE4",
                    width: 200,
                },
                {
                    title: "Propuesta",
                    dataIndex: "propuestaE4",
                    key: "propuestaE4",
                    width: 200,
                },
            ]
        },
        {
            title: "Información Electoral",
            children: [
                {
                    title: "En materia de",
                    dataIndex: "materiales",
                    key: "materiales",
                    width: 200,
                },
                {
                    title: "Detalle",
                    dataIndex: "detalle",
                    key: "detalle",
                    width: 200,
                },
            ]
        },
        {
            title: "Medio de Captura",
            dataIndex: "medioCaptura",
            key: "medioCaptura",
            width: 150,
        },
        {
            title: "Fecha de elaboración",
            dataIndex: "fechaInforme",
            key: "fechaInforme",
            width: 150,
        },
        {
            title: "Acciones",
            fixed: "right",
            align: "center",
            width: 100,
            render: (record) => (
              <>
                <Tooltip title="Descargar informe">
                    <button 
                      style={{ marginLeft: "0px" }}
                      className="btn-circle btn_secundario"
                      onClick={(e) => download(record)}
                    >
                      <Icon type="download" />
                    </button>
                </Tooltip>
              </>
            ),
          },
    ]

    const columnasJD = [
        {
            title: FilterByName,
            dataIndex: "nombreObservador",
            key: "nombreObservador",
            fixed: 'left',
            width: 300,
        },
        {
            title: FilterByClave,
            dataIndex: "claveElector",
            key: "claveElector",
            width: 200,
        },
        {
            title: "Tipo de Solicitud",
            dataIndex: "tipoSolicitud",
            key: "tipoSolicitud",
            width: 150,
        },
        {
            title: "Agrupación",
            dataIndex: "agrupacion",
            key: "agrupacion",
            width: 150,
        },
        {
            title: "Jornada Electoral en la que participó",
            dataIndex: "proceso",
            key: "proceso",
            width: 150,
        },
        {
            title: "Etapa: Preparación de la elección",
            children: [
                {
                    title: "Entidad",
                    dataIndex: "entidadE1",
                    key: "entidadE1",
                    width: 150,
                },
                {
                    title: "Municipio",
                    dataIndex: "municipioE1",
                    key: "municipioE1",
                    width: 150,
                },
                {
                    title: "Distrito Federal",
                    dataIndex: "distritoFederalE1",
                    key: "distritoFederalE1",
                    width: 150,
                },
                {
                    title: "Distrito Local",
                    dataIndex: "distritoLocalE1",
                    key: "distritoLocalE1",
                    width: 150,
                },
                {
                    title: "Incidentes",
                    dataIndex: "incidentesE1",
                    key: "incidentesE1",
                    width: 200,
                },
                {
                    title: "Actividades",
                    dataIndex: "actividadesE1",
                    key: "actividadesE1",
                    width: 200,
                },
                {
                    title: "Propuesta",
                    dataIndex: "propuestaE1",
                    key: "propuestaE1",
                    width: 200,
                },
            ]
        },
        {
            title: "Etapa: Jornada Electoral",
            children: [
                {
                    title: "Entidad",
                    dataIndex: "entidadE2",
                    key: "entidadE2",
                    width: 150,
                },
                {
                    title: "Municipio",
                    dataIndex: "municipioE2",
                    key: "municipioE2",
                    width: 150,
                },
                {
                    title: "Distrito Federal",
                    dataIndex: "distritoFederalE2",
                    key: "distritoFederalE2",
                    width: 150,
                },
                {
                    title: "Distrito Local",
                    dataIndex: "distritoLocalE2",
                    key: "distritoLocalE2",
                    width: 200,
                },
                {
                    title: "Incidentes",
                    dataIndex: "incidentesE2",
                    key: "incidentesE2",
                    width: 200,
                },
                {
                    title: "Actividades",
                    dataIndex: "actividadesE2",
                    key: "actividadesE2",
                    width: 200,
                },
                {
                    title: "Propuesta",
                    dataIndex: "propuestaE2",
                    key: "propuestaE2",
                    width: 200,
                },
            ]
        },
        {
            title: "Etapa: Resultados y declaración de validez de la elección",
            children: [
                {
                    title: "Entidad",
                    dataIndex: "entidadE3",
                    key: "entidadE3",
                    width: 150,
                },
                {
                    title: "Municipio",
                    dataIndex: "municipioE3",
                    key: "municipioE3",
                    width: 150,
                },
                {
                    title: "Distrito Federal",
                    dataIndex: "distritoFederalE3",
                    key: "distritoFederalE3",
                    width: 150,
                },
                {
                    title: "Distrito Local",
                    dataIndex: "distritoLocalE3",
                    key: "distritoLocalE3",
                    width: 150,
                },
                {
                    title: "Incidentes",
                    dataIndex: "incidentesE3",
                    key: "incidentesE3",
                    width: 200,
                },
                {
                    title: "Actividades",
                    dataIndex: "actividadesE3",
                    key: "actividadesE3",
                    width: 200,
                },
                {
                    title: "Propuesta",
                    dataIndex: "propuestaE3",
                    key: "propuestaE3",
                    width: 200,
                },
            ]
        },
        {
            title: "Etapa: Dictamen y declaraciones de validez de la elección",
            children: [
                {
                    title: "Entidad",
                    dataIndex: "entidadE4",
                    key: "entidadE4",
                    width: 150,
                },
                {
                    title: "Municipio",
                    dataIndex: "municipioE4",
                    key: "municipioE4",
                    width: 150,
                },
                {
                    title: "Distrito Federal",
                    dataIndex: "distritoFederalE4",
                    key: "distritoFederalE4",
                    width: 150,
                },
                {
                    title: "Distrito Local",
                    dataIndex: "distritoLocalE4",
                    key: "distritoLocalE4",
                    width: 150,
                },
                {
                    title: "Incidentes",
                    dataIndex: "incidentesE4",
                    key: "incidentesE4",
                    width: 200,
                },
                {
                    title: "Actividades",
                    dataIndex: "actividadesE4",
                    key: "actividadesE4",
                    width: 200,
                },
                {
                    title: "Propuesta",
                    dataIndex: "propuestaE4",
                    key: "propuestaE4",
                    width: 200,
                },
            ]
        },
        {
            title: "Información Electoral",
            children: [
                {
                    title: "En materia de",
                    dataIndex: "materiales",
                    key: "materiales",
                    width: 200,
                },
                {
                    title: "Detalle",
                    dataIndex: "detalle",
                    key: "detalle",
                    width: 200,
                },
            ]
        },
        {
            title: "Medio de Captura",
            dataIndex: "medioCaptura",
            key: "medioCaptura",
            width: 150,
        },
        {
            title: "Fecha de elaboración",
            dataIndex: "fechaInforme",
            key: "fechaInforme",
            width: 150,
        },
        {
            title: "Acciones",
            fixed: "right",
            align: "center",
            width: 100,
            render: (record) => (
              <>
                <Tooltip title="Descargar informe">
                    <button 
                      style={{ marginLeft: "0px" }}
                      className="btn-circle btn_secundario"
                      onClick={(e) => download(record)}
                    >
                      <Icon type="download" />
                    </button>
                </Tooltip>
              </>
            ),
          },
    ]

    function tipoTabla() {
        if (entidad.id === null) {
            // reporte nivel OC
            return (
                <Table
                    columns={columnasOC}
                    dataSource={dataSource}
                    loading={loading}
                    locale={{ emptyText: "Sin registros" }}
                    size="small"
                    scroll={{ x: true }}
                />
            );
        } else {
            // reporte nivel JL
            if (junta.id === null) {
                return (
                    <Table
                        columns={columnasJL}
                        dataSource={dataSource}
                        loading={loading}
                        locale={{ emptyText: "Sin registros" }}
                        size="small"
                        scroll={{ x: true }}
                    />
                );
            } else {
            // reporte nivel JD
                return (
                    <Table
                        columns={columnasJD}
                        dataSource={dataSource}
                        loading={loading}
                        locale={{ emptyText: "Sin registros" }}
                        size="small"
                        scroll={{ x: 7000 }}
                    />
                );
            }
        }
    }

    return (
        <div>{tipoTabla()}</div>        
    );
}

export default withRouter(TableInformation);