import axios from "axios";
import { getAccessTokenApi } from "./auth";
import { basePath } from "./config";

export function getProcesoRatificacion() {
    const url = `${basePath}/notificaciones/obtenProcesoRatifica`;
    const jwt = getAccessTokenApi();
    return axios.post(
        url,
        {  },
        { headers: { Authorization: jwt } }
    );
}

export function sendNotifRat(idProceso, idDetalle) {
    const url = `${basePath}/notificaciones/enviaNotifRatifica`;
    const jwt = getAccessTokenApi();
    return axios.post(
        url,
        { idProceso: idProceso, idDetalle: idDetalle },
        { headers: { Authorization: jwt, Parametrizacion: idProceso } }
    );
}
