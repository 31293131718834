import React, { useState, useEffect, useRef } from "react";
import { Redirect, Link, withRouter } from "react-router-dom";
//Se importan los componentes a utilizar
import Login from "../../../components/Login/Login";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
//import Sesion from "../../../components/Login/Sesion";

import { ReactComponent as Logo_Header } from "../../../assets/img/ID_observadores.svg";
import { ReactComponent as Logo_Login } from "../../../assets/img/ID_Docs2.svg";
//Componentes de ant desig
import { Layout, Col, Row, Icon, Modal } from "antd";
import "./Login.scss";
import { getAccessTokenApi } from "../../../api/auth";
//import Carrusel from "../../../components/Carrusel/Carrusel";
import BlockLoader from "../../../components/Generals/Loader";
import moment from "moment";
import Barchart from "../../../components/Login/Barchart";
import { getEstadisticasLogin } from "../../../api/estadisticas";
import { emptyCache } from "../../../components/Generals/functions";

function LoginPrincipal(props) {
  const {
    history: {
      location: { pathname },
    },
  } = props;
  const [datos, setDatos] = useState({});
  const [visibleSession, setVisibleSession] = useState(false);
  const [loader, setLoader] = useState({ loading: false, message: "" }); //Status para mostrar el loader
  const [datosGrafica, setDatosGrafica] = useState([]);
  const { Content } = Layout; //Se obtiene el componente hijo de Layout
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    emptyCache();
    getEstadisticasLogin().then((res) => {
      if (isMounted.current) {
        setDatos(res.data.estadisticaLogin);
        setDatosGrafica(res.data.estadisticaLogin.grafica);
      }
    });
  }, []);

  //Se valida si tiene una sesión activa lo mande al home
  if (getAccessTokenApi()) {
    return <Redirect to="/admin/home" />;
  }

  const UltimaActualizacion = () => {
    const fechaHora = new Date();
    moment.locale("es");
    const fecha = moment(fechaHora).format("DD/MM/YYYY");
    const hora = moment(fechaHora).format("HH:mm:ss");
    return (
      <div style={{ fontSize: "12px" }}>
        Última actualización {fecha} {hora} hrs
      </div>
    );
  };
  //Funciones para mostrar el modal
  const showModalSession = () => {
    setVisibleSession(true);
  };
  const handleOkSesion = (e) => {
    setVisibleSession(false);
  };

  const handleCancelSesion = (e) => {
    setVisibleSession(false);
  };

  return (
    //Para mostrar el loader en el login
    <BlockLoader loading={loader.loading} message={loader.message}>
      <Modal
        visible={visibleSession}
        onOk={handleOkSesion}
        onCancel={handleCancelSesion}
        footer={null}
        className="modalLoginRegistro"
      >
        <Login Logo={Logo_Login} setLoader={setLoader} />
      </Modal>
      <Layout>
        {/**Se importa el header y se le envía el logo correspondiente */}
        <Header Logo={Logo_Header} />
        <Content className="content">
          {/**Contenido  */}
          <Row>
            <Row>
              <Col span={12}>
                <div>
                  <h2>
                    <strong>{datos.nombreProceso}</strong>
                  </h2>
                </div>
                <UltimaActualizacion />
              </Col>
              <Col span={12}>
                <div className="loginCiudadano">
                  <Link to={pathname} type="link" onClick={showModalSession}>
                    <Icon type="user" /> Iniciar sesión
                  </Link>
                </div>
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col xs={0} sm={0} md={0} lg={0} xl={0} xxl={0}></Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                    <div className="card-menu-home">
                      <p>
                        <b>Total de solicitudes recibidas</b>
                      </p>
                      <Row gutter={20}>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={24}>
                          <p className="subTitulo">Portal público</p>
                          <p className="numPortal">{datos.recibidasPortal}</p>
                        </Col>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={24}>
                          <p className="subTitulo">JE/OPL</p>
                          <p className="numJE">{datos.recibidasJEOPL}</p>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                    <div className="card-menu-home">
                      <p>
                        <b>Solicitudes por edad (años)</b>
                      </p>
                      <Row gutter={21}>
                        <Col xxl={8} xl={8} lg={8} md={8} sm={8} xs={24}>
                          <p className="subTitulo">18 a 25</p>
                          <p className="edad1">{datos.edad1}</p>
                        </Col>
                        <Col xxl={8} xl={8} lg={8} md={8} sm={8} xs={24}>
                          <p className="subTitulo">26 a 40</p>
                          <p className="edad2">{datos.edad2}</p>
                        </Col>
                        <Col xxl={8} xl={8} lg={8} md={8} sm={8} xs={24}>
                          <p className="subTitulo">41 o más</p>
                          <p className="edad3">{datos.edad3}</p>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                    <div className="card-menu-home">
                      <p>
                        <b>Solicitudes acreditadas</b>
                      </p>
                      <Row gutter={20}>
                        <Col xxl={10} xl={10} lg={10} md={10} sm={24} xs={24}>
                          <p className="subTitulo">Mujeres</p>
                          <p className="numAcreMujeres">{datos.mujerAcreditado}</p>
                        </Col>
                        <Col xxl={10} xl={10} lg={10} md={10} sm={24} xs={24}>
                          <p className="subTitulo">Hombres</p>
                          <p className="numAcreHombres">{datos.hombreAcreditado}</p>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col></Col>
                  <Col span={24}>
                    <div className="card-menu-home">
                      <div className="encabezado">
                        <Row>
                          <b>Detalle de solicitudes recibidas</b>
                        </Row>
                      </div>
                      <Barchart datosGrafica={datosGrafica} />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Row>
        </Content>
        {/**Footer con la versión del sistema */}
        <Footer version="Versión 1.0" anio="2021" />
      </Layout>
    </BlockLoader>
  );
}
export default withRouter(LoginPrincipal);
