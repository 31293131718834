import React, { useState, useEffect } from "react";
import { Row, Col, Alert } from "antd";
import { withRouter } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { rol_captura, rol_consulta, rol_modifica, rol_descarga, rol_borrar } from "../../../utils/Accreditation/roles";
import { Ast } from "../../../utils/Accreditation/functions";
import FormAcreditacion from "../../../components/Admin/Documents/Form";
import "./Documents.scss";
import { getSolicitudesAcreditadasDocs } from "../../../api/accreditation";
import { validaModuloAbierto } from "../../../components/Generals/functions";
import { MOD_DOCUMENTOS_ACRE } from "../../../utils/constanst";

function Accreditation(props) {
  const [, setRolModulo] = useState({ captura: false, modifica: false, consulta: false, descarga: false, borrar: false, });
  const { user: { rolUsuario } } = useAuth();
  const { proceso, setLoader } = props;
  const [reload, setReload] = useState(false);
  const [saved, setSaved] = useState(false);
  const [verAlert, setVerAlert] = useState(false);
  const [, setData] = useState([]); // Lista con la información de las solicitudes por acreditar
  const [, setRequests] = useState({ list: [], loading: false });
  const [moduloAbierto, setModuloAbierto] = useState(false); //Para validar si el módulo esa abierto o cerrado

  useEffect(() => {
    //Valida los permisos que tienen el usuario
    let captura = false;
    let modifica = false;
    let consulta = false;
    let descarga = false;
    let borrar = false;
    captura = rol_captura.includes(rolUsuario);
    modifica = rol_modifica.includes(rolUsuario);
    consulta = rol_consulta.includes(rolUsuario);
    descarga = rol_descarga.includes(rolUsuario);
    borrar = rol_borrar.includes(rolUsuario);
    setRolModulo(() => ({ captura, modifica, consulta, descarga, borrar }));
  }, [rolUsuario]);

  useEffect(() => {
    if (proceso.distrito !== undefined && proceso.distrito !== null) {
      setModuloAbierto(validaModuloAbierto(MOD_DOCUMENTOS_ACRE));
      if (saved) {
        setVerAlert(true);
        setSaved(false);
      }
      getSolicitudesAcreditadasDocs(proceso.proceso.idProcesoElectoral, proceso.proceso.idDetalleProceso, 
        proceso.estado.idEstado, proceso.distrito.idDistrito).then((res) => {
          const dataSource = [];
          if (res.data.solicitudesAcreditadasDocs !== null && res.data.solicitudesAcreditadasDocs.length > 0) {
            res.data.solicitudesAcreditadasDocs.map((reg, index) => {
              return dataSource.push({ key: index, indice: index + 1, ...reg, });
            });
          }
          setData(dataSource);
          setRequests({ list: dataSource, loading: false });          
      });
    }
    setReload(false);
  }, [proceso, saved, reload]);

  function Formulario() {
    return (
    <div>
        <Row className="rowListRequests">
            <Col span={24}>
                <div>
                    <FormAcreditacion
                    proceso={proceso}
                    setReload={setReload}
                    setLoader={setLoader}
                    setSaved={setSaved}
                    moduloAbierto={moduloAbierto}
                    />
                </div>
            </Col>
        </Row>
    </div>
    );
  };
  
  return (
    <div>
      <Row>
        <h1>Documentos de acreditación</h1>
        <Col span={24}>
          <small>Los datos con ( <Ast /> ) son requeridos</small>
          <Alert
            message={<p style={{ marginBottom: "0px" }}>
              Si los botones de la descarga de documentos "constancia" y/o "gafete" se encuentran deshabilitados, es necesario generar los documentos de acreditación para las y los ciudadanos.</p>}
            type="info" showIcon
          />
        </Col>
        <Col span={24} className="rowListRequests">
          {verAlert && <Alert message={<p style={{ marginBottom: "0px" }}>
          Los documentos de las acreditaciones se generaron correctamente. </p>} type="success" showIcon />}
        </Col>
      </Row>
      {Formulario()}
    </div>
  );
}

export default withRouter(Accreditation);