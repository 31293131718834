import React from "react";
import { Row, Col, Icon, message, Divider, Button } from "antd";
import "./Home.scss";
import { getDownload } from "../../../../api/requestPub";
import {
  ID_DOC_COMPROBANTE,
  ID_DOC_ACREDITACION,
  MSN_GUARDAR,
  ID_DOC_GAFETE,
  ID_DOC_ACREDITACION_OBS,
  ESTATUS_VALIDADO,
  MODALIDAD_LINEA,
  ID_DOC_CONSTANCIA,
  ID_DOC_INFORME,
  ID_DOC_CONSTANCIA_PRE
} from "../../../../utils/constanst";
import { Link } from "react-router-dom";
import { ReactComponent as MaterialLogo } from "../../../../assets/img/CourseOnline/matCapa.svg";
import { useSelector } from "react-redux";

export default function Dowload(props) {
  const {
    ciudadano,
    /* detalle, */
    setLoader,
    page,
    estadoGralSolicitud,
    status,
  } = props; //status
  const { constancia } = useSelector((state) => state.constancia);

  //Función que descarga un documento de acuerdo al tipo
  const download = (type) => {
    setLoader({ loading: true, message: MSN_GUARDAR }); //Se activa el loader

    getDownload(
      ciudadano.idCuenta,
      type,
      /* detalle[0].idProcesoElectoral,
      detalle[0].idDetalleProceso, */
      ciudadano.id ? ciudadano.id : 0
    )
      .then((res) => {
        setLoader({ loading: false, message: "" }); //Se desactiva el loader
        if (res.data) {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(
            new Blob([res.data], { type: "application/pdf" })
          );
          link.setAttribute("download", nameFile(type));
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch((error) => {
        setLoader({ loading: false, message: "" }); //Se desactiva el loader
        message.error("El archivo no se encuentra disponible");
      });
  };


  const nameFile = (type) => {
    var nombreArchivo;
    switch (type) {
      case 4:
        nombreArchivo = "Comprobante.pdf";
        break;
      case 5:
        nombreArchivo = "Solicitud de acreditacion.pdf";
        break;
      case 6:
        nombreArchivo = "ConstanciaCurso.pdf";
        break;
      case 8:
        nombreArchivo = "Informe.pdf";
        break;
      case 9:
        nombreArchivo = "ConstanciaCursoPre.pdf";
        break;
      case 21:
        nombreArchivo = "gafeteAcreditacion.pdf";
        break;
      case 22:
        nombreArchivo = "constanciaAcreditacion.pdf";
        break;      
      default:
        nombreArchivo = "archivo.pdf";
    }

    return nombreArchivo;
  };
  //status.fase === 3 &&

  return (
    <div className="secDownload">
      <div>
        <h4> Documentos de tu proceso</h4>
        {page.page === 10 && (
          <>
            <Row gutter={24}>
              <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={24}>
                <Icon type="file" style={{ color: "#e149a4" }} /> Informes <br />
                <button
                  disabled={estadoGralSolicitud !== 4}
                  className="btnDownload"
                  onClick={(e) => download(ID_DOC_INFORME, e)}
                >
                  descargar
                </button>
              </Col>
            </Row>
            <hr style={{ border: "1px solid #C6C6C6" }} />
          </>
        )}
        {page.fase >= 3 && (
          <>
            <Row gutter={24}>
              <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={24}>
                <Icon type="file" style={{ color: "#e149a4" }} /> Gafete de
                observador/a <br />
                <button
                  disabled={estadoGralSolicitud !== 4}
                  className="btnDownload"
                  onClick={(e) => download(ID_DOC_GAFETE, e)}
                >
                  descargar
                </button>
              </Col>
              <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={24}>
                <Icon type="file" style={{ color: "#e149a4" }} /> Constancia de
                acreditación <br />
                <button
                  disabled={estadoGralSolicitud !== 4}
                  className="btnDownload"
                  onClick={(e) => download(ID_DOC_ACREDITACION_OBS, e)}
                >
                  descargar
                </button>
              </Col>
            </Row>
            <hr style={{ border: "1px solid #C6C6C6" }} />
          </>
        )}

        <Row gutter={24}>
          <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={24}>
            <Icon type="file" style={{ color: "#e149a4" }} /> Solicitud de
            acreditación <br />
            <button
              className="btnDownload"
              onClick={(e) => download(ID_DOC_ACREDITACION, e)}
            >
              descargar
            </button>
          </Col>
          <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={24}>
            <Icon type="file" style={{ color: "#e149a4" }} /> Comprobante de la
            solicitud <br />
            <button
              className="btnDownload"
              onClick={(e) => download(ID_DOC_COMPROBANTE, e)}
            >
              descargar
            </button>
          </Col>
          {constancia && JSON.stringify(ciudadano.modalidad)==="1" && (
            <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={24}>
              <Icon type="file" style={{ color: "#e149a4" }} /> Constancia del
              curso <br />
              <button
                className="btnDownload"
                onClick={(e) => download(ID_DOC_CONSTANCIA, e)}
              >
                descargar
              </button>
            </Col>
          )}
          {constancia && JSON.stringify(ciudadano.modalidad)==="2" && (
            <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={24}>
              <Icon type="file" style={{ color: "#e149a4" }} /> Constancia del
              curso presencial <br />
              <button
                className="btnDownload"
                onClick={(e) => download(ID_DOC_CONSTANCIA_PRE, e)}
              >
                descargar
              </button>
            </Col>
          )}
        </Row>
        {ciudadano.id && (
          <Row gutter={24} style={{marginTop:25}}>
            <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={24}>
              <Link to={{ pathname: "/ciudadano/solicitud/consultar", state: true, modificar: false }}>
                <Button type="primary" style={{width:200}}>Consultar solicitud</Button>
              </Link>
            </Col>
          </Row>
        )}

        {status.status === ESTATUS_VALIDADO &&
          ciudadano.modalidad === MODALIDAD_LINEA && (
            <>
              <Divider />
              <Link to={{ pathname: "/ciudadano/material" }}>
              {/*<Link to={{ pathname: "/ciudadano/materiales-consulta-popular" }}>*/}
                <Row gutter={24} className="matCapacitacion">
                  <Col span={10}>
                    <MaterialLogo />
                  </Col>
                  <Col span={14}>
                    <h1>Materiales de capacitación</h1>
                  </Col>
                </Row>
              </Link>
            </>
          )}
      </div>
    </div>
  );
}
