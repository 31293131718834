import React, { useEffect } from "react";
import { Col, Row } from "antd";
import introImg from "../../../../../assets/img/CourseOnline/introduccion.png";
import "../../CourseOnline/Courses.scss";

/**
 * Pantalla 2
 * Componentes que muestra la pantalla de introducción al inicar el curso
 * path: /ciudadano/introduccion
 */
export default function Introduccion(props) {
  const row = 18;

  useEffect(() => {
    window.scrollTo(0, 0); // para posicionar el scroll al inicio
  }, []);

  return (
    <>
      <Row gutter={24} className="material">
        <Col span={row} xxl={row} xl={row} lg={row} md={row} sm={24} xs={24}>
          <div className="texto_parrafo">
            <h1>El Proceso Electoral 2021</h1>
            <p>
              En este curso identificarás qué es el Proceso Electoral, sus características, los actos preparatorios, los momentos de la Jornada del Proceso Electoral y los actos posteriores, estas actividades las podrás observar al momento de desempeñar tus funciones.
            </p>
            <p>
              Tu curso está integrado por dos lecciones, en cada una encontrarás la información precisa y los distintos materiales de apoyo para tu capacitación. En cualquier momento podrás consultar tu Guía temática, así como la normatividad electoral vigente para el desarrollo del Proceso Electoral.
            </p>
            <p>
              Podrás realizar las actividades desde tu computadora, laptop, tableta o celular y avanzar a tu propio ritmo, tomar descansos y retomar el curso en la lección donde te quedaste.
            </p>
            <p>
              Recuerda que tu participación como observador u observadora del Proceso Electoral es fundamental, y su importancia se refleja en cada etapa.
            </p>
            <p>
              <b>Agradecemos tu interés de ser testigo y formar parte activa de la democracia del país.</b>
            </p>
            <img src={introImg} alt="img" className="imagen" />
            {/* <Link to={{ pathname: "/ciudadano/evaluacion" }}>
                <Button style={{ marginRight: 20 }}>
                    Contestar evaluación
                </Button>
            </Link> */}
            {/* <Link to={{ pathname: "/ciudadano/materiales-consulta-popular" }}>
                <Button>
                    Materiales de consulta
                </Button>
            </Link> */}
          </div>
        </Col>
      </Row>
    </>
  );
}
