import { useEffect, useState } from "react";

export const useLesson3 = (idEntidad, idParrafo) => {
  const [lesson, setLesson] = useState({});

  useEffect(() => {
    switch (idEntidad) {
      //completa aguascalientes
      case 1:
        switch (idParrafo) {
          case 1:
            setLesson({
              parrafo:"celebrará la elección local",
            });
            break;
          case 2:
            setLesson({
              parrafo:"Gubernatura",
            });
            break;
          case 3:
            setLesson({
              parrafo:"<li>Preparación de la Elección</li><li>Jornada Electoral</li><li>Resultados y declaración de validez de la elección</li>",
            });
            break;
          case 4:
            setLesson({
              parrafo:"Consejo General del IEE",
            });
            break;
          case 5:
            setLesson({
              parrafo:"18 Consejos Distritales Electorales del IEE",
            });
            break;
          case 6:
            setLesson({
              parrafo:"la primera semana del mes de febrero del año de la elección",
            });
            break;
          case 7:
            setLesson({
              parrafo:"3",
            });
            break;
          case 8:
            setLesson({
              parrafo:"La boleta para la elección es aprobada por el Consejo General del IEE",
            });
            break;
          case 9:
            setLesson({
              parrafo:"la elección de Gubernatura",
            });
            break;
          case 10:
            setLesson({
              parrafo:"Resultados y declaración de validez de la elección",
            });
            break;
          case 11:
            setLesson({
              parrafo:"los consejos distritales electorales",
            });
            break;
          case 12:
            setLesson({
              parrafo:"las resoluciones que, en su caso, emita en última instancia el órgano jurisdiccional correspondiente",
            });
            break;
          case 13:
            setLesson({
              parrafo:"la elección",
            });
            break;
          case 14:
            setLesson({
              parrafo:"gubernatura",
            });
            break;
          case 15:
            setLesson({
              parrafo:"la elección",
            });
            break;
          case 16:
            setLesson({
              parrafo:"Consejos Distritales Electorales del IEE",
            });
            break;
          case 17:
            setLesson({
              parrafo:"Para la Elección de Gubernatura serán los Consejos Distritales Electorales y el Consejo General del IEE.",
            });
            break;
          case 18:
            setLesson({
              parrafo:"especial",
            });
            break;
          case 19:
            setLesson({
              parrafo:"Cómputo con recuento total al inicio.",
            });
            break;
          case 20:
            setLesson({
              parrafo:"",
            });
            break;
          case 21:
            setLesson({
              parrafo:"",
            });
            break;
          case 22:
            setLesson({
              parrafo:"",
            });
            break;
          case 23:
            setLesson({
              parrafo:"",
            });
            break;
          case 24:
            setLesson({
              parrafo:"Gubernatura",
            });
            break;
          case 25:
            setLesson({
              parrafo:"el Código Electoral para el Estado de Aguascalientes",
            });
            break;
          case 26:
            setLesson({
              parrafo:"Tribunal Electoral del Estado de Aguascalientes",
            });
            break;
          default:
            return setLesson({});
        }
      break;
      //Completa durango
      case 10:
        switch (idParrafo) {
          case 1:
            setLesson({
              parrafo:"celebrarán las elecciones locales",
            });
            break;
          case 2:
            setLesson({
              parrafo:"Gubernatura y Ayuntamientos",
            });
            break;
          case 3:
            setLesson({
              parrafo:"<li>Preparación de la Elección</li><li>Jornada Electoral</li><li>Resultados y declaración de validez de la elección</li>",
            });
            break;
          case 4:
            setLesson({
              parrafo:"Consejo General del IEPC",
            });
            break;
          case 5:
            setLesson({
              parrafo:"39 Consejos Municipales, 9 de ellos fungirán como Consejos Municipales Cabecera de Distrito Local Electoral del IEPC",
            });
            break;
          case 6:
            setLesson({
              parrafo:"la primera semana de enero de 2022",
            });
            break;
          case 7:
            setLesson({
              parrafo:"4",
            });
            break;
          case 8:
            setLesson({
              parrafo:"Las boletas para las elecciones son aprobadas por el Consejo General del IEPC",
            });
            break;
          case 9:
            setLesson({
              parrafo:"las elecciones de Gubernatura y Ayuntamiento",
            });
            break;
          case 10:
            setLesson({
              parrafo:"Resultados y declaración de validez de las elecciones",
            });
            break;
          case 11:
            setLesson({
              parrafo:"los consejos municipales",
            });
            break;
          case 12:
            setLesson({
              parrafo:"los cómputos y declaraciones de validez de las elecciones por los órganos electorales competentes del IEPC, o con las resoluciones que, en su caso, emita en última instancia el órgano jurisdiccional correspondiente",
            });
            break;
          case 13:
            setLesson({
              parrafo:"las elecciones",
            });
            break;
          case 14:
            setLesson({
              parrafo:"gubernatura",
            });
            break;
          case 15:
            setLesson({
              parrafo:"las elecciones",
            });
            break;
          case 16:
            setLesson({
              parrafo:"Consejos Municipales del IEPC",
            });
            break;
          case 17:
            setLesson({
              parrafo:"<p>Para la Elección de Ayuntamientos serán los Consejos Municipales del IEPC.</p><p>Para la Elección de Gubernatura respecto al cómputo distrital, serán los Consejos Municipales Cabecera de Distrito Local Electoral y respecto al cómputo estatal será el Consejo General del IEPC.</p>",
            });
            break;
          case 18:
            setLesson({
              parrafo:"especial",
            });
            break;
          case 19:
            setLesson({
              parrafo:"Cómputo con recuento total al inicio.",
            });
            break;
          case 20:
            setLesson({
              parrafo:"<p><b>Asignación de Regidurías de Representación Proporcional</b></p>",
            });
            break;
          case 21:
            setLesson({
              parrafo:"<p>Al finalizar el cómputo municipal, se expedirá la constancia de mayoría a las candidaturas a la Presidencia y Sindicatura Municipal que hayan obtenido la mayoría de los votos, así como a quienes se les hubieren asignado regidurías, y se hará la declaratoria de validez de la elección conforme al Cómputo Municipal.</p>",
            });
            break;
          case 22:
            setLesson({
              parrafo:"<p><b>Cómputo distrital para la elección de Gubernatura</b></p>",
            });
            break;
          case 23:
            setLesson({
              parrafo:"<p>Una vez concluido el cómputo para la elección de Ayuntamientos, los Consejos Municipales de: Durango, Pueblo Nuevo, Santiago Papasquiaro, El Oro,  Mapimí, Gómez Palacio, Lerdo, Cuencamé y Nombre de Dios; pasan a ser denominados Consejos Municipales Cabecera de Distrito Local Electoral, con el propósito de efectuar el cómputo distrital de la elección de Gubernatura, para lo cual se seguirá el mismo procedimiento que para el cómputo de la elección de Ayuntamiento.</p><p>Finalizado el cómputo, el Consejo Municipal Cabecera de Distrito, levantará el acta de cómputo, haciendo constar en ella las operaciones practicadas, las objeciones que se hayan presentado y el resultado de la elección.</p><p><b>Cómputo estatal para la elección de Gubernatura</b></p><p>En Sesión Especial el Consejo General del IEPC determinará mediante la suma de los resultados anotados en las actas de cómputo distrital, la votación obtenida en la elección para la Gubernatura.</p><p><b>Conclusión del Proceso Electoral</b></p><p>El Proceso Electoral Local 2021-2022 concluye con las determinaciones sobre la validez de las elecciones correspondientes y el otorgamiento o asignación de constancias que realicen los Consejos del IEPC o con las resoluciones que, en su caso, se pronuncien a nivel jurisdiccional.</p>",
            });
            break;
          case 24:
            setLesson({
              parrafo:"Gubernatura, Ayuntamientos o Legislaturas locales",
            });
            break;
          case 25:
            setLesson({
              parrafo:"Ley de Medios de Impugnación en Materia Electoral y de Participación Ciudadana para el Estado de Durango",
            });
            break;
          case 26:
            setLesson({
              parrafo:"Tribunal Electoral del Estado de Durango",
            });
            break;
          default:
            return setLesson({});
        }
      break;
      //Completa Hidalgo
      case 13:
        switch (idParrafo) {
          case 1:
            setLesson({
              parrafo:"celebrará la elección local",
            });
            break;
          case 2:
            setLesson({
              parrafo:"Gubernatura",
            });
            break;
          case 3:
            setLesson({
              parrafo:"<li>Preparación de la Elección</li><li>Jornada Electoral</li><li>Resultados Electorales</li><li>Cómputo y Declaración de Validez de las Elecciones</li><li>Conclusión del Proceso Electoral</li>",
            });
            break;
          case 4:
            setLesson({
              parrafo:"Consejo General del IEEH",
            });
            break;
          case 5:
            setLesson({
              parrafo:"18 Consejos Distritales del IEEH",
            });
            break;
          case 6:
            setLesson({
              parrafo:"el 10 de enero de 2022",
            });
            break;
          case 7:
            setLesson({
              parrafo:"7",
            });
            break;
          case 8:
            setLesson({
              parrafo:"La boleta para la elección es aprobada por el Consejo General del IEEH",
            });
            break;
          case 9:
            setLesson({
              parrafo:"la elección de Gubernatura",
            });
            break;
          case 10:
            setLesson({
              parrafo:"Resultados Electorales",
            });
            break;
          case 11:
            setLesson({
              parrafo:"los Consejos Distritales del IEEH",
            });
            break;
          case 12:
            setLesson({
              parrafo:"la publicación de los resultados preliminares",
            });
            break;
          case 13:
            setLesson({
              parrafo:"la elección",
            });
            break;
          case 14:
            setLesson({
              parrafo:"gubernatura",
            });
            break;
          case 15:
            setLesson({
              parrafo:"la elección",
            });
            break;
          case 16:
            setLesson({
              parrafo:"Consejos Distritales del IEEH",
            });
            break;
          case 17:
            setLesson({
              parrafo:"Para la Elección de Gubernatura serán los Consejos Distritales.",
            });
            break;
          case 18:
            setLesson({
              parrafo:"especial",
            });
            break;
          case 19:
            setLesson({
              parrafo:"Cómputo con recuento total al inicio.",
            });
            break;
          case 20:
            setLesson({
              parrafo:"<p><b>Cuarta etapa: Cómputo y Declaración de Validez de las Elecciones</b></p>",
            });
            break;
          case 21:
            setLesson({
              parrafo:"<p>Inicia al resolverse el último de los medios de impugnación que se hubiesen interpuesto en contra de esta elección o cuando se tenga constancia de que no se presentó ninguno y concluye al aprobar el Tribunal, el dictamen que contenga el cómputo final y la declaración de validez de la elección de Gubernatura.</p>",
            });
            break;
          case 22:
            setLesson({
              parrafo:"<p><b>Quinta etapa: Conclusión del Proceso Electoral.</b></p>",
            });
            break;
          case 23:
            setLesson({
              parrafo:"<p>El Proceso Electoral Local 2021-2022 concluye con la determinación sobre la validez de la elección correspondiente y el otorgamiento o asignación de la constancia que realice el Consejo General del IEEH o con las resoluciones que, en su caso, se pronuncien a nivel jurisdiccional.</p>",
            });
            break;
          case 24:
            setLesson({
              parrafo:"Gubernatura, Legislaturas Locales o Ayuntamientos",
            });
            break;
          case 25:
            setLesson({
              parrafo:"el Código Electoral del Estado de Hidalgo y en la Ley General del Sistema de Medios de Impugnación en Materia Electoral",
            });
            break;
          case 26:
            setLesson({
              parrafo:"Tribunal Electoral del Estado de Hidalgo",
            });
            break;
          default:
            return setLesson({});
        }
      break;
      //completa oaxaca
      case 20:
        switch (idParrafo) {
          case 1:
            setLesson({
              parrafo:"celebrará la elección local",
            });
            break;
          case 2:
            setLesson({
              parrafo:"Gubernatura",
            });
            break;
          case 3:
            setLesson({
              parrafo:"<li>Preparación de la Elección</li><li>Jornada Electoral</li><li>Resultados y declaración de validez de la elección</li><li>Dictamen y declaración de validez de la elección y de gubernatura electa</li><li>Conclusión del proceso electoral</li>",
            });
            break;
          case 4:
            setLesson({
              parrafo:"Consejo General del IEEPCO",
            });
            break;
          case 5:
            setLesson({
              parrafo:"25 Consejos Distritales del IEEPCO",
            });
            break;
          case 6:
            setLesson({
              parrafo:"el 30 de noviembre de 2021",
            });
            break;
          case 7:
            setLesson({
              parrafo:"10",
            });
            break;
          case 8:
            setLesson({
              parrafo:"La boleta para la elección es aprobada por el Consejo General del IEEPCO",
            });
            break;
          case 9:
            setLesson({
              parrafo:"la elección de Gubernatura",
            });
            break;
          case 10:
            setLesson({
              parrafo:"Resultados y declaración de validez de la elección",
            });
            break;
          case 11:
            setLesson({
              parrafo:"los consejos distritales del IEEPCO",
            });
            break;
          case 12:
            setLesson({
              parrafo:"las resoluciones que, en su caso, emita en última instancia el Tribunal Electoral del Estado de Oaxaca",
            });
            break;
          case 13:
            setLesson({
              parrafo:"la elección",
            });
            break;
          case 14:
            setLesson({
              parrafo:"gubernatura",
            });
            break;
          case 15:
            setLesson({
              parrafo:"la elección",
            });
            break;
          case 16:
            setLesson({
              parrafo:"Consejos Distritales del IEEPCO",
            });
            break;
          case 17:
            setLesson({
              parrafo:"Para la Elección de Gubernatura serán los Consejos Distritales y el Consejo General del IEEPCO.",
            });
            break;
          case 18:
            setLesson({
              parrafo:"especial",
            });
            break;
          case 19:
            setLesson({
              parrafo:"Cómputo con recuento total al inicio.",
            });
            break;
          case 20:
            setLesson({
              parrafo:"<p><b>Cuarta etapa: Dictamen y declaración de validez de la elección y de Gubernatura electa</b></p>",
            });
            break;
          case 21:
            setLesson({
              parrafo:"<p>El Consejo General del IEEPCO sesionará con el objeto de hacer el cómputo general de la elección de Gubernatura. Concluido el procedimiento, el Instituto Estatal Electoral y de Participación Ciudadana de Oaxaca declarará la validez de la elección de Gubernatura y expedirá la constancia de mayoría correspondiente luego remitirá los expedientes y la documentación respectiva al Tribunal Electoral.</p>",
            });
            break;
          case 22:
            setLesson({
              parrafo:"<p><b>Quinta etapa: Conclusión del Proceso Electoral.</b></p>",
            });
            break;
          case 23:
            setLesson({
              parrafo:"<p>El Proceso Electoral Local 2021-2022 concluye al resolverse el último de los medios de impugnación que se hubieren interpuesto en contra de la elección de Gubernatura, o cuando se tenga constancia de que no se presentó ninguno y culmina al aprobar el Tribunal el dictamen que contenga el cómputo final, la calificación de la elección y la declaración de Gobernadora o Gobernador Electo.</p>",
            });
            break;
          case 24:
            setLesson({
              parrafo:"Gubernatura",
            });
            break;
          case 25:
            setLesson({
              parrafo:"Ley del Sistema de Medios de Impugnación en Materia Electoral y de Participación Ciudadana para el Estado de Oaxaca",
            });
            break;
          case 26:
            setLesson({
              parrafo:"Tribunal Electoral del Estado de Oaxaca",
            });
            break;
          default:
            return setLesson({});
        }
      break;
      //Completa Quintana Roo
      case 23:
        switch (idParrafo) {
          case 1:
            setLesson({
              parrafo:"celebrarán las elecciones locales",
            });
            break;
          case 2:
            setLesson({
              parrafo:"Gubernatura y Diputaciones",
            });
            break;
          case 3:
            setLesson({
              parrafo:"<li>Preparación de la Elección</li><li>Jornada Electoral</li><li>Resultados y declaraciones de validez de la elección</li><li>Dictamen y Declaración de Validez de la Elección y de Gubernatura Electa</li><li>Conclusión del Proceso Electoral</li>",
            });
            break;
          case 4:
            setLesson({
              parrafo:"Consejo General del IEQROO",
            });
            break;
          case 5:
            setLesson({
              parrafo:"15 Consejos Distritales del IEQROO",
            });
            break;
          case 6:
            setLesson({
              parrafo:"el 7 de febrero de 2022",
            });
            break;
          case 7:
            setLesson({
              parrafo:"4",
            });
            break;
          case 8:
            setLesson({
              parrafo:"Las boletas para las elecciones son aprobadas por el Consejo General del IEQROO",
            });
            break;
          case 9:
            setLesson({
              parrafo:"las elecciones de Gubernatura y Diputaciones Locales",
            });
            break;
          case 10:
            setLesson({
              parrafo:"<p>Resultados y declaraciones de validez de las Elección</p>",
            });
            break;
          case 11:
            setLesson({
              parrafo:"los consejos distritales",
            });
            break;
          case 12:
            setLesson({
              parrafo:"las resoluciones que, en su caso, emita en última instancia el órgano jurisdiccional correspondiente",
            });
            break;
          case 13:
            setLesson({
              parrafo:"las elecciones",
            });
            break;
          case 14:
            setLesson({
              parrafo:"gubernatura",
            });
            break;
          case 15:
            setLesson({
              parrafo:"las elecciones",
            });
            break;
          case 16:
            setLesson({
              parrafo:"Consejos Distritales del IEQROO",
            });
            break;
          case 17:
            setLesson({
              parrafo:"<p>Para la Elección de Diputaciones Locales será el Consejo Distrital.</p><p>Para la Elección de Gubernatura será el Consejo Distrital y el Consejo General del IEQROO.</p>",
            });
            break;
          case 18:
            setLesson({
              parrafo:"especial",
            });
            break;
          case 19:
            setLesson({
              parrafo:"Cómputo con recuento total al inicio.",
            });
            break;
          case 20:
            setLesson({
              parrafo:"<p><b>Cómputo y de la Asignación de Diputaciones de Representación Proporcional</b></p>",
            });
            break;
          case 21:
            setLesson({
              parrafo:"El cómputo para la asignación de diputaciones por el principio de Representación Proporcional es la suma que realiza el Consejo General del IEQROO de los resultados anotados en las actas levantadas con motivo del cómputo distrital, a fin de determinar la votación obtenida en dicha elección por cada partido político.",
            });
            break;
          case 22:
            setLesson({
              parrafo:"<p><b>Cuarta etapa: Dictamen y declaración de validez de la elección  y de Gubernatura electa</b></p>",
            });
            break;
          case 23:
            setLesson({
              parrafo:"<p>Inicia al resolverse el último de los medios de impugnación que se hubiesen interpuesto en contra de esta elección o cuando se tenga constancia de que no se presentó ninguno y concluye al aprobar el Tribunal, el dictamen que contenga el cómputo final y las declaraciones de validez de la elección de Gubernatura.</p><p><b>Quinta etapa: Conclusión del Proceso Electoral</b></p><p>El Proceso Electoral Local 2021-2022 concluye con las determinaciones sobre la validez de la elección correspondiente y el otorgamiento o asignación de constancias que realicen los Consejos Distritales del IEQROO o con las resoluciones que, en su caso, se pronuncien a nivel jurisdiccional.</p>",});
            break;
          case 24:
            setLesson({
              parrafo:"Gubernatura o Legislaturas locales",
            });
            break;
          case 25:
            setLesson({
              parrafo:"la Ley Estatal de Medios de Impugnación en Materia Electoral",
            });
            break;
          case 26:
            setLesson({
              parrafo:"Tribunal Electoral de Quintana Roo",
            });
            break;
          default:
            return setLesson({});
        }
      break;
      //completa tamaulipas
      case 28:
        switch (idParrafo) {
          case 1:
            setLesson({
              parrafo:"celebrará la elección local",
            });
            break;
          case 2:
            setLesson({
              parrafo:"Gubernatura",
            });
            break;
          case 3:
            setLesson({
              parrafo:"<li>Preparación de la Elección</li><li>Jornada Electoral</li><li>Resultados y declaraciones de validez de la elección</li>",
            });
            break;
          case 4:
            setLesson({
              parrafo:"Consejo General del IETAM",
            });
            break;
          case 5:
            setLesson({
              parrafo:"22 Consejos Distritales del IETAM",
            });
            break;
          case 6:
            setLesson({
              parrafo:"la primera semana del mes de febrero de 2022",
            });
            break;
          case 7:
            setLesson({
              parrafo:"9",
            });
            break;
          case 8:
            setLesson({
              parrafo:"La boleta para la elección es aprobada por el Consejo General del IETAM",
            });
            break;
          case 9:
            setLesson({
              parrafo:"la elección de Gubernatura",
            });
            break;
          case 10:
            setLesson({
              parrafo:"Resultados y declaraciones de validez de la elección",
            });
            break;
          case 11:
            setLesson({
              parrafo:"los consejos distritales del IETAM",
            });
            break;
          case 12:
            setLesson({
              parrafo:"las resoluciones que, en su caso, emita en última instancia el órgano jurisdiccional correspondiente",
            });
            break;
          case 13:
            setLesson({
              parrafo:"la elección",
            });
            break;
          case 14:
            setLesson({
              parrafo:"gubernatura",
            });
            break;
          case 15:
            setLesson({
              parrafo:"la elección",
            });
            break;
          case 16:
            setLesson({
              parrafo:"Consejos Distritales del IETAM",
            });
            break;
          case 17:
            setLesson({
              parrafo:"Para la Elección de Gubernatura serán los Consejos Distritales y el Consejo General del IETAM.",
            });
            break;
          case 18:
            setLesson({
              parrafo:"",
            });
            break;
          case 19:
            setLesson({
              parrafo:"Cómputo con recuento total al inicio.",
            });
            break;
          case 20:
            setLesson({
              parrafo:"<p><b>Resultados y declaración de validez de la elección de Gobernadora o Gobernador electo</b></p>",
            });
            break;
          case 21:
            setLesson({
              parrafo:"Se inicia al resolverse el último de los medios de impugnación que se hubiesen interpuesto en contra de esta elección o cuando se tenga constancia de que no se presentó ninguno y concluye al aprobar el Tribunal, el dictamen que contenga el cómputo final y la declaración de validez de la elección de Gubernatura.",
            });
            break;
          case 22:
            setLesson({
              parrafo:"",
            });
            break;
          case 23:
            setLesson({
              parrafo:"",
            });
            break;
          case 24:
            setLesson({
              parrafo:"Gubernatura",
            });
            break;
          case 25:
            setLesson({
              parrafo:"la Ley Electoral del Estado de Tamaulipas",
            });
            break;
          case 26:
            setLesson({
              parrafo:"Tribunal Electoral del Estado de Tamaulipas",
            });
            break;
          default:
            return setLesson({});
        }
      break;
      default:
        return setLesson({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return lesson;
};
