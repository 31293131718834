import React, { useState, useEffect } from 'react';
import { Table, Input, Select, Row, Icon, Button, Col } from 'antd';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { saveExcelOrg } from '../../../api/xls';
import "./Reports.scss";

function TableOrg(props) {

    const { data, loading } = props;//Información del reporte de Organizaciones
    const [dataSourceOrg, setDataSourceOrg] = useState([]); // hook para almacenar el array
    const [valueOrg, setValueOrg] = useState("");
    const [valueSig, setValueSig] = useState("");
    const [valueRep, setValueRep] = useState("");
    const [valueFec, setValueFec] = useState("");
    const [valueAp, setValueAp] = useState("");
    const [valueCod, setValueCod] = useState("");
    const [valueTipo, setValueTipo] = useState("");
    const [valueEntidad, setValueEntidad] = useState("");
    const [valueRfc, setValueRfc] = useState("");
    const [valueTel, setValueTel] = useState("");
    const [valueCorreo, setValueCorreo] = useState("");
    const { Option } = Select;

    useEffect(() => {
        setDataSourceOrg(data); //se inicializa el array
    }, [data]);

    //Filtros 
    const FilterByNameOrg = (
        <>
            Nombre de la organización
            <Input
                placeholder="Nombre de la organización"
                value={valueOrg}
                onChange={(e) => {
                    const currValue = e.target.value;
                    setValueAp("");
                    setValueFec("");
                    setValueRep("");
                    setValueSig("");
                    setValueCod("");
                    setValueTipo("");
                    setValueEntidad("");
                    setValueRfc("");
                    setValueTel("");
                    setValueCorreo("");
                    setValueOrg(currValue);
                    const filteredData = data.filter((entry) =>
                        entry.organizacion
                            .toString()
                            .toLowerCase()
                            .includes(currValue.toLowerCase())
                    );
                    setDataSourceOrg(filteredData);
                }}
            />
        </>
    );

    const FilterBySiglas = (
        <>
            Siglas
            <Input
                placeholder="Siglas"
                value={valueSig}
                onChange={(e) => {
                    const currValue = e.target.value;
                    setValueAp("");
                    setValueFec("");
                    setValueRep("");
                    setValueOrg("");
                    setValueCod("");
                    setValueTipo("");
                    setValueEntidad("");
                    setValueRfc("");
                    setValueTel("");
                    setValueCorreo("");
                    setValueSig(currValue);
                    const filteredData = data.filter((entry) =>
                        entry.siglas
                            .toString()
                            .toLowerCase()
                            .includes(currValue.toLowerCase())
                    );
                    setDataSourceOrg(filteredData);
                }}
            />
        </>
    );

    const FilterByRepre = (
        <>
            Representante legal
            <Input
                placeholder="Representante legal"
                value={valueRep}
                onChange={(e) => {
                    const currValue = e.target.value;
                    setValueAp("");
                    setValueFec("");
                    setValueSig("");
                    setValueOrg("");
                    setValueCod("");
                    setValueTipo("");
                    setValueEntidad("");
                    setValueRfc("");
                    setValueTel("");
                    setValueCorreo("");
                    setValueRep(currValue);
                    const filteredData = data.filter((entry) =>
                        entry.nombreRepre
                            .toString()
                            .toLowerCase()
                            .includes(currValue.toLowerCase())
                    );
                    setDataSourceOrg(filteredData);
                }}
            />
        </>
    );
    const FilterByFecha = (
        <>
            Fecha de alta
            <Input
                placeholder="dd/mm/aaaa"
                value={valueFec}
                onChange={(e) => {
                    const currValue = e.target.value;
                    setValueAp("");
                    setValueRep("");
                    setValueSig("");
                    setValueOrg("");
                    setValueCod("");
                    setValueTipo("");
                    setValueEntidad("");
                    setValueRfc("");
                    setValueTel("");
                    setValueCorreo("");
                    setValueFec(currValue);
                    const filteredData = data.filter((entry) =>
                        moment(entry.fechaAlta).format("DD/MM/YYYY").includes(currValue)
                    );
                    setDataSourceOrg(filteredData);
                }}
            />
        </>
    );

    const FilterByApoyo = (
        <>
            ¿Solicita apoyo económico?

            <Select
                style={{ width: 150 }}
                placeholder="Todos"
                optionFilterProp="children"
                value={valueAp}
                onChange={(value) => {
                    const currValue = value;
                    setValueFec("");
                    setValueRep("");
                    setValueSig("");
                    setValueOrg("");
                    setValueCod("");
                    setValueTipo("");
                    setValueEntidad("");
                    setValueRfc("");
                    setValueTel("");
                    setValueCorreo("");
                    setValueAp(currValue);
                    const filteredData = data.filter((entry) =>
                        entry.apoyoEconomico
                            .toString()
                            .toLowerCase()
                            .includes(currValue.toLowerCase())
                    );
                    setDataSourceOrg(filteredData);
                }}
            >
                <Option value="">Todos</Option>
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
        </>
    );


    const FilterByCodigo = (
        <>
            Código
            <Input
                placeholder="Código"
                value={valueCod}
                onChange={(e) => {
                    const currValue = e.target.value;
                    setValueAp("");
                    setValueFec("");
                    setValueRep("");
                    setValueOrg("");
                    setValueTipo("");
                    setValueEntidad("");
                    setValueRfc("");
                    setValueTel("");
                    setValueCorreo("");
                    setValueCod(currValue);
                    const filteredData = data.filter((entry) =>
                        entry.codigo
                            .toString()
                            .toLowerCase()
                            .includes(currValue.toLowerCase())
                    );
                    setDataSourceOrg(filteredData);
                }}
            />
        </>
    );

    const FilterByTipo = (
        <>
            Tipo
            <Input
                placeholder="Tipo"
                value={valueTipo}
                onChange={(e) => {
                    const currValue = e.target.value;
                    setValueAp("");
                    setValueFec("");
                    setValueRep("");
                    setValueOrg("");
                    setValueCod("");
                    setValueEntidad("");
                    setValueRfc("");
                    setValueTel("");
                    setValueCorreo("");
                    setValueTipo(currValue);
                    const filteredData = data.filter((entry) =>
                        entry.tipoOrganizacion
                            .toString()
                            .toLowerCase()
                            .includes(currValue.toLowerCase())
                    );
                    setDataSourceOrg(filteredData);
                }}
            />
        </>
    );

    const FilterByEntidad = (
        <>
            Entidad
            <Input
                placeholder="Entidad"
                value={valueEntidad}
                onChange={(e) => {
                    const currValue = e.target.value;
                    setValueAp("");
                    setValueFec("");
                    setValueRep("");
                    setValueOrg("");
                    setValueCod("");
                    setValueTipo("");
                    setValueRfc("");
                    setValueTel("");
                    setValueCorreo("");
                    setValueEntidad(currValue);
                    const filteredData = data.filter((entry) =>
                        entry.nombreEstado
                            .toString()
                            .toLowerCase()
                            .includes(currValue.toLowerCase())
                    );
                    setDataSourceOrg(filteredData);
                }}
            />
        </>
    );

    const FilterByRfc = (
        <>
            RFC
            <Input
                placeholder="RFC"
                value={valueRfc}
                onChange={(e) => {
                    const currValue = e.target.value;
                    setValueAp("");
                    setValueFec("");
                    setValueRep("");
                    setValueOrg("");
                    setValueCod("");
                    setValueTipo("");
                    setValueEntidad("");
                    setValueTel("");
                    setValueCorreo("");
                    setValueRfc(currValue);
                    const filteredData = data.filter((entry) =>
                        entry.rfc
                            .toString()
                            .toLowerCase()
                            .includes(currValue.toLowerCase())
                    );
                    setDataSourceOrg(filteredData);
                }}
            />
        </>
    );

    const FilterByTelefono = (
        <>
            Teléfono
            <Input
                placeholder="Teléfono"
                value={valueTel}
                onChange={(e) => {
                    const currValue = e.target.value;
                    setValueAp("");
                    setValueFec("");
                    setValueRep("");
                    setValueOrg("");
                    setValueCod("");
                    setValueTipo("");
                    setValueEntidad("");
                    setValueRfc("");
                    setValueCorreo("");
                    setValueTel(currValue);
                    const filteredData = data.filter((entry) =>
                        entry.telefono
                            .toString()
                            .toLowerCase()
                            .includes(currValue.toLowerCase())
                    );
                    setDataSourceOrg(filteredData);
                }}
            />
        </>
    );

    const FilterByCorreo = (
        <>
            Correo
            <Input
                placeholder="Correo"
                value={valueCorreo}
                onChange={(e) => {
                    const currValue = e.target.value;
                    setValueAp("");
                    setValueFec("");
                    setValueRep("");
                    setValueOrg("");
                    setValueCod("");
                    setValueTipo("");
                    setValueEntidad("");
                    setValueRfc("");
                    setValueTel("");
                    setValueCorreo(currValue);
                    const filteredData = data.filter((entry) =>
                        entry.correo
                            .toString()
                            .toLowerCase()
                            .includes(currValue.toLowerCase())
                    );
                    setDataSourceOrg(filteredData);
                }}
            />
        </>
    );

    //se definen las columnas de la tabla de organizaciones
    const columns = [
        {
            title: FilterByCodigo,
            dataIndex: "codigo",
            key: "codigo"
        },
        {
            title: FilterByTipo,
            dataIndex: "tipoOrganizacion",
            key: "tipoOrganizacion"
        },
        {
            title: FilterByNameOrg,
            dataIndex: "organizacion",
            key: "organizacion"
        },
        {
            title: FilterByEntidad,
            dataIndex: "nombreEstado",
            key:"nombreEstado"
        },
        {
            title: FilterBySiglas,
            dataIndex: "siglas",
            key: "siglas"
        },
        {
            title: FilterByRfc,
            dataIndex: "rfc",
            key: "rfc"
        },
        {
            title: FilterByApoyo,
            dataIndex: "apoyoEconomico",
            key: "apoyoEconomico"
        },
        {
            title: FilterByRepre,
            dataIndex: "nombreRepre",
            key: "nombreRepre"
        },
        {
            title: FilterByTelefono,
            dataIndex: "telefono",
            key: "telefono"
        },
        {
            title: FilterByCorreo,
            dataIndex: "correo",
            key: "correo"
        },
        {
            title: FilterByFecha,
            dataIndex: "fechaAlta",
            render: (record) => moment(record).format("DD/MM/YYYY")
        },
        {
            title: "Domicilio",
            dataIndex: "domicilio",
            key: "domicilio"
        }

    ]

    const downloadExcel = () => {
        saveExcelOrg("Organizaciones", "Reporte de Organizaciones", dataSourceOrg);
    }

    function validaBoton() {
        var band = true;
        if(dataSourceOrg !== undefined && dataSourceOrg !== null) {
            if(dataSourceOrg.length > 0) {
                band = false;
                return band;
            } else
                return band;
        }
        return band;
    }

    return (
        <div>
            <Row>
                <Col span={12}>
                    <div style={{ fontWeight:"bold", fontSize:"14px" }}>
                        Descarga el reporte:
                        <Button onClick={() => downloadExcel()} className="btn_descarga_reporte" type="link"
                                disabled={validaBoton()}>
                            <Icon type="file-excel" />Excel
                        </Button>
                    </div>
                </Col>
                <Col span={12}>
                    <div style={{ position: "absolute", right: "0" }}>
                        Organizaciones
                    </div>
                </Col>
            </Row>
            <Row>
                <Table
                    columns={columns}
                    dataSource={dataSourceOrg}
                    loading={loading}
                    locale={{ emptytext: "Sin registros" }}
                    scroll={{ x: 1700 }} />
            </Row>
        </div >
    );
}

export default withRouter(TableOrg);